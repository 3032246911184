// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.full-screen-table-container.invoice-confirm {
  padding-bottom: 80px;
}
.full-screen-table-container.invoice-confirm .admin-blue-btn, .full-screen-table-container.invoice-confirm .admin-black-btn {
  padding: 16px 24px;
}
.full-screen-table-container.invoice-confirm .text-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.full-screen-table-container.invoice-confirm .text-title h3 {
  text-transform: capitalize;
}
.full-screen-table-container.invoice-confirm .text-title .btns {
  display: flex;
  gap: 30px;
}
.full-screen-table-container.invoice-confirm .card {
  padding: 50px 40px 50px 40px;
  box-shadow: 0px 4px 208px 0px rgba(72, 72, 72, 0.0705882353);
  border: none;
  color: #212429;
  gap: 28px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/InvoiceConfirm/InvoiceConfirm.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;AACJ;AAAI;EACI,kBAAA;AAER;AAAI;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AAER;AADQ;EACA,0BAAA;AAGR;AADQ;EACI,aAAA;EACA,SAAA;AAGZ;AAAI;EACI,4BAAA;EACA,4DAAA;EACA,YAAA;EACA,cAAA;EACA,SAAA;EAEA,cAAA;AACR","sourcesContent":[".full-screen-table-container.invoice-confirm{\r\n    padding-bottom: 80px;\r\n    .admin-blue-btn, .admin-black-btn{\r\n        padding: 16px 24px;\r\n    }\r\n    .text-title {\r\n        display: flex;\r\n        justify-content: space-between;\r\n        align-items: center;\r\n        h3{\r\n        text-transform: capitalize;\r\n        }\r\n        .btns{\r\n            display: flex;\r\n            gap: 30px;\r\n        }\r\n    }\r\n    .card{\r\n        padding: 50px 40px 50px 40px;\r\n        box-shadow: 0px 4px 208px 0px #48484812;\r\n        border: none;\r\n        color: #212429;\r\n        gap: 28px;\r\n        // width: 1078px;\r\n        margin: 0 auto;\r\n       \r\n       \r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
