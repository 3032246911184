import React, { useEffect, useState } from "react";
import './AccordionSection.scss'
import { useNavigate, Link } from 'react-router-dom';
import $ from 'jquery';

const AccordionSection =({headingWeb, headingTab, headingMob, description, accordionData})=>{
  const [activeSection, setActiveSection] = useState(null);
  const handleSectionClick = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

const renderHTML = (text) => ({ __html: text });

    return(


      <div className='accordion-section'>
      {/* <div className='inner'>
        <h1 className="only-web" dangerouslySetInnerHTML={renderHTML(headingWeb)}></h1>
        <h1 className="only-tab" dangerouslySetInnerHTML={renderHTML(headingTab)}></h1>
        <h1 className="only-mob" dangerouslySetInnerHTML={renderHTML(headingMob)}></h1>
        <p  dangerouslySetInnerHTML={renderHTML(description)}></p>

        {accordionData.map((item, index) => {
          return (
            <div className={item.title.length>28?'segment double-lined':"segment"} key={index} onClick={selectedAccordion==index?()=>selectAccordion(null):()=>selectAccordion(index)}>
              <img className="arrow" src="/images/common-components/accordion/arrow-up.svg"></img>
              <h2>{item.title}</h2>
              <p>{item.description}</p>
              {item.link&&<button className='button-black-box' onClick={()=>navigate(item.link)}>LEARN MORE</button>}

            </div>
          )
        })}
      </div> */}

<div className='ash-body'>
 
  <div className="main-head montserrat-hard" dangerouslySetInnerHTML={renderHTML(headingWeb)}></div>
     <div className='sub-head' dangerouslySetInnerHTML={renderHTML(description)}></div>
</div>


<div className='sec6-homepage'>
    <div className='maincontentdiv-sec6-home'>
      {accordionData.map((section, index) => (
        <div className='firstdiv-sec6' key={index}>
          <div className='title-sec6' onClick={() => handleSectionClick(index)}>
            <span className='montserrat-hard'>{section.title}</span>
            <img 
              src="/images/home/Vector (5).svg" 
              alt="Toggle Content" 
              className={activeSection === index ? 'rotate' : ''}
            />
          </div>
          <div className={`content-s6 ${activeSection === index ? 'show' : ''}`}>
            <p>{section.content}</p>
          </div>
        </div>
      ))}
    </div>
    <div className='forimage-sec6-home'>
        {activeSection !== null ? (
          <img 
            src={sections[activeSection].path} 
            className='sec6-img' 
            alt={sections[activeSection].title} 
          />
        ):
        <img src="\images\protect-your-customers-data\Rectangle 185.png" className='sec6-img'/>}
      </div>

    </div>
    </div>
    )
}

export default AccordionSection