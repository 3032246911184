import React, { useEffect, useRef, useState } from 'react'
import './Insurance.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'
import SimpleSection from '../../common-component/SimpleSection/SimpleSection.js'
import ImageOverlapedSection from "../../common-component/ImageOverlapedSection/ImageOverlapedSection"
import SpecialSliderSection from "../../common-component/SpecialSliderSection/SpecialSliderSection"
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'


import { useNavigate } from 'react-router-dom';

import API from "../../../API.js"
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import Slider from 'react-slick'




const Insurance = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
    setIsLoading(true)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        setBlogData(response.data)
        setIsLoading(false)

      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)

      });
  }, [])

  
  const sec3Data = [
    { title: "Cybersecurity Solutions", description: "Fortify digital defences against evolving threats, ensuring data protection and compliance integrity." },
    { title: "RPA Solutions", description: "Accelerate processes and reduce manual effort with advanced Robotic Process Automation (RPA) solutions." },
    { title: "CRM Solutions", description: "Nurture lasting relationships and loyalty through personalised customer experiences with our CRM solutions." }
  ]

  const sec5Data = [
    {
      title: "Next Level Digitisation",
      description: "Elevate your digital transformation with advanced solutions tailored for your insurance business, maximising efficiency and innovation.",
    },
    {
      title: "Optimise Returns",
      description: "Maximise the performance of your investment portfolio with tailored strategies, ensuring optimal returns and sustainable growth.",
    },
    {
      title: "Streamline Payments",
      description: "Simplify and expedite premium collections while ensuring swift payouts, enhancing customer satisfaction and operational efficiency.",
    },
    {
      title: "Revamp Operations",
      description: "Overhaul your treasury and finance operations with cutting-edge technology, streamlining processes and driving agility and profitability.",
    },
    {
      title: "Increase Efficiency",
      description: "Enhance your bottom line by reducing costs and optimising workflows, improving efficiency and productivity across your insurance operations.",
    },



  ]

  
  const sec4Data = [
    { title: "",
     description: "",
     image_url:"/images/why-zog-global/sec-4-1.png"
     },

    { title: "",
     description: "",
    image_url:"/images/why-zog-global/sec-4-2.png" 
  },

    { title: "",
     description: "",
    image_url:"/images/why-zog-global/sec-4-3.png" 
  },
  
  ]

  const sec6Data=[
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    
    
  ]

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };








  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index)
  };

  const sliderRef=useRef(null)
  
    const next = () => {
      sliderRef.current.slickNext();
  };
  
  const previous = () => {
      sliderRef.current.slickPrev();
  };

  const getImageSrc = (index) => {
    switch (index) {
      case 0:
        return "/images/insurance/sec-4-1.png";
      case 1:
        return "/images/insurance/sec-4-2.png";
      case 2:
        return "/images/insurance/sec-4-3.png";
    
      default:
        return "/images/insurance/sec-4-1.png";
    }
  };
  

  useEffect(() => {
    const imgs = document.querySelectorAll(".img-mark");
  
    if (imgs.length === 0) {
      // Exit if there are no images found
      return;
    }
  
    imgs.forEach((img, index) => {
      if (index === currentSlide) {
        img.classList.add("active");
      } else {
        img.classList.remove("active");
      }
    });
  }, [currentSlide]);







  //bluecontainer
  

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
      
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
    ]

  };



  const sliderRef2=useRef(null)
  
    const next2 = () => {
      sliderRef2.current.slickNext();
  };
  
  const previous2 = () => {
      sliderRef2.current.slickPrev();
  };
  const [isToggled, setIsToggled] = useState(false);
  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  const [content1, setContent1]=useState(false);
   
  const cont1btn=()=>{
    setContent1(!content1);
  }


  










  return (
    <div className='insurance-page'>

<BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"insurance"}
        blueTitle={"Insurance"}
        titleWeb={"Next-Gen Insurance<br/>IT Solutions"}
        titleTab={"Next-Gen Insurance<br/> IT Solutions"}
        titleMob={"Next-Gen<br/> Insurance IT<br/> Solutions"}
        subTitleWeb={"Start your journey to digital insurance<br/> excellence."}
        subTitleTab={"Start your journey to digital insurance<br/> excellence."}
        subTitleMob={"Start your journey to digital<br/> insurance excellence."}
        iconTopWeb={'49%'}
        iconLeftWeb={'87%'}
        iconTopTab={'60%'}
        iconLeftTab={'69%'}
        iconTopMob={'65%'}
        iconLeftMob={'60%'}
        iconRotationWeb={149}
        iconRotationTab={135}
        iconRotationMob={149}>
      </BannerSubPages>




      <div className='img-sec2-itoutsource'>

 

<div className='for-text'>
  <span>Embrace Change to Stay Ahead</span>
  <p>Insurance companies face unrelenting pressures to trim costs in today's dynamic landscape. However, the key to attracting and retaining customers, boosting profitability, and staying ahead is ongoing investment in the latest insurance technology solutions.</p>

</div>

<div className='for-img'>
  <img src="\images\insurance\sec-2.png"/>

</div>

</div>



<ImageOverlapedSection 
      pageName={'insurance'} 
      secNo={'3'} 
      imageFirst={false}
      numberPresent={true}
      headingWeb={"Maximising<br/> Business Potential"} 
      headingTab={"Maximising Business Potential"} 
      headingMob={"Maximising<br/> Business<br/> Potential"} 
      descriptionWeb={"At ZOG Global, we empower you to exceed policyholder expectations, safeguard your<br/> business, and outperform competitors."}
      descriptionTab={"At ZOG Global, we empower you to exceed policyholder expectations, safeguard your business, and outperform competitors."}
       descriptionMob={"At ZOG Global, we empower you to<br/> exceed policyholder expectations,<br/> safeguard your business, and<br/> outperform competitors."}

      subHeadding1={"Enhance Operations"} 
      description1={"Upgrade essential processes to achieve optimal performance and reliability in vital operations. We provide robust solutions tailored to enhance your operational capabilities and deliver exceptional service."} 
      subHeadding2={"Improve Efficiency"} 
      description2={"Enhance precision, streamline workflows, and achieve superior results with our solutions. We focus on refining processes to boost efficiency and drive impactful outcomes for your business."} 
      subHeadding3={"Strengthen Compliance"} 
      description3={"Ensure rigorous adherence to regulations and bolster compliance standards to fortify security measures. Our solutions are designed to uphold regulatory requirements and safeguard your business operations effectively."}
      />





<div className='white-head'>
  <div className='headfont'>IT Solutions for the Insurance Industry</div>
  <p>Discover how our innovative approach can streamline operations, enhance security, and drive growth for your insurance business.</p>
  
</div>




<div className='complex-slider'>
  <div className='width50'>
    <div className='arow-cont'>
    <img src="\images\healthcare\arrow-cs.svg" onClick={previous}/>
<img src="\images\healthcare\arrow2-cs.svg" onClick={next}/>
</div>
  <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
        <div>
        <div className='card'>
          <span className='montserrat-hard'>Cybersecurity Solutions</span>
          <p className='montserrat-lit '>
          Fortify digital defences against evolving threats, ensuring data protection and compliance integrity.

          </p>
        </div>
        </div>
        
        <div>
        <div className='card'>
          <span className='montserrat-hard'> RPA Solutions
          </span>
          
          <p className='montserrat-lit '>
          Accelerate processes and reduce manual effort with advanced Robotic Process Automation (RPA) solutions.
          

          </p>
        </div>
        </div>

        <div>
        <div className='card'>
          <span className='montserrat-hard'>
          CRM Solutions
             </span>
          <p className='montserrat-lit '>
          Nurture lasting relationships and loyalty through personalised customer experiences with our CRM solutions.
         
         
         </p>
        </div>
        </div>

        
      </Slider>
    </div>

  </div>
  <div className='width50-2'>
  
 
  {sec3Data.map((_, index) => (
    <img key={index} className='img-mark' src={getImageSrc(index)} alt="Healthcare" />
  ))}
    
    </div>
</div>









<div id="targetDiv"  className={`blueslider-update ${isToggled ? 'black-bg' : ''}`}>

  <div className='head montserrat-hard'>
  Why Our IT
Solutions Stand Out</div>

  <p className='montserrat-lit'></p>
  <div className='combine-btn'>
  <div className='arrow'>

    <img src="\images\protect-your-customers-data\1.svg" onClick={previous2}/>
    <img src="\images\protect-your-customers-data\Frame (1).svg" onClick={next2}/>

  </div>

  {/* <div className="toggle-container">
          <input type="checkbox" id="toggleButton" className="toggle-checkbox" onChange={handleToggleChange} />
          <label htmlFor="toggleButton" className="toggle-label">
            <span className="toggle-button">
            <img src="\images\protect-your-customers-data\icons8-open-lock.svg" className='im1'/>
            <img src="\images\protect-your-customers-data\icons8-lock.svg" className='im2'/>
            </span>
          </label>
        </div> */}
        </div>



       
  <div className='slickblue-update montserrat-lit'>
  <div className="slider-container ">
      <Slider ref={sliderRef2} {...settings2}>
        <div>
         <div className='cardofblue-update'>
          <span> Next Level Digitisation </span>
          <p>Elevate your digital transformation with advanced solutions tailored for your insurance business, maximising efficiency and innovation.</p>
         </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Optimise Returns</span>
        <p>Maximise the performance of your investment portfolio with tailored strategies, ensuring optimal returns and sustainable growth.</p>

        </div>
        </div>
        <div>
        <div className='cardofblue-update'>

        <span>Streamline Payments</span>
        <p>Simplify and expedite premium collections while ensuring swift payouts, enhancing customer satisfaction and operational efficiency.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>
        Revamp Operations
        </span>
        <p>
        Overhaul your treasury and finance operations with cutting-edge technology, streamlining processes and driving agility and profitability.

        </p>
        </div>
        </div>

        <div>
        <div className='cardofblue-update'>
        <span>
        Increase Efficiency
        </span>
        <p>
        Enhance your bottom line by reducing costs and optimising workflows, improving efficiency and productivity across your insurance operations.
        

        </p>
        </div>
        </div>
       
       
        
       
      </Slider>
    </div>
    

  </div>




</div>












<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>




      {/* <SimpleSection
        pageName={"insurance"}
        secNo={"2"}
        headdingPresent={true}
        buttonPresent={false}
        imagePosition={"right"}
        headingWeb={"Embrace Change to<br/> Stay Ahead"}
        headingTab={"Embrace Change to Stay Ahead"}
        headingMob={"Embrace Change to<br/> Stay Ahead"}
        description={"Insurance companies face unrelenting pressures to trim costs in today's dynamic landscape. However, the key to attracting and retaining customers, boosting profitability, and staying ahead is ongoing investment in the latest insurance technology solutions."}
        buttonText={""}
      /> */}


{/* 
<SpecialSliderSection 
      pageName={"insurance"}
      secNo={"4"}
      
      headingBoxPresent={true}
      headingWeb={"IT Solutions for<br/> the Insurance Industry"}
      headingTab={"IT Solutions for<br/> the Insurance Industry"}
      headingMob={"IT Solutions for<br/> the Insurance<br/> Industry"}
      descriptionWeb={"Discover how our innovative approach can streamline operations, enhance security,<br/> and drive growth for your insurance business."}
      descriptionTab={"Discover how our innovative approach can streamline operations, enhance security, and drive growth for your insurance business."}
      descriptionMob={"Discover how our innovative approach can streamline operations, enhance security, and drive growth for your insurance business."}
      boxAlignment={"center"}
      
      subTitle={""}
      data={sec3Data} 
      />


      


      <BlueNotchSection
        headingWeb={"Why Our IT<br/> Solutions Stand Out"}     // <br/>
        headingTab={"Why Our IT Solutions<br/> Stand Out"}
        headingMob={"Why Our IT<br/> Solutions Stand<br/> Out"}
        descriptionPresent={false}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        listData={sec5Data}

      />



 */}

      <div className='sec-7'>

      </div>




      <BottomBanner
        pageName={"insurance"}
        titleWeb={"Let's Discuss<br/> your IT Needs"}
        titleTab={"Let's Discuss your<br/> IT Needs"}
        titleMob={"Let's Discuss<br/> your IT Needs"}
                
        subTitlePresent={true}
        subTitleWeb={"We can help your business<br/> prepare for the future."}
        subTitleTab={"We can help your business<br/> prepare for the future."}
        subTitleMob={"We can help your business<br/> prepare for the future."}

        buttonText={"contact us now"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default Insurance