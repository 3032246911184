import React, { useEffect, useState } from 'react'
import './BeAPartner.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'
import FiveCardsSection from '../../common-component/FiveCardsSection/FiveCardsSection'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection.js'

import { useNavigate } from 'react-router-dom';

import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';




const BeAPartner = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sec3Data = [
    {
      title: "Accelerate Growth",
      description: "Leverage our data-driven insights to identify key market trends and seize strategic opportunities for rapid expansion and increased profitability.",
    },

    {
      title: "Enhanced Customer Solutions",
      description: "Utilise personalised data analytics to anticipate customer needs and deliver bespoke solutions, ensuring unparalleled satisfaction and loyalty.",
    },
    {
      title: "Cultivate Innovation",
      description: "Empower your teams with agile methodologies and collaborative tools, fostering an environment where innovation thrives, and breakthrough ideas emerge.",
    },
    {
      title: "Discover New Opportunities",
      description: "Harness predictive analytics and market intelligence to stay ahead of the competition, uncovering untapped markets and niche segments for growth.",
    },
    


  ]
  const sec4Data = [
    { title: "",
     description: "",
     image_url:"/images/why-zog-global/sec-4-1.png"
     },

    { title: "",
     description: "",
    image_url:"/images/why-zog-global/sec-4-2.png" 
  },

    { title: "",
     description: "",
    image_url:"/images/why-zog-global/sec-4-3.png" 
  },
  
  ]


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };

  return (
    <div className='be-a-partner-page'>

<BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"be-a-partner"}
        blueTitle={"BE A PARTNER"}
        titleWeb={"Let’s Grow Together<br/> with Us"}
        titleTab={"Let’s Grow Together<br/> with Us"}
        titleMob={"Let’s Grow<br/> Together<br/> with Us"}
        subTitleWeb={"We have a track record of standing by<br/> our partners and helping them prosper."}
        subTitleTab={"We have a track record of standing by<br/> our partners and helping them prosper."}
        subTitleMob={"We have a track record of standing<br/> by our partners and helping them<br/> prosper."}
        iconTopWeb={'10%'}
        iconLeftWeb={'89%'}
        iconTopTab={'49%'}
        iconLeftTab={'80%'}
        iconTopMob={'62%'}
        iconLeftMob={'72%'}
        iconRotationWeb={81}
        iconRotationTab={82}
        iconRotationMob={-36}>
      </BannerSubPages>


      
<FiveCardsSection
        pageName={'be-a-partner'}
        secNo={'2'}
        cardHeightWeb={287}
        cardHeightTab={267}
        headingWeb={"Our Diverse Partnership Opportunities"}
        headingTab={"Our Diverse Partnership Opportunities"}
        headingMob={"Our Diverse Partnership Opportunities"}
        description={""}
        

        subHeading1={"Financial Partners"}
        description1={""}
        subHeading2={"Consulting Partners"}
        description2={""}
        subHeading3={"Technology Partners"}
        description3={""}
        subHeading4={"Reseller Partners"}
        description4={""}
        subHeading5={"Outsourcing Partners"}
        description5={""}
      />

      <VioletCardsSection
        pageName={'be-a-partner'}
        headingWeb={"Benefits of Becoming ZOG Global Partner?"}
        headingTab={"Benefits of Becoming ZOG Global Partner?"}
        headingMob={"Benefits of Becoming ZOG Global Partner?"}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        cardsData={ sec3Data}

      />


     




      <BottomBanner
        pageName={"be-a-partner"}
        titleWeb={"Become a<br/> ZOG Global Partner"}
        titleTab={"Become a<br/> ZOG Global Partner"}
        titleMob={"Become a ZOG<br/> Global Partner"}
                
        subTitlePresent={true}
        subTitleWeb={"Let's cultivate meaningful connections<br/> and thrive together as partners in progress."}
        subTitleTab={"Let's cultivate meaningful connections and<br/> thrive together as partners in progress."}
        subTitleMob={"Let's cultivate meaningful connections<br/> and thrive together as partners in<br/> progress."}

        buttonText={"be a partner"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default BeAPartner
