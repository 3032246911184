import React, {useEffect, useRef, useState } from 'react'
import './ManagedITServices.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages.js'
import ImageOverlapedSection from '../../common-component/ImageOverlapedSection/ImageOverlapedSection.js'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'
import GreyCardsSection from '../../common-component/GreyCardsSection/GreyCardsSection.js'

import { useNavigate } from 'react-router-dom';

import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import Slider from 'react-slick'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'




const ManagedITServices = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


 


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])
  const sec3Data = [
    {
      title: "Consistency in Service Quality",
      description: "Our unwavering commitment to delivering top-notch IT solutions is backed by a track record of reliability, ensuring consistent performance and satisfaction for your business.",
    },

    {
      title: "Exceptional Customer Service",
      description: "Our team is dedicated to delivering remarkable customer service, offering personalised support that consistently exceeds your expectations and fosters long-term partnerships.",
    },
    {
      title: "Committed to High Standards",
      description: "We uphold the highest standards in everything we do, ensuring your IT infrastructure operates at peak performance while meeting industry regulations and compliance requirements.",
    },
    {
      title: "Customised Strategic Solutions",
      description: "We tailor our IT strategies to your unique business needs, leveraging cutting-edge technologies and industry best practices to drive innovation and growth for your organisation.",
    },
    {
      title: "Proactive Security Measures",
      description: "Our proactive approach to security ensures your data and operations are protected against evolving cyber threats, with comprehensive measures in place to mitigate risks and prevent breaches.",
    },
    {
      title: "Expert Technical Guidance",
      description: "Benefit from the expertise of our expert professionals, who provide strategic guidance and innovative solutions to address your IT challenges and confidently drive your business forward.",
    },


  ]
  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
      
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
    ]

  };



  const sliderRef=useRef(null)
  
    const next = () => {
      sliderRef.current.slickNext();
  };
  
  const previous = () => {
      sliderRef.current.slickPrev();
  };

  const [isToggled, setIsToggled] = useState(false);
  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  const [content1, setContent1]=useState(false);
   
  const cont1btn=()=>{
    setContent1(!content1);
  }

















  return (
    <div className='managed-it-services-page'>
<BannerSubPages
        imglink={"images/managed-it-services/main-banner.png"}
        pageName={"managed-it-services"}
        blueTitle={"MANAGED IT SERVICES"}
        titleWeb={"Achieve<br/> Long-Term Success"}
        titleTab={"Achieve Long-Term<br/> Success"}
        titleMob={"Achieve Long-<br/>Term Success"}
        subTitleWeb={"Our managed IT services keep you<br/> connected, secure and ahead of the<br/> curve."}
        subTitleTab={"Our managed IT services keep you<br/> connected, secure and ahead of the<br/> curve."}
        subTitleMob={"Our managed IT services<br/> keep you connected,<br/> secure and ahead of the curve."}
        iconTopWeb={'7%'}
        iconLeftWeb={'91%'}
        iconTopTab={'25%'}
        iconLeftTab={'80%'}
        iconTopMob={'29%'}
        iconLeftMob={'82%'}
        iconRotationWeb={152}
        iconRotationTab={155}
        iconRotationMob={155}>
      </BannerSubPages>

      <ImageOverlapedSection 
      pageName={'managed-it-services'} 
      secNo={'2'} 
      imageFirst={false}
      numberPresent={true}
      headingWeb={"Future-Proof Your Business<br/> with Expert Managed IT Services"} 
      headingTab={"Future-Proof Your Business with Expert Managed IT Services"} 
      headingMob={"Future-Proof Your Business with Expert Managed IT Services"} 


      subHeadding1={"Data Backup and Disaster Recovery"} 
      description1={"Ensure seamless continuity with robust data backup solutions and rapid disaster recovery strategies tailored to your business needs. Protect your critical data assets against unforeseen disruptions, ensuring business resilience."} 
      subHeadding2={"Network Infrastructure Management"} 
      description2={"Optimise network performance and security with expert management, monitoring, and maintenance of your IT infrastructure. Enhance operational efficiency and safeguard your network against evolving cyber threats."} 
      subHeadding3={"IT Consulting"} 
      description3={"Gain strategic insights and tailored solutions from experienced consultants to maximise your IT investments and achieve business objectives. Leverage expert guidance to align technology with your organisational goals and stay ahead in a competitive landscape."}
      />





<div id="targetDiv"  className={`blueslider-update ${isToggled ? 'black-bg' : ''}`}>

  <div className='head montserrat-hard'>
  Why Choosing Us as Your Managed IT Provider?  </div>

  <p className='montserrat-lit'>Reliable, efficient, innovative: Your ultimate Managed IT provider solution awaits.</p>
  <div className='combine-btn'>
  <div className='arrow'>

    <img src="\images\protect-your-customers-data\1.svg" onClick={previous}/>
    <img src="\images\protect-your-customers-data\Frame (1).svg" onClick={next}/>

  </div>

  {/* <div className="toggle-container">
          <input type="checkbox" id="toggleButton" className="toggle-checkbox" onChange={handleToggleChange} />
          <label htmlFor="toggleButton" className="toggle-label">
            <span className="toggle-button">
            <img src="\images\protect-your-customers-data\icons8-open-lock.svg" className='im1'/>
            <img src="\images\protect-your-customers-data\icons8-lock.svg" className='im2'/>
            </span>
          </label>
        </div> */}
        </div>



       
  <div className='slickblue-update montserrat-lit'>
  <div className="slider-container ">
      <Slider ref={sliderRef} {...settings}>
        <div>
         <div className='cardofblue-update'>
          <span>Consistency in Service Quality</span>
          <p>Our unwavering commitment to delivering top-notch IT solutions is backed by a track record of reliability, ensuring consistent performance and satisfaction for your business.</p>
         </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Exceptional Customer Service</span>
        <p>Our team is dedicated to delivering remarkable customer service, offering personalised support that consistently exceeds your expectations and fosters long-term partnerships.</p>

        </div>
        </div>
        <div>
        <div className='cardofblue-update'>

        <span>Committed to High Standards</span>
        <p>We uphold the highest standards in everything we do, ensuring your IT infrastructure operates at peak performance while meeting industry regulations and compliance requirements.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Customised Strategic Solutions</span>
        <p>We tailor our IT strategies to your unique business needs, leveraging cutting-edge technologies and industry best practices to drive innovation and growth for your organisation.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Advanced Threat Detection</span>
        <p>We use advanced tech and algorithms for proactive threat detection, neutralising emerging risks in real-time and ensuring robust defence against cyber threats.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Expert Guidance</span>
        <p>Tailoring data protection strategies ensures compliance with legal standards and industry norms, seamlessly integrating with operations while addressing organisational nuances.</p>
        </div>
        </div>
        
       
      </Slider>
    </div>
    

  </div>



</div>
















      {/* <GreyCardsSection
        pageName={'managed-it-services'}
        headingWeb={"Why Choosing Us as Your<br/> Managed IT Provider?"}
        headingTab={"Why Choosing Us as Your Managed IT Provider?"}
        headingMob={"Why Choosing Us as Your Managed IT Provider?"}
        descriptionWeb={"Reliable, efficient, innovative: Your ultimate Managed IT provider solution awaits."}
        descriptionTab={"Reliable, efficient, innovative: Your ultimate Managed IT provider solution awaits."}
        descriptionMob={"Reliable, efficient, innovative: Your ultimate Managed IT provider solution awaits."}
        cardsData={sec3Data}

      /> */}


      <BottomBanner
        pageName={"managed-it-services"}
        titleWeb={"Ready to<br/> Streamline your IT?"}
        titleTab={"Ready to<br/> Streamline your IT?"}
        titleMob={"Ready to<br/> Streamline your IT?"}

        
        subTitlePresent={false}
        subTitleWeb={""}
        subTitleTab={""}
        subTitleMob={""}

        buttonText={"contact us now"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>

      

  <BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>




      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default ManagedITServices