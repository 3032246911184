import React, {useState} from 'react'
import './RetrievePopup.scss'
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';

import API from '../../../API';
import ErrorModal from '../../../ErrorModal';
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"


const RetrievePopup = ({setterFunction, okClickedFunction,idSelected }) => {
  const okClicked=()=>{
    okClickedFunction();
    setterFunction(false)
  }
  
  const [expiryDate, setExpiryDate] = useState(null);

  const handleDateChange = (date) =>{
    const formattedDate = date.toISOString().slice(0, 10);
    setExpiryDate(formattedDate)
  }

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // update Expiry date
  const handleExpiryDateChange = () => {
    if (expiryDate ){
      setIsLoading(true);
      API.put(`/recruitment/jobs/${idSelected}/`,{ expiry_date: expiryDate,})
      .then((response) => {
        setIsLoading(false);
        setterFunction(false)
        okClickedFunction();
      })
      .catch((error) => {
        setIsErrorModalOpen(true);
        setMessage(error.message || "An error occurred.");
        setIsLoading(false);
      });
    }
      
  }

  
  return (
    <div className='custom-modal filter-modal retrieve'>
        <div className='card'>
            <div className='close-btn' >
                <button  onClick={okClicked}>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.49951 7.5L22.4995 22.5" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22.5005 7.5L7.50049 22.5" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </button>
                
            </div>
            <div className='card-content'>
              <h1>Change Job Expiry Date</h1>
              <div className='option'>
                <span className='option-title' >Set Job Expiry Date</span>
                {/* <CustomDatePicker selectedDate={expiryDate} setSelectedDate={setExpiryDate}
                  dateFormat="yyy/MM/dd" placeholder={'DD/MM/YYYY'} minDate={new Date()} /> */}
                <CustomDatePicker
                  selected={expiryDate}
                  dateFormat={"dd/MM/yyyy"} placeholder={'DD/MM/YYYY'} 
                  // showMonthYearPicker={showMonthYearPicker}
                  // showYearPicker={showYearPicker} 
                  minDate={new Date()}
                  onChange={(date)=>handleDateChange(date)} />
              </div>
              <div className='submit-section'>
                <button className='admin-blue-btn' onClick={handleExpiryDateChange}>
                    Add
                </button>
              </div>
              
            </div>
        </div>
        {isLoading && <FixedOverlayLoadingSpinner />}
    </div>
  )
}

export default RetrievePopup