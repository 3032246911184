// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.admin-layout {
  display: flex;
  justify-content: space-between;
}
.admin-layout .rhs {
  width: calc(100vw - 70px);
  margin-left: 70px;
}
.admin-layout .rhs .app-content {
  background-color: #FFF !important;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  scroll-behavior: smooth;
}
.admin-layout .rhs .auth-mask {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  color: #0770a8;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/common-components/Layout.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,8BAAA;AACD;AAAC;EACG,yBAAA;EACA,iBAAA;AAEJ;AADI;EACE,iCAAA;EACD,WAAA;EACA,wBAAA;EAAA,mBAAA;EACA,uBAAA;AAGL;AADI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;EACA,WAAA;EACA,cAAA;AAGN","sourcesContent":[".admin-layout{\r\n display: flex; \r\n justify-content: space-between;  \r\n .rhs{\r\n    width: calc(100vw - 70px);\r\n    margin-left: 70px;\r\n    .app-content{\r\n      background-color: #FFF !important;\r\n     width: 100%;\r\n     height: fit-content;\r\n     scroll-behavior: smooth;\r\n  }\r\n    .auth-mask{\r\n      display: flex;\r\n      align-items: center;\r\n      justify-content: center;\r\n      height: 100vh;\r\n      width: 100%;\r\n      color: #0770a8;\r\n    }\r\n }\r\n}\r\n\r\n\r\n@media(max-width:991px){\r\n\r\n}\r\n\r\n@media(max-width:576px) {\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
