import React,{useState, useEffect} from 'react';
import '../JobFilter/JobFilter.scss'
import '../FilterPopup.scss'
import $ from 'jquery';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';

import API from '../../../API';
import ErrorModal from '../../../ErrorModal';


const ApplicationFilter = ({ message,title, state,filters, setFilters, setterFunction, okClickedFunction,setSelectedOptions,selectedOptions  }) => {

    const okClicked=()=>{ 
      okClickedFunction();
      setterFunction(false);
      setFilters(filters);
    }

  const clearFilters = () => {
    setFilters({
      application_status: null,
      source: null,
      job_department: null,
      job_title: null,
      location: null,
      from_date: null, 
      to_date: null,
      search_key: null,
      sort_order:null,
      period:'By Month'
    });
    setSelectedOptions({})
  };

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [categoryListOpened, toggleCategoryList] = useState(false)
    const [locationListOpened, toggleLocationList] = useState(false)
    const [jobTitleListOpened, toggleJobTitleList] = useState(false)
    const [periodOptionListOpened, togglePeriodOptionList] = useState(false)
    
    const [jobDepartments, setJobDepartments] = useState([])
    // GET Job Departments from Backend
    useEffect(() => {
      API.get(`/recruitment/departments/`)
        .then((response) => {
          setJobDepartments(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);

    const [jobTitles, setJobTitles] = useState([])
    // GET Job Departments from Backend
    useEffect(() => {
      API.get(`/recruitment/jobs/`)
        .then((response) => {
          setJobTitles(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);

    const [jobLocations, setJobLocations] = useState(['India','United Kingdom','United States'])
    // GET Job Locations from Backend
    // useEffect(() => {
    //   API.get(`/recruitment/locations/`)
    //     .then((response) => {
    //       setJobLocations(response.data)
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
        
    // }, []);
    

    const handleJobTitleChange = (id,name) => {
      setFilters({ ...filters, job_title:name });
      setSelectedOptions({ ...selectedOptions, job_title: name });
    };
   
    const handleCategoryChange = (id,name) => {
        setFilters({ ...filters, department:id });
        setSelectedOptions({ ...selectedOptions, department: name });
      };
    const handleLocationChange = (id,name) => {
      setFilters({ ...filters, location:id });
      setSelectedOptions({ ...selectedOptions, location: id });
    };
    
   
    const selectPeriod = (value) => {
        setFilters({
          ...filters,
          "period": value
        });
      }
    const [dateFormat, setDateFormat] = useState("MM/dd/yyyy");
    const [showMonthYearPicker, setShowMonthYearPicker] = useState(false);
    const [showYearPicker, setShowYearPicker] = useState(false);
    useEffect(() => {
      if (filters.period === 'By Month') {
        setDateFormat("MM/yyyy");
        setShowMonthYearPicker(true);
        setShowYearPicker(false);
      } else if (filters.period === 'By Year') {
        setDateFormat("yyyy");
        setShowYearPicker(true);
        setShowMonthYearPicker(false);
      } else {
        setDateFormat("MM/dd/yyyy");
        setShowMonthYearPicker(true);
        setShowYearPicker(false);
      }
    }, [filters.period,showMonthYearPicker,showYearPicker]);
  
    
    useEffect(() => {
      const $OptionDropList = $("#job_title .option-list");
      if (jobTitleListOpened) {
        const height = $OptionDropList[0].scrollHeight;
        $OptionDropList.animate({ height: height + "px" }, 400);
        $OptionDropList.css("opacity", "1");
      } else {
        $OptionDropList.animate({ height: "0px" }, 400);
        $OptionDropList.css("opacity", "0");
      }
    }, [jobTitleListOpened]);

    
    useEffect(() => {
      const $OptionDropList = $("#category .option-list");
      if (categoryListOpened) {
        const height = $OptionDropList[0].scrollHeight;
        $OptionDropList.animate({ height: height + "px" }, 400);
        $OptionDropList.css("opacity", "1");
      } else {
        $OptionDropList.animate({ height: "0px" }, 400);
        $OptionDropList.css("opacity", "0");
      }
    }, [categoryListOpened]);

    useEffect(() => {
      const $OptionDropList = $("#location .option-list");
      if (locationListOpened) {
        const height = $OptionDropList[0].scrollHeight;
        $OptionDropList.animate({ height: height + "px" }, 400);
        $OptionDropList.css("opacity", "1");
      } else {
        $OptionDropList.animate({ height: "0px" }, 400);
        $OptionDropList.css("opacity", "0");
      }
    }, [locationListOpened]);
      
    useEffect(() => {
      const $OptionDropList = $("#period .option-list");
      if (periodOptionListOpened) {
        const height = $OptionDropList[0].scrollHeight;
        $OptionDropList.animate({ height: height + "px" }, 400);
        $OptionDropList.css("opacity", "1");
      } else {
        $OptionDropList.animate({ height: "0px" }, 400);
        $OptionDropList.css("opacity", "0");
      }
    }, [periodOptionListOpened])

    const handleSourceChange = (value) => {
        setFilters({ ...filters, source:value });
    };
   

    const getLastDayOfMonth = (date) => {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const lastDay = new Date(year, month, 0);
      return lastDay.getDate();
    };
    const handleStartDateChange = (date) => {
        if (filters.to_date && new Date(date) > new Date(filters.to_date)) {
          setErrorMessage("From date cannot be greater than To date");
            setIsErrorModalOpen(true)
            return;
        }
        const formattedDate = date.toISOString().slice(0, 10);
        setFilters({ ...filters, from_date: formattedDate });
    };
    

    const handleEndDateChange = (date) => {
      let endDate;
      if (filters.period === 'By Month'){
        const lastDayOfMonth = getLastDayOfMonth(date);
        endDate = new Date(date.getFullYear(), date.getMonth(), lastDayOfMonth);
      } else if (filters.period === 'By Year') {
        endDate = new Date(date.getFullYear(), 11, 31); 
      }

      if (filters.from_date && new Date(endDate) < new Date(filters.from_date)) {
        setErrorMessage("To date cannot be less than From date");
        setIsErrorModalOpen(true)
        return;
      }
      
      const formattedDate = endDate.toISOString().slice(0, 10);
      setFilters({ ...filters, to_date: formattedDate });
    };
  
  console.log(selectedOptions);

  return (
    <div className='custom-modal filter-modal jobs'>
        <div className='card'>
            <div className='close-btn' >
                <button  onClick={okClicked}>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.49951 7.5L22.4995 22.5" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22.5005 7.5L7.50049 22.5" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </button>
                

            </div>
            <div className='card-content'>
                <div className='heading'>
                  <h1>Filter options</h1>
                  <div className='clr-btn' onClick={clearFilters}>
                    Clear All
                  </div>
                </div>
                <div className='option'>
                    <span className='option-title'>Source Applied</span>
                    <div className='options-list'>
                      <label className="custom-select-box">
                          <input 
                              type="radio" 
                              name="status" 
                              value="facebook" 
                              checked={filters.source=="facebook"} 
                              onChange={() => handleSourceChange("facebook")} 
                          />
                          <span className="checkmark"></span>
                          <h4>Facebook</h4>
                      </label>
                      <label className="custom-select-box">
                          <input 
                              type="radio" 
                              name="status" 
                              value="mail" 
                              checked={filters.source=="mail"} 
                              onChange={() => handleSourceChange("mail")} 
                          />
                          <span className="checkmark"></span>
                          <h4>Mail</h4>
                      </label>
                      <label className="custom-select-box">
                          <input 
                              type="radio" 
                              name="status" 
                              value="linkedin" 
                              checked={filters.source=="linkedin"} 
                              onChange={() => handleSourceChange("linkedin")} 
                          />
                          <span className="checkmark"></span>
                          <h4>LinkedIn</h4>
                      </label>
                      <label className="custom-select-box">
                          <input 
                              type="radio" 
                              name="status" 
                              value="website" 
                              checked={filters.source=="website"} 
                              onChange={() => handleSourceChange("website")} 
                          />
                          <span className="checkmark"></span>
                          <h4>Website</h4>
                      </label>
                  </div>
                </div>
                
                <div className='option job-title'>
                    <span className='option-title category' >Job Title</span>
                    <div className="form-select custom-select" id="job_title" name="job_title"
                        onClick={() => toggleJobTitleList(!jobTitleListOpened)} >
                        <div className="selected-value">{selectedOptions.job_title ? selectedOptions.job_title : "Select Job Title"}
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_3764_11686" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                        <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_3764_11686)">
                        <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        </svg>
                        </div>
                        <div className='option-list'>
                          {jobTitles.map(job => (
                            <div 
                              key={job.id} 
                              className='option' 
                              onClick={() => handleJobTitleChange(job.id,job.title)}
                            >
                              {job.title}
                            </div>
                          ))}
                      </div>

                    </div>
                    
                </div>

                <div className='option department'>
                    <span className='option-title category' >Department</span>
                    <div className="form-select custom-select" id="category" name="category"
                        onClick={() => toggleCategoryList(!categoryListOpened)} >
                        <div className="selected-value">{selectedOptions.department ? selectedOptions.department : "Select Job Department"}
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_3764_11686" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                        <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_3764_11686)">
                        <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        </svg>
                        </div>
                        <div className='option-list'>
                          {jobDepartments.map(department => (
                            <div 
                              key={department.id} 
                              className='option' 
                              onClick={() => handleCategoryChange(department.id,department.name)}
                            >
                              {department.name}
                            </div>
                          ))}
                      </div>

                    </div>
                    
                </div>
                <div className='option location'>
                    <span className='option-title location' >Location</span>
                    <div className="form-select custom-select" id="location" name="location"
                        onClick={() => toggleLocationList(!locationListOpened)} >
                        <div className="selected-value">{selectedOptions.location ? selectedOptions.location : "Select Location"}
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_3764_11686" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                        <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_3764_11686)">
                        <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        </svg>
                        </div>
                        <div className='option-list'>
                          {jobLocations.map(location => (
                            <div 
                              key={location} 
                              className='option' 
                              onClick={() => handleLocationChange(location)}
                            >
                              {location}
                            </div>
                          ))}
                      </div>

                    </div>
                    
                </div>
                <div className='option period'>
                    <span className='option-title period' >Period</span>
                    <div className="form-select custom-select" id="period" name="period"
                        onClick={() => togglePeriodOptionList(!periodOptionListOpened)} >
                        <div className="selected-value">{filters.period ? filters.period : "By Month"}
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_3764_11686" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                        <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_3764_11686)">
                        <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        </svg>
                        </div>
                        <div className='option-list'>
                          <div className='option' onClick={() => selectPeriod("By Month")}>By Month</div>
                          <div className='option' onClick={() => selectPeriod("By Year")}>By Year</div>
                        </div>

                    </div>
                    <CustomDatePicker
                      selected={filters.from_date}
                      dateFormat={dateFormat} placeholder={'From'} 
                      showMonthYearPicker={showMonthYearPicker}
                      showYearPicker={showYearPicker}
                      onChange={(date)=>handleStartDateChange(date)} />

                    <CustomDatePicker
                      selected={filters.to_date}
                      dateFormat={dateFormat} placeholder={'To'} 
                      showMonthYearPicker={showMonthYearPicker}
                      showYearPicker={showYearPicker}
                      onChange={(date)=>handleEndDateChange(date)} />
                </div>
                
                
            </div>
                
        </div>
            
      <ErrorModal state={isErrorModalOpen} message={errorMessage} setterFunction={setIsErrorModalOpen}  />
    </div>
  );
};

export default ApplicationFilter;