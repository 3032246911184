import React, { Component, useEffect, useState, useContext, useRef } from 'react'
import './Telecom.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'
import DiamondSection from '../../common-component/DiamondSection/DiamondSection.js'
import SpecialSliderSection from "../../common-component/SpecialSliderSection/SpecialSliderSection"
import ImageOverlapedSection from '../../common-component/ImageOverlapedSection/ImageOverlapedSection.js'
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'


import Carousel from 'react-bootstrap/Carousel';
import { useNavigate, Link } from 'react-router-dom';

import API from "../../../API.js"
import LoadingSpinner from "../../../LoadingSpinner.js";
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';

import { UserContext } from '../../../authentication/pages/UserContext.js';

import Slider from 'react-slick'


const Telecom = () => {
  const navigate = useNavigate();

  const { isLoggedIn, login, logout } = useContext(UserContext);

  const [tabSelected, selectTab] = useState(0);

  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
    setIsLoading(true)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        setBlogData(response.data)
        setIsLoading(false)

      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)

      });
  }, [])

  
  const sec3Data = [
    { title: "Digital Transformation", description: "Empowering telecom businesses with innovative digital solutions for enhanced efficiency and customer experience." },
    { title: "Network Infrastructure Management", description: "Expert management and optimisation of telecom networks for seamless connectivity, performance, and reliability." },
    { title: "Data Security Solutions", description: "Advanced defence strategies fortify telecom operations against cyber threats, ensuring data privacy and compliance." },
    { title: "Cloud Migration Services", description: "Seamless transition to cloud infrastructure for telecom businesses, ensuring scalability, agility, and cost-effectiveness." }
  ]

  const sec5Data = [
    {
      title: "Specialised Expertise",
      description: "Benefit from industry-specific knowledge, tailored solutions, and a deep understanding of telecom challenges for optimal outcomes.",
    },
    {
      title: "Cost Efficiency",
      description: "Streamlined processes, automation, and strategic planning lead to minimised costs and maximised value from IT investments.",
    },
    {
      title: "Scalability & Flexibility",
      description: "Dynamic solutions adapt to changing demands, ensuring seamless growth, agility, and scalability in evolving telecom environments.",
    },
    {
      title: "Enhanced Performance",
      description: "Proactive monitoring, robust security measures, and strategic infrastructure management ensure high performance and reliability.",
    },
    {
      title: "Strategic Partnerships & Support",
      description: "Personalised attention, ongoing support, and commitment to client success foster strong partnerships and mutual growth opportunities.",
    },



  ]

  
  const sec4Data = [
    { title: "",
     description: "",
     image_url:"/images/why-zog-global/sec-4-1.png"
     },

    { title: "",
     description: "",
    image_url:"/images/why-zog-global/sec-4-2.png" 
  },

    { title: "",
     description: "",
    image_url:"/images/why-zog-global/sec-4-3.png" 
  },
  
  ]

  const sec6Data=[
    
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    
  ]
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };








  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index)
  };

  const sliderRef=useRef(null)
  
    const next = () => {
      sliderRef.current.slickNext();
  };
  
  const previous = () => {
      sliderRef.current.slickPrev();
  };

  const getImageSrc = (index) => {
    switch (index) {
      case 0:
        return "/images/telecom/sec-3-1-tab.png";
      case 1:
        return "/images/telecom/sec-3-2-web.png";
      case 2:
        return "/images/telecom/sec-3-3-web.png";
      case 3:
        return "/images/telecom/sec-3-4-web.png";
      default:
        return "/images/telecom/sec-3-1-web.png";
    }
  };
  

  useEffect(() => {
    const imgs = document.querySelectorAll(".img-mark");
  
    if (imgs.length === 0) {
      // Exit if there are no images found
      return;
    }
  
    imgs.forEach((img, index) => {
      if (index === currentSlide) {
        img.classList.add("active");
      } else {
        img.classList.remove("active");
      }
    });
  }, [currentSlide]);







  //bluecontainer
  

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
      
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
    ]

  };



  const sliderRef2=useRef(null)
  
    const next2 = () => {
      sliderRef2.current.slickNext();
  };
  
  const previous2 = () => {
      sliderRef2.current.slickPrev();
  };
  const [isToggled, setIsToggled] = useState(false);
  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  const [content1, setContent1]=useState(false);
   
  const cont1btn=()=>{
    setContent1(!content1);
  }





















  return (
    <div className='telecom-page'>

<BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"telecom"}
        blueTitle={"Telecom"}
        titleWeb={"Innovative IT Services<br/> for Telecom"}
        titleTab={"Innovative IT<br/> Services for<br/> Telecom"}
        titleMob={"Innovative IT<br/> Services for<br/> Telecom"}
        subTitleWeb={"Driving digital evolution in Telecom<br/> businesses."}
        subTitleTab={"Driving digital evolution in Telecom<br/> businesses."}
        subTitleMob={"Driving digital evolution in<br/> Telecom businesses."}
        iconTopWeb={'8%'}
        iconLeftWeb={'90%'}
        iconTopTab={'26%'}
        iconLeftTab={'75%'}
        iconTopMob={'38%'}
        iconLeftMob={'70%'}
        iconRotationWeb={140}
        iconRotationTab={31}
        iconRotationMob={19}>
      </BannerSubPages>



      {/* <DiamondSection
        pageName={'telecom'}
        secNo={'2'}
        theme={"light"}
        headingBoxPresent={false}
        
        subHeadding1={"Our Tech-Forward Approach"}
        description1={"ZOG Global leverages its vast domain knowledge and technological expertise to deliver high-value IT services tailored for telecom clients throughout the UK. Our solutions empower businesses to capitalise on revenue-generating opportunities, adapt to evolving customer demands, and expand into new markets. With our innovative telecom technology solutions, we offer enhanced methods to achieve measurable business success."}
        subHeadding2={"‌Next Generation Networks"}
        description2={"Next Generation Networks (NGNs) underscore the critical role of IT in the telecom sector. With instantaneous technological advancements and increasing demands for connectivity, telecom companies rely heavily on robust IT infrastructure to meet evolving customer needs."}
      /> */}

<div className='img-sec2-itoutsource'>

<div className='for-img'>
    <img src="\images\telecom\sec-2.png"/>

  </div>


  <div className='for-text'>
    <span>Our Tech-Forward Approach</span>
    <p>ZOG Global leverages its vast domain knowledge and technological expertise to deliver high-value IT services tailored for telecom clients throughout the UK. Our solutions empower businesses to capitalise on revenue-generating opportunities, adapt to evolving customer demands, and expand into new markets. With our innovative telecom technology solutions, we offer enhanced methods to achieve measurable business success.</p>

  </div>

  
</div>



<div className='img-sec2-itoutsource'>

 

  <div className='for-text'>
    <span>‌Next Generation Networks</span>
    <p>Next Generation Networks (NGNs) underscore the critical role of IT in the telecom sector. With instantaneous technological advancements and increasing demands for connectivity, telecom companies rely heavily on robust IT infrastructure to meet evolving customer needs.</p>

  </div>

  <div className='for-img'>
    <img src="\images\telecom\sec-3.png"/>

  </div>

</div>






<div className='white-head'>
  <div className='headfont'>Our Telecom Offerings</div>
  <p>We offer tailored solutions and innovative services designed to empower telecom businesses for sustained growth and success.</p>
  
</div>




<div className='complex-slider'>
  <div className='width50'>
    <div className='arow-cont'>
    <img src="\images\healthcare\arrow-cs.svg" onClick={previous}/>
<img src="\images\healthcare\arrow2-cs.svg" onClick={next}/>
</div>
  <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
        <div>
        <div className='card'>
          <span className='montserrat-hard'>
          Digital Transformation
          </span>
          <p className='montserrat-lit '>
          Empowering telecom businesses with innovative digital solutions for enhanced efficiency and customer experience.
            
            </p>
        </div>
        </div>
        
        <div>
        <div className='card'>
          <span className='montserrat-hard'> Network Infrastructure Management
</span>
          
          <p className='montserrat-lit '>Expert management and optimisation of telecom networks for seamless connectivity, performance, and reliability.</p>
        </div>
        </div>

        <div>
        <div className='card'>
          <span className='montserrat-hard'>Data Security Solutions
         </span>
          <p className='montserrat-lit '>
          Advanced defence strategies fortify telecom operations against cyber threats, ensuring data privacy and compliance.</p>
        </div>
        </div>

        <div>
        <div className='card'>
          <span className='montserrat-hard'>Cloud Migration Services
         
       </span>
          <p className='montserrat-lit '> Seamless transition to cloud infrastructure for telecom businesses, ensuring scalability, agility, and cost-effectiveness.</p>
        </div>
        </div>
        
      </Slider>
    </div>

  </div>
  <div className='width50-2'>
  
 
  {sec3Data.map((_, index) => (
    <img key={index} className='img-mark' src={getImageSrc(index)} alt="Healthcare" />
  ))}
    
    </div>
</div>























{/* <SpecialSliderSection 
      pageName={"telecom"}
      secNo={"3"}
      
      headingBoxPresent={true}
      headingWeb={"Our Telecom Offerings"}
      headingTab={"Our Telecom Offerings"}
      headingMob={"Our Telecom Offerings"}
      descriptionWeb={"We offer tailored solutions and innovative services designed to empower telecom<br/> businesses for sustained growth and success."}
      descriptionTab={"We offer tailored solutions and innovative services designed to empower telecom businesses for sustained growth and success."}
      descriptionMob={"We offer tailored solutions and innovative services designed to empower telecom businesses for sustained growth and success."}
      boxAlignment={"center"}
      
      subTitle={""}
      data={sec3Data} 
      /> */}


<ImageOverlapedSection 
      pageName={'telecom'} 
      secNo={'3'} 
      imageFirst={false}
      numberPresent={true}
      headingWeb={"Advanced Security Offerings"} 
      headingTab={"Advanced Security Offerings"} 
      headingMob={"Advanced Security Offerings"} 
      descriptionWeb={"Step into a fortified future with our advanced security offerings custom-tailored to<br/> shield your telecom business and customer data."}
      descriptionTab={"Step into a fortified future with our advanced security offerings custom-tailored to shield your telecom business and customer data."}
       descriptionMob={"Step into a fortified future with our advanced security offerings custom-tailored to shield your telecom business and customer data."}

      subHeadding1={"Threat Intelligence"} 
      description1={"Stay ahead of cyber threats with dynamic insights for proactive defence strategies. Our threat intelligence services provide real-time monitoring and analysis of potential risks, ensuring timely responses to evolving threats across your network infrastructure."} 
      subHeadding2={"Secure SD-WAN"} 
      description2={"Enhance network security and performance through our advanced SD-WAN solutions. Our experts configure and deploy secure SD-WAN architectures that integrate seamlessly with your existing systems, prioritising data integrity and operational continuity."} 
      subHeadding3={"Penetration Testing"} 
      description3={"Identify and strengthen security weaknesses with thorough penetration testing services. Our certified testers simulate real-world cyberattacks to uncover vulnerabilities, delivering comprehensive reports and actionable recommendations tailored to your specific regulatory requirements."}
      />







<div id="targetDiv"  className={`blueslider-update ${isToggled ? 'black-bg' : ''}`}>

  <div className='head montserrat-hard'>
  Why Choose ZOG Global  </div>

  <p className='montserrat-lit'></p>
  <div className='combine-btn'>
  <div className='arrow'>

    <img src="\images\protect-your-customers-data\1.svg" onClick={previous2}/>
    <img src="\images\protect-your-customers-data\Frame (1).svg" onClick={next2}/>

  </div>

  {/* <div className="toggle-container">
          <input type="checkbox" id="toggleButton" className="toggle-checkbox" onChange={handleToggleChange} />
          <label htmlFor="toggleButton" className="toggle-label">
            <span className="toggle-button">
            <img src="\images\protect-your-customers-data\icons8-open-lock.svg" className='im1'/>
            <img src="\images\protect-your-customers-data\icons8-lock.svg" className='im2'/>
            </span>
          </label>
        </div> */}
        </div>



       
  <div className='slickblue-update montserrat-lit'>
  <div className="slider-container ">
      <Slider ref={sliderRef2} {...settings2}>
        <div>
         <div className='cardofblue-update'>
          <span> Specialised Expertise</span>
          <p>Benefit from industry-specific knowledge, tailored solutions, and a deep understanding of telecom challenges for optimal outcomes.</p>
         </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Cost Efficiency</span>
        <p>Streamlined processes, automation, and strategic planning lead to minimised costs and maximised value from IT investments.</p>

        </div>
        </div>
        <div>
        <div className='cardofblue-update'>

        <span>Scalability & Flexibility</span>
        <p>Dynamic solutions adapt to changing demands, ensuring seamless growth, agility, and scalability in evolving telecom environments.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Enhanced Performance</span>
        <p>Proactive monitoring, robust security measures, and strategic infrastructure management ensure high performance and reliability.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span> Strategic Partnerships & Support</span>
        <p> Personalised attention, ongoing support, and commitment to client success foster strong partnerships and mutual growth opportunities.</p>
        </div>
        </div>
       
       
       
        
       
      </Slider>
    </div>
    

  </div>



</div>













{/* 
      <BlueNotchSection
        headingWeb={"Why Choose ZOG Global"}     // <br/>
        headingTab={"Why Choose ZOG Global"}
        headingMob={"Why Choose<br/> ZOG Global"}
        descriptionPresent={false}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        listData={sec5Data}

      /> */}




<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>




      <BottomBanner
        pageName={"telecom"}
        titleWeb={"Interested in<br/> Our Solutions?"}
        titleTab={"Interested in<br/> Our Solutions?"}
        titleMob={"Interested in Our<br/> Solutions?"}
                
        subTitlePresent={true}
        subTitleWeb={"Reach out to discuss your telecom IT needs with our<br/> expert team."}
        subTitleTab={"Reach out to discuss your<br/> telecom IT needs with our expert team."}
        subTitleMob={"Reach out to discuss your telecom IT<br/> needs with our expert team."}

        buttonText={"contact us now"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default Telecom