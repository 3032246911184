import React, { Component, useEffect, useState, useContext, useRef } from 'react'
import './Education.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'
import SimpleSection from '../../common-component/SimpleSection/SimpleSection.js'
import SpecialSliderSection from "../../common-component/SpecialSliderSection/SpecialSliderSection"
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'


import Carousel from 'react-bootstrap/Carousel';
import { useNavigate, Link } from 'react-router-dom';

import API from "../../../API.js"
import LoadingSpinner from "../../../LoadingSpinner.js";
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';

import { UserContext } from '../../../authentication/pages/UserContext.js';

import Slider from 'react-slick'


const Education = () => {
  const navigate = useNavigate();

  const { isLoggedIn, login, logout } = useContext(UserContext);

  const [tabSelected, selectTab] = useState(0);

  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
    setIsLoading(true)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        setBlogData(response.data)
        setIsLoading(false)

      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)

      });
  }, [])

  
  const sec3Data = [
    { title: "Education Software", description: "Enhance your teaching methods with cutting-edge software, fostering interactive and immersive learning experiences for students of all levels." },
    { title: "Cloud Solutions", description: "Streamline administrative tasks and enhance classroom collaboration with our secure, cloud-based solutions designed for educational institutions." },
    { title: "Cybersecurity", description: "Ensure the security and privacy of your institution's digital assets with our comprehensive cybersecurity solutions, safeguarding against cyber threats." }
  ]

  const sec4Data = [
    {
      title: "Parent-Teacher Communication Platform",
      description: "Enable real-time updates, progress reports, and collaboration between parents and teachers for enhanced student development and engagement.",
    },
    {
      title: "Virtual Classroom Platforms",
      description: "Transform classrooms with live video, chat, whiteboards, and collaborative tools for immersive remote learning experiences and seamless interaction.",
    },
    {
      title: "Educational CMS",
      description: "Centralise creation, storage, and distribution of educational content for easy access, organisation, and customisation to diverse learning needs.",
    },
    {
      title: "SIS Enhancements",
      description: "Optimise administrative processes with customised enhancements to student information systems, including automated reporting and streamlined data management.",
    },
    {
      title: "Adaptive Learning Platforms",
      description: "Personalise learning experiences with AI-driven platforms that tailor content and assessments to individual student abilities and learning preferences.",
    },



  ]

  
  const sec5Data=[
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    
    
  ]


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };







  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index)
  };

  const sliderRef=useRef(null)
  
    const next = () => {
      sliderRef.current.slickNext();
  };
  
  const previous = () => {
      sliderRef.current.slickPrev();
  };

  const getImageSrc = (index) => {
    switch (index) {
      case 0:
        return "/images/education/sec-3-1.png";
      case 1:
        return "/images/education/sec-3-2.png";
      case 2:
        return "/images/education/sec-3-3.png";
    
      default:
        return "/images/education/sec-3-1.png";
    }
  };
  

  useEffect(() => {
    const imgs = document.querySelectorAll(".img-mark");
  
    if (imgs.length === 0) {
      // Exit if there are no images found
      return;
    }
  
    imgs.forEach((img, index) => {
      if (index === currentSlide) {
        img.classList.add("active");
      } else {
        img.classList.remove("active");
      }
    });
  }, [currentSlide]);












  return (
    <div className='education-page'>

<BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"education"}
        blueTitle={"Education"}
        titleWeb={"Smart IT Solutions for<br/>Brighter Futures"}
        titleTab={"Smart IT<br/> Solutions for<br/> Brighter Futures"}
        titleMob={"Smart IT<br/> Solutions for<br/> Brighter Futures"}
        subTitleWeb={"We enhance the learning environments<br/> with our tech expertise."}
        subTitleTab={"We enhance the learning environments<br/> with our tech expertise."}
        subTitleMob={"We enhance the learning<br/> environments with our tech expertise."}
        iconTopWeb={'30%'}
        iconLeftWeb={'88%'}
        iconTopTab={'55%'}
        iconLeftTab={'75%'}
        iconTopMob={'58%'}
        iconLeftMob={'70%'}
        iconRotationWeb={140}
        iconRotationTab={73}
        iconRotationMob={70}>
      </BannerSubPages>


      {/* <SimpleSection
        pageName={"education"}
        secNo={"2"}
        headdingPresent={true}
        buttonPresent={false}
        imagePosition={"right"}
        headingWeb={"Transforming<br/> Education Industry"}
        headingTab={"Transforming Education Industry"}
        headingMob={"Transforming<br/> Education Industry"}
        description={"ZOG Global specialises in securing cloud infrastructures for educational institutions, ensuring compliance and reliability. We focus on optimising student-oriented learning environments with world-class software and managed solutions. We help schools and universities maximise technology investments for a safe and efficient digital experience."}
        buttonText={""}
      /> */}



<div className='img-sec2-itoutsource'>

 

<div className='for-text'>
  <span>Transforming Education Industry</span>
  <p>ZOG Global specialises in securing cloud infrastructures for educational institutions, ensuring compliance and reliability. We focus on optimising student-oriented learning environments with world-class software and managed solutions. We help schools and universities maximise technology investments for a safe and efficient digital experience.</p>

</div>

<div className='for-img'>
  <img src="\images\education\sec-2.png"/>

</div>

</div>




{/* <SpecialSliderSection 
      pageName={"education"}
      secNo={"3"}
      
      headingBoxPresent={true}
      headingWeb={"Our Service Offerings"}
      headingTab={"Our Service Offerings"}
      headingMob={"Our Service<br/> Offerings"}
      descriptionWeb={"Improve educational experiences with our IT solutions personalised to optimise<br/> operations and support learning objectives within the industry."}
      descriptionTab={"Improve educational experiences with our IT solutions personalised to optimise operations and support learning objectives within the industry."}
      descriptionMob={"Improve educational experiences with our IT solutions personalised to optimise operations and support learning objectives within the industry."}
      boxAlignment={"center"}
      
      subTitle={""}
      data={sec3Data} 
      /> */}






<div className='white-head'>
  <div className='headfont'>Our Service Offerings</div>
  <p>Improve educational experiences with our IT solutions personalised to optimise operations and support learning objectives within the industry.</p>
  
</div>




<div className='complex-slider'>
  <div className='width50'>
    <div className='arow-cont'>
    <img src="\images\healthcare\arrow-cs.svg" onClick={previous}/>
<img src="\images\healthcare\arrow2-cs.svg" onClick={next}/>
</div>
  <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
        <div>
        <div className='card'>
          <span className='montserrat-hard'>Education Software</span>
          <p className='montserrat-lit '>
          Enhance your teaching methods with cutting-edge software, fostering interactive and immersive learning experiences for students of all levels.

          </p>
        </div>
        </div>
        
        <div>
        <div className='card'>
          <span className='montserrat-hard'>  Cloud Solutions
          </span>
          
          <p className='montserrat-lit '>
          Streamline administrative tasks and enhance classroom collaboration with our secure, cloud-based solutions designed for educational institutions.

          </p>
        </div>
        </div>

        <div>
        <div className='card'>
          <span className='montserrat-hard'>
          Cybersecurity
             </span>
          <p className='montserrat-lit '>
          Ensure the security and privacy of your institution's digital assets with our comprehensive cybersecurity solutions, safeguarding against cyber threats.
         
         </p>
        </div>
        </div>

        
      </Slider>
    </div>

  </div>
  <div className='width50-2'>
  
 
  {sec3Data.map((_, index) => (
    <img key={index} className='img-mark' src={getImageSrc(index)} alt="Healthcare" />
  ))}
    
    </div>
</div>







<div className='pink-card montserrat-lit'>

<div className='head1'>
<div className='texthed'>Advanced Software Solutions</div>
<p></p>
</div>

<div className='head2'>
<div className='each-sec'>
<span>
Parent-Teacher Communication Platform


</span>
<p>
Enable real-time updates, progress reports, and collaboration between parents and teachers for enhanced student development and engagement. Streamline communication with instant messaging, secure portals, and mobile access for on-the-go updates. Customisable notifications ensure parents stay informed about their child's academic progress and school events, fostering a supportive learning environment.

</p>
</div>

<div className='each-sec'>
<span>Virtual Classroom Platforms

</span>
<p>
Transform classrooms with live video, chat, whiteboards, and collaborative tools for immersive remote learning experiences and seamless interaction. Facilitate group projects, interactive lessons, and teacher-student consultations, all within a user-friendly interface. Integrate with existing school systems for a cohesive digital learning ecosystem, ensuring no disruption in the educational process.


</p>
</div>

<div className='each-sec'>
<span>Reliable Support & Maintenance

</span>
<p>

Our comprehensive support and maintenance services ensure your IT infrastructure remains secure, efficient, and hassle-free. With proactive monitoring and timely interventions, we empower your business to grow without interruption, allowing you to focus on your core activities with peace of mind.

</p>
</div>

<div className='each-sec'>
<span>
Educational CMS


</span>
<p>
Centralise the creation, storage, and distribution of educational content for easy access, organisation, and customisation to diverse learning needs. Support multimedia content, including videos, quizzes, and interactive modules, enhancing student engagement. Allow educators to track content usage and performance metrics to refine and improve instructional materials continuously.


</p>
</div>
<div className='each-sec'>
<span>
  
SIS Enhancements


</span>
<p>
Optimise administrative processes with customised enhancements to student information systems, including automated reporting and streamlined data management. Simplify enrolment, attendance tracking, and grade recording with intuitive interfaces and robust data security. Facilitate data-driven decision-making with advanced analytics and visualisations, helping schools manage resources more effectively.

</p>
</div>

<div className='each-sec'>
<span>Adaptive Learning Platforms

</span>
<p>
Personalise learning experiences with AI-driven platforms that tailor content and assessments to individual student abilities and learning preferences. Monitor student progress in real-time, providing instant feedback and adaptive challenges to keep learners engaged. Support diverse learning styles and paces, ensuring each student receives a tailored educational journey that maximises their potential.


</p>
</div>



</div>
</div>









{/* 

      <BlueNotchSection
        headingWeb={"Advanced Software Solutions"}     // <br/>
        headingTab={"Advanced<br/> Software Solutions"}
        headingMob={"Advanced<br/> Software<br/> Solutions"}
        descriptionPresent={false}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        listData={sec4Data}

      /> */}




<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>

      <div className='sec-6'>

      </div>


      <div className='sec-7'>

      </div>




      <BottomBanner
        pageName={"education"}
        titleWeb={"Have any Queries?"}
        titleTab={"Have any Queries?"}
        titleMob={"Have any Queries?"}
                
        subTitlePresent={true}
        subTitleWeb={"Get in touch with our expert team<br/> to discuss your IT needs."}
        subTitleTab={"Get in touch with our expert<br/> team to discuss your IT needs."}
        subTitleMob={"Get in touch with our expert team to<br/> discuss your IT needs."}

        buttonText={"contact us now"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default Education