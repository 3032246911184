import React, {  useEffect, useRef, useState } from 'react'
import './Healthcare.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'  
import SimpleSection from '../../common-component/SimpleSection/SimpleSection.js'
import SpecialSliderSection from "../../common-component/SpecialSliderSection/SpecialSliderSection"
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'
import GreyCardsSection from '../../common-component/GreyCardsSection/GreyCardsSection.js'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'


import { useNavigate } from 'react-router-dom';

import API from "../../../API.js"
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import Slider from 'react-slick'




const Healthcare = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
    setIsLoading(true)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        setBlogData(response.data)
        setIsLoading(false)

      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)

      });
  }, [])

  
  const sec3Data = [
    { title: "Software Development", description: "Crafted with care, our healthcare software solutions prioritise patient well-being, streamline administrative tasks, and ensure data accuracy." },
    { title: "Cloud Migration", description: "Elevate your healthcare practice with a smooth and secure transition to the cloud, providing flexibility, accessibility, and peace of mind." },
    { title: "Cybersecurity Solutions", description: "Ensuring the protection of your patient's privacy is our utmost priority. Our comprehensive cybersecurity solutions ensure trust and confidence." },
    { title: "Infrastructure Management", description: "Behind every successful healthcare operation is reliable infrastructure management. We ensure seamless performance to support patient care." }
  ]

  const sec5Data = [
    {
      title: "Enhanced Efficiency",
      description: "Streamline workflows and automate tasks to boost operational efficiency and productivity within your healthcare organisation with our tailored IT solutions.",
    },
    {
      title: "Improved Patient Care",
      description: "Empower healthcare providers with advanced technology tools for personalised care delivery, leading to improved patient satisfaction and outcomes.",
    },
    {
      title: "Enhanced Data Security",
      description: "Ensure patient data's confidentiality, integrity, and availability while maintaining compliance with healthcare regulations using our security measures.",
    },

    
    {
      title: "Cost Savings",
      description: "Optimise resource allocation, minimise operational costs and achieve economies of scale with our cost-effective IT solutions tailored for healthcare.",
    },
    {
      title: "Scalability & Future-Readiness",
      description: "Stay ahead of industry trends and seamlessly adapt to evolving healthcare needs with our scalable and future-ready IT infrastructure and services.",
    },



  ]

  
  const data = [
    {
      title: "Electronic Health Record System",
      description: "Manage patient data seamlessly, ensuring accuracy and accessibility while facilitating efficient information sharing among healthcare providers.",
    },

    {
      title: "Patient Management Portal",
      description: "Empower patients with easy access to appointments, records, and communication channels, enhancing engagement and care coordination.",
    },
    {
      title: "Clinical Decision Support System",
      description: "Optimise clinical workflows with real-time insights and evidence-based recommendations, improving decision-making and patient outcomes.",
    },
    {
      title: "Telehealth Integration",
      description: "Revolutionise healthcare delivery through integrated telehealth, enabling remote consultations, monitoring, and seamless virtual care experiences for all.",
    },
    
  ]

  const sec6Data=[
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
   
    
  ]
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };




  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index)
  };

  const sliderRef=useRef(null)
  
    const next = () => {
      sliderRef.current.slickNext();
  };
  
  const previous = () => {
      sliderRef.current.slickPrev();
  };

  const getImageSrc = (index) => {
    switch (index) {
      case 0:
        return "/images/healthcare/sec-3-1.png";
      case 1:
        return "/images/healthcare/sec-3-2.png";
      case 2:
        return "/images/healthcare/sec-3-3.png";
      case 3:
        return "/images/healthcare/sec-3-4.png";
      default:
        return "/images/healthcare/sec-3-1.png";
    }
  };
  

  useEffect(() => {
    const imgs = document.querySelectorAll(".img-mark");
  
    if (imgs.length === 0) {
      // Exit if there are no images found
      return;
    }
  
    imgs.forEach((img, index) => {
      if (index === currentSlide) {
        img.classList.add("active");
      } else {
        img.classList.remove("active");
      }
    });
  }, [currentSlide]);







  //bluecontainer
  

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
      
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
    ]

  };



  const sliderRef2=useRef(null)
  
    const next2 = () => {
      sliderRef2.current.slickNext();
  };
  
  const previous2 = () => {
      sliderRef2.current.slickPrev();
  };
  const [isToggled, setIsToggled] = useState(false);
  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  const [content1, setContent1]=useState(false);
   
  const cont1btn=()=>{
    setContent1(!content1);
  }


  


  return (
    <div className='healthcare-page'>

<BannerSubPages
        imglink={"/images/healthcare/main-banner.png"}
        pageName={"healthcare"}
        blueTitle={"Healthcare"}
        titleWeb={"Streamlining Healthcare<br/> with IT Expertise"}
        titleTab={"Streamlining<br/> Healthcare with IT<br/> Expertise"}
        titleMob={"Streamlining<br/> Healthcare with<br/> IT Expertise"}
        subTitleWeb={"We lead the way in healthcare-focused<br/> IT innovations."}
        subTitleTab={"We lead the way in healthcare-focused<br/> IT innovations."}
        subTitleMob={"We lead the way in<br/> healthcare-focused IT<br/> innovations."}
        iconTopWeb={'10%'}
        iconLeftWeb={'90%'}
        iconTopTab={'40%'}
        iconLeftTab={'75%'}
        iconTopMob={'44%'}
        iconLeftMob={'72%'}
        iconRotationWeb={80}
        iconRotationTab={30}
        iconRotationMob={81}>
      </BannerSubPages>

      {/* <SimpleSection
        pageName={"healthcare"}
        secNo={"2"}
        headdingPresent={true}
        buttonPresent={false}
        imagePosition={"right"}
        headingWeb={"All-in-One<br/> Healthcare IT Partner"}
        headingTab={"All-in-One Healthcare IT Partner"}
        headingMob={"All-in-One Healthcare IT Partner"}
        description={"We transform healthcare operations with advanced IT solutions, optimising efficiency, ensuring data security, and meeting regulatory standards for enhanced patient outcomes."}
        buttonText={""}
      /> */}


<div className='img-sec2-itoutsource'>

 

  <div className='for-text'>
    <span>All-in-One Healthcare IT Partner</span>
    <p>We transform healthcare operations with advanced IT solutions, optimising efficiency, ensuring data security, and meeting regulatory standards for enhanced patient outcomes.</p>

  </div>

  <div className='for-img'>
    <img src="\images\healthcare\sec-2.png"/>

  </div>

</div>

  

<div className='white-head'>
  <div className='headfont'>IT Services Specialised for Healthcare</div>
  <p>Our commitment to healthcare shines through our bespoke IT services, designed to streamline operations, fortify security, and elevate patient experiences.</p>
  
</div>




<div className='complex-slider'>
  <div className='width50'>
    <div className='arow-cont'>
    <img src="\images\healthcare\arrow-cs.svg" onClick={previous}/>
<img src="\images\healthcare\arrow2-cs.svg" onClick={next}/>
</div>
  <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
        <div>
        <div className='card'>
          <span className='montserrat-hard'>Software Development</span>
          <p className='montserrat-lit '>Crafted with care, our healthcare software solutions prioritise patient well-being, streamline administrative tasks, and ensure data accuracy.</p>
        </div>
        </div>
        
        <div>
        <div className='card'>
          <span className='montserrat-hard'> Cloud Migration</span>
          
          <p className='montserrat-lit '>Elevate your healthcare practice with a smooth and secure transition to the cloud, providing flexibility, accessibility, and peace of mind.</p>
        </div>
        </div>

        <div>
        <div className='card'>
          <span className='montserrat-hard'> Infrastructure Management</span>
          <p className='montserrat-lit '>
          Behind every successful healthcare operation is reliable infrastructure management. We ensure seamless performance to support patient care.</p>
        </div>
        </div>

        <div>
        <div className='card'>
          <span className='montserrat-hard'>Cybersecurity Solutions
       </span>
          <p className='montserrat-lit '>   Ensuring the protection of your patient's privacy is our utmost priority. Our comprehensive cybersecurity solutions ensure trust and confidence.</p>
        </div>
        </div>
        
      </Slider>
    </div>

  </div>
  <div className='width50-2'>
  
 
  {sec3Data.map((_, index) => (
    <img key={index} className='img-mark' src={getImageSrc(index)} alt="Healthcare" />
  ))}
    
    </div>
</div>





















{/* <SpecialSliderSection 
      pageName={"healthcare"}
      secNo={"3"}
      
      headingBoxPresent={true}
      headingWeb={"IT Services Specialised<br/> for Healthcare"}
      headingTab={"IT Services Specialised<br/> for Healthcare"}
      headingMob={"IT Services Specialised for Healthcare"}
      descriptionWeb={"Our commitment to healthcare shines through our bespoke IT services, designed to<br/> streamline operations, fortify security, and elevate patient experiences."}
      descriptionTab={"Our commitment to healthcare shines through our bespoke IT services, designed to streamline operations, fortify security, and elevate patient experiences."}
      descriptionMob={"Our commitment to healthcare shines through our bespoke IT services, designed to streamline operations, fortify security, and elevate patient experiences."}
      boxAlignment={"center"}
      
      subTitle={""}
      data={sec3Data} 
      /> */}

{/* 
      <GreyCardsSection
        pageName={''}
        headingWeb={"Advanced Software Solutions"}
        headingTab={"Advanced Software Solutions"}
        headingMob={"Advanced Software Solutions"}
        descriptionWeb={"We offer software solutions designed to address diverse industry needs, optimise operations, and drive performance through advanced features and functionality."}
        descriptionTab={"We offer software solutions designed to address diverse industry needs, optimise operations, and drive performance through advanced features and functionality."}
        descriptionMob={"We offer software solutions designed to address diverse industry needs, optimise operations, and drive performance through advanced features and functionality."}
        cardsData={ sec4Data}

      /> */}









<div id="targetDiv"  className={`blueslider-update ${isToggled ? 'black-bg' : ''}`}>

  <div className='head montserrat-hard'>
  Advanced Software Solutions  </div>

  <p className='montserrat-lit'>We offer software solutions designed to address diverse industry needs, optimise operations, and drive performance through advanced features and functionality.</p>
  <div className='combine-btn'>
  <div className='arrow'>

    <img src="\images\protect-your-customers-data\1.svg" onClick={previous2}/>
    <img src="\images\protect-your-customers-data\Frame (1).svg" onClick={next2}/>

  </div>

  {/* <div className="toggle-container">
          <input type="checkbox" id="toggleButton" className="toggle-checkbox" onChange={handleToggleChange} />
          <label htmlFor="toggleButton" className="toggle-label">
            <span className="toggle-button">
            <img src="\images\protect-your-customers-data\icons8-open-lock.svg" className='im1'/>
            <img src="\images\protect-your-customers-data\icons8-lock.svg" className='im2'/>
            </span>
          </label>
        </div> */}
        </div>



       
  <div className='slickblue-update montserrat-lit'>
  <div className="slider-container ">
      <Slider ref={sliderRef2} {...settings2}>
        <div>
         <div className='cardofblue-update'>
          <span> Electronic Health Record System</span>
          <p>Manage patient data seamlessly, ensuring accuracy and accessibility while facilitating efficient information sharing among healthcare providers.</p>
         </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Patient Management Portal </span>
        <p>Empower patients with easy access to appointments, records, and communication channels, enhancing engagement and care coordination.</p>

        </div>
        </div>
        <div>
        <div className='cardofblue-update'>

        <span>Clinical Decision Support System</span>
        <p>Optimise clinical workflows with real-time insights and evidence-based recommendations, improving decision-making and patient outcomes.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Telehealth Integration</span>
        <p>Revolutionise healthcare delivery through integrated telehealth, enabling remote consultations, monitoring, and seamless virtual care experiences for all.</p>
        </div>
        </div>
       
       
        
       
      </Slider>
    </div>
    

  </div>



</div>







<div className='pink-card montserrat-lit'>

<div className='head1'>
<div className='texthed'>Why Choose our Healthcare IT Services</div>
<p></p>
</div>

<div className='head2'>
<div className='each-sec'>
<span>Enhanced Efficiency</span>
<p>Streamline workflows and automate repetitive tasks to boost operational efficiency and productivity within your healthcare organisation with our tailored IT solutions. Our advanced systems integrate seamlessly, reducing administrative burdens and allowing staff to focus on patient care, ultimately enhancing overall organisational performance.</p>
</div>

<div className='each-sec'>
<span>Improved Patient Care
</span>
<p>
Empower healthcare providers with advanced technology tools for personalised care delivery, leading to improved patient satisfaction and outcomes. Our solutions facilitate accurate diagnosis, effective treatment plans, and continuous patient monitoring, ensuring a higher quality of care and fostering patient trust and loyalty.

</p>
</div>

<div className='each-sec'>
<span>Enhanced Data Security
</span>
<p>

Ensure patient data's confidentiality, integrity, and availability while maintaining compliance with healthcare regulations using our robust security measures. Our comprehensive security protocols protect against breaches, safeguard sensitive information, and ensure uninterrupted access to critical data, enhancing patient and provider confidence.
</p>
</div>

<div className='each-sec'>
<span>Cost Savings</span>
<p>

Optimise resource allocation, minimise operational costs, and achieve economies of scale with our cost-effective IT solutions tailored for healthcare. By streamlining processes and reducing inefficiencies, our solutions help you maximise your budget, allowing for reinvestment in patient care and organisational growth.

</p>
</div>
<div className='each-sec'>
<span>Enhanced Adaptability</span>
<p>
Embrace rapid changes in technology and market demands by leveraging our IT resources for quick scalability and adaptation. Our flexible solutions ensure that your IT infrastructure can swiftly adjust to new requirements, whether it's scaling up during peak periods or integrating the latest technologies. Stay competitive and responsive to market shifts with our adaptable and dynamic IT support.
</p>
</div>

<div className='each-sec'>
<span>Scalability & Future-Readiness
</span>
<p>
Stay ahead of industry trends and seamlessly adapt to evolving healthcare needs with our scalable and future-ready IT infrastructure and services. Our flexible solutions support growth and innovation, ensuring your organisation can respond to new challenges and opportunities with agility and resilience.

</p>
</div>


</div>
</div>





















{/* <BlueNotchSection
        headingWeb={"Why Choose our<br/> Healthcare IT Services"}     // <br/>
        headingTab={"Why Choose our<br/> Healthcare IT Services"}
        headingMob={"Why Choose our<br/> Healthcare IT<br/> Services"}
        descriptionPresent={false}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        listData={sec5Data}

      /> */}





<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>



      <div className='sec-7'>

      </div>




      <BottomBanner
        pageName={"healthcare"}
        titleWeb={"Discover the Future<br/> of Healthcare IT"}
        titleTab={"Discover the Future of<br/> Healthcare IT"}
        titleMob={"Discover the<br/> Future of<br/> Healthcare IT"}
                
        subTitlePresent={true}
        subTitleWeb={"Let's collaborate for unparalleled<br/> innovation and growth."}
        subTitleTab={"Let's collaborate for unparalleled<br/> innovation and growth."}
        subTitleMob={"Let's collaborate for unparalleled<br/> innovation and growth."}

        buttonText={"contact us now"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default Healthcare