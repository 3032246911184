import React, { useEffect, useState, useRef } from 'react';
import './Insights.scss';
import Banner from '../../common-component/BannerMainPages/BannerMainPages.js';
import BottomBanner from '../../common-component/BottomBanner/BottomBanner';
import SimpleSection from '../../common-component/SimpleSection/SimpleSection';
import { useNavigate } from 'react-router-dom';
import API from "../../../API.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js";
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";

import $ from 'jquery';
import WhiteBanner from '../../common-component/BannerMainPages/WhiteBanner.js';
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js';

const View = () => {
  const navigate = useNavigate();
  const [sec2Data, setSec2Data] = useState(null);
  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [slideSelectedIndex, selectSlide] = useState(0);

  const slideRefs = useRef([]);


  const scrollToElement = (index) => {
    const current = slideRefs.current[index];
    if (current) {
      const slider = document.querySelector('.slider');
      const sliderRect = slider.getBoundingClientRect();
      const currentRect = current.getBoundingClientRect();
      const scrollLeft = currentRect.left - sliderRect.left - (sliderRect.width - currentRect.width) / 2;
      current.scrollIntoView({ behavior: "smooth", block: "nearest" }); // Vertical scroll
      slider.scrollTo({
        left: slider.scrollLeft + scrollLeft,
        behavior: "smooth" // Horizontal scroll
      });

      // Center the active menu scroll
      const menuScroller = document.querySelector('.menu-scroller');
      const activeMenuScroll = document.querySelector('.small-card.active');
      if (menuScroller && activeMenuScroll) {
        const menuScrollerRect = menuScroller.getBoundingClientRect();
        const activeMenuScrollRect = activeMenuScroll.getBoundingClientRect();
        const scrollOffset = activeMenuScrollRect.left - menuScrollerRect.left - (menuScrollerRect.width - activeMenuScrollRect.width) / 2;
        menuScroller.scrollTo({
          left: menuScroller.scrollLeft + scrollOffset,
          behavior: "smooth" 
        });
      }
    }
  };



  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])

  useEffect(() => {
    scrollToElement(slideSelectedIndex);
  }, [slideSelectedIndex]);

  useEffect(() => {
    // API.get(`blogs/?page_name=insights&section_number=2`)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        console.log("response.data", response.data)
        setSec2Data(response.data)
      })
      .catch((error) => {
        setIsErrorModalOpen(true);
        setMessage(error.message || "An error occurred.");
      });
  }, []);

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };

  return (
    <div className='insights-page'>
      <WhiteBanner
        imglink={"/images/insights/Rectangle 195.png"}
        pageName={"insights"}
        titleWeb={"Discover, Collaborate<br/> and Thrive"}
        titleTab={"Discover, Collaborate<br/> and Thrive"}
        titleMob={"Discover,<br/> Collaborate<br/> and Thrive"}
        subTitleWeb={"Explore our blog, partnership opportunities, and<br/> rewarding career paths."}
        subTitleTab={"Explore our blog, partnership opportunities, and<br/> rewarding career paths."}
        subTitleMob={"Explore our blog, partnership<br/> opportunities, and rewarding<br/> career paths."}
      />


<div className='sec2-insight'>

<div className='card-insight-ab'>
<div className='card-head'>
  <img src="\images\insights\Rectangle 191 (4).png"/>
 

</div>
<div className='card-body'>
<span className='title-in montserrat-hard'>Design Marketing</span>
  <div className='btn-div-in montserrat-lit'>
    <div className='btn-gr'>banking</div>
    <div className='btn-bl'>ui/ux dESIGN</div>
  </div>
  <div className='montserrat-lit auth'><span>Author</span>
  <p className='name'>Anu</p>
  </div>
  <div className='montserrat-lit dat'>16 June, 2024</div>

</div>



</div>



<div className='card-insight'>
<div className='card-head'>
  <img src="\images\insights\Rectangle 191 (3).png"/>
 

</div>
<div className='card-body'>
<span className='title-in montserrat-hard'>Design Marketing</span>
  <div className='btn-div-in montserrat-lit'>
    <div className='btn-gr'>banking</div>
    <div className='btn-bl'>ui/ux dESIGN</div>
  </div>
  <div className='montserrat-lit auth'><span>Author</span>
  <p className='name'>Anu</p>
  </div>
  <div className='montserrat-lit dat'>16 June, 2024</div>

</div>



</div>




<div className='card-insight'>
<div className='card-head'>
  <img src="\images\insights\Rectangle 191.png"/>
 

</div>
<div className='card-body'>
<span className='title-in montserrat-hard'> Modern SEO </span>
  <div className='btn-div-in montserrat-lit'>
    <div className='btn-gr'>banking</div>
    <div className='btn-bl'>ui/ux dESIGN</div>
  </div>
  <div className='montserrat-lit auth'><span>Author</span>
  <p className='name'>Rahul</p>
  </div>
  <div className='montserrat-lit dat'>16 June, 2024</div>

</div>



</div>



<div className='card-insight'>
<div className='card-head'>
  <img src="\images\insights\Rectangle 191 (5).png"/>
 

</div>
<div className='card-body'>
<span className='title-in montserrat-hard'>Security Planning</span>
  <div className='btn-div-in montserrat-lit'>
    <div className='btn-gr'>banking</div>
    <div className='btn-bl'>ui/ux dESIGN</div>
  </div>
  <div className='montserrat-lit auth'><span>Author</span>
  <p className='name'>Joseph</p>
  </div>
  <div className='montserrat-lit dat'>16 June, 2024</div>

</div>



</div>



<div className='card-insight'>
<div className='card-head'>
  <img src="\images\insights\Rectangle 191 (2).png"/>
 

</div>
<div className='card-body'>
<span className='title-in montserrat-hard'>Business Analysis</span>
  <div className='btn-div-in montserrat-lit'>
    <div className='btn-gr'>banking</div>
    <div className='btn-bl'>ui/ux dESIGN</div>
  </div>
  <div className='montserrat-lit auth'><span>Author</span>
  <p className='name'>Arun</p>
  </div>
  <div className='montserrat-lit dat'>16 June, 2024</div>

</div>



</div>


<div className='card-insight'>
<div className='card-head'>
  <img src="\images\insights\Rectangle 191 (5).png"/>
 

</div>
<div className='card-body'>
<span className='title-in montserrat-hard'>Modern CyberSecurity</span>
  <div className='btn-div-in montserrat-lit'>
    <div className='btn-gr'>banking</div>
    <div className='btn-bl'>ui/ux dESIGN</div>
  </div>
  <div className='montserrat-lit auth'><span>Author</span>
  <p className='name'>Midhun</p>
  </div>
  <div className='montserrat-lit dat'>16 June, 2024</div>

</div>



</div>

<div className='card-insight'>
<div className='card-head'>
  <img src="\images\insights\Rectangle 191 (3).png"/>
 

</div>
<div className='card-body'>
<span className='title-in montserrat-hard'>IT market</span>
  <div className='btn-div-in montserrat-lit'>
    <div className='btn-gr'>banking</div>
    <div className='btn-bl'>ui/ux dESIGN</div>
  </div>
  <div className='montserrat-lit auth'><span>Author</span>
  <p className='name'>Akshay P k</p>
  </div>
  <div className='montserrat-lit dat'>16 June, 2024</div>

</div>



</div>
</div>







<div className='sec3-insight'>
  <div className='fortext'>
    <p className='tlt montserrat-hard'>Be a Partner</p>
    <p className='subtlt montserrat-lit '>ZOG Global prioritises partnerships for growth and impact, uniting with public, private, and corporate allies to share knowledge, experience, commitment, and investments, improving the world collaboratively.</p>
    <span type="button" onClick={() => navigate("/contact-us")}>GET STARTED</span>
  </div>
  <div className='forimg'>
    <img src="\images\insights\Banner2.png"/>

  </div>
</div>




<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>





<div className='sec4-banner-insight'>
  <img src="\images\insights\bottom-banner-web.png"/>

   <div className='half2-sec4'>
        <span className='montserrat-hard'>Start your Career Journey</span>
        <p className='montserrat-lit'>Begin your journey towards your dream career by taking the first step from here.</p>
        <button type="button" onClick={() => navigate("/career")} className='montserrat-lit'>check out latest vacancies</button>


      </div>
  

</div>

















      {/* <div className='sec-2'>
        <div className='scroll-bar-hider-main'>
          <div className='slider' >
            <div className='slider-list'>
              {sec2Data && sec2Data.map((item, index) => (
                <div className='big-card' key={index} ref={el => slideRefs.current[index] = el}>
                <div className='image-box' style={{ backgroundImage: `url(${item.image_url})` }}>
</div>
                  <div className='text-box'>
                    <div className='date'>{item.created_at}</div>
                    <div className='title'>{item.title}</div>
                    <div className='description'>{item.department}</div>
                    <div className='auther'>{item.extra}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='scroll-bar-hider-sub'>
        <div className='menu-scroller'>
          <div className='menu-track'>
            {sec2Data && sec2Data.map((item, index) => (
              <div className={slideSelectedIndex == index ? 'small-card active' : 'small-card'} key={index} onClick={() => selectSlide(index)}>
                <div className='date'>{item.created_at}</div>
                <div className='title'>{item.title}</div>
                <div className='description'>{item.department}</div>
                <div className='auther'>{item.extra}</div>
              </div>
            ))}
          </div>
        </div>
        </div>
        <div className='arrow-box'>
          <img src='images/insights/sec-2-prev-arrow.svg' onClick={() => selectSlide(slideSelectedIndex - 1)}></img>
          <img src='images/insights/sec-2-next-arrow.svg' onClick={() => selectSlide(slideSelectedIndex + 1)}></img>
        </div>
        <button className='button-black-box' onClick={()=>navigate('/blog')}>DISCOVER MORE</button>
      </div> */}


      {/* <SimpleSection
        pageName={"insights"}
        secNo={"3"}
        type={2}
        headdingPresent={true}
        buttonPresent={true}
        imagePosition={"right"}
        headingWeb={"Be a Partner"}
        headingTab={"Be a Partner"}
        headingMob={"Be a Partner"}
        description={"ZOG Global prioritises partnerships for growth and impact, uniting with public, private, and corporate allies to share knowledge, experience, commitment, and investments, improving the world collaboratively."}
        buttonText={"Get Started"}
      /> */}
      {/* <BottomBanner
        pageName={"insights"}
        titleWeb={"Start Your<br/> Career Journey"}
        titleTab={"Start<br/> Your Career Journey"}
        titleMob={"Start Your Career<br/> Journey"}
        subTitlePresent={true}
        subTitleWeb={"Begin your journey towards your dream career by taking<br/> the first step from here."}
        subTitleTab={"Begin your journey towards<br/> your dream career<br/> by taking the first step from here."}
        subTitleMob={"Begin your journey towards your dream<br/> career by taking the first step from<br/> here."}
        buttonText={"CHECK OUR LATEST VACANCIES"}
        buttonTheme={"light"}
        buttonLink={"/career"}
        textTheme={"light"}
      /> */}
      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}
    </div>
  );
}

export default View;
