import React, { useEffect, useRef } from 'react'
import "./BannerSubPages.scss"

function BannerSubPagesold({ imglink, pageName, titleWeb, titleTab, titleMob, subTitleWeb, subTitleTab, subTitleMob, title1Web, title2Tab, title3Mob }) {

    const renderHTML = (text) => ({ __html: text });
    const mainHRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-viewm');
          } else {
            entry.target.classList.remove('in-viewm');
          }
        },
        {
          threshold: 0.1,
        }
      );
      const elementsToObserve = [mainHRef.current];
      elementsToObserve.forEach((element) => {
        if (element) observer.observe(element);
      });

      return () => {
        elementsToObserve.forEach((element) => {
          if (element) observer.unobserve(element);
        });
      };
    }, []);



    
  return (
    <div className='subbanner-body'>
    <img src={`/images/${pageName}/main-banner.png`} className='img-ani' alt={pageName} />
  <div
    className="inner"
    id="idban"
    ref={mainHRef}
    
  >
  

    <div className='sub-title1 montserrat-bold only-web' dangerouslySetInnerHTML={renderHTML(title1Web)}></div>
    <div className='sub-title1 montserrat-bold only-tab' dangerouslySetInnerHTML={renderHTML(title2Tab)}></div>
    <div className='sub-title1 montserrat-bold only-mob' dangerouslySetInnerHTML={renderHTML(title3Mob)}></div>

    <div className='title montserrat-bold only-web' dangerouslySetInnerHTML={renderHTML(titleWeb)}></div>
    <div className='title montserrat-bold only-tab' dangerouslySetInnerHTML={renderHTML(titleTab)}></div>
    <div className='title montserrat-bold only-mob' dangerouslySetInnerHTML={renderHTML(titleMob)}></div>

    <div className='sub-title montserrat-lit only-web' dangerouslySetInnerHTML={renderHTML(subTitleWeb)}></div>
    <div className='sub-title montserrat-lit only-tab' dangerouslySetInnerHTML={renderHTML(subTitleTab)}></div>
    <div className='sub-title montserrat-lit only-mob' dangerouslySetInnerHTML={renderHTML(subTitleMob)}></div>
  </div>
</div>
  )
}

export default BannerSubPagesold