import React, { useEffect, useRef, useState } from 'react'
import './Pharma.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'
import SimpleSection from '../../common-component/SimpleSection/SimpleSection.js'
import SpecialSliderSection from "../../common-component/SpecialSliderSection/SpecialSliderSection"
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'

import { useNavigate } from 'react-router-dom';

import API from "../../../API.js"
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import Slider from 'react-slick'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'



const Pharma = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
    setIsLoading(true)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        setBlogData(response.data)
        setIsLoading(false)

      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)

      });
  }, [])


  const sec3Data = [
    { title: "RPA Solutions", description: "Streamline processes, enhance efficiency, and reduce errors with our advanced Robotic Process Automation solutions." },
    { title: "AI & Machine Learning", description: "Leverage cutting-edge AI and Machine Learning technologies for predictive analytics and optimised decision-making." },
    { title: "Cybersecurity Solutions", description: "Protect sensitive data and ensure compliance with robust cybersecurity solutions tailored for the pharmaceutical sector." }
  ]

  const sec4Data = [
    {
      title: "Efficiency Enhancement",
      description: "Streamline repetitive tasks, reducing human error and operational costs in the face of stringent regulatory requirements.",
    },
    {
      title: "Optimised Drug Development",
      description: "Accelerate research and development cycles, navigating complex data landscapes to identify trends and insights efficiently.",
    },
    {
      title: "Robust Data Protection",
      description: "Safeguard sensitive patient information and intellectual property from evolving cyber threats, ensuring compliance with regulations.",
    },
    {
      title: "Enhanced Regulatory Compliance",
      description: "Leverage automated processes to ensure adherence to UK pharmaceutical regulations, mitigating risks and avoiding penalties.",
    },
    {
      title: "Personalised Patient Care",
      description: "Utilise AI algorithms to analyse patient data, enabling personalised treatment plans and enhancing healthcare outcomes across the UK.",
    },



  ]


  const sec5Data = [

    {
      title: "Trädmord infodemi dekaledes",
      auther: "By John Doe",
      date: "Jan 30, 2024",
      department: "Banking, UI/UX"
    },
    {
      title: "Trädmord infodemi dekaledes",
      auther: "By John Doe",
      date: "Jan 30, 2024",
      department: "Banking, UI/UX"
    },
    {
      title: "Trädmord infodemi dekaledes",
      auther: "By John Doe",
      date: "Jan 30, 2024",
      department: "Banking, UI/UX"
    },
    {
      title: "Trädmord infodemi dekaledes",
      auther: "By John Doe",
      date: "Jan 30, 2024",
      department: "Banking, UI/UX"
    },
    {
      title: "Trädmord infodemi dekaledes",
      auther: "By John Doe",
      date: "Jan 30, 2024",
      department: "Banking, UI/UX"
    },

  ]

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };





  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index)
  };

  const sliderRef = useRef(null)

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const getImageSrc = (index) => {
    switch (index) {
      case 0:
        return "/images/pharma/sec-3-1.png";
      case 1:
        return "/images/pharma/sec-3-2.png";
      case 2:
        return "/images/pharma/sec-3-3.png";

      default:
        return "/images/pharma/sec-3-1.png";
    }
  };


  useEffect(() => {
    const imgs = document.querySelectorAll(".img-mark");

    if (imgs.length === 0) {
      // Exit if there are no images found
      return;
    }

    imgs.forEach((img, index) => {
      if (index === currentSlide) {
        img.classList.add("active");
      } else {
        img.classList.remove("active");
      }
    });
  }, [currentSlide]);











  return (
    <div className='pharma-page'>

      <BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"pharma"}
        blueTitle={"Pharma"}
        titleWeb={"Infusing Innovation<br/> into Pharma"}
        titleTab={"Infusing Innovation<br/> into Pharma"}
        titleMob={"Infusing<br/> Innovation into Pharma"}
        subTitleWeb={"We help pharma businesses on the<br/> journey to thrive in the tech world."}
        subTitleTab={"We help pharma businesses on the<br/> journey to thrive in the tech world."}
        subTitleMob={"We help pharma businesses<br/> on the journey to thrive in<br/> the tech world."}
        iconTopWeb={'53%'}
        iconLeftWeb={'83%'}
        iconTopTab={'58%'}
        iconLeftTab={'70%'}
        iconTopMob={'53%'}
        iconLeftMob={'70%'}
        iconRotationWeb={37}
        iconRotationTab={22}
        iconRotationMob={20}>
      </BannerSubPages>


      {/* <SimpleSection
        pageName={"pharma"}
        secNo={"2"}
        headdingPresent={true}
        buttonPresent={false}
        imagePosition={"right"}
        headingWeb={"Enhance Drug<br/> Development"}
        headingTab={"Enhance Drug Development"}
        headingMob={"Enhance Drug<br/> Development"}
        description={"Digital technology is revolutionising the pharma industry, and one exciting example is AI. With its ability to analyse enormous amounts of data, AI is uncovering valuable patterns and connections, leading to faster and higher-quality drug development."}
        buttonText={""}
      /> */}



      <div className='img-sec2-itoutsource'>



        <div className='for-text'>
          <span>Enhance Drug Development</span>
          <p>Digital technology is revolutionising the pharma industry, and one exciting example is AI. With its ability to analyse enormous amounts of data, AI is uncovering valuable patterns and connections, leading to faster and higher-quality drug development.</p>

        </div>

        <div className='for-img'>
          <img src="\images\pharma\sec-2.png" />

        </div>

      </div>








      <div className='white-head'>
        <div className='headfont'>IT Solutions for Pharma Industry</div>
        <p>ZOG Global offers a range of specialised IT solutions to empower pharmaceutical companies with innovative technologies that drive efficiency, compliance, and growth.</p>

      </div>




      <div className='complex-slider'>
        <div className='width50'>
          <div className='arow-cont'>
            <img src="\images\healthcare\arrow-cs.svg" onClick={previous} />
            <img src="\images\healthcare\arrow2-cs.svg" onClick={next} />
          </div>
          <div className="slider-container">
            <Slider {...settings} ref={sliderRef}>
              <div>
                <div className='card'>
                  <span className='montserrat-hard'>
                    RPA Solutions
                  </span>
                  <p className='montserrat-lit '>
                    Streamline processes, enhance efficiency, and reduce errors with our advanced Robotic Process Automation solutions.


                  </p>
                </div>
              </div>

              <div>
                <div className='card'>
                  <span className='montserrat-hard'> AI & Machine Learning

                  </span>

                  <p className='montserrat-lit '>
                    Leverage cutting-edge AI and Machine Learning technologies for predictive analytics and optimised decision-making.


                  </p>
                </div>
              </div>

              <div>
                <div className='card'>
                  <span className='montserrat-hard'>
                    Cybersecurity Solutions
                  </span>
                  <p className='montserrat-lit '>
                    Protect sensitive data and ensure compliance with robust cybersecurity solutions tailored for the pharmaceutical sector.


                  </p>
                </div>
              </div>


            </Slider>
          </div>

        </div>
        <div className='width50-2'>


          {sec3Data.map((_, index) => (
            <img key={index} className='img-mark' src={getImageSrc(index)} alt="Healthcare" />
          ))}

        </div>
      </div>









      

<VioletCardsSection cardsData={sec4Data}headingWeb={"How you Benefit from Our Tech Solutions"} headingTab={"How you Benefit from Our Tech Solutions"} headingMob={"How you Benefit from Our Tech Solutions"}></VioletCardsSection>





      {/* 

<SpecialSliderSection 
      pageName={"pharma"}
      secNo={"3"}
      
      headingBoxPresent={true}
      headingWeb={"IT Solutions<br/> for Pharma Industry"}
      headingTab={"IT Solutions<br/> Pharma Industry"}
      headingMob={"IT Solutions for<br/> Pharma Industry"}
      descriptionWeb={"ZOG Global offers a range of specialised IT solutions to empower pharmaceutical<br/> companies with innovative technologies that drive efficiency, compliance, and growth."}
      descriptionTab={"ZOG Global offers a range of specialised IT solutions to empower pharmaceutical companies with innovative technologies that drive efficiency, compliance, and growth."}
      descriptionMob={"ZOG Global offers a range of specialised IT solutions to empower pharmaceutical companies with innovative technologies that drive efficiency, compliance, and growth."}
      boxAlignment={"center"}
      
      subTitle={""}
      data={sec3Data} 
      />


      <BlueNotchSection
        headingWeb={"How you Benefit<br/> from Our Tech Solutions"}     // <br/>
        headingTab={"How you Benefit from<br/> Our Tech Solutions"}
        headingMob={"How you<br/> Benefit from<br/> Our Tech<br/> Solutions"}
        descriptionPresent={false}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        listData={sec4Data}

      />


*/}







      <BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>






      <div className='sec-6'>

      </div>


      <div className='sec-7'>

      </div>




      <BottomBanner
        pageName={"pharma"}
        titleWeb={"Need IT Support?"}
        titleTab={"Need IT Support?"}
        titleMob={"Need IT Support?"}

        subTitlePresent={true}
        subTitleWeb={"Let's talk about your challenges<br/> and how we can help."}
        subTitleTab={"Let's talk about your<br/> challenges and how we can help."}
        subTitleMob={"Let's talk about your<br/> challenges and how we can help."}

        buttonText={"Get Started"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default Pharma