// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-section{
width: 70%;
}
.image-preview-box{
  margin-bottom: 37px;
}
@media (max-width:767px) {
  .form-section .w-50{
    width: 100% !important;
  } 
  .form-section .button-box{
    justify-content: space-between !important;
  }
}

.image-section{
width: 30%;
}
.img-section .img-error-msg {
    color: red;
    font-size: 10px;
    margin-bottom: 10px;
  }
.img-section  .delete-btn{
    background-color: rgb(48, 48, 48) !important;
    position: absolute;
    z-index: 3;
    bottom: 0;
    color:#fff;
    border-radius: 0;
  }
  .img-section  .delete-btn:hover{
    color:#eee
  }
@media (max-width: 992px){
    .form-section{
        width: 100%;
        }
        
        .image-section{
        width: 100%;
        }
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/Profile/Profile.css"],"names":[],"mappings":"AAAA;AACA,UAAU;AACV;AACA;EACE,mBAAmB;AACrB;AACA;EACE;IACE,sBAAsB;EACxB;EACA;IACE,yCAAyC;EAC3C;AACF;;AAEA;AACA,UAAU;AACV;AACA;IACI,UAAU;IACV,eAAe;IACf,mBAAmB;EACrB;AACF;IACI,4CAA4C;IAC5C,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,UAAU;IACV,gBAAgB;EAClB;EACA;IACE;EACF;AACF;IACI;QACI,WAAW;QACX;;QAEA;QACA,WAAW;QACX;AACR","sourcesContent":[".form-section{\r\nwidth: 70%;\r\n}\r\n.image-preview-box{\r\n  margin-bottom: 37px;\r\n}\r\n@media (max-width:767px) {\r\n  .form-section .w-50{\r\n    width: 100% !important;\r\n  } \r\n  .form-section .button-box{\r\n    justify-content: space-between !important;\r\n  }\r\n}\r\n\r\n.image-section{\r\nwidth: 30%;\r\n}\r\n.img-section .img-error-msg {\r\n    color: red;\r\n    font-size: 10px;\r\n    margin-bottom: 10px;\r\n  }\r\n.img-section  .delete-btn{\r\n    background-color: rgb(48, 48, 48) !important;\r\n    position: absolute;\r\n    z-index: 3;\r\n    bottom: 0;\r\n    color:#fff;\r\n    border-radius: 0;\r\n  }\r\n  .img-section  .delete-btn:hover{\r\n    color:#eee\r\n  }\r\n@media (max-width: 992px){\r\n    .form-section{\r\n        width: 100%;\r\n        }\r\n        \r\n        .image-section{\r\n        width: 100%;\r\n        }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
