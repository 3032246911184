// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-modal.retrieve .card {
  height: -moz-fit-content;
  height: fit-content;
  width: 40%;
  padding: 30px 35px 50px 35px;
}
.filter-modal.retrieve .card .card-content h1 {
  font-size: 20px;
  line-height: 30px;
  padding: 30px 0px;
  padding: 15px 10px;
  border-left: 15px solid #2694E3;
  text-transform: capitalize;
}
.filter-modal.retrieve .card .card-content .option {
  margin-top: 30px;
}
.filter-modal.retrieve .card .card-content .submit-section {
  padding-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/common-components/RetrievePopup/RetrievePopup.scss"],"names":[],"mappings":"AACI;EACI,wBAAA;EAAA,mBAAA;EACA,UAAA;EACA,4BAAA;AAAR;AAEY;EACI,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,kBAAA;EACA,+BAAA;EACA,0BAAA;AAAhB;AAEY;EACI,gBAAA;AAAhB;AAEY;EACI,iBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAhB","sourcesContent":[".filter-modal.retrieve{\r\n    .card{\r\n        height: fit-content;\r\n        width: 40%;\r\n        padding: 30px 35px 50px 35px;\r\n        .card-content{\r\n            h1{\r\n                font-size: 20px;\r\n                line-height: 30px;\r\n                padding: 30px 0px;\r\n                padding: 15px 10px;\r\n                border-left: 15px solid #2694E3;\r\n                text-transform: capitalize;\r\n            }\r\n            .option{\r\n                margin-top: 30px;\r\n            }\r\n            .submit-section{\r\n                padding-top: 20px;\r\n                width: 100%;\r\n                display: flex;\r\n                align-items: center;\r\n                justify-content: center;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
