import React, { useState, useEffect, useRef } from 'react';
import './BlogBasicSlider.scss';
import { getFontSizeFactor, renderDescription } from "../../../admin-panel/common-functions/commonFunctions"


const BlogBasicSlider = ({ TitleDescription, blogData, currentSlide,setCurrentSlide }) => {
  const containerRef = useRef(null);

    useEffect(() => {
        if (containerRef.current) {
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            const index = Number(entry.target.dataset.index);
                            setCurrentSlide(index);
                        }
                    });
                },
                { root: containerRef.current, threshold: 0.5 }
            );

            const slides = containerRef.current.querySelectorAll('.blog-slide');
            slides.forEach((slide) => observer.observe(slide));

            return () => {
                slides.forEach((slide) => observer.unobserve(slide));
            };
        }
    }, [setCurrentSlide]);

    useEffect(() => {
        if (containerRef.current) {
            const activeSlide = containerRef.current.querySelector('.blog-slide.active');
            if (activeSlide) {
                const containerRect = containerRef.current.getBoundingClientRect();
                const activeSlideRect = activeSlide.getBoundingClientRect();
                const offset = activeSlideRect.top - containerRect.top + containerRef.current.scrollTop;

                containerRef.current.scrollTo({
                    top: offset,
                    behavior: 'auto'
                });
            }
        }
    }, [currentSlide]);

    if (!blogData || blogData.length === 0) {
        return null;
    }
    // console.log(blogData);


    return (
        <div className="blog-slider-container" ref={containerRef}>
            <div className="blog-slider">
                <p>{TitleDescription}</p>
                {blogData.map((item, index) => (
                    <div key={index} data-index={index} className={`blog-slide ${index === currentSlide ? 'active' : 'hidden'}`}>
                        <h2>{item.sub_title}</h2>
                        {item.image_url &&
                            <img src={`${item.image_url}`} alt='' />
                        }
                        {/* <p>{item.description}</p> */}
                        <div className='description' dangerouslySetInnerHTML={{ __html: renderDescription(item.description,getFontSizeFactor()) }} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BlogBasicSlider;
