import React, {useEffect, useState} from 'react'
import './Career.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'

import { useNavigate} from 'react-router-dom';

import API from "../../../API.js"
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';


import Accordion from './CareerAccordion.js';


const Career = () => {
  const navigate = useNavigate();

 
  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  
 

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  // FILTER DATA
  const [sec2Data, setSec2Data] = useState(null);
  const [filters, setFilters] = useState({
    search_key: '',
    department: '',
    job_type: '',
    location: ''
  }); 
  useEffect(() => {
    fetchJobs();
  }, [filters]);

  const [jobDepartments, setJobDepartments] = useState([])
    // GET Job Departments from Backend
    useEffect(() => {
      API.get(`/recruitment/departments/`)
        .then((response) => {
          setJobDepartments(response.data)
          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }, []);

    const [jobLocations, setJobLocations] = useState([])
    // GET Job Locations from Backend
    useEffect(() => {
      API.get(`/recruitment/locations/`)
        .then((response) => {
          setJobLocations(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
        
    }, []);

  const fetchJobs = () => {
    const params = new URLSearchParams(filters);
    // console.log(params);
    API.get(`recruitment/jobs/?is_published=True&page=1&page_size=100&${params.toString()}`)
      .then((response) => {
        setSec2Data(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        setIsErrorModalOpen(true);
        setMessage(error.message || "An error occurred.");
      });
  };

  const [categoryListOpened, toggleCategoryList] = useState(false) 
  const [JobTypeListOpened, toggleJobTypeList] = useState(false)
  const [LocationListOpened, toggleLocationList] = useState(false)
    
    const selectCategory = (value) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        "department": value.id
      }));
    }
    const selectJobType = (value) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          "job_type": value
        }));
      }
      const selectLocation = (value) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          "location": value.id
        }));
      }

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value
    }));
  };

  const handleFilterSubmit = (event) => {
    // console.log(filters);
    event.preventDefault();
    fetchJobs();
  };

  useEffect(() => {
    const $OptionDropList = $("#category .option-list");
    if (categoryListOpened) {
      const height = $OptionDropList[0].scrollHeight;
      $OptionDropList.animate({ height: height + "px" }, 400);
      $OptionDropList.css("opacity", "1");
    } else {
      $OptionDropList.animate({ height: "0px" }, 400);
      $OptionDropList.css("opacity", "0");
    }
  }, [categoryListOpened]);
  useEffect(() => {
    const $OptionDropList = $("#job_type .option-list");
    if (JobTypeListOpened) {
      const height = $OptionDropList[0].scrollHeight;
      $OptionDropList.animate({ height: height + "px" }, 400);
      $OptionDropList.css("opacity", "1");
    } else {
      $OptionDropList.animate({ height: "0px" }, 400);
      $OptionDropList.css("opacity", "0");
    }
  }, [JobTypeListOpened]);
  useEffect(() => {
    const $OptionDropList = $("#location .option-list");
    if (LocationListOpened) {
      const height = $OptionDropList[0].scrollHeight;
      $OptionDropList.animate({ height: height + "px" }, 400);
      $OptionDropList.css("opacity", "1");
    } else {
      $OptionDropList.animate({ height: "0px" }, 400);
      $OptionDropList.css("opacity", "0");
    }
  }, [LocationListOpened]);

  return (
    <div className='career-page'>

<BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"career"}
        blueTitle={"careers"}
        titleWeb={"Innovate.<br/> Improve.<br/> Inspire."}
        titleTab={"Innovate.<br/> Improve.<br/> Inspire."}
        titleMob={"Innovate.<br/> Improve.<br/> Inspire."}
        subTitleWeb={""}
        subTitleTab={""}
        subTitleMob={""}
        iconTopWeb={'58%'}
        iconLeftWeb={'55%'}
        iconTopTab={'73%'}
        iconLeftTab={'29%'}
        iconTopMob={'65%'}
        iconLeftMob={'24%'}
        iconRotationWeb={20}
        iconRotationTab={257}
        iconRotationMob={260}>
      </BannerSubPages>


      <div className='sec-2'>
        <div className='form-data'>
          <h1 className='only-web'>Navigate Towards a<br/>Rewarding Career</h1>
          <h1 className='only-tab'>Navigate Towards a<br/>Rewarding Career</h1>
          <h1 className='only-mob'>Navigate Towards a Rewarding Career</h1>
          <p>Team up with ZOG Global to accelerate your career and craft moments of happiness.</p>

          <div className='form-section'>
            <form onSubmit={handleFilterSubmit}>
              <div class="row">
                <div class="col-lg-6 mb-5">
                  <input
                    type="text"
                    id="search_key"
                    name="search_key"
                    className={`form-control form-control-lg keyword`}
                    placeholder='Keyword'
                    value={filters.search_key}
                    onChange={handleFilterChange}
                  />
                </div>
                <div class="col-lg-6 mb-5">
                <div class="custom-select form-control form-select" id="category" name="category"
                    onClick={() => toggleCategoryList(!categoryListOpened)} >
                    <div className="selected-value">
                      {filters.department ? 
                        (jobDepartments.find(department => department.id === filters.department)?.name || "Department") : 
                        "Department"}
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_3810_8531" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                    <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_3810_8531)">
                    <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    </svg>
                    </div>
                    <div className='option-list'>
                      {jobDepartments.map(department => (
                        <div 
                          key={department.id} 
                          className='option' 
                          onClick={() => selectCategory(department)}
                        >
                          {department.name}
                        </div>
                      ))}
                      
                    </div>

                  </div>

              </div>
            </div>

            <div class="row">
             
              <div class="col-lg-6 mb-5">
                <div class="custom-select form-control form-select" id="job_type" name="job_type"
                    onClick={() => toggleJobTypeList(!JobTypeListOpened)} >
                    <div className="selected-value">{filters.job_type ? filters.job_type : "Job Type"}
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_3810_8531" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                    <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_3810_8531)">
                    <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    </svg>
                    </div>
                    <div className='option-list jobtype'>
                      <div className='option' onClick={() => selectJobType("full time")}>full time</div>
                      <div className='option' onClick={() => selectJobType("permanent")}>permanent</div>
                      <div className='option' onClick={() => selectJobType("contract")}>contract</div>
                 
                    </div>
                  </div>
              </div>
              <div class="col-lg-6 mb-5">
                <div class="custom-select form-control form-select" id="location" name="location"
                    onClick={() => toggleLocationList(!LocationListOpened)} >
                    <div className="selected-value">
                      {filters.location ? 
                        (jobLocations.find(location => location.id === filters.location)?.name || "Location") : 
                        "Location"}
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0_3810_8531" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                    <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_3810_8531)">
                    <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    </svg>
                    </div>
                    <div className='option-list'>
                      {jobLocations.map(location => (
                        <div 
                          key={location.id} 
                          className='option' 
                          onClick={() => selectLocation(location)}
                        >
                          {location.name}
                        </div>
                      ))}
                    </div>
                  </div>
                
              </div>
            </div>
            <div class="row">
            <div class="col-lg-6 mb-5">
                <button className='button-black-box button-clear' type='submit'>CLEAR FILTERS</button>
              </div>
              <div class="col-lg-6 mb-5">
                <button className='button-black-box button-submit' type='submit'>APPLY FILTERS</button>
              </div>
            </div>

          </form>
          </div>
          
        </div>
        <div className='data-section'>
        <Accordion accordionData={sec2Data} />
        </div>

      </div>


      <BottomBanner
        pageName={"career"}
        titleWeb={"Join Our<br/> Team Today"}
        titleTab={"Join Our<br/> Team Today"}
        titleMob={"Join Our<br/> Team Today"}
                
        subTitlePresent={true}
        subTitleWeb={"Explore opportunities, spark your<br/> career journey."}
        subTitleTab={"Explore opportunities, spark<br/> your career journey."}
        subTitleMob={"Explore opportunities, spark your career<br/> journey."}

        buttonText={"SEND YOUR QUERIES TO JOBS@ZOGGLOBAL.COM"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default Career


