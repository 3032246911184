import React, { useEffect, useState } from 'react'
import './Industries.scss'


import Banner from '../../common-component/BannerMainPages/BannerMainPages'
import WhiteBanner from "../../common-component/BannerMainPages/WhiteBanner.js"

import { useNavigate } from 'react-router-dom';

import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';


import Slider from 'react-slick'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js';


const View = () => {
  const navigate = useNavigate();

 
  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const sec4Data = [
    {
      title: "Healthcare",
      link: "/healthcare",
    },

    {
      title: "Technology",
      link: "/technology",
    },

    {
      title: "Telecom",
      link: "/telecom",
    },
    {
      title: "Education",
      link: "/education",
    },

    {
      title: "Travel",
      link: "/travel",
    },

    {
      title: "Banking ",
      link: "/banking",
    },
    {
      title: "Insurance",
      link: "/insurance",
    },

    {
      title: "Legal",
      link: "/legal",
    },

    {
      title: "Media",
      link: "/media",
    },
    {
      title: "Pharma",
      link: "/pharma",
    },

  ]


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])
  const sec4SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 1000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };


  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1033,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
         
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          speed:500,
          autoplaySpeed: 4000,
          pauseOnHover: true,
          infinite: true,
          dots: false,
          centerMode: true,
          centerPadding: '0px',
        },
      },
    ]
    
  };



  return (
    <div className='industries-page'>

      <WhiteBanner
      imglink={"/images/industries/main-banner.png"}
        pageName={"industries"}
        titleWeb={"Innovative IT Solutions for Diverse <span><br/>Industries<span>"}
        titleTab={"Innovative IT Solutions<br/> for Diverse Industries"}
        titleMob={"Innovative IT<br/> Solutions for<br/> Diverse<br/> Industries"}
        subTitleWeb={"Embrace the future of technology with a partner<br/> committed to transforming your challenges into success<br/>stories."}
        subTitleTab={"Embrace the future of technology with a partner<br/> committed to transforming your challenges into<br/> success stories."}
        subTitleMob={"Embrace the future of technology<br/> with a partner committed to<br/> transforming your challenges into<br/> success stories."}
        title1Web={"INDUSTRIES"}
        title2Tab={"INDUSTRIES"}
        title3Mob={"INDUSTRIES"}
        >
      </WhiteBanner>

    <div className='sec2-ind'>
      <div className='first-sec2'>
<div className='for-img'>
  <img src="\images\industries\Banner2.png" className='imgsrc'/>
   
</div>
<div className='for-text'>
  <span className='montserrat-hard'>Fast-Track <br/>Your Success</span>
  <p className='montserrat-lit'>Whether you are a small business or a large enterprise, we provide innovative and custom solutions to help your business achieve its goals and accelerate growth.</p>

</div>


      </div>

      <div className='second-sec2'>
     
<div className='for-text'>
  <span className='montserrat-hard'>Unlock New  <br/>Possibilities</span>
  <p className='montserrat-lit'>Transform as we unlock industry potential. Our innovative IT services empower agility, fostering creativity and opening doors to unprecedented opportunities for your business.</p>

</div>
<div className='for-img'>
  <img src="\images\industries\Banner2 (1).png" className='imgsrc'/>
   
</div>




      </div>





    </div>






    <div className="sec3-ind" >
    <img src="/images/industries/bac.png" className='img-mr'/> 
      {/* <video autoPlay muted loop id="bg-video">
        <source src="\images\industries\videoplayback.webm" type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}
      <div className="content">
        <div className='text-for montserrat-hard'>
        <span>Discover IT Solutions for Your<span className='texto'> Industry</span></span>
        <div className='hr2'></div>
       

        </div>
        

      </div>
      <div className='mobslider montserrat-hard'>
      <Slider {...settings}>
            <div>
            <div className='eachimg'>
          <img src="\images\industries\ind\Frame 338 (2).png" className='imgcard'/>
          <span >Health</span>
          </div>
          </div>
          
          <div>
          <div className='eachimg'>
          <img src="\images\industries\ind\Frame 336.png" className='imgcard'/>
          <span >Technology</span>
          </div>
            
            </div>

            <div>

            <div className='eachimg'>
          <img src="\images\industries\ind\Frame 337.png" className='imgcard'/>
          <span >Telecom</span>
          </div>
           
            </div>

            
       

            
          </Slider>

      </div>
      


      <div className='cards'>
        <div className='cards-1 montserrat-hard'>
          <div className='eachimg'>
          <img src="\images\industries\ind\Frame 338 (2).png" className='imgcard'/>
          <span >Health</span>
          </div>
          <div className='eachimg'>
          <img src="\images\industries\ind\Frame 336.png" className='imgcard'/>
          <span >Technology</span>
          </div>
          <div className='eachimg'>
          <img src="\images\industries\ind\Frame 337.png" className='imgcard'/>
          <span >Telecom</span>
          </div>
        </div>




        <div className='cards-1 montserrat-hard'>
        <div className='eachimg'>
          <img src="\images\industries\ind\Frame 338.png" className='imgcard'/>
          <span >Education</span>
          </div>
          <div className='eachimg'>
          <img src="\images\industries\ind\Frame 339.png" className='imgcard'/>
          <span >Travel</span>
          </div>
          <div className='eachimg'>
          <img src="\images\industries\ind\Frame 341.png" className='imgcard'/>
          <span >Banking Service</span>
          </div>

</div>


<div className='cards-1 montserrat-hard'>
        <div className='eachimg'>
          <img src="\images\industries\ind\Frame 338 (1).png" className='imgcard'/>
          <span >Insurance</span>
          </div>
          <div className='eachimg'>
          <img src="\images\industries\ind\Frame 339 (1).png" className='imgcard'/>
          <span >Legal</span>
          </div>
          <div className='eachimg'>
          <img src="\images\industries\ind\Frame 341 (1).png" className='imgcard'/>
          <span >Media</span>
          </div>

</div>

<div className='cards-1 montserrat-hard'>

<div className='eachimg'>
          <img src="\images\industries\ind\Frame 338 (2).png" className='imgcard'/>
          <span >Pharma</span>
          </div>
          

</div>

      </div>




      
    </div>




<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>




    <div className='sec4-ind'>
      <div className='half1-sec4'>
        <img src="\images\industries\Banner2.png" className='img23'/>


      </div>
      <div className='half2-sec4'>
        <span className='montserrat-hard'>Let's Discuss Your IT Needs!</span>
        <p className='montserrat-lit'>Ready to transform your industry? Contact us now for a personalised consultation and take the first step towards a future-ready business!</p>
        <button  type="button" onClick={() => navigate("/contact-us")} className='montserrat-lit'>TALK TO OUR TEAM</button>


      </div>

    </div>














      {/* <div className='sec-2'>
        <h1>Fast-Track Your Success</h1>
        <p>Whether you are a small business or a large enterprise, we provide innovative and custom solutions to help your business achieve its goals and accelerate growth.</p>
      </div>


      <div className='sec-3'>
       <div className='inner'>
       <h1>Unlock New Possibilities</h1>
        <p>Transform as we unlock industry potential. Our innovative IT services empower agility, fostering creativity and opening doors to unprecedented opportunities for your business.</p>

       </div>
      </div>


      <div className='sec-4'>
      <h1 className='only-web'>Discover IT Solutions for<br /> Your Industry</h1>
      <h1 className='only-tab'>Discover IT Solutions for<br /> Your Industry</h1>
        <h1 className='only-mob'>Discover IT<br /> Solutions for<br /> Your Industry</h1>

        <Slider {...sec4SlideSettings}>

          {sec4Data.map((item, index) => {
            return (
              <div className='card' key={index}>
                <div className='inner' style={{ backgroundImage: `url("/images/industries/sec-4-${index + 1}.png")` }}>
                  <div className='title'>{item.title}</div>
                  <button onClick={() => navigate(`${item.link}`)}>Learn More <img src='/images/industries/sec-4-arrow.svg'></img></button>
                </div>
              </div>
            )
          })}

        </Slider>

      </div>


      <div className='sec-5'>
        <div className='lhs'>
        <h1 className='top-blue-line only-web'>Let's<br /> Discuss Your<br /> IT Needs!</h1>
        <h1 className='top-blue-line only-tab'>Let's<br /> Discuss Your<br /> IT Needs!</h1>
          <h1 className='top-blue-line only-mob'>Let's Discuss<br/> Your IT Needs!</h1>
          <p>Ready to transform your industry? Contact us now for a personalised consultation and take the first step towards a future-ready business!</p>

          <button className='button-black-box' onClick={() => navigate("/contact-us")}>TALK TO OUR TEAM</button>
        </div>
        <div className='rhs'>
          <p>Ready to transform your industry? Contact us now for a personalised consultation and take the first step towards a future-ready business!</p>
        </div>
      </div> */}









      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default View