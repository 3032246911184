import React, {useState } from "react";
import './GreySliderSection.scss'
import $ from 'jquery';
import Slider from 'react-slick'

const GreySliderSection = ({ pageName, secNo,numberMode, headingWeb, headingTab, headingMob, subHeading, data }) => {
  const renderHTML = (text) => ({ __html: text });


  const Settings = {
    dots: false,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: "linear",
 
  
    
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          centerPadding: "36px",
    speed: 500,

        }
      }
    ]
  };

  const [isCardClicked, setIsCardClicked] = useState(Array(data.length).fill(false));

  const handleArrowButtonClick = (index) => {
    const newHoveredState = [...isCardClicked];
    newHoveredState[index] = !newHoveredState[index];
    setIsCardClicked(newHoveredState);
  };


  const cardClicked = (index) => {
    console.log("card clicked");
    $(".card").removeClass("active");
    $(".card").eq(index).addClass("active");
  };

 
  return (
    <div className={numberMode==true?'grey-slider-section number-type':'grey-slider-section'}>
      <div className='headding-box'>
        <h1 className='only-web' dangerouslySetInnerHTML={renderHTML(headingWeb)}></h1>
        <h1 className='only-tab' dangerouslySetInnerHTML={renderHTML(headingTab)}></h1>
        <h1 className='only-mob' dangerouslySetInnerHTML={renderHTML(headingMob)}></h1>
        {subHeading && <p>{subHeading}</p>}
      </div>



      <Slider {...Settings}>

        {data.map((item, index) => {
          return (
            <div className={`card ${isCardClicked[index] ? 'active' : ''}`} key={index} >
              {/* {isCardClicked[index] &&
                // <button className='close-btn' onClick={() => handleArrowButtonClick(index)}>
                //   <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                //   <path d="M11.8125 11.8125L42.1875 42.1875" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                //   <path d="M11.8125 42.1875L42.1875 11.8125" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                //   </svg>
                // </button>
              } */}
              <div className="inner-base-box">
              <div className="inner-moving-box">
                {numberMode==true?
                <div className="number">0{index+1}</div>
                :""
                // <img src={`/images/${pageName}/sec-${secNo}-${index + 1}.svg`}></img>
                }


              <div className='title'>{item.title}</div>
              {/* <button onClick={() => handleArrowButtonClick(index)}>
                <svg width="34" height="16" viewBox="0 0 34 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M33.065 8.70711C33.4555 8.31658 33.4555 7.68342 33.065 7.29289L26.701 0.928932C26.3105 0.538408 25.6773 0.538408 25.2868 0.928932C24.8963 1.31946 24.8963 1.95262 25.2868 2.34315L30.9436 8L25.2868 13.6569C24.8963 14.0474 24.8963 14.6805 25.2868 15.0711C25.6773 15.4616 26.3105 15.4616 26.701 15.0711L33.065 8.70711ZM0.357849 9H32.3578V7H0.357849V9Z" fill="#000" />
                </svg>
              </button> */}
              <div className='description'>{item.description}</div>

              
              </div>
  </div>


            </div>
          )
        })}

      </Slider>



    </div>
  )
}

export default GreySliderSection