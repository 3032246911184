import React, { useEffect, useRef } from "react";

import './GreyCardsSection.scss'

const GreyCardsSection = ({ pageName, headingWeb, headingTab, headingMob, descriptionWeb, descriptionTab, descriptionMob, cardsData }) => {
  const renderHTML = (text) => ({ __html: text });


  const refs = useRef([]);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
          if (entry.isIntersecting) {
              entry.target.classList.add('in-view');
          } else {
              entry.target.classList.remove('in-view');
          }
      },
      {
          threshold: 0.1,
      }
  );
    const elementsToObserve = refs.current;
    elementsToObserve.forEach((element) => {
        if (element) observer.observe(element);
    });
  
    return () => {
        elementsToObserve.forEach((element) => {
            if (element) observer.unobserve(element);
        });
    };
  }, []);
  

  return (
    <div className={'grey-card-section'}>
      <div className="inner">
      <div className='headding-box'>
        <h1 className='only-web' dangerouslySetInnerHTML={renderHTML(headingWeb)}></h1>
        <h1 className='only-tab' dangerouslySetInnerHTML={renderHTML(headingTab)}></h1>
        <h1 className='only-mob' dangerouslySetInnerHTML={renderHTML(headingMob)}></h1>

        <p className='only-web' dangerouslySetInnerHTML={renderHTML(descriptionWeb)}></p>
        <p className='only-tab' dangerouslySetInnerHTML={renderHTML(descriptionTab)}></p>
        <p className='only-mob' dangerouslySetInnerHTML={renderHTML(descriptionMob)}></p>

      </div>


      <div className="cards-outer">

        {cardsData.map((item, index) => {

const titleRef = item.title ? (el) => (refs.current[index] = el) : null;


          return (
            <div className={item.title?'card':'card no-title'} key={index}>
              {item.title&&<div className="title"  ref={titleRef}>{item.title}</div>}
              <div className="description">{item.description}</div>
            </div>
          )
        })}


      </div>
      </div>
    </div>
  )
}

export default GreyCardsSection