import React, {  useEffect, useRef, useState } from 'react'
import './ITOutSourcing.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages.js'
import SimpleSection from '../../common-component/SimpleSection/SimpleSection.js'
import StatisticsSection from '../../common-component/StatisticsSection/StatisticsSection.js'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'
import GreyCardsSection from '../../common-component/GreyCardsSection/GreyCardsSection.js'
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'

import { useNavigate } from 'react-router-dom';

import API from "../../../API.js"
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import Slider from 'react-slick'




const ITOutSourcing = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
    setIsLoading(true)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        setBlogData(response.data)
        setIsLoading(false)

      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)

      });
  }, [])


  const sec6Data = [
    {
      title: "Advanced Risk Management",
      description: "Mitigate risks associated with IT operations through proactive monitoring, compliance adherence, and disaster recovery strategies.",
    },
    {
      title: "Focus on Core Business Activities",
      description: "By outsourcing IT tasks, businesses can focus on core strengths and strategic initiatives, driving growth and innovation.",
    },
    {
      title: "Access to Specialised Expertise",
      description: "Gain access to a varied talent pool of experienced professionals skilled in diverse tech domains and industry standards.",
    },
    {
      title: "Cost Savings",
      description: "Reduce operational expenses by outsourcing IT functions, eliminating the need for in-house infrastructure and personnel.",
    },
    {
      title: "Enhanced Adaptability",
      description: "Embrace rapid changes in technology and market demands by leveraging our IT resources for quick scalability and adaptation.",
    },
    {
      title: "Improved Productivity",
      description: "Streamline IT workflows, leveraging automation and optimisation techniques to enhance overall efficiency and productivity.",
    },



  ]


  const sec4Data = [
    {
      text:"Client Satisfaction",
      leftSign:"",
      number:"97",
      rightSign:"%",
    },

    {
      text:"Successful Projects",
      leftSign:"",
      number:"150",
      rightSign:"+",
    },
    {
      text:"On-Time Delivery",
      leftSign:"",
      number:"98",
      rightSign:"%",
    },
    {
      text:"IT Costs Reduction",
      leftSign:"",
      number:"30",
      rightSign:"%",
    },


  ]
  const sec5Data = [
    {
      title: "Scalability & Flexibility",
      description: "We offer flexible solutions adapting to your business needs, ensuring agility and scalability.",
    },

    {
      title: "Reliability & Security",
      description: "Reliable services with robust security measures safeguard your valuable assets consistently.",
    },
    {
      title: "Timely Project Delivery",
      description: "We prioritise deadlines, ensuring projects are delivered promptly without compromising quality.",
    },
    {
      title: "Ongoing Support",
      description: "We offer continuous assistance and guidance for seamless operations and sustained performance.",
    },



  ]


  const sec7Data=[
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    
    
  ]
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };





  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
      
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
    ]

  };



  const sliderRef=useRef(null)
  
    const next = () => {
      sliderRef.current.slickNext();
  };
  
  const previous = () => {
      sliderRef.current.slickPrev();
  };
  const [isToggled, setIsToggled] = useState(false);
  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  const [content1, setContent1]=useState(false);
   
  const cont1btn=()=>{
    setContent1(!content1);
  }



  const statsRef = useRef(null);

  useEffect(() => {

    if (statsRef.current) {
      const stats = statsRef.current.querySelectorAll('.number');

      const updateCount = (stat) => {
          const target = +stat.getAttribute('data-target');
          const current = +stat.innerText.replace('%', '').replace('+', '');
          const increment = target / 100;

          if (current < target) {
              stat.innerText = Math.ceil(current + increment) + (stat.innerText.includes('%') ? '%' : (stat.innerText.includes('+') ? '+' : ''));
              setTimeout(() => updateCount(stat), 20);
          } else {
              stat.innerText = target + (stat.innerText.includes('%') ? '%' : (stat.innerText.includes('+') ? '+' : ''));
          }
      };

      const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
              if (entry.isIntersecting) {
                  stats.forEach(stat => updateCount(stat));
              }
          });
      }, { threshold: 0.1 });

      observer.observe(statsRef.current);

      return () => observer.disconnect();
    }
  }, []);













  return (
    <div className='it-outsourcing-page'>
<BannerSubPages
        imglink={"/images/it-outsourcing/Rectangle 195.png"}
        pageName={"it-outsourcing"}
        blueTitle={"IT Outsourcing"}
        titleWeb={"Outsource Your IT to<br/> Outperform"}
        titleTab={"Outsource Your<br/> IT to Outperform"}
        titleMob={"Outsource <br/>Your IT to<br/> Outperform"}
        subTitleWeb={"Our commitment is to deliver<br/> exceptional results through outsourcing."}
        subTitleTab={"Our commitment is to deliver<br/> exceptional results through outsourcing."}
        subTitleMob={"Our commitment is to deliver<br/> exceptional results through<br/> outsourcing."}
        iconTopWeb={'30%'}
        iconLeftWeb={'82%'}
        iconTopTab={'60%'}
        iconLeftTab={'62%'}
        iconTopMob={'45%'}
        iconLeftMob={'77%'}
        iconRotationWeb={-38}
        iconRotationTab={-16}
        iconRotationMob={192}>
      </BannerSubPages>

      {/* <SimpleSection
        pageName={"it-outsourcing"}
        secNo={"2"}
        headdingPresent={true}
        buttonPresent={false}
        imagePosition={"left"}
        headingWeb={"The Power of<br/> Outsourcing"}
        headingTab={"The Power of Outsourcing"}
        headingMob={"The Power of Outsourcing"}
        description={"IT outsourcing services provide numerous valuable benefits for businesses. From cost savings to enhanced efficiency, businesses nationwide recognise the strategic advantage of entrusting their IT needs to dedicated experts. As the demand for streamlined operations and scalable solutions persists, more and more companies in the UK are turning to outsourcing to optimise their IT infrastructure."}
        buttonText={""}
      /> */}

<div className='img-sec2-itoutsource'>

  <div className='for-img'>
    <img src="\images\it-outsourcing\Banner2.png"/>

  </div>

  <div className='for-text'>
    <span>The Power of Outsourcing</span>
    <p>IT outsourcing services provide numerous valuable benefits for businesses. From cost savings to enhanced efficiency, businesses nationwide recognise the strategic advantage of entrusting their IT needs to dedicated experts. As the demand for streamlined operations and scalable solutions persists, more and more companies in the UK are turning to outsourcing to optimise their IT infrastructure.im</p>

  </div>

</div>



<div className='small-slider'>
  <div className='heading'>
    <div className='head'>Our IT Outsourcing Offerings</div>
    <div className='text'>Delve into the specifics of our capabilities across various technologies – click the cards for detailed breakdowns.</div>
  </div>
  <div className='cards-itout'>
    <div className='card'>
    <img src="\images\it-outsourcing\Rectangle 180.png"  onClick={()=>navigate('/managed-it-services')}/>
    <span>Managed IT Services</span>
    </div>
   
    <div className='card'>
    <img src="\images\it-outsourcing\Rectangle 180 (1).png" onClick={()=>navigate('/cyber-security')}/>
    <span>Quality Assurance Services</span>
    </div>
   
    <div className='card'>
    <img src="\images\it-outsourcing\Rectangle 180 (2).png"  onClick={()=>navigate('/quality-assurance')}/>
    <span>Cybersecurity Services</span>
    </div>
   
    <div className='card'>
    <img src="\images\it-outsourcing\Rectangle 180 (3).png" onClick={()=>navigate('/dev-sec-ops')}/>
    <span>DevSecOps Services</span>
    </div>
   


  </div>

</div>











{/* 
      <div className='sec-3'>
<div className='inner'>
  <div className='text-box'>
  <h1>Our IT Outsourcing Offerings</h1>
  <p>Delve into the specifics of our capabilities across various technologies – click the cards for detailed breakdowns.</p>

  </div>
  
  <div className='flex-box'>
  <div className='card' onClick={()=>navigate('/managed-it-services')}>
      <img src='/images/it-outsourcing/sec-3-1.png'></img>
      <div className='text'>Managed IT Services</div> 
    </div>

    <div className='card' onClick={()=>navigate('/cyber-security')}>
      <img src='/images/it-outsourcing/sec-3-2.png'></img>
      <div className='text'>Cybersecurity Services</div>
    </div>

    <div className='card' onClick={()=>navigate('/quality-assurance')}>
      <img src='/images/it-outsourcing/sec-3-3.png'></img>
      <div className='text'>Quality Assurance Services</div>
    </div>

    <div className='card' onClick={()=>navigate('/dev-sec-ops')}>
      <img src='/images/it-outsourcing/sec-3-4.png'></img>
      <div className='text'>DevSecOps Services</div>
    </div>
  </div>
</div>
      </div> */}


   
        <StatisticsSection data={sec4Data} background={"linear-gradient(90deg, #AB5CF5 0%, #5928D1 100%)"}/>










<div id="targetDiv"  className={`blueslider-update ${isToggled ? 'black-bg' : ''}`}>

  <div className='head montserrat-hard'>
  How We Ensure Next-Level Protection  </div>

  <p className='montserrat-lit'>ZOG Global offers unparalleled data protection services in the UK, ensuring your information remains secure and compliant. Here are six compelling reasons why our solutions stand out.</p>
  <div className='combine-btn'>
  <div className='arrow'>

    <img src="\images\protect-your-customers-data\1.svg" onClick={previous}/>
    <img src="\images\protect-your-customers-data\Frame (1).svg" onClick={next}/>

  </div>

  {/* <div className="toggle-container">
          <input type="checkbox" id="toggleButton" className="toggle-checkbox" onChange={handleToggleChange} />
          <label htmlFor="toggleButton" className="toggle-label">
            <span className="toggle-button">
            <img src="\images\protect-your-customers-data\icons8-open-lock.svg" className='im1'/>
            <img src="\images\protect-your-customers-data\icons8-lock.svg" className='im2'/>
            </span>
          </label>
        </div> */}
        </div>



       
  <div className='slickblue-update montserrat-lit'>
  <div className="slider-container ">
      <Slider ref={sliderRef} {...settings}>
        <div>
         <div className='cardofblue-update'>
          <span> Scalability & Flexibility</span>
          <p>We offer flexible solutions adapting to your business needs, ensuring agility and scalability.</p>
         </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Reliability & Security </span>
        <p>Reliable services with robust security measures safeguard your valuable assets consistently.</p>

        </div>
        </div>
        <div>
        <div className='cardofblue-update'>

        <span> Timely Project Delivery</span>
        <p>We prioritise deadlines, ensuring projects are delivered promptly without compromising quality.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span> Ongoing Support</span>
        <p>We offer continuous assistance and guidance for seamless operations and sustained performance.</p>
        </div>
        </div>
       
       
        
       
      </Slider>
    </div>
    

  </div>



</div>











{/* 
      <GreyCardsSection
        pageName={'it-outsourcing'}
        headingWeb={"Highlighting Our<br/> IT Outsourcing Features"}
        headingTab={"Highlighting Our IT<br/> Outsourcing Features"}
        headingMob={"Highlighting Our<br/> IT Outsourcing<br/> Features"}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        cardsData={sec5Data}

      /> */}






<div className='pink-card montserrat-lit'>

<div className='head1'>
<div className='texthed'>Benefits Of Our IT Outsourcing Solutions</div>
<p></p>
</div>

<div className='head2'>
<div className='each-sec'>
<span>Advanced Risk Management</span>
<p>Mitigate risks associated with IT operations through proactive monitoring, compliance adherence, and disaster recovery strategies. Our expert team ensures continuous system oversight, reducing vulnerabilities, and safeguarding your data from potential threats. We implement robust security protocols, conduct regular audits, and maintain comprehensive contingency plans to handle any disruptions swiftly.</p>
</div>

<div className='each-sec'>
<span>Focus on Core Business Activities</span>
<p>
By outsourcing IT tasks, businesses can focus on core strengths and strategic initiatives, driving growth and innovation. This enables your team to dedicate more time and resources to developing new products, improving customer experiences, and expanding market reach. Outsourcing routine IT operations frees up your internal staff to concentrate on high-value projects that align with your company's long-term goals.
</p>
</div>

<div className='each-sec'>
<span>Access to Specialised Expertise</span>
<p>
Gain access to a varied talent pool of experienced professionals skilled in diverse tech domains and industry standards. Our team consists of specialists in cybersecurity, cloud computing, software development, and IT support, providing you with the expertise needed to tackle complex challenges. Stay ahead of technological advancements and industry trends by leveraging our collective knowledge and innovative solutions.
</p>
</div>

<div className='each-sec'>
<span>Cost Savings</span>
<p>
Reduce operational expenses by outsourcing IT functions, eliminating the need for in-house infrastructure and personnel. Outsourcing allows you to convert fixed costs into variable costs, optimising your budget allocation. Benefit from economies of scale and lower capital expenditures, while accessing top-tier technology and services without the burden of continuous investments in training and equipment.
</p>
</div>
<div className='each-sec'>
<span>Enhanced Adaptability</span>
<p>
Embrace rapid changes in technology and market demands by leveraging our IT resources for quick scalability and adaptation. Our flexible solutions ensure that your IT infrastructure can swiftly adjust to new requirements, whether it's scaling up during peak periods or integrating the latest technologies. Stay competitive and responsive to market shifts with our adaptable and dynamic IT support.
</p>
</div>

<div className='each-sec'>
<span>Improved Productivity </span>
<p>
Streamline IT workflows, leveraging automation and optimisation techniques to enhance overall efficiency and productivity. Our solutions focus on reducing manual interventions, minimising downtime, and ensuring seamless operations. By optimising your IT processes, we help you achieve faster turnaround times, better resource utilisation, and improved service delivery, leading to increased business performance and satisfaction.
</p>
</div>


</div>
</div>

















      {/* <BlueNotchSection
        headingWeb={"Benefits Of<br/> Our IT Outsourcing Solutions"}     // <br/>
        headingTab={"Benefits Of Our IT<br/> Outsourcing Solutions"}
        headingMob={"Benefits Of Our<br/> IT Outsourcing<br/> Solutions"}
        descriptionPresent={false}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        listData={sec6Data}

      /> */}


{/* <BlogNewSection/> */}



 
 

      

  <BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>




      <BottomBanner
        pageName={"it-outsourcing"}
        titleWeb={"Thinking About<br/> IT Outsourcing?"}
        titleTab={"Thinking About<br/> IT Outsourcing?"}
        titleMob={"Thinking About IT<br/> Outsourcing?"}

        subTitlePresent={true}
        subTitleWeb={"Our experts are here<br/> to guide you every step of the way."}
        subTitleTab={"Our experts are here to guide you<br/> every step of the way."}
        subTitleMob={"Our experts are here to<br/> guide you every step of the way."}

        buttonText={"talk to our experts"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default ITOutSourcing