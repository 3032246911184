import React, { useEffect, useRef, useState } from 'react'
import './EngagementModel.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages.js'
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection'
import FiveCardsSection from '../../common-component/FiveCardsSection/FiveCardsSection'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'
import ThreeCardsSection from '../../common-component/ThreeCardsSection/ThreeCardsSection.js'

import { useNavigate } from 'react-router-dom';

import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import Slider from 'react-slick'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'




const EngagementModel = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const sec2Data = [
    {
      title: "Fixed Bid (FB)",
      description: "Fixed Bid (FB) engagements offer meticulously defined project scopes and budgets. By locking in costs upfront, we ensure clarity, accountability, and successful project outcomes, all within predetermined parameters.",
    },
    {
      title: "T&M Model",
      description: "Through the Time and Materials (T&M) Model, ZOG Global offers client’s flexibility and transparency, enabling precise resource allocation and real-time budget management for evolving project needs.",
    },
    {
      title: "Agile Fixed Price (AFP)",
      description: "Agile Fixed Price (AFP) combines the adaptability of Agile methodology with the stability of fixed pricing, empowering ZOG Global to respond swiftly to changes while maintaining budget predictability and project control.",
    },



  ]
  const sec4Data = [
    { title: "",
     description: "",
     image_url:"/images/why-zog-global/sec-4-1.png"
     },

    { title: "",
     description: "",
    image_url:"/images/why-zog-global/sec-4-2.png" 
  },

    { title: "",
     description: "",
    image_url:"/images/why-zog-global/sec-4-3.png" 
  },
  
  ]


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };



  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
      
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
    ]

  };



  const sliderRef=useRef(null)
  
    const next = () => {
      sliderRef.current.slickNext();
  };
  
  const previous = () => {
      sliderRef.current.slickPrev();
  };

  const [isToggled, setIsToggled] = useState(false);
  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  const [content1, setContent1]=useState(false);
   
  const cont1btn=()=>{
    setContent1(!content1);
  }










  return (
    <div className='engagement-model-page'>

<BannerSubPages
        imglink={"/images/engagement-model/Rectangle 195.png"}
        pageName={"engagement-model"}
        blueTitle={"engagement model"}
        titleWeb={"Find<br/> Your Ideal Fit"}
        titleTab={"Find<br/> Your Ideal Fit"}
        titleMob={"Find<br/> Your Ideal Fit"}
        subTitleWeb={"IT services customised to match your<br/> preferred engagement model."}
        subTitleTab={"IT services customised to match your<br/> preferred engagement model."}
        subTitleMob={"IT services customised to match<br/> your preferred engagement model."}
        iconTopWeb={'20%'}
        iconLeftWeb={'83%'}
        iconTopTab={'20%'}
        iconLeftTab={'84%'}
        iconTopMob={'62%'}
        iconLeftMob={'60%'}
        iconRotationWeb={82}
        iconRotationTab={80}
        iconRotationMob={88}>
      </BannerSubPages>

      {/* <BlueNotchSection
        headingWeb={"Explore Our Flexible<br/> Engagement Models"}     // <br/>
        headingTab={"Explore Our Flexible<br/> Engagement Models"}
        headingMob={"Explore Our Flexible Engagement Models"}
        descriptionPresent={false}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        listData={sec2Data}

      /> */}


<div className='pink-card montserrat-lit'>

<div className='head1'>
<div className='texthed'>Explore Our Flexible Engagement Models</div>
<p></p>
</div>

<div className='head2'>
<div className='each-sec'>
<span>Fixed Bid (FB)</span>
<p>Fixed Bid (FB) engagements offer meticulously defined project scopes and budgets. By locking in costs upfront, we ensure clarity, accountability, and successful project outcomes, all within predetermined parameters. By providing a clear, detailed plan from the outset, this model minimises financial risk for clients and ensures the fulfilment of all agreed deliverables.</p>
</div>

<div className='each-sec'>
<span>T&M Model</span>
<p>
Empower small and medium enterprises with robust data security. Our scalable solutions cater to SMEs' unique needs, providing cost-effective yet comprehensive protection, ensuring compliance, and fostering growth without compromising security. Our services include continuous monitoring, threat detection, and user-friendly interfaces, allowing SMEs to focus on growth while we handle their security.
</p>
</div>

<div className='each-sec'>
<span>Agile Fixed Price (AFP)</span>
<p>
Agile Fixed Price (AFP) combines the adaptability of Agile methodology with the stability of fixed pricing, empowering ZOG Global to respond swiftly to changes while maintaining budget predictability and project control. This approach balances flexibility with financial stability, allowing for iterative development and continuous improvement without compromising on cost efficiency.
</p>
</div>


</div>
</div>


<ThreeCardsSection
        pageName={'engagement-model'}
        secNo={'3'}
        cardHeight={420}
        headingWeb={"Comprehensive Financial <br/> Management"}
        headingTab={"Comprehensive<br/> Financial Management"}
        headingMob={"Comprehensive<br/> Financial<br/> Management"}
        descriptionWeb={"Maximise financial efficiency with tailored pricing, accurate budgeting, and<br/> transparent cost breakdowns."}
        descriptionTab={"Maximise financial efficiency with tailored pricing, accurate budgeting, and transparent cost breakdowns."}
        descriptionMob={"Maximise financial efficiency with<br/> tailored pricing, accurate budgeting,<br/> and transparent cost breakdowns."}


        subHeading1={"Flexible Pricing Models"}
        description1={"We offer tailored models for clients' needs, budget alignment, and scalability for evolving projects."}
        subHeading2={"Accurate Budget Estimation"}
        description2={"Advanced techniques for precise forecasts, minimising overruns, ensuring financial predictability."}
        subHeading3={"Transparent Cost Breakdown"}
        description3={"Clear breakdown of budget allocation across project components, fostering trust and accountability."}
      />


      
{/* <FiveCardsSection
        pageName={'engagement-model'}
        secNo={'4'}
        cardHeightWeb={165}
        cardHeightTab={425}
        headingWeb={"Advantages of Our Engagement Model"}
        headingTab={"Advantages of<br/> Our Engagement Model"}
        headingMob={"Advantages of Our Engagement Model"}
        description={""}
        

        subHeading1={"Iteration Preparation"}
        description1={"Pre-launch iteration analysis and organisation opportunity for clients, ensuring thorough preparation for successful project implementation."}
        subHeading2={"Agile Enhancement"}
        description2={"Agile practices implementation enhances project value without compromising completion dates, ensuring efficiency and effectiveness."}
        subHeading3={"Goal Enablement"}
        description3={"Empower clients with data analysis, operational setup, due diligence, and investment services to achieve unique business goals."}
        subHeading4={"Schedule Dedication"}
        description4={"Dedicated commitment to project objectives and schedules, ensuring guaranteed focus and alignment with client expectations."}
        subHeading5={"Expansion Guidance"}
        description5={"Partnership business development, investment recommendations, and analysis provided for clients navigating expansion or sustainability paths."}
      /> */}




<div id="targetDiv"  className={`blueslider-update ${isToggled ? 'black-bg' : ''}`}>

  <div className='head montserrat-hard'>
  Advantages of Our Engagement Model</div>

  <p className='montserrat-lit'></p>
  <div className='combine-btn'>
  <div className='arrow'>

    <img src="\images\protect-your-customers-data\1.svg" onClick={previous}/>
    <img src="\images\protect-your-customers-data\Frame (1).svg" onClick={next}/>

  </div>

  {/* <div className="toggle-container">
          <input type="checkbox" id="toggleButton" className="toggle-checkbox" onChange={handleToggleChange} />
          <label htmlFor="toggleButton" className="toggle-label">
            <span className="toggle-button">
            <img src="\images\protect-your-customers-data\icons8-open-lock.svg" className='im1'/>
            <img src="\images\protect-your-customers-data\icons8-lock.svg" className='im2'/>
            </span>
          </label>
        </div> */}
        </div>



       
  <div className='slickblue-update montserrat-lit'>
  <div className="slider-container ">
      <Slider ref={sliderRef} {...settings}>
        <div>
         <div className='cardofblue-update'>
          <span>Iteration Preparation</span>
          <p>Pre-launch iteration analysis and organisation opportunity for clients, ensuring thorough preparation for successful project implementation.</p>
         </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Schedule Dedication</span>
        <p>Dedicated commitment to project objectives and schedules, ensuring guaranteed focus and alignment with client expectations.</p>

        </div>
        </div>
        <div>
        <div className='cardofblue-update'>

        <span>Agile Enhancement</span>
        <p>Agile practices implementation enhances project value without compromising completion dates, ensuring efficiency and effectiveness.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Schedule Dedication</span>
        <p>Dedicated commitment to project objectives and schedules, ensuring guaranteed focus and alignment with client expectations.</p>
        </div>
        </div>
        
       
        
       
      </Slider>
    </div>
    

  </div>



</div>


















      

      <div className='sec-5'>

      </div>


      <div className='sec-6'>

      </div>


      <div className='sec-7'>

      </div>




      <BottomBanner
        pageName={"engagement-model"}
        titleWeb={"Have a<br/> Project in Mind?"}
        titleTab={"Have a<br/> Project in Mind?"}
        titleMob={"Have a Project<br/> in Mind?"}
                
        subTitlePresent={false}
        subTitleWeb={""}
        subTitleTab={""}
        subTitleMob={""}

        buttonText={"TALK TO OUR EXPERTS"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      <BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>


      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default EngagementModel