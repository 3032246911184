// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-table .table-img {
  min-width: 106px;
  min-height: 106px;
}
.product-table img {
  border-radius: 6px;
  object-fit: cover;
  width: 106px;
  height: 106px;
}

@media (max-width: 768px) {
  .product-table img {
    width: 100%;
    height: 100%;
  }
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/pages/BlogList/BlogList.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,iBAAA;AAAR;AAEI;EACI,kBAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;AAAR;;AAMA;EAEQ;IACI,WAAA;IACA,YAAA;EAJV;AACF","sourcesContent":[".product-table{\r\n    .table-img{\r\n        min-width: 106px;\r\n        min-height: 106px;\r\n    }\r\n    img{\r\n        border-radius: 6px;\r\n        object-fit: cover;\r\n        width: 106px;\r\n        height: 106px;\r\n    }\r\n    \r\n\r\n}\r\n\r\n@media (max-width: 768px) {\r\n    .product-table{\r\n        img{\r\n            width: 100%;\r\n            height: 100%;\r\n        }\r\n        \r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
