import React, {  useEffect, useState, useContext, useRef } from 'react'
import './WhyZogGlobal.scss'
import Banner from '../../common-component/BannerMainPages/BannerMainPages'
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate, Link } from 'react-router-dom';

import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'



import API from "../../../API.js"
import LoadingSpinner from "../../../LoadingSpinner.js";
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';

import { UserContext } from '../../../authentication/pages/UserContext.js';

import Slider from 'react-slick'
import WhiteBanner from '../../common-component/BannerMainPages/WhiteBanner.js';


const View = () => {
  const navigate = useNavigate();

  const { isLoggedIn, login, logout } = useContext(UserContext);

  const [tabSelected, selectTab] = useState(0);

  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [blogData, setBlogData] = useState(null);


  // useEffect(() => {
  //   setIsLoading(true)
  //   API.get("blogs/?page_name=generic&section_number=1")
  //     .then((response) => {
  //       setBlogData(response.data.slice(0,4))
  //       setIsLoading(false)

  //     })
  //     .catch((error) => {

  //       setMessage(error.response?.status + " " + error.response?.data.message);
  //       setIsErrorModalOpen(true)
  //       setIsLoading(false)

  //     });
  // }, [])




  const sec2ImgHRefWeb = useRef(null);
  const sec2ImgHRefTab = useRef(null);
  const sec2ImgHRefMob = useRef(null);

  const sec3HRef1 = useRef(null);
  const sec3HRef2 = useRef(null);
  const sec3HRef3 = useRef(null);



  useEffect(() => {

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
        } else {
          entry.target.classList.remove('in-view');
        }
      },
      {
        threshold: 0.1,
      }
    );
    const elementsToObserve = [sec3HRef1.current, sec3HRef2.current, sec3HRef3.current, sec2ImgHRefWeb.current, sec2ImgHRefTab.current, sec2ImgHRefMob.current];
    elementsToObserve.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);







  const sec3RespsSlideSettings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])





  // useEffect(() => {
  //   const handleScroll = (event) => {
  //     const track = document.querySelector('.track');
  //     const isScrollingOverTrack = track.contains(event.target); // Check if the scroll event occurred within the track element

  //     // If scrolling is over the track, prevent default behavior to avoid vertical scrolling of the page
  //     if (isScrollingOverTrack) {
  //       event.preventDefault();

  //       // Check if the track is fully scrolled horizontally
  //       if (track.scrollLeft === (track.scrollWidth - track.clientWidth)) {
  //         // If the track is fully scrolled, allow vertical scrolling of the page
  //         document.body.style.overflowY = 'auto';
  //       } else {
  //         // If the track is not fully scrolled, continue horizontal scrolling and prevent vertical scrolling
  //         document.body.style.overflowY = 'hidden';
  //         track.scrollLeft += (event.deltaY);
  //       }
  //     } else {
  //       // If scrolling is not over the track, allow normal vertical scrolling of the page
  //       document.body.style.overflowY = 'auto';
  //     }
  //   };

  //   // Attach scroll event listener to the window
  //   window.addEventListener('wheel', handleScroll, { passive: false });



  //   // Cleanup function to remove event listener
  //   return () => {
  //     window.removeEventListener('wheel', handleScroll);
  //   };
  // }, []); 


  // const sec5PrevClicked = () => {
  //   const track = document.querySelector('.track');

  //   track.scrollLeft += -382;
  // }

  // const sec5NextClicked = () => {
  //   const track = document.querySelector('.track');

  //   track.scrollLeft += 382;
  // }

  const scrollDivRef = useRef(null);
  


  useEffect(() => {
    const scrollDiv = scrollDivRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      { threshold: 0.1 } // Adjust this threshold as needed
    );

    if (scrollDiv) {
      observer.observe(scrollDiv);
    }

    return () => {
      if (scrollDiv) {
        observer.unobserve(scrollDiv);
      }
    };
  }, []);





  const div1ref=useRef(null);

  const oneref=useRef(null);
  const tworef=useRef(null);
  const threeref=useRef(null);
  const fourref=useRef(null);
  const fiveref=useRef(null);
  const sixref=useRef(null);
  const sevenref=useRef(null);
  const eightref=useRef(null);

  useEffect(() => {

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-div');
        } else {
          entry.target.classList.remove('in-div');
        }
      },
      {
        threshold: 0.1,
      }
    );
    const elementsToObserve = [
      div1ref.current,
      oneref.current,
      tworef.current,
      threeref.current,
      fourref.current,
      fiveref.current,
      sixref.current,
      sevenref.current,
      eightref.current
    ];

    elementsToObserve.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);


  useEffect(() => {

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-div2');
        } else {
          entry.target.classList.remove('in-div2');
        }
      },
      {
        threshold: 0.1,
      }
    );
    const elementsToObserve = [
     
      fiveref.current,
      sixref.current,
      sevenref.current,
      eightref.current
    ];

    elementsToObserve.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);


  


  return (
    <div className='why-zog-global-page'>

      {/* <Banner
        pageName={"why-zog-global"}
        titleWeb={"Creating Opportunities<br/> Using Technology"}
        titleTab={"Creating Opportunities<br/> Using Technology"}
        titleMob={"Creating Opportunities Using Technology"}
        subTitleWeb={"Empowering business growth through innovative<br /> solutions and technological opportunities."}
        subTitleTab={"Empowering business growth through innovative<br /> solutions and technological opportunities."}
        subTitleMob={'Empowering business growth<br/> through innovative solutions and<br/> technological opportunities.'}>
      </Banner>
       */}

<WhiteBanner
        imglink={"/images/why-zog-global/main-banner.png"}
        pageName={"why-zog-global"}
        titleWeb={"Creating Opportunities Using<br/> <span> Technology<span>"}
        titleTab={"Creating Opportunities<br/> Using Technology"}
        titleMob={"Creating Opportunities Using Technology"}
        subTitleWeb={"Empowering business growth through innovative solutions and technological<br /> opportunities."}
        subTitleTab={"Empowering business growth through innovative<br /> solutions and technological opportunities."}
        subTitleMob={'Empowering business growth<br/> through innovative solutions and<br/> technological opportunities.'}
        title1Web={"WHY ZOG GLOBAL"}
        title2Tab={"WHY ZOG GLOBAL"}
        title3Mob={"WHY ZOG GLOBAL"}>
      </WhiteBanner>



      <div className='sec1-whyzog' id='idwhy' ref={scrollDivRef}>
        <div className='img-sec1-whyzog'>
          <img src='\images\why-zog-global\Banner2.png' className=''/>
        </div>
        <div className='bluebody-sec1-whyzog montserrat-lit'><p>
        Elevate possibilities and navigate innovation. In the digital realm, we sculpt solutions. Your IT aspirations meet our expertise; we forge a seamless future together. Welcome to transformation.
        </p>
        </div>

      </div>


      <div className='sec2-whyzog'  >
      <div className='text-sec2-whyzog montserrat-lit'><p>
        Nurturing<br/> Innovation Shaping Futures
        </p>
        </div>
        <div className='img-sec2-whyzog'>

          <div className='hr2-wz'></div>
         
        </div>
       

      </div>



      <div className='sec3-whyzog'  ref={div1ref}>

        <div className='mainfirst'>
        <div className='subclass1'>
          <div className='colorsec3'>

          </div>
          <div className='imgsec3'>
          <img src="\images\why-zog-global\Item1.png"/>
            
          </div>
          
        </div>

        <div className='subclass2'>
        <div className='imgsec3'>
<img src="\images\why-zog-global\Item1 (2).png"/>
  
</div>
        <div className='colorsec3'>
          <span className='montserrat-hard'>Our Mission</span>
          <p className='montserrat-lit'>Achieving global leadership in cybersecurity, software development, automation, and artificial intelligence.</p>

</div>

          
        </div>
        <div></div>
        </div>







        <div className='mainfirst2' >
        <div className='subclass1'>
          <div className='colorsec3'>
          <span className='montserrat-hard'>Our Vision</span>
          <p className='montserrat-lit'>Crafting customer-focused digital solutions to propel businesses seamlessly into the digital era.</p>

          </div>
          <div className='imgsec3'>
          <img src="\images\why-zog-global\Item1 (1).png"/>
            
          </div>
          
        </div>
        <div className='subclass2'>
       
<div className='imgsec3'>
<img src="\images\why-zog-global\Item1 (3).png"/>
  
</div>
<div className='colorsec3'>
<span className='montserrat-hard'>Our Values</span>
          <p className='montserrat-lit'>We strive to build happiness for everyone we work with, including technology partners, employees, and clients.</p>


</div>
          
        </div>

        </div>

      </div>
      




<div className='sec4-whyzog'>

  <div  className='first-sec4'>
  <div ref={oneref} className='one-sec4 montserrat-hard'><span>The Value We Bring to Your Business</span><div className='hr2'></div></div>
  <div ref={tworef} className='two-sec4'><span className='montserrat-hard'>Enhanced Efficiency</span><p className='montserrat-lit'>Streamlined processes amplify productivity, ensuring tasks are completed seamlessly for optimal performance.</p></div>
  <div ref={threeref} className='three-sec4'><span className='montserrat-hard'>Seamless Integration</span><p className='montserrat-lit'>Our solutions effortlessly blend with existing systems, promoting synergy across your technological landscape.</p></div>
  <div ref={fourref} className='four-sec4'><span className='montserrat-hard'>Proactive Security</span><p className='montserrat-lit'>Streamline communication with advanced networking solutions like SD-WAN and SDN alongside unified communication services for seamless connectivity.</p></div>

  </div>
  <div className='second-sec4'>
  <div ref={fiveref} className='one-sec4'><span className='montserrat-hard'>Agile Scalability</span><p className='montserrat-lit'>Protect your digital assets with our comprehensive cybersecurity solutions, effectively safeguarding your systems and data against evolving threats.</p></div>
  <div ref={sixref} className='two-sec4'><span className='montserrat-hard'>Insightful Analytics</span><p className='montserrat-lit'>Streamline communication with advanced networking solutions like SD-WAN and SDN alongside unified communication services for seamless connectivity.</p></div>
  <div ref={sevenref} className='three-sec4'><span className='montserrat-hard'>Responsive Support</span><p className='montserrat-lit'>Streamline communication with advanced networking solutions like SD-WAN and SDN alongside unified communication services for seamless connectivity.</p></div>
  <div ref={eightref} className='four-sec4'></div>
    
  </div>






</div>


{/* 
<div className='sec8-whyzog'>

<div className='sec8-homepage'>
      <p className='montserrat-hard'>Blog Highlights</p>
      <div className='boxcontent-sec8'>
        <div className='firstsec8'>
          {blogData && blogData.map((blog,index)=>(
               <div className='box1 montserrat-lit ' style={{
                backgroundImage: `url(${blog.image_url})`,
                backgroundSize: "cover",
                
              }}
              key={index}
              onClick={() => navigate(`/blog-details/${blog.id}`)}>
                <p className='montserrat-hard'>{blog.title}</p>
                <span>By {blog.extra}</span>
                <span>{blog.created_at}</span>
                <img src="\images\home\arrow-bl-anim.svg" className='arrow-sec8'/>
    
              </div>


          ))}
       
         


        </div>
        

      </div>


    </div>

    </div> */}


<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>




     
       

      {/* <div className='sec-2'>
        <img src='/images/why-zog-global/sec-2-web.png' ref={sec2ImgHRefWeb} className='d-none d-lg-block'></img>
        <img src='/images/why-zog-global/sec-2-tab.png' ref={sec2ImgHRefTab} className='d-none d-md-block d-lg-none'></img>
        <img src='/images/why-zog-global/sec-2-mob.png' ref={sec2ImgHRefMob} className='d-block d-md-none'></img>
        <div>
          <p>Elevate possibilities and navigate innovation. In the digital realm, we sculpt solutions. Your IT aspirations meet our expertise; we forge a seamless future together. Welcome to transformation.</p>

        </div>
      </div>


      <div className='sec-3'>
        <div className='inner'>
          <h1>Nurturing Innovation<br />
            Shaping Futures</h1>
          <div className='blue-box'>
            <div className='sub-title'>UNVEILING THE ESSENCE THAT GUIDES US </div>

            <div className='segments-box only-web'>
              <div className='segment segment-1' ref={sec3HRef1}>
                <div className='title'>Our Vision</div>
                <div className='description'>Crafting customer-focused digital solutions to propel businesses seamlessly into the digital era.</div>
              </div>

              <div className='segment segment-2' ref={sec3HRef2}>
                <div className='title'>Our Mission</div>
                <div className='description'>Achieving global leadership in cybersecurity, software development, automation, and artificial intelligence.</div>
              </div>

              <div className='segment segment-3' ref={sec3HRef3}>
                <div className='title'>Our Values</div>
                <div className='description'>We strive to build happiness for everyone we work with, including technology partners, employees, and clients.</div>
              </div>
            </div>

            <div className='segments-box d-block d-lg-none'>

              <Slider {...sec3RespsSlideSettings}>
                <div className='segment segment-1'>
                  <div className='title'>Our Vision</div>
                  <div className='description'>Crafting customer-focused digital solutions to propel businesses seamlessly into the digital era.</div>
                </div>

                <div className='segment segment-2'>
                  <div className='title'>Our Mission</div>
                  <div className='description'>Achieving global leadership in cybersecurity, software development, automation, and artificial intelligence.</div>
                </div>

                <div className='segment segment-3'>
                  <div className='title'>Our Values</div>
                  <div className='description'>We strive to build happiness for everyone we work with, including technology partners, employees, and clients.</div>
                </div>
              </Slider>
            </div>

          </div>
        </div>
      </div>


      <div className='sec-4'>
        <h1 className='only-web'>The Value We Bring to<br /> Your Business</h1>
        <h1 className='only-tab'>The Value We Bring to<br /> Your Business</h1>
        <h1 className='only-mob'>The Value We Bring to Your Business</h1>
        <p>Maximise profitability and efficiency with our comprehensive solutions, adding unmatched value to propel your business forward.</p>

        <div className='segment odd segment-1'>
          <h2>Enhanced Efficiency</h2>
          <p>Streamlined processes amplify productivity, ensuring tasks are completed seamlessly for optimal performance.</p>
        </div>

        <div className='segment even segment-2'>
          <h2>Seamless Integration</h2>
          <p>Our solutions effortlessly blend with existing systems, promoting synergy across your technological landscape.</p>
        </div>

        <div className='segment odd segment-3'>
          <h2>Proactive Security</h2>
          <p className='description'>Advanced protective measures fortify your digital assets, preventing threats and ensuring data integrity.</p>
        </div>

        <div className='segment even segment-4'>
          <h2>Agile Scalability</h2>
          <p>Our flexible solutions empower your business to grow dynamically and adapt to evolving demands.</p>
        </div>

        <div className='segment odd segment-5'>
          <h2>Insightful Analytics</h2>
          <p>Unlock valuable data-driven insights, empowering strategic decision-making for sustainable business growth and innovation.</p>
        </div>
        <div className='segment even segment-6'>
          <h2>Responsive Support</h2>
          <p>Our dedicated team offers swift assistance, resolving issues promptly to minimise disruptions and optimise operations.</p>
        </div>


      </div> */}


      {/* <div className='sec-5'>
        <div className='inner'>
          <div className='lhs'>
            <h1 className='only-mob'>
              Navigating<br /> Success Stories
            </h1>
          </div>

          <div className='scroll-bar-hiding-box  d-none d-md-block'>
          <div className='track'>
            <div className=' list '>

              <div className='first-card'>
                <h1 className='only-web'>
                  Navigating Success<br /> Stories
                </h1>
                <h1 className='only-tab'>
                  Navigating<br /> Success Stories
                </h1>
              </div>

              {sec5Data.map((item, index) => {

                return (
                  <div className='card'>
                    <div className='img-box' style={{backgroundImage:`url(${item.image_url})`, backgroundSize:"cover"}}>
                    </div>
                    <div className='text-box'>
                      <div className='title'>{item.title}</div>
                      <div className='sub-title'>{item.description}</div>
                    </div>
                  </div>
                )
              })}
            </div>

          </div>
          </div>
         


          <div className='d-block d-md-none w-100'>
            {sec5Data.map((item, index) => {
              return (
                <div className='card'>
                  <div className='img-box'>
                    <img src={item.image_url}></img>
                  </div>
                  <div className='text-box'>
                    <div className='title'>{item.title}</div>
                    <div className='sub-title'>{item.description}</div>
                  </div>
                </div>
              )
            })}
          </div>

          <div className='prev-button  d-none d-lg-block' onClick={sec5PrevClicked}></div>
          <div className='next-button  d-none d-lg-block' onClick={sec5NextClicked}></div>
        </div>

      </div> */}










      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/why-zog-global")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default View