import React, {  useEffect, useRef, useState } from 'react'
import './Media.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'
import DiamondSection from '../../common-component/DiamondSection/DiamondSection.js'
import SpecialSliderSection from "../../common-component/SpecialSliderSection/SpecialSliderSection"
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'

import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'

import { useNavigate } from 'react-router-dom';

import API from "../../../API.js"
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import Slider from 'react-slick'



const Media = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
    setIsLoading(true)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        setBlogData(response.data)
        setIsLoading(false)

      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)

      });
  }, [])

  
  const sec3Data = [
    { title: "Custom Software", description: "Customised software solutions crafted precisely to align with your business objectives, ensuring efficiency and scalability." },
    { title: "Security Solutions", description: "Our comprehensive security measures and strategies safeguard your digital assets, protecting your organisation from cyber threats." },
    { title: "AI & Automation", description: "Cutting-edge AI technologies and automation systems optimise processes and enhance productivity across your operations." },
    { title: "CRM Solutions", description: "Advanced customer relationship management platforms for seamless engagement and personalised interactions with your clients." }
  ]

  const sec4Data = [
    {
      title: "Improve Operation",
      description: "Streamline workflows, optimise processes, and boost efficiency with our tailored IT solutions for seamless operational enhancement.",
    },
    {
      title: "Keep Your Data Secure",
      description: "Safeguard your valuable data with robust cybersecurity measures and comprehensive strategies tailored to your specific needs.",
    },
    {
      title: "Enhance User Experience",
      description: "Elevate user satisfaction and engagement with intuitive interfaces, personalised content, and seamlessly integrated digital experience.",
    },
    {
      title: "Connect Everything Easily",
      description: "Integrate systems effortlessly, facilitate collaboration, and ensure smooth communication across platforms for enhanced connectivity.",
    },
    {
      title: "Drive Business Growth",
      description: "Leverage cutting-edge technologies and data-driven insights to unlock new opportunities and accelerate your organisation's growth.",
    },



  ]

  
  const sec5Data=[
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    
    
  ]



  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };










  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index)
  };

  const sliderRef=useRef(null)
  
    const next = () => {
      sliderRef.current.slickNext();
  };
  
  const previous = () => {
      sliderRef.current.slickPrev();
  };

  const getImageSrc = (index) => {
    switch (index) {
      case 0:
        return "/images/media/sec-4-1.png";
      case 1:
        return "/images/media/sec-4-2.png";
      case 2:
        return "/images/media/sec-4-3.png";
    
      default:
        return "/images/media/sec-4-1.png";
    }
  };
  

  useEffect(() => {
    const imgs = document.querySelectorAll(".img-mark");
  
    if (imgs.length === 0) {
      // Exit if there are no images found
      return;
    }
  
    imgs.forEach((img, index) => {
      if (index === currentSlide) {
        img.classList.add("active");
      } else {
        img.classList.remove("active");
      }
    });
  }, [currentSlide]);







  //bluecontainer
  

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
      
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
    ]

  };



  const sliderRef2=useRef(null)
  
    const next2 = () => {
      sliderRef2.current.slickNext();
  };
  
  const previous2 = () => {
      sliderRef2.current.slickPrev();
  };
  const [isToggled, setIsToggled] = useState(false);
  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  const [content1, setContent1]=useState(false);
   
  const cont1btn=()=>{
    setContent1(!content1);
  }











  return (
    <div className='media-page'>

<BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"media"}
        blueTitle={"Media & Entertainment"}
        titleWeb={"Streaming Success<br/> Powered by IT"}
        titleTab={"Streaming Success<br/> Powered by IT"}
        titleMob={"Streaming<br/> Success<br/> Powered by IT"}
        subTitleWeb={"We guarantee your audience will always<br/> have a rich experience."}
        subTitleTab={"We guarantee your audience will always<br/> have a rich experience."}
        subTitleMob={"We guarantee your audience<br/> will always have a rich<br/> experience."}
        iconTopWeb={'7%'}
        iconLeftWeb={'90%'}
        iconTopTab={'59%'}
        iconLeftTab={'68%'}
        iconTopMob={'56%'}
        iconLeftMob={'67%'}
        iconRotationWeb={142}
        iconRotationTab={102}
        iconRotationMob={-28}>
      </BannerSubPages>


      {/* <DiamondSection
        pageName={'media'}
        secNo={'2'}
        theme={"light"}
        headingBoxPresent={false}
        


        subHeadding1={"Expand Market Presence"}
        description1={"Unlock unlimited growth opportunities for your business with our advanced software solution. Share your content and reach out to audiences worldwide, opening up exciting new markets for your brand. The possibilities are truly endless!"}
        subHeadding2={"Personalise Experience"}
        description2={"In order to provide a top-notch experience for your customers, it's important to offer personalised messages and content that align with their preferences. This can easily be accomplished using AI and extensive data management, allowing you to stay ahead of their needs and desires."}
      />
 */}



<div className='img-sec2-itoutsource'>

 
<div className='for-img'>
  <img src="\images\media\sec-2.png"/>

</div>

<div className='for-text'>
  <span>Expand Market Presence</span>
  <p>Unlock unlimited growth opportunities for your business with our advanced software solution. Share your content and reach out to audiences worldwide, opening up exciting new markets for your brand. The possibilities are truly endless!</p>

</div>


</div>


<div className='img-sec2-itoutsource'>

 

<div className='for-text'>
  <span>Personalise Experience</span>
  <p>In order to provide a top-notch experience for your customers, it's important to offer personalised messages and content that align with their preferences. This can easily be accomplished using AI and extensive data management, allowing you to stay ahead of their needs and desires.</p>

</div>

<div className='for-img'>
  <img src="\images\media\sec-2-2.png"/>

</div>

</div>










<div className='white-head'>
  <div className='headfont'>Media & Entertainment IT Solutions</div>
  <p>Step onto the digital stage confidently. Our specialised IT solutions set the scene for your business success.</p>
  
</div>




<div className='complex-slider'>
  <div className='width50'>
    <div className='arow-cont'>
    <img src="\images\healthcare\arrow-cs.svg" onClick={previous}/>
<img src="\images\healthcare\arrow2-cs.svg" onClick={next}/>
</div>
  <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
        <div>
        <div className='card'>
          <span className='montserrat-hard'>
          Custom Software
          </span>
          <p className='montserrat-lit '>
          Customised software solutions crafted precisely to align with your business objectives, ensuring efficiency and scalability.

          </p>
        </div>
        </div>


        <div>
        <div className='card'>
          <span className='montserrat-hard'>
          Security Solutions
             </span>
          <p className='montserrat-lit '>
          Our comprehensive security measures and strategies safeguard your digital assets, protecting your organisation from cyber threats.
         
         </p>
        </div>
        </div>


        
        <div>
        <div className='card'>
          <span className='montserrat-hard'> 
          AI & Automation 
          </span>
          
          <p className='montserrat-lit '>
          Cutting-edge AI technologies and automation systems optimise processes and enhance productivity across your operations.

          </p>
        </div>
        </div>

       

        <div>
        <div className='card'>
          <span className='montserrat-hard'>
          CRM Solutions
             </span>
          <p className='montserrat-lit '>
        Advanced customer relationship management platforms for seamless engagement and personalised interactions with your clients.
         
         </p>
        </div>
        </div>


      

        
      </Slider>
    </div>

  </div>
  <div className='width50-2'>
  
 
  {sec3Data.map((_, index) => (
    <img key={index} className='img-mark' src={getImageSrc(index)} alt="Healthcare" />
  ))}
    
    </div>
</div>











<div id="targetDiv"  className={`blueslider-update ${isToggled ? 'black-bg' : ''}`}>

  <div className='head montserrat-hard'>
  Let us help you</div>

  <p className='montserrat-lit'></p>
  <div className='combine-btn'>
  <div className='arrow'>

    <img src="\images\protect-your-customers-data\1.svg" onClick={previous2}/>
    <img src="\images\protect-your-customers-data\Frame (1).svg" onClick={next2}/>

  </div>

  {/* <div className="toggle-container">
          <input type="checkbox" id="toggleButton" className="toggle-checkbox" onChange={handleToggleChange} />
          <label htmlFor="toggleButton" className="toggle-label">
            <span className="toggle-button">
            <img src="\images\protect-your-customers-data\icons8-open-lock.svg" className='im1'/>
            <img src="\images\protect-your-customers-data\icons8-lock.svg" className='im2'/>
            </span>
          </label>
        </div> */}
        </div>



       
  <div className='slickblue-update montserrat-lit'>
  <div className="slider-container ">
      <Slider ref={sliderRef2} {...settings2}>
        <div>
         <div className='cardofblue-update'>
          <span> 
          Improve Operation

          </span>
          <p>
Streamline workflows, optimise processes, and boost efficiency with our tailored IT solutions for seamless operational enhancement.
            
            </p>
         </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>
Keep Your Data Secure

        </span>
        <p>
Safeguard your valuable data with robust cybersecurity measures and comprehensive strategies tailored to your specific needs.
          
         </p>

        </div>
        </div>
        <div>
        <div className='cardofblue-update'>

        <span>
        Enhance User Experience




        </span>
        <p>
Elevate user satisfaction and engagement with intuitive interfaces, personalised content, and seamlessly integrated digital experience.
          
        </p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>
        Connect Everything Easily
        </span>
        <p>
        Integrate systems effortlessly, facilitate collaboration, and ensure smooth communication across platforms for enhanced connectivity.
          
         </p>
        </div>
        </div>
       
        <div>
        <div className='cardofblue-update'>
        <span>
       
Drive Business Growth
        </span>
        <p>
Leverage cutting-edge technologies and data-driven insights to unlock new opportunities and accelerate your organisation's growth.
        
          
         </p>
        </div>
        </div>
       
       
        
       
      </Slider>
    </div>
    

  </div>




</div>





<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>





{/* 

<SpecialSliderSection 
      pageName={"media"}
      secNo={"3"}
      
      headingBoxPresent={true}
      headingWeb={"Media & Entertainment<br/> IT Solutions"}
      headingTab={"Media & Entertainment IT Solutions"}
      headingMob={"Media & Entertainment IT Solutions"}
      descriptionWeb={"Step onto the digital stage confidently. Our specialised IT solutions set the scene for<br/> your business success."}
      descriptionTab={"Step onto the digital stage confidently. Our specialised IT solutions set the scene for your business success."}
      descriptionMob={"Step onto the digital stage confidently. Our specialised IT solutions set the scene for your business success."}
      boxAlignment={"center"}
      
      subTitle={""}
      data={sec3Data} 
      />


      <BlueNotchSection
        headingWeb={"Let us help you"}     // <br/>
        headingTab={"Let us help you"}
        headingMob={"Let us help you"}
        descriptionPresent={false}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        listData={sec4Data}

      />


 */}

      <div className='sec-6'>

      </div>


      <div className='sec-7'>

      </div>




      <BottomBanner
        pageName={"media"}
        titleWeb={"Have a Query?"}
        titleTab={"Have a Query?"}
        titleMob={"Have a Query?"}
                
        subTitlePresent={true}
        subTitleWeb={"Connect with us, and let's explore the endless<br/> possibilities together."}
        subTitleTab={"Connect with us, and let's explore<br/> the endless possibilities together."}
        subTitleMob={"Connect with us, and let's explore the<br/> endless possibilities together."}

        buttonText={"Get Started"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default Media