import React,{useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../API';
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import $ from 'jquery';

import './BlogPreview.scss';
import { getFontSizeFactor, renderDescription } from "../../common-functions/commonFunctions"


const BlogPreview = ({ title, state, setterFunction, okClickedFunction,blogSections  }) => {

    const okClicked=()=>{
        okClickedFunction();
        setterFunction(false)
    }

    let navigate = useNavigate();
    const loadPage = () => {
      navigate(window.location.pathname);
    };

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const [blogItem, setBlogItem] = useState({});
    const [BlogSubItems,setBlogSubItems] = useState([])
    const blogID  = localStorage.getItem('itemSelectedId')
    
    useEffect(() => {
        setIsLoading(true) ;
        API.get(`/blogs/${blogID}/`)
        .then((response) => {
            setBlogItem(response.data);
            setBlogSubItems(response.data.blog_items)
            setIsLoading(false);
            // console.log(response.data)
        })
        .catch((error) => {
            setIsErrorModalOpen(true);
            console.log(error,`/blogs/${blogID}/`)
            setMessage(error.message || "An error occurred.");
        });
    
    }, []);


    

      const handlePublish = async (isPublished) => {
        setIsLoading(true);
        let allSectionsAddedSuccessfully = true;
        try {
            // Update blog as published
            await API.put(`/blogs/${blogID}/`, { is_published: isPublished });
            setMessage(isPublished ? 'Blog has been published.' : 'Blog saved as draft.');
            // setIsMessageModalOpen(true);
            
            // Add blog sections
            for (let index = 0; index < blogSections.length; index++) {
              const BlogSectionForAdd = blogSections[index];
          
              const SectionformData = new FormData();
              SectionformData.append('blog', localStorage.getItem('itemSelectedId'));
          
              // Check if the section already has an ID (indicating it already exists in the database)
              if (BlogSectionForAdd.id) {
                  // If it has an ID, update the existing section
                  for (const key in BlogSectionForAdd) {
                    if (key !== 'image_url') {
                        SectionformData.append(key, BlogSectionForAdd[key]);
                    } else {
                        // Check if image_url is a File object
                        if (BlogSectionForAdd[key] instanceof File) {
                            SectionformData.append(key, BlogSectionForAdd[key]);
                        }
                    }
                }
                
          
                  try {
                      const response = await API.put(`/blogs/blog-items/${BlogSectionForAdd.id}/`, SectionformData, {
                          headers: {
                              'Content-Type': 'multipart/form-data',
                          }
                      });
                      // console.log(`Section ${index + 1} updated:`, response.data);
                  } catch (sectionError) {
                      allSectionsAddedSuccessfully = false;
                      setMessage(sectionError.message || "An error occurred.")
                      setIsErrorModalOpen(true);
                      // console.log(`Error updating section ${index + 1}:`, sectionError.response ? sectionError.response.data : sectionError.message);
                      
                  }
              } else {
                  // If it doesn't have an ID, create a new section
                  for (const key in BlogSectionForAdd) {
                      SectionformData.append(key, BlogSectionForAdd[key]);
                  }
          
                  try {
                      const response = await API.post(`/blogs/blog-items/`, SectionformData, {
                          headers: {
                              'Content-Type': 'multipart/form-data',
                          }
                      });
                      // console.log(`New section ${index + 1} added:`, response.data);
                  } catch (sectionError) {
                      allSectionsAddedSuccessfully = false;
                      setMessage(sectionError.message || "An error occurred.")
                      setIsErrorModalOpen(true);
                      // console.log(`Error adding new section ${index + 1}:`, sectionError.response ? sectionError.response.data : sectionError.message);
                  }
              }

              // console.log(SectionformData);
          }
    
            // Remove items from localStorage after all sections are added successfully
            if (allSectionsAddedSuccessfully) {
              setIsMessageModalOpen(true)
              localStorage.removeItem('itemSelectedId');
              localStorage.removeItem('blogSections');
            }
        } catch (error) {
            setIsErrorModalOpen(true);
            console.error(error,'----');
            setMessage(error.message || "An error occurred.");
        } finally {
            setIsLoading(false);
        }
    };

  return (
    <>
    <div className='custom-modal blog-preview'>
        <div className='card'>
            <div className='close-btn' >
                <button  onClick={okClicked}>
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.999 15.0005L44.999 45.0005" stroke="#263238" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M45.001 15.0005L15.001 45.0005" stroke="#263238" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className='blog-header'>
                <h3>Publish Blog</h3>
                <div className='d-flex'>
                    <button className='admin-blue-btn' onClick={() => handlePublish(true)}>Publish Blog</button>
                    <button className='admin-black-btn' onClick={() => handlePublish(false)}>Save as Draft</button>
                </div>
            </div>
            {/* <div className='blog-banner'style={{backgroundImage:`url(http://zogglobaltest.com/media/blog/generic/section-1/Rectangle_16.png`}}> */}
            <div className='blog-banner'style={{backgroundImage:`url(${blogItem.image_url}`}}>
                <div className='inner'>
                    <div className="department">{blogItem.department}</div>
                    <div className='title'>{blogItem.title}</div>
                    <div className='sub-title' >by {blogItem.extra}</div>
                    <div className='sub-title2' >{blogItem.created_at}</div>
                </div>
            </div>
            <div className='blog-slider'>
                {blogSections.map((item, index) => (
                    <div key={index} className='section'>
                        <h2>{item.sub_title}</h2>
                        {item.image_url ? (
                                  typeof item.image_url === 'object' ? (
                                      <img src={URL.createObjectURL(item.image_url)} alt='' />
                                  ) : (
                                      <img src={item.image_url} alt='' />
                                  )
                          ) : null}
                        <div className='description' dangerouslySetInnerHTML={{ __html: renderDescription(item.description,getFontSizeFactor()) }} />
                    </div>
                ))}
            </div>
        
        </div>
            
        
    </div>
    {isLoading && <FixedOverlayLoadingSpinner />} 
    <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={loadPage} />
    {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={()=> navigate('/admin/blogs/')} />}
    </>
  );
};

export default BlogPreview;