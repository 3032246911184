import React, { Component, useEffect, useState, useContext, useRef } from 'react'
import './Technology.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'
import SpecialSliderSection from "../../common-component/SpecialSliderSection/SpecialSliderSection"
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'
import GreyCardsSection from '../../common-component/GreyCardsSection/GreyCardsSection.js'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'


import Carousel from 'react-bootstrap/Carousel';
import { useNavigate, Link } from 'react-router-dom';

import API from "../../../API.js"
import LoadingSpinner from "../../../LoadingSpinner.js";
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';

import { UserContext } from '../../../authentication/pages/UserContext.js';

import Slider from 'react-slick'


const Technology = () => {
  const navigate = useNavigate();

  const { isLoggedIn, login, logout } = useContext(UserContext);

  const [tabSelected, selectTab] = useState(0);

  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
    setIsLoading(true)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        setBlogData(response.data)
        setIsLoading(false)

      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)

      });
  }, [])

  
  const sec2Data = [
    {
      title: "",
      description: "Customised IT solutions addressing specific tech business challenges, aligning with goals for optimal performance and efficiency.",
    },

    {
      title: "",
      description: "Deep understanding of tech industry intricacies, offering proactive solutions and staying ahead with the latest technologies and trends.",
    },
    {
      title: "",
      description: "Solutions designed to adapt to tech businesses' evolving needs, ensuring support for growth, expansions, and market changes.",
    },
    {
      title: "",
      description: "Ongoing maintenance and updates to ensure robust IT infrastructure, providing reliable support for achieving business objectives.",
    },
    


  ]
  const sec3Data = [
    { title: "AI & Automation", description: "Our AI solutions are like having a team of tireless assistants, streamlining tasks and sparking innovation." },
    { title: "Bespoke Software", description: "Imagine software tailored just for you, like a perfectly fitted suit, boosting productivity and growth." },
    { title: "Business Security", description: "We're your digital guardians, keeping your valuable data safe from cyber threats and ensuring peace of mind." },
    { title: "Cloud Migration", description: "Think of us as your cloud experts, guiding you smoothly to a world of flexibility and efficiency ahead." }
  ]
  const sec4Data = [
    {
      title: "Expertise in the Tech Industry",
      description: "Expert knowledge tailored to tech businesses ensures solutions that meet unique needs effectively.",
    },
    {
      title: "Customised Solutions",
      description: "Tailored IT solutions addressing your specific challenges and goals for enhanced efficiency and success.",
    },
    {
      title: "Reliable Support & Maintenance",
      description: "Ongoing support ensures your IT remains secure, efficient, and hassle-free, empowering business growth.",
    },
    {
      title: "Innovative Approach to Tech",
      description: "Embrace the latest technological trends, ensuring your business stays competitive and drives growth.",
    },
    {
      title: "Exceptional Customer Satisfaction",
      description: "Consistently exceed expectations, ensuring your satisfaction and success as our cherished partner.",
    },



  ]

  
  const sec5Data=[
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    
    
  ]

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };







  //bluecontainer
  

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
      
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
    ]

  };



  const sliderRef2=useRef(null)
  
    const next2 = () => {
      sliderRef2.current.slickNext();
  };
  
  const previous2 = () => {
      sliderRef2.current.slickPrev();
  };
  const [isToggled, setIsToggled] = useState(false);
  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  const [content1, setContent1]=useState(false);
   
  const cont1btn=()=>{
    setContent1(!content1);
  }








  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index)
  };

  const sliderRef=useRef(null)
  
    const next = () => {
      sliderRef.current.slickNext();
  };
  
  const previous = () => {
      sliderRef.current.slickPrev();
  };

  const getImageSrc = (index) => {
    switch (index) {
      case 0:
        return "/images/technology/sec-3-1.png";
      case 1:
        return "/images/technology/sec-3-2.png";
      case 2:
        return "/images/technology/sec-3-3.png";
      case 3:
        return "/images/technology/sec-3-4.png";
      default:
        return "/images/technology/sec-3-1.png";
    }
  };
  

  useEffect(() => {
    const imgs = document.querySelectorAll(".img-mark");
  
    if (imgs.length === 0) {
      // Exit if there are no images found
      return;
    }
  
    imgs.forEach((img, index) => {
      if (index === currentSlide) {
        img.classList.add("active");
      } else {
        img.classList.remove("active");
      }
    });
  }, [currentSlide]);












  return (
    <div className='technology-page'>

<BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"technology"}
        blueTitle={"Technology"}
        titleWeb={"IT Solutions for<br/> Tech Businesses"}
        titleTab={"IT Solutions for<br/> Tech Businesses"}
        titleMob={"IT Solutions<br/> for Tech<br/> Businesses"}
        subTitleWeb={"We offer transformative support for<br/> your tech needs."}
        subTitleTab={"We offer transformative support for<br/> your tech needs."}
        subTitleMob={"We offer transformative<br/> support for your tech needs."}
        iconTopWeb={'55%'}
        iconLeftWeb={'82%'}
        iconTopTab={'58%'}
        iconLeftTab={'73%'}
        iconTopMob={'49%'}
        iconLeftMob={'76%'}
        iconRotationWeb={44}
        iconRotationTab={22}
        iconRotationMob={15}>
      </BannerSubPages>

      
      {/* <GreyCardsSection
        pageName={'technology'}
        headingWeb={"Unsatisfied with Your Current IT Provider?"}
        headingTab={"Unsatisfied with Your Current IT Provider?"}
        headingMob={"Unsatisfied with Your Current IT Provider?"}
        descriptionWeb={"You're not alone. The majority of our clients were in your situation. They had IT assistance, but they still had reoccurring issues, downtime, techs, and, worst of all, poor response times.<br/><br/> At first, they thought it was just the cost of doing business—until they found us. By working with us, they were able to improve their IT infrastructure, eliminate recurring issues, and get their business back on track. Discover how our tailored approach and commitment to excellence can propel your tech business forward."}
        descriptionTab={"You're not alone. The majority of our clients were in your situation. They had IT assistance, but they still had reoccurring issues, downtime, techs, and, worst of all, poor response times.<br/><br/> At first, they thought it was just the cost of doing business—until they found us. By working with us, they were able to improve their IT infrastructure, eliminate recurring issues, and get their business back on track. Discover how our tailored approach and commitment to excellence can propel your tech business forward."}
        descriptionMob={"You're not alone. The majority of our clients were in your situation. They had IT assistance, but they still had reoccurring issues, downtime, techs, and, worst of all, poor response times.<br/><br/> At first, they thought it was just the cost of doing business—until they found us. By working with us, they were able to improve their IT infrastructure, eliminate recurring issues, and get their business back on track. Discover how our tailored approach and commitment to excellence can propel your tech business forward."}
        cardsData={ sec2Data}

      /> */}








<div id="targetDiv"  className={`blueslider-update ${isToggled ? 'black-bg' : ''}`}>

  <div className='head montserrat-hard'>
  Unsatisfied with Your Current <br/>IT Provider?  </div>

  <p className='montserrat-lit'>You're not alone. The majority of our clients were in your situation. They had IT assistance, but they still had reoccurring issues, downtime, techs, and, worst of all, poor response times. At first, they thought it was just the cost of doing business—until they found us. By working with us, they were able to improve their IT infrastructure, eliminate recurring issues, and get their business back on track. Discover how our tailored approach and commitment to excellence can propel your tech business forward.</p>
  <div className='combine-btn'>
  <div className='arrow'>

    <img src="\images\protect-your-customers-data\1.svg" onClick={previous2}/>
    <img src="\images\protect-your-customers-data\Frame (1).svg" onClick={next2}/>

  </div>

  {/* <div className="toggle-container">
          <input type="checkbox" id="toggleButton" className="toggle-checkbox" onChange={handleToggleChange} />
          <label htmlFor="toggleButton" className="toggle-label">
            <span className="toggle-button">
            <img src="\images\protect-your-customers-data\icons8-open-lock.svg" className='im1'/>
            <img src="\images\protect-your-customers-data\icons8-lock.svg" className='im2'/>
            </span>
          </label>
        </div> */}
        </div>



       
  <div className='slickblue-update montserrat-lit'>
  <div className="slider-container ">
      <Slider ref={sliderRef2} {...settings2}>
        <div>
         <div className='cardofblue-update'>
          <span> </span>
          <p>Customised IT solutions addressing specific tech business challenges, aligning with goals for optimal performance and efficiency.</p>
         </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span></span>
        <p>Deep understanding of tech industry intricacies, offering proactive solutions and staying ahead with the latest technologies and trends.</p>

        </div>
        </div>
        <div>
        <div className='cardofblue-update'>

        <span></span>
        <p>Solutions designed to adapt to tech businesses' evolving needs, ensuring support for growth, expansions, and market changes.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span> </span>
        <p>Ongoing maintenance and updates to ensure robust IT infrastructure, providing reliable support for achieving business objectives.</p>
        </div>
        </div>
       
       
        
       
      </Slider>
    </div>
    

  </div>



</div>








<div className='white-head'>
  <div className='headfont'>Our IT offerings for the Tech Industry</div>
  <p>Discover the power of your business with cutting-edge AI, bespoke software, robust cybersecurity, and seamless cloud migration services. Future-proof your success today.</p>
  
</div>




<div className='complex-slider'>
  <div className='width50'>
    <div className='arow-cont'>
    <img src="\images\healthcare\arrow-cs.svg" onClick={previous}/>
<img src="\images\healthcare\arrow2-cs.svg" onClick={next}/>
</div>
  <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
        <div>
        <div className='card'>
          <span className='montserrat-hard'>AI & Automation</span>
          <p className='montserrat-lit '>Our AI solutions are like having a team of tireless assistants, streamlining tasks and sparking innovation.</p>
        </div>
        </div>
        
        <div>
        <div className='card'>
          <span className='montserrat-hard'>Bespoke Software
         </span>
          
          <p className='montserrat-lit '> Imagine software tailored just for you, like a perfectly fitted suit, boosting productivity and growth.</p>
        </div>
        </div>

        <div>
        <div className='card'>
          <span className='montserrat-hard'>Business Security
         </span>
          <p className='montserrat-lit '>
          We're your digital guardians, keeping your valuable data safe from cyber threats and ensuring peace of mind.</p>
        </div>
        </div>

        <div>
        <div className='card'>
          <span className='montserrat-hard'>Cloud Migration
          
       </span>
          <p className='montserrat-lit '> Think of us as your cloud experts, guiding you smoothly to a world of flexibility and efficiency ahead.</p>
        </div>
        </div>
        
      </Slider>
    </div>

  </div>
  <div className='width50-2'>
  
 
  {sec3Data.map((_, index) => (
    <img key={index} className='img-mark' src={getImageSrc(index)} alt="Healthcare" />
  ))}
    
    </div>
</div>





















{/* <SpecialSliderSection 
      pageName={"technology"}
      secNo={"3"}
      
      headingBoxPresent={true}
      headingWeb={"Our IT offerings for the<br/> Tech Industry"}
      headingTab={"Our IT offerings for the<br/> Tech Industry"}
      headingMob={"Our IT offerings for the Tech Industry"}
      descriptionWeb={"Discover the power of your business with cutting-edge AI, bespoke software, robust<br/> cybersecurity, and seamless cloud migration services. Future-proof your success<br/> today."}
      descriptionTab={"Discover the power of your business with cutting-edge AI, bespoke software, robust cybersecurity, and seamless cloud migration services. Future-proof your success today."}
      descriptionMob={"Discover the power of your business with cutting-edge AI, bespoke software, robust cybersecurity, and seamless cloud migration services. Future-proof your success today."}
      boxAlignment={"center"}
      
      subTitle={""}
      data={sec3Data} 
      /> */}


      {/* <BlueNotchSection
        headingWeb={"Why Choose ZOG Global"}     // <br/>
        headingTab={"Why Choose ZOG Global"}
        headingMob={"Why Choose<br/> ZOG Global"}
        descriptionPresent={false}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        listData={sec4Data}

      /> */}









<div className='pink-card montserrat-lit'>

<div className='head1'>
<div className='texthed'>Why Choose ZOG Global</div>
<p></p>
</div>

<div className='head2'>
<div className='each-sec'>
<span>Expertise in the Tech Industry</span>
<p>
Our team possesses expert knowledge uniquely tailored to tech businesses, enabling us to craft innovative solutions that meet your specific needs effectively. With deep industry insights, we ensure your business leverages advanced technology to thrive in a competitive landscape.</p>
</div>

<div className='each-sec'>
<span>Customised Solutions
</span>
<p>
We offer tailored IT solutions designed to address your unique challenges and goals. By understanding your business objectives, we create bespoke strategies that enhance operational efficiency, drive success, and deliver measurable results, ensuring your business remains agile and forward-thinking.

</p>
</div>

<div className='each-sec'>
<span>Reliable Support & Maintenance
</span>
<p>

Stay ahead of the curve by embracing the latest technological trends and advancements. Our innovative approach ensures your business not only stays competitive but also drives growth. We continuously explore new technologies to deliver solutions that propel your business forward in the digital age.
</p>
</div>

<div className='each-sec'>
<span>Innovative Approach to Tech</span>
<p>

Optimise resource allocation, minimise operational costs, and achieve economies of scale with our cost-effective IT solutions tailored for healthcare. By streamlining processes and reducing inefficiencies, our solutions help you maximise your budget, allowing for reinvestment in patient care and organisational growth.

</p>
</div>
<div className='each-sec'>
<span>Exceptional Customer Satisfaction</span>
<p>
We are committed to consistently exceeding expectations, ensuring your satisfaction and success as our cherished partner. Through dedicated service, attention to detail, and a customer-centric approach, we build lasting relationships that foster trust and drive mutual growth and prosperity.
</p>
</div>




</div>
</div>



{/* <BlogNewSection/> */}
<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>









      <BottomBanner
        pageName={"technology"}
        titleWeb={"Need IT Support?"}
        titleTab={"Need IT Support?"}
        titleMob={"Need IT Support?"}
                
        subTitlePresent={true}
        subTitleWeb={"Have a quick call with our expert team to<br/> discuss your IT needs."}
        subTitleTab={"Have a quick call with our<br/> expert team to discuss your IT needs."}
        subTitleMob={"Have a quick call with our expert team<br/> to discuss your IT needs."}

        buttonText={"CONNECT NOW"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default Technology