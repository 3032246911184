import React, { useEffect, useState } from 'react'
import './CyberSecurity2.scss'
import Banner from '../../common-component/BannerSubPages/BannerSubPages.js'

import { useNavigate } from 'react-router-dom';

import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import WhiteBanner from '../../common-component/BannerMainPages/WhiteBanner.js';
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages.js';




const CyberSecurity2 = () => {
  const navigate = useNavigate();
  const [selectedAccordion, selectAccordion]=useState(0)   
  

  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [tabSelected, selectTab] = useState(0);
  const [menuOpened, openMenu] = useState(0);



  useEffect(()=>{ 

    $(".accordion-section .segment").removeClass("active")
    if(selectedAccordion!==null){
    $(".accordion-section .segment").eq(selectedAccordion).addClass("active")
    }
  },[selectedAccordion])
  


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])



  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };


  const faqData = [
    {
      title: "Risk Assessment",
      description: "Identify vulnerabilities and evaluate potential risks within your data infrastructure. Our comprehensive analysis provides insights to fortify defences, ensuring proactive mitigation strategies against potential threats.",
    },
    {
      title: "Encryption Solutions",
      description: "Implement robust encryption protocols to secure sensitive data. Our encryption services ensure that your information remains unintelligible to unauthorised users, fortifying your data against breaches or unauthorised access.",
    },
    {
      title: "Data Privacy Consulting",
      description: "Rely on our expertise to navigate complex data privacy regulations. Our consulting services offer guidance in aligning your practices with legal frameworks, ensuring compliance and fostering a privacy-centric culture within your organisation.",
    },
    {
      title: "Incident Response Planning",
      description: "Prepare for potential data breaches with our incident response planning. We develop tailored strategies to respond to security incidents swiftly, minimising their impact and ensuring a timely and effective resolution.",
    },
    {
      title: "Continuous Monitoring",
      description: "Our vigilant monitoring services offer real-time oversight of your data environment. Through continuous surveillance, we detect anomalies and potential threats, enabling proactive intervention to maintain data integrity and compliance.",
    },
    {
      title: "Access Control Solutions",
      description: "Implement robust access control measures to manage data accessibility. Our solutions ensure that only authorised personnel can access specific information, reducing the risk of internal breaches or unauthorised data manipulation.",
    },
    {
      title: "Compliance Audits",
      description: "Validate your adherence to data protection regulations through our compliance audits. We conduct thorough assessments, identify gaps, and recommend corrective measures to ensure ongoing compliance and minimise legal risks."

    }


  ]
  return (
    <div className='cyber-security-two-page'>

      <BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"cyber-security"}
        subTitleWeb={"Secure your service and<br/>  operations with our Cyberteam."}
        subTitleTab={"Secure your service and<br/>  operations with our Cyberteam."}
        subTitleMob={"Secure your<br/> service and <br/> operations with our Cyberteam."}
        blueTitle={"CyberSecurity"}
        // titleWeb={faqData[tabSelected].title}
        titleWeb={"Ensure your protection, with us"}
        titleTab={"Ensure<br/>  your protection,<br/> with us"}
        titleMob={"Ensure<br/>  your protection,<br/>  with us"}

        iconTopWeb={'55%'}
        iconLeftWeb={'60%'}
        iconTopTab={'47%'}
        iconLeftTab={'78%'}
        iconTopMob={'20%'}
        iconLeftMob={'79%'}
        iconRotationWeb={185}
        iconRotationTab={185}
        iconRotationMob={185}>

      </BannerSubPages>


      <div className='sec-1'>
        <div className='lhs'>
          <div className='menu'>
            <div className={tabSelected == 0 ? "tab-btn active" : 'tab-btn'} onClick={() => selectTab(0)}>Regnoooooooooobågsbarn apfälla simp</div>
            <div className={tabSelected == 1 ? "tab-btn active" : 'tab-btn'} onClick={() => selectTab(1)}>Seismisk Facebookfest</div>
            <div className={tabSelected == 2 ? "tab-btn active" : 'tab-btn'} onClick={() => selectTab(2)}>Hälsolitteracitet inaskad</div>
            <div className={tabSelected == 3 ? "tab-btn active" : 'tab-btn'} onClick={() => selectTab(3)}>Snubbelsten lånegarderob</div>
            <div className={tabSelected == 4 ? "tab-btn active" : 'tab-btn'} onClick={() => selectTab(4)}>Regnbågsbarn apfälla simp</div>
          </div>

          <div className='contact-card'>
            <div className='title'>Speak to our Experts</div>
            <div className='description'>Connect with our cybersecurity experts for personalised advice and services tailored to your business's unique security needs and challenges.</div>
            <div className='link-box'>
              <button>
                <img src='/images/cyber-security-two/contact-icon-1.svg'></img>
              </button>

              <button>
                <img src='/images/cyber-security-two/contact-icon-2.svg'></img>
              </button>

              <button>
                <img src='/images/cyber-security-two/contact-icon-3.svg'></img>
              </button>


            </div>
          </div>
        </div>

        <div className='rhs'>
          <div className={tabSelected == 0 ? "tab-content active" : 'tab-content'}>


            <div className='card'>
              <h2 className="title">Our Approach to Compliance</h2>
              <p className="description">
              Our comprehensive approach to compliance ensures that your organisation not only meets all necessary regulatory requirements but also strengthens its overall security posture. We work closely with your team to develop tailored strategies that integrate seamlessly with your existing processes. Our proactive methodology includes continuous monitoring, regular assessments, and detailed reporting to keep you ahead of compliance obligations.
              </p>
            </div>

            
            <div className='card'>
              <h2 className="title">Regulatory Compliance Audits</h2>
              <p className="description">
              Ensuring adherence to industry standards and regulations is critical for your business. Our Regulatory Compliance Audits are designed to thoroughly evaluate your organisation’s adherence to applicable laws, regulations, and standards. We conduct meticulous audits that identify gaps and vulnerabilities, providing you with actionable insights and recommendations. Whether you need to comply with GDPR, HIPAA, PCI-DSS, or any other regulatory framework, our expert auditors are here to help you achieve and maintain compliance with ease.

              </p>
            </div>

            <div className='card half-width'>
              <h2 className="title">Data Protection and Privacy</h2>
              <p className="description">
Our Data Protection and Privacy services focus on safeguarding your organisation's critical information. We help you implement robust data protection strategies that align with global privacy regulations such as GDPR and CCPA. From data encryption and access controls to data breach response plans, we ensure your data is secure throughout its lifecycle. Our services also include privacy impact assessments and the development of privacy policies that foster trust and compliance.
</p>
            </div>

            <div className='card half-width'>
              <h2 className="title">Policy Development and Implementation</h2>
              <p className="description">
Strong, well-defined policies are the foundation of effective compliance and cybersecurity programs. Our Policy Development and Implementation services guide you in creating comprehensive policies that address your unique compliance needs. We work with you to develop policies that are not only compliant with regulatory standards but also practical and enforceable. Our team assists with the full implementation process, ensuring that your policies are effectively communicated and integrated into your organisational culture. Continuous review and updates ensure that your policies remain relevant and effective in the face of evolving regulatory requirements and security threats.
</p>
            </div>

            <div className='card'>
              <h2 className="title">Ensuring Advanced Security</h2>
              <p className="description">
We prioritise advanced security measures to safeguard your digital assets against evolving cyber threats. Our cybersecurity services are designed to implement most advanced technologies and industry best practices, ensuring robust protection for your organisation's sensitive data and infrastructure.</p>
            </div>

          </div>

          <div className={tabSelected == 1 ? "tab-content active" : 'tab-content'}>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

            <div className='card half-width'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Ofast jobb födiktig. Finanssmälta gyjant plastis. Usie plapongar hybridkrig. Preppare beslutsblindhet bloggbävning. Betalvärd plånboksbröllop. Asktåg dinar, inklusive tjejsamla.</p>
            </div>

            <div className='card half-width'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Ofast jobb födiktig. Finanssmälta gyjant plastis. Usie plapongar hybridkrig. Preppare beslutsblindhet bloggbävning. Betalvärd plånboksbröllop. Asktåg dinar, inklusive tjejsamla.</p>
            </div>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

          </div>


          <div className={tabSelected == 2 ? "tab-content active" : 'tab-content'}>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

            <div className='card half-width'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Ofast jobb födiktig. Finanssmälta gyjant plastis. Usie plapongar hybridkrig. Preppare beslutsblindhet bloggbävning. Betalvärd plånboksbröllop. Asktåg dinar, inklusive tjejsamla.</p>
            </div>

            <div className='card half-width'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Ofast jobb födiktig. Finanssmälta gyjant plastis. Usie plapongar hybridkrig. Preppare beslutsblindhet bloggbävning. Betalvärd plånboksbröllop. Asktåg dinar, inklusive tjejsamla.</p>
            </div>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

          </div>


          <div className={tabSelected == 3 ? "tab-content active" : 'tab-content'}>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

            <div className='card half-width'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Ofast jobb födiktig. Finanssmälta gyjant plastis. Usie plapongar hybridkrig. Preppare beslutsblindhet bloggbävning. Betalvärd plånboksbröllop. Asktåg dinar, inklusive tjejsamla.</p>
            </div>

            <div className='card half-width'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Ofast jobb födiktig. Finanssmälta gyjant plastis. Usie plapongar hybridkrig. Preppare beslutsblindhet bloggbävning. Betalvärd plånboksbröllop. Asktåg dinar, inklusive tjejsamla.</p>
            </div>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

          </div>


          <div className={tabSelected == 4 ? "tab-content active" : 'tab-content'}>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

            <div className='card half-width'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Ofast jobb födiktig. Finanssmälta gyjant plastis. Usie plapongar hybridkrig. Preppare beslutsblindhet bloggbävning. Betalvärd plånboksbröllop. Asktåg dinar, inklusive tjejsamla.</p>
            </div>

            <div className='card half-width'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Ofast jobb födiktig. Finanssmälta gyjant plastis. Usie plapongar hybridkrig. Preppare beslutsblindhet bloggbävning. Betalvärd plånboksbröllop. Asktåg dinar, inklusive tjejsamla.</p>
            </div>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

          </div>


          <div className={tabSelected == 5 ? "tab-content active" : 'tab-content'}>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

            <div className='card half-width'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Ofast jobb födiktig. Finanssmälta gyjant plastis. Usie plapongar hybridkrig. Preppare beslutsblindhet bloggbävning. Betalvärd plånboksbröllop. Asktåg dinar, inklusive tjejsamla.</p>
            </div>

            <div className='card half-width'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Ofast jobb födiktig. Finanssmälta gyjant plastis. Usie plapongar hybridkrig. Preppare beslutsblindhet bloggbävning. Betalvärd plånboksbröllop. Asktåg dinar, inklusive tjejsamla.</p>
            </div>

            <div className='card'>
              <h2 className="title">Regnbågsbarn apfälla simp</h2>
              <p className="description">Borölig pseudoska. Idobel sosa sprita samt åsiktskorridor. Terja aktiga. Nal loba eurososka i plasuskapet postlog. Laspesm vask, och säg i prepovis trollfilter. Bofav multibera poher. Krona polysonar. Pelogi europp och sol osade. Polyre menskopp ifall stödkorv, jasminrevolution, emedan bioktig. Dosk munt förutom anagisk. Vire blogga samt kasa keltisk tiger. Bed daligt för att ness respektive tvodd. Åre. Nätdeklarant. Förväntningssamhälle post digital-tv i intras. Kront intrasoss. Lasm päboskapet.</p>
            </div>

          </div>

        </div>

      </div>


      <div className='sec-2'>
      <div className='accordion-section'>
      <div className='inner'>
        
        

        {faqData.map((item, index) => {
          return (
            <div className={item.title.length>28?'segment double-lined':"segment"} key={index} onClick={selectedAccordion==index?()=>selectAccordion(null):()=>selectAccordion(index)}>
              <img className="arrow" src="/images/common-components/accordion/arrow-up.svg"></img>
              <h2>{item.title}</h2>
              <p>{item.description}</p>
              {item.link&&<button className='button-black-box' onClick={()=>navigate(item.link)}>LEARN MORE</button>}

            </div>
          )
        })}
        
      </div>
    </div>
      </div>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default CyberSecurity2