import React, { useEffect, useRef, useState } from 'react'
import './Legal.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'
import SimpleSection from '../../common-component/SimpleSection/SimpleSection.js'
import SpecialSliderSection from "../../common-component/SpecialSliderSection/SpecialSliderSection"
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'

import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'

import { useNavigate } from 'react-router-dom';

import API from "../../../API.js"
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import Slider from 'react-slick'




const Legal = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
    setIsLoading(true)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        setBlogData(response.data)
        setIsLoading(false)

      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)

      });
  }, [])

  
  const sec3Data = [
    { title: "Data Security", description: "Ensure legal data protection with robust encryption, firewalls, and compliance measures for confidentiality." },
    { title: "Cloud Services", description: "Transform legal operations with cloud-based document management, storage, and secured collaboration tools." },
    { title: "Automation Services", description: "Streamline legal processes through automated workflows, reducing manual tasks and optimising productivity." }
  ]

  const sec4Data = [
    {
      title: "Simplify Operations",
      description: "Streamline your processes and workflows for smoother, more efficient day-to-day operations.",
    },
    {
      title: "Boost Productivity",
      description: "Enhance your team's performance with our IT solutions designed to maximise efficiency and output.",
    },
    {
      title: "Enhanced Security",
      description: "Fortify your defences and safeguard sensitive data against cyber threats and breaches effectively.",
    },
    {
      title: "Protect Client Records",
      description: "Uphold client trust by implementing stringent measures to safeguard their sensitive information.",
    },
    {
      title: "Reduce Operational Costs",
      description: "Optimise resource utilisation and minimise expenses with cost-effective IT solutions and strategies.",
    },



  ]

  const sec5Data=[
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    
  ]
 

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };










  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index)
  };

  const sliderRef=useRef(null)
  
    const next = () => {
      sliderRef.current.slickNext();
  };
  
  const previous = () => {
      sliderRef.current.slickPrev();
  };

  const getImageSrc = (index) => {
    switch (index) {
      case 0:
        return "/images/legal/sec-3-1.png";
      case 1:
        return "/images/legal/sec-3-2.png";
      case 2:
        return "/images/legal/sec-3-3.png";
    
      default:
        return "/images/legal/sec-3-1.png";
    }
  };
  

  useEffect(() => {
    const imgs = document.querySelectorAll(".img-mark");
  
    if (imgs.length === 0) {
      // Exit if there are no images found
      return;
    }
  
    imgs.forEach((img, index) => {
      if (index === currentSlide) {
        img.classList.add("active");
      } else {
        img.classList.remove("active");
      }
    });
  }, [currentSlide]);













  return (
    <div className='legal-page'>

<BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"legal"}
        blueTitle={"Legal"}
        titleWeb={"Legal IT Solutions<br/> Simplified"}
        titleTab={"Legal IT Solutions<br/> Simplified"}
        titleMob={"Legal IT<br/> Solutions<br/> Simplified"}
        subTitleWeb={"We are dedicated to providing law firms<br/> with reliable IT services."}
        subTitleTab={"We are dedicated to providing law firms<br/> with reliable IT services."}
        subTitleMob={"We are dedicated to<br/> providing law firms with<br/> reliable IT services."}
        iconTopWeb={'28%'}
        iconLeftWeb={'90%'}
        iconTopTab={'59%'}
        iconLeftTab={'68%'}
        iconTopMob={'67%'}
        iconLeftMob={'67%'}
        iconRotationWeb={80}
        iconRotationTab={102}
        iconRotationMob={150}>
      </BannerSubPages>


      {/* <SimpleSection
        pageName={"legal"}
        secNo={"2"}
        headdingPresent={true}
        buttonPresent={false}
        imagePosition={"left"}
        headingWeb={"Tech Solutions for<br/> Legal Success"}
        headingTab={"Tech Solutions for Legal Success"}
        headingMob={"Tech Solutions for<br/> Legal Success"}
        description={"Wondering if your law firm should invest in technology? Absolutely, yes. In the legal sector, IT is commonly used to enhance work-life balance through improved efficiency and productivity via automation and cloud convenience."}
        buttonText={""}
      />
       */}

       
<div className='img-sec2-itoutsource'>

 

<div className='for-text'>
  <span>Tech Solutions for Legal Success</span>
  <p>Wondering if your law firm should invest in technology? Absolutely, yes. In the legal sector, IT is commonly used to enhance work-life balance through improved efficiency and productivity via automation and cloud convenience.</p>

</div>

<div className='for-img'>
  <img src="\images\legal\sec-2.png"/>

</div>

</div>








<div className='white-head'>
  <div className='headfont'>IT Solutions for Legal Firms</div>
  <p>Experience a transformative approach to legal operations, blending expertise and technology for unparalleled efficiency and success.</p>
  
</div>




<div className='complex-slider'>
  <div className='width50'>
    <div className='arow-cont'>
    <img src="\images\healthcare\arrow-cs.svg" onClick={previous}/>
<img src="\images\healthcare\arrow2-cs.svg" onClick={next}/>
</div>
  <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
        <div>
        <div className='card'>
          <span className='montserrat-hard'>
          Data Security
          </span>
          <p className='montserrat-lit '>
          Ensure legal data protection with robust encryption, firewalls, and compliance measures for confidentiality.
          

          </p>
        </div>
        </div>
        
        <div>
        <div className='card'>
          <span className='montserrat-hard'>  
          Cloud Services
          </span>
          
          <p className='montserrat-lit '>
          Transform legal operations with cloud-based document management, storage, and secured collaboration tools.Cloud Solutions

          </p>
        </div>
        </div>

        <div>
        <div className='card'>
          <span className='montserrat-hard'>
          Automation Services
             </span>
          <p className='montserrat-lit '>
Streamline legal processes through automated workflows, reducing manual tasks and optimising productivity.
         
         </p>
        </div>
        </div>

        
      </Slider>
    </div>

  </div>
  <div className='width50-2'>
  
 
  {sec3Data.map((_, index) => (
    <img key={index} className='img-mark' src={getImageSrc(index)} alt="Healthcare" />
  ))}
    
    </div>
</div>








{/* 
<SpecialSliderSection 
      pageName={"legal"}
      secNo={"3"}
      
      headingBoxPresent={true}
      headingWeb={"IT Solutions for Legal Firms"}
      headingTab={"IT Solutions for Legal Firms"}
      headingMob={"IT Solutions for<br/> Legal Firms"}
      descriptionWeb={"Experience a transformative approach to legal operations, blending expertise and technology for unparalleled efficiency and success."}
      descriptionTab={"Experience a transformative approach to legal operations, blending expertise and technology for unparalleled efficiency and success."}
      descriptionMob={"Experience a transformative approach to legal operations, blending expertise and technology for unparalleled efficiency and success."}
      boxAlignment={"center"}
      
      subTitle={""}
      data={sec3Data} 
      /> */}



      


<div className='pink-card montserrat-lit'>

<div className='head1'>
<div className='texthed'>Benefits
You Can Expect</div>
<p></p>
</div>

<div className='head2'>
<div className='each-sec'>
<span>
Simplify Operations


</span>
<p>
Simplify your day-to-day operations with streamlined processes and workflows, ensuring tasks are efficiently managed from start to finish. By eliminating unnecessary complexities, you can enhance overall efficiency and focus on core business objectives.


</p>
</div>

<div className='each-sec'>
<span>
Boost Productivity

</span>
<p>
Increase your team's productivity through tailored IT solutions that streamline tasks and workflows. Our solutions are designed to empower your workforce, enabling them to achieve more in less time, and ensuring optimal output and efficiency across all departments.



</p>
</div>

<div className='each-sec'>
<span>
Enhanced Security

</span>
<p>
Strengthen your security measures to protect against cyber threats and breaches. Our robust solutions fortify your infrastructure and sensitive data, ensuring comprehensive protection that complies with regulatory standards and enhances overall peace of mind.


</p>
</div>

<div className='each-sec'>
<span>
Protect Client Records


</span>
<p>
Safeguard sensitive client information with stringent security measures. We implement industry-leading practices to maintain confidentiality and integrity, earning and maintaining client trust through proactive data protection strategies and compliance with data privacy regulations.



</p>
</div>
<div className='each-sec'>
<span>
  
Reduce Operational Costs


</span>
<p>
Optimise resource allocation and minimise expenditure with cost-effective IT solutions. By leveraging efficient technologies and strategic planning, we help you identify savings opportunities and streamline processes, ensuring maximum operational efficiency and financial sustainability.


</p>
</div>





</div>
</div>



<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>



{/* 
      <BlueNotchSection
        headingWeb={"Benefits<br/> You Can Expect"}     // <br/>
        headingTab={"Benefits You Can Expect"}
        headingMob={"Benefits You<br/> Can Expect"}
        descriptionPresent={false}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        listData={sec4Data}

      /> */}



      <div className='sec-6'>

      </div>


      <div className='sec-7'>

      </div>




      <BottomBanner
        pageName={"legal"}
        titleWeb={"Ready for the<br/> Difference?"}
        titleTab={"Ready for the<br/> Difference?"}
        titleMob={"Ready for the<br/> Difference?"}
                
        subTitlePresent={true}
        subTitleWeb={"Get the IT support you need for your<br/> legal firm with ZOG Global."}
        subTitleTab={"Get the IT support you need<br/> for your legal firm with ZOG Global."}
        subTitleMob={"Get the IT support you need for your<br/> legal firm with ZOG Global."}

        buttonText={"talk to our team"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default Legal