import React,{useState, useEffect} from 'react';
import '../FilterPopup.scss'
import './BlogFilterPopup.scss'
import $ from 'jquery';
import ErrorModal from "../../../ErrorModal";
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';

const FilterModal = ({ message,title, state,filters, setFilters, setterFunction, okClickedFunction  }) => {

    const okClicked=()=>{
      okClickedFunction();
      setterFunction(false);
      setFilters(filters);
    }
    const clearFilters = () => {
      setFilters({
        department_1: null,
        department_2: null,
        department_3: null,
        from_date: null, 
        to_date: null,
        period:'By Month'
      });
  };

    const [categoryListOpened, toggleCategoryList] = useState(false)
    const [periodOptionListOpened, togglePeriodOptionList] = useState(false)
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    // CATEGORY SELECT
    const categoriesList = [
      "AI","Advanced Networking", "Automation", "Cloud Computing", "Cybersecurity","Software Development",
      "Quality Assurance", "UI/UX", "Unified Communications",
  ]
    const [selectedCategories, setSelectedCategories] = useState([]);

    useEffect(() => {
      const selected = Object.keys(filters)
          .filter(key => key.startsWith('department_'))
          .map(key => filters[key])
          .filter(value => value !== null);
  
      // console.log("Selected categories:", selected);
      setSelectedCategories(selected);
  }, [filters]);

    const handleCategoryChange = (value) => {
        if (!selectedCategories.includes(value)) {
            setSelectedCategories([...selectedCategories, value]);
            // Update filters based on selectedCategories
            if (!filters.department_1) {
                filters.department_1 = value;
            } else if (!filters.department_2) {
                filters.department_2 = value;
            } else if (!filters.department_3) {
                filters.department_3 = value;
            }
          }
        };
    
    const handleRemoveCategory = (category) => {
        setSelectedCategories(selectedCategories.filter(item => item !== category));
        if (filters.department_1 === category) {
          filters.department_1 = null;
        } else if (filters.department_2 === category) {
            filters.department_2 = null;
        } else if (filters.department_3 === category) {
            filters.department_3 = null;
        }
      };
    
    const selectPeriod = (value) => {
        setFilters({
          ...filters,
          "period": value
        });
      }
    
    const [dateFormat, setDateFormat] = useState("MM/dd/yyyy");
    const [showMonthYearPicker, setShowMonthYearPicker] = useState(false);
    const [showYearPicker, setShowYearPicker] = useState(false);
  
    useEffect(() => {
      if (filters.period === 'By Month') {
        setDateFormat("MM/yyyy");
        setShowMonthYearPicker(true);
        setShowYearPicker(false);
      } else if (filters.period === 'By Year') {
        setDateFormat("yyyy");
        setShowYearPicker(true);
        setShowMonthYearPicker(false);
      } else {
        setDateFormat("MM/dd/yyyy");
        setShowMonthYearPicker(true);
        setShowYearPicker(false);
      }
    }, [filters.period,showMonthYearPicker,showYearPicker]);

    
    useEffect(() => {
      const $OptionDropList = $("#category .option-list");
      if (categoryListOpened) {
        const height = $OptionDropList[0].scrollHeight;
        $OptionDropList.animate({ height: height + "px" }, 400);
        $OptionDropList.css("opacity", "1");
      } else {
        $OptionDropList.animate({ height: "0px" }, 400);
        $OptionDropList.css("opacity", "0");
      }
    }, [categoryListOpened]);
      
    useEffect(() => {
      const $OptionDropList = $("#period .option-list");
      if (periodOptionListOpened) {
        const height = $OptionDropList[0].scrollHeight;
        $OptionDropList.animate({ height: height + "px" }, 400);
        $OptionDropList.css("opacity", "1");
      } else {
        $OptionDropList.animate({ height: "0px" }, 400);
        $OptionDropList.css("opacity", "0");
      }
    }, [periodOptionListOpened])

    const handleStatusChange = (value) => {
        setFilters({ ...filters, is_published:value });
    };


    const getLastDayOfMonth = (date) => {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const lastDay = new Date(year, month, 0);
      return lastDay.getDate();
    };
    const handleStartDateChange = (date) => {
        if (filters.to_date && new Date(date) > new Date(filters.to_date)) {
          setErrorMessage("From date cannot be greater than To date");
            setIsErrorModalOpen(true)
            return;
        }
        const formattedDate = date.toISOString().slice(0, 10);
        setFilters({ ...filters, from_date: formattedDate });
    };
    

    const handleEndDateChange = (date) => {
      let endDate;
      if (filters.period === 'By Month'){
        const lastDayOfMonth = getLastDayOfMonth(date);
        endDate = new Date(date.getFullYear(), date.getMonth(), lastDayOfMonth);
      } else if (filters.period === 'By Year') {
        endDate = new Date(date.getFullYear(), 11, 31); 
      }

      if (filters.from_date && new Date(endDate) < new Date(filters.from_date)) {
        setErrorMessage("To date cannot be less than From date");
        setIsErrorModalOpen(true)
        return;
      }
      
      const formattedDate = endDate.toISOString().slice(0, 10);
      setFilters({ ...filters, to_date: formattedDate });
    };
    // console.log(filters);

  return (
    <div className='custom-modal filter-modal'>
        <div className='card'>
            <div className='close-btn' >
                <button  onClick={okClicked}>
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.49951 7.5L22.4995 22.5" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M22.5005 7.5L7.50049 22.5" stroke="#263238" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </button>
                

            </div>
            <div className='card-content'>
              <div className='heading'>
                  <h1>Filter options</h1>
                  <div className='clr-btn' onClick={clearFilters}>
                    Clear All
                  </div>
                </div>
                <div className='option'>
                    <span className='option-title'>Status</span>
                    <div className='options-list'>
                        <label className="custom-select-box">
                            <input type="radio" name="status" 
                              value={'False'} 
                              checked={filters.is_published === 'False'} 
                              onChange={() => handleStatusChange('False')} />
                            <span className="checkmark"></span>
                            <h4>Pending</h4>
                        </label>
                        <label className="custom-select-box">
                            <input type="radio" 
                              name="status" 
                              value={'True'} 
                              checked={filters.is_published === 'True'} 
                              onChange={() => handleStatusChange('True')} />
                            <span className="checkmark"></span>
                            <h4>Published</h4>
                        </label>
                    </div>
                </div>

                <div className='option category'>
                    <span className='option-title category' >Category</span>
                    <div className="form-select custom-select" id="category" name="category"
                        onClick={() => toggleCategoryList(!categoryListOpened)} >
                        <div className="selected-value">Select
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_3764_11686" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                        <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_3764_11686)">
                        <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        </svg>
                        </div>
                        <div className='option-list'>
                          {categoriesList.map((option, index) => (
                            <div className='option' onClick={() => handleCategoryChange(option)}>{option}</div>
                            ))
                          }
                        </div>

                    </div>

                    {selectedCategories && 
                        <div className='selected-options'>
                            {selectedCategories.map((option, index) => (
                                <div key={index} className='optn'>
                                    <span >{option} </span>
                                    <button onClick={() => handleRemoveCategory(option)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="10" fill="black"/>
                                        <path d="M6.56689 6.73535L13.737 13.9054" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.7373 6.73535L6.56724 13.9054" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                        
                                </div>
                            ))}
                        </div>
                        }

                    
                    
                    
                </div>
                <div className='option period'>
                    <span className='option-title period' >Period</span>
                    <div className="form-select custom-select" id="period" name="period"
                        onClick={() => togglePeriodOptionList(!periodOptionListOpened)} >
                        <div className="selected-value">{filters.period ? filters.period : "By Month"}
                        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <mask id="mask0_3764_11686" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                        <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                        </mask>
                        <g mask="url(#mask0_3764_11686)">
                        <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        </svg>
                        </div>
                        <div className='option-list'>
                          <div className='option' onClick={() => selectPeriod("By Month")}>By Month</div>
                          <div className='option' onClick={() => selectPeriod("By Year")}>By Year</div>
                        </div>

                    </div>
                  
                    <CustomDatePicker
                      selected={filters.from_date}
                     dateFormat={dateFormat} placeholder={'From'} 
                     showMonthYearPicker={showMonthYearPicker}
                     showYearPicker={showYearPicker}
                     onChange={(date)=>handleStartDateChange(date)} />

                    <CustomDatePicker
                      selected={filters.to_date}
                     dateFormat={dateFormat} placeholder={'To'} 
                     showMonthYearPicker={showMonthYearPicker}
                     showYearPicker={showYearPicker}
                     onChange={(date)=>handleEndDateChange(date)} />

                </div>
                
            </div>
                
        </div>
            
      <ErrorModal state={isErrorModalOpen} message={errorMessage} setterFunction={setIsErrorModalOpen}  />
    </div>
  );
};

export default FilterModal;