// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-time-picker {
  width: 100%;
}
.custom-time-picker .react-time-picker {
  width: 100%;
}
.custom-time-picker .react-time-picker__wrapper {
  border: none;
}
.custom-time-picker .react-time-picker__inputGroup {
  padding: 0px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-time-picker .react-time-picker__button {
  width: 0;
  display: none;
}
.custom-time-picker .react-time-picker__clock {
  width: 0;
  display: none;
}
.custom-time-picker .react-time-picker__inputGroup__input:invalid {
  background: none;
}
.custom-time-picker .react-time-picker__inputGroup__input {
  padding: 0;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/common-components/TimePicker/TimePicker.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;AAAI;EACI,WAAA;AAER;AAAI;EACI,YAAA;AAER;AAAI;EACI,gBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAER;AAAI;EACI,QAAA;EACA,aAAA;AAER;AAAI;EACI,QAAA;EACA,aAAA;AAER;AAAI;EACI,gBAAA;AAER;AAAI;EACI,UAAA;EACA,YAAA;AAER","sourcesContent":[".custom-time-picker{\r\n    width: 100%;\r\n    .react-time-picker{\r\n        width: 100%;\r\n    }\r\n    .react-time-picker__wrapper{\r\n        border: none;\r\n    }\r\n    .react-time-picker__inputGroup{\r\n        padding: 0px 5px;\r\n        display: flex;\r\n        justify-content: space-between;\r\n        align-items: center;\r\n    }\r\n    .react-time-picker__button{\r\n        width: 0;\r\n        display: none;\r\n    }\r\n    .react-time-picker__clock{\r\n        width: 0;\r\n        display: none;\r\n    }\r\n    .react-time-picker__inputGroup__input:invalid{\r\n        background:none;\r\n    }\r\n    .react-time-picker__inputGroup__input{\r\n        padding: 0;\r\n        height: auto;\r\n    }\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
