// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.penetration-testing-page .sec-2 {
  width: 976px;
  margin: 0 auto;
}
.penetration-testing-page .sec-3 {
  width: 976px;
  margin: 0 auto;
}
.penetration-testing-page .sec-4 .simple-section {
  background-color: #000022;
}
.penetration-testing-page .sec-4 .simple-section h2 {
  color: #fff;
}
.penetration-testing-page .sec-4 .simple-section p {
  color: #fff;
}
.penetration-testing-page .sec-5 {
  width: 976px;
  margin: 0 auto;
}
.penetration-testing-page .sec-6 {
  width: 976px;
  margin: 0 auto;
}
.penetration-testing-page .sec-7 {
  width: 976px;
  margin: 0 auto;
}
.penetration-testing-page .sec-8 {
  width: 976px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/user-panel/pages/PenetrationTesting/PenetrationTesting.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;EACA,cAAA;AAFJ;AAOE;EACE,YAAA;EACA,cAAA;AALJ;AAYI;EACA,yBAAA;AAVJ;AAWA;EACE,WAAA;AATF;AAYA;EACE,WAAA;AAVF;AAiBE;EACE,YAAA;EACA,cAAA;AAfJ;AAmBE;EACE,YAAA;EACA,cAAA;AAjBJ;AAqBE;EACE,YAAA;EACA,cAAA;AAnBJ;AAuBE;EACE,YAAA;EACA,cAAA;AArBJ","sourcesContent":[".penetration-testing-page {\r\n \r\n\r\n  .sec-2 {\r\n    width: 976px;\r\n    margin: 0 auto;\r\n\r\n\r\n  }\r\n\r\n  .sec-3 {\r\n    width: 976px;\r\n    margin: 0 auto;\r\n\r\n\r\n\r\n  }\r\n\r\n  .sec-4 {\r\n    .simple-section{\r\n    background-color: #000022;\r\nh2{\r\n  color: #fff;\r\n\r\n}\r\np{\r\n  color: #fff;\r\n}\r\n\r\n    }\r\n\r\n  }\r\n\r\n  .sec-5 {\r\n    width: 976px;\r\n    margin: 0 auto;\r\n\r\n  }\r\n\r\n  .sec-6 {\r\n    width: 976px;\r\n    margin: 0 auto;\r\n\r\n  }\r\n\r\n  .sec-7 {\r\n    width: 976px;\r\n    margin: 0 auto;\r\n\r\n  }\r\n\r\n  .sec-8 {\r\n    width: 976px;\r\n    margin: 0 auto;\r\n\r\n  }\r\n}\r\n\r\n@media (max-width: 1005px) {\r\n  .penetration-testing-page {\r\n   \r\n  }\r\n}\r\n\r\n\r\n@media (max-width:767px) {\r\n  .penetration-testing-page {\r\n    \r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
