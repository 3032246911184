import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import $ from 'jquery';
import '../BlogCreate/BlogCreate.scss'
import BlogPreview from '../../common-components/BlogPreview/BlogPreviewPopup';
import ImageCropper from '../../common-components/ImageCrop/ImageCropDialogue';
import FileDropzone from '../../common-components/ImageUploader/ImageFileUploader';
import RichTextEditor from '../../common-components/RichText/RichText';


const BlogUpdate = () => {
    let navigate = useNavigate();
    useEffect(() => {
        $(function() {
      $(window).scrollTop(0);
      });
      }, []) 

    const loadPage = () => {
        navigate(window.location.pathname);
    };

    

    const blogID = localStorage.getItem("itemSelectedId");

    const [message, setMessage] = useState(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    
    const [BlogMainSectionCompleted, SetBlogMainSection] = useState(true)
    const [bannerImage, SetBannerImage] = useState(null)

    const [formData, setFormData] = useState({
        "title": "",
        "section_number": "0",
        "image_url": null,
        "department_1": "",
        "department_2": "",
        "department_3": "",
        "extra": "",
      });
    
    const [blogSections, setBlogSections] = useState([]);
    
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedPages, setSelectedPages] = useState([]);
    
    useEffect(() => {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const response = await API.get(`/blogs/${blogID}/`);
          const blogData = response.data;
          setSelectedCategories(
            [blogData.department_1, blogData.department_2, blogData.department_3].filter(Boolean)
          );
          setSelectedPages(
            blogData.pages.map(page => page.name)
          );
          
          const filteredBlogSections = (blogData.blog_items || []).map((item, index) => ({
            section_id: index + 1,
            id: item.id,
            sub_title: item.sub_title || "",
            description: item.description || "",
            image_url: item.image_url || "",
          }));
        
          setBlogSections(filteredBlogSections);

          // update blog Form
          const imageUrl = blogData.image_url;
          SetBannerImage(imageUrl)
          setFormData({
            title: blogData.title || "",
            section_number: blogData.section_number || "0",
            image_url: blogData.image_url,
            department_1: blogData.department_1 || "",
            department_2: blogData.department_2 || "",
            department_3: blogData.department_3 || "",
            is_published: blogData.is_published || false,
            extra: blogData.extra || "",
            page_name: (blogData.pages.map(page => page.name)).join(','),
          });
          
         
        } catch (error) {
          console.error('Error fetching blog data:', error);
        } finally {
          setIsLoading(false);
        }
      };
    
      fetchData();
    }, [blogID]);
    
    useEffect(() => {
    //   console.log(selectedPages);
      setFormData(formData => ({
        ...formData,
        page_name: selectedPages.join(','),
      }));
    }, [selectedPages]);

    const isRichTextEmpty = richText => {
        // Try to parse richText as JSON
        try {
          const content = JSON.parse(richText);
          // Check if content is in the expected DraftJS raw format
          if (content.blocks && Array.isArray(content.blocks)) {
            for (const block of content.blocks) {
              if (block.text.trim().length > 0) {
                return false;
              }
            }
            return true;
          } else {
            throw new Error('Not DraftJS raw content');
          }
        } catch (error) {
          // If parsing fails, treat richText as plain text
          return richText.trim().length === 0;
        }
      };

    const validateForm = (data) => {
        const errors = {};

        if (!data.title.trim()) {
            errors.title = "Title is required.";
        }
        if (!data.image_url) {
            errors.image_url = "Cover image is required.";
        }
        if (!data.extra) {
            errors.extra = "Choose an author";
        }
        if (!data.department_1 && !data.department_2 && !data.department_3) {
            errors.category = "At least one category is required.";
        }

        return errors;
    };
    
    const handleInputChange = (e) => {
    const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    
    // file drag and drop
    const handleBannerFileSelect = (img) => {
        setFormData((formData) => ({
            ...formData,
            "image_url": img
          }));
          const imageURL = URL.createObjectURL(img);
          SetBannerImage(imageURL)
      };

    const [IsImageCropOpen, setShowImageCrop] = useState(false);
    
    // Author
    const [authorListOpened, toggleAuthorList] = useState(false)
    const selectAuthor = (value) => {
        setFormData((formData) => ({
          ...formData,
          "extra": value
        }));
      }
    useEffect(() => {
        const $OptionDropList = $("#author .option-list");
        if (authorListOpened) {
            const height = $OptionDropList[0].scrollHeight;
            $OptionDropList.animate({ height: height + "px" }, 400);
            $OptionDropList.css("opacity", "1");
        } else {
            $OptionDropList.animate({ height: "0px" }, 400);
            $OptionDropList.css("opacity", "0");
        }
    }, [authorListOpened]);


    // CATEGORY SELECT
    const categoriesList = [
        "AI","Advanced Networking", "Automation", "Cloud Computing", "Cybersecurity","Software Development",
        "Quality Assurance", "UI/UX", "Unified Communications",
    ]
    const [categoryListOpened, toggleCategoryList] = useState(false)
    useEffect(() => {
        const $OptionDropList = $("#category .option-list");
        if (categoryListOpened) {
            const height = $OptionDropList[0].scrollHeight;
            $OptionDropList.animate({ height: height + "px" }, 400);
            $OptionDropList.css("opacity", "1");
        } else {
            $OptionDropList.animate({ height: "0px" }, 400);
            $OptionDropList.css("opacity", "0");
        }
    }, [categoryListOpened]);

    
    const selectCategory = (value) => {
        if (!selectedCategories.includes(value)) {
            if (selectedCategories.length < 3) {
                setSelectedCategories([...selectedCategories, value]);
                // Update formData accordingly
                if (!formData.department_1) {
                    formData.department_1 = value;
                } else if (!formData.department_2) {
                    formData.department_2 = value;
                } else if (!formData.department_3) {
                    formData.department_3 = value;
                }
                errors.category = ""
            } else {
                errors.category = "Three categories are allowed";
                // console.log("Maximum categories selected.");
            }
        }
    };
    const handleRemoveCategory = (category) => {
        setSelectedCategories(selectedCategories.filter(item => item !== category));
        if (formData.department_1 === category) {
          formData.department_1 = null;
        } else if (formData.department_2 === category) {
            formData.department_2 = null;
        } else if (formData.department_3 === category) {
            formData.department_3 = null;
        }
    };

    // PAGE SELECT
    const [showCheckboxes, setShowCheckboxes] = useState(false);
    
    const pages = [
        { id: "Advanced Networking Services", name: "Advanced Networking Services" },
        { id: "AI", name: "AI" },
        { id: "Automation", name: "Automation" },
        { id: "Banking and Financial services", name: "Banking and Financial services" },
        { id: "Blog", name: "Blog" },
        { id: "Cloud", name: "Cloud" },
        { id: "Cybersecurity", name: "Cybersecurity" },
        { id: "DevOps", name: "DevOps" },
        { id: "DevSecOps", name: "DevSecOps" },
        { id: "Education", name: "Education" },
        { id: "Healthcare", name: "Healthcare" },
        { id: "Insurance", name: "Insurance" },
        { id: "Legal", name: "Legal" },
        { id: "Media and Entertainment", name: "Media and Entertainment" },
        { id: "Pharma", name: "Pharma" },
        { id: "Quality Assurance", name: "Quality Assurance" },
        { id: "RPA", name: "RPA" },
        { id: "Software Development", name: "Software Development" },
        { id: "Technology", name: "Technology" },
        { id: "Telecom", name: "Telecom" },
        { id: "Testing", name: "Testing" },
        { id: "Travel", name: "Travel" },
        { id: "Unified Communications", name: "Unified Communications" },
        { id: "Why ZOG Global", name: "Why ZOG Global" },
        
    ];
    const numColumns = Math.ceil(pages.length / 8);
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedPages([...selectedPages, value]);
        } else {
            setSelectedPages(selectedPages.filter(page => page !== value));
        }
    };
    // Function to update formData when selectedPages change
    useEffect(() => {
        const pagesText = selectedPages.join(','); // Convert pages array to comma-separated text
        setFormData((formData) => ({
            ...formData,
            "page_name": pagesText 
          }));
    }, [selectedPages]);
    const toggleCheckboxes = () => {
        setShowCheckboxes(!showCheckboxes);
    };


    // update Blog
    const handleSubmit = async () => {
        const validationErrors = validateForm(formData);
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            setIsLoading(true)
            const BlogformData = new FormData();
            for (const key in formData) {
                if (key !== 'image_url') {
                  BlogformData.append(key, formData[key]);
                } else {
                // Check if image_url is a File object
                if (formData[key] instanceof File) {
                    BlogformData.append(key, formData[key]);
                }
              }
            }
            try {
                console.log(BlogformData);
                const response = await API.put(`/blogs/${blogID}/`, BlogformData, {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    }
                  });
                setIsLoading(false)
                setMessage('Blog main section updated successfully!')
                setIsMessageModalOpen(true)
                // console.log(response);
                SetBlogMainSection(true)
                
              } catch (error) {
                setIsLoading(false)
                setIsErrorModalOpen(true);
                console.log(error);
                setMessage(error.message || "An error occurred.");
              }
        }
    }



    // -------------------   Blog Sub Sections   -----------------------
    useEffect(() => {
        localStorage.setItem('blogSections', JSON.stringify(blogSections));
      }, [blogSections]);

    const [sectionErrors, setSectionErrors] = useState(Array.from({ length: blogSections.length }, () => ({})));
    const addBlogSection = async (e) => {
        if (blogSections.length > 0) {
            const previousSection = blogSections[blogSections.length - 1];
            // console.log(previousSection.description.trim());
            if (!previousSection.description.trim() || isRichTextEmpty(previousSection.description)) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    section_error: 'Description field is required for the section.'
                }));
                return; 
            }
        }
        setBlogSections([...blogSections, { section_id: blogSections.length + 1, title: "", image: null, description: "" }]);
            setSectionErrors([...sectionErrors, {}]);
            setErrors(prevErrors => ({
                ...prevErrors,
                section_error: ''
            }));

        };

    
    const handleSectionChange = (section_id, field, value) => {
        const updatedSections = blogSections.map(section => 
        section.section_id === section_id ? { ...section, [field]: value } : section
        );
        const previousSection = blogSections[blogSections.length - 1];
        if (!previousSection.description.trim() || (isRichTextEmpty && isRichTextEmpty(previousSection.description))) {
            setErrors(prevErrors => ({
                ...prevErrors,
                section_error: 'Description field is required for the section.'
            }));
        } else{
            setErrors(prevErrors => ({
                ...prevErrors,
                section_error: ''
            }));
        } 
        
        setBlogSections(updatedSections);
    };

    const removeSection = (sectionId,blogItemId) => {
        // remove from database
        if (blogItemId) {
          API.delete(`/blogs/blog-items/${blogItemId}/`)
          .then((response) => {
              console.log(response.data)
          })
          .catch((error) => {
              setIsErrorModalOpen(true);
              console.log(error,`/blogs/blog-items/${blogItemId}/`)
              setMessage(error.message || "An error occurred.");
          });
        }

        // Filter out the section with the given ID
        const updatedSections = blogSections.filter(section => section.section_id !== sectionId);
        const updatedSectionErrors = sectionErrors.filter((_, index) => blogSections[index].section_id !== sectionId);
        setBlogSections(updatedSections);
        setSectionErrors(updatedSectionErrors);
    };

    const handleItemFileSelect = (section_id, imageURL) => {
        handleSectionChange(section_id, 'image_url', imageURL);
    };
    // To delete section image
    const handleItemFileRemove = (section_id) => {
        handleSectionChange(section_id, 'image_url', '');
    };
    


    const handleItemContent = (section_id, content) => {
        handleSectionChange(section_id, 'description', content);
    };

    const handleDoneButton = () => {
      if (blogSections && blogSections.length > 0) {
        const previousSection = blogSections[blogSections.length - 1];
        if (!previousSection.description.trim() || (isRichTextEmpty && isRichTextEmpty(previousSection.description))) {
            setErrors(prevErrors => ({
                ...prevErrors,
                section_error: 'Description field is required for the section.'
            }));
            return
        } 
      } 
      setIsPreviewModalOpen(true);
    }


    // console.log(blogSections);
    

    return (
        <>
        
        <div className='px-0 px-md-4 py-3 full-screen-table-container blog-create'>
            <div className='text-title'>
                <h3>Update Blog</h3>
            </div>
            <form className='form-card'>
                <div className=' cover'>
                    <div className='card lhs'>
                            <div className='sub-titles'>
                                <div className='sub-title'>Blog Cover</div>
                                <p className='sub-para'>Add the Blog Cover Title and the Banner image</p>
                            </div>
                            <div className='text-input'>
                                <label>Title</label>
                                <input type='text' className='form-control'  disabled={false} 
                                    name='title' value={formData.title} onChange={handleInputChange} />
                                {errors.title && <div className="invalid-feedback">{errors.title}</div>}
                            </div>
                            <div className='img-upload'>
                                <label>Upload Image</label>
                                <FileDropzone onFileSelect={handleBannerFileSelect} disabled={false}
                                    inputId={`BlogImageInput`}  errors={errors} setErrors={setErrors} />
                                {errors.image_url && <div className="invalid-feedback">{errors.image_url}</div>}
                            </div>
                            
                    </div>
                    <div className='card rhs'>
                        <div className='sub-titles'>
                                <div className='sub-title'>
                                    Preview
                                </div>
                                {formData.image_url &&
                                    <button type='button'  className='crop-btn' disabled={false }  onClick={()=>setShowImageCrop(true)}>
                                        <svg width="32" height="32" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_333_3764)">
                                        <path d="M4.75 7.91667V14.25H7.91667V7.91667H14.25V4.75H7.91667C6.16708 4.75 4.75 6.16708 4.75 7.91667ZM7.91667 23.75H4.75V30.0833C4.75 31.8329 6.16708 33.25 7.91667 33.25H14.25V30.0833H7.91667V23.75ZM30.0833 30.0833H23.75V33.25H30.0833C31.8329 33.25 33.25 31.8329 33.25 30.0833V23.75H30.0833V30.0833ZM30.0833 4.75H23.75V7.91667H30.0833V14.25H33.25V7.91667C33.25 6.16708 31.8329 4.75 30.0833 4.75Z" fill="#8791A9"/>
                                        </g>
                                        <defs>
                                        <clipPath id="clip0_333_3764">
                                        <rect width="38" height="38" fill="white"/>
                                        </clipPath>
                                        </defs>
                                        </svg>
                                    </button>
                                }
                                
                        </div>
                        <p>View the blog thumbnail preview</p>
                        <div className='banner-img'>
                            <label>Banner</label>
                            <div className='img-sec'>
                                {/* {formData.image_url && <img src={URL.createObjectURL(formData.image_url)} alt=''></img> } */}
                                {/* {formData.image_url && <img src={formData.image_url} alt=''></img> } */}
                                {formData.image_url ? (
                                    typeof formData.image_url === 'object' ? (
                                        <img src={URL.createObjectURL(formData.image_url)} alt='' />
                                    ) : (
                                        <img src={formData.image_url} alt='' />
                                    )
                                ) : null}
                                
                            </div>
                        </div>
                        <div className='thumbnail-img'>
                            <label>Thumbnail</label>
                            <div className='img-sec'>
                            {/* {formData.image_url && <img src={URL.createObjectURL(formData.image_url)} alt=''></img> } */}
                            {/* {formData.image_url && <img src={formData.image_url} alt=''></img> } */}
                            {formData.image_url ? (
                                    typeof formData.image_url === 'object' ? (
                                        <img src={URL.createObjectURL(formData.image_url)} alt='' />
                                    ) : (
                                        <img src={formData.image_url} alt='' />
                                    )
                            ) : null}
                            </div>
                        </div>
                    </div>
                
                </div>
                {/* Details */}
                <div className='details'>
                    <div className='card'>
                        <div className='sub-titles'>
                            <div className='sub-title'>Blog Details</div>
                            <p className='sub-para'>Add blog details</p>
                        </div>

                        {/* Author */}
                        <div className='author-select'>
                            <label>Blog Author</label>
                            <div className="custom-select form-control form-select" id="author" name="author"
                                onClick={() => toggleAuthorList(!authorListOpened)} >

                                <div className="selected-value">{formData.extra ? formData.extra : "Select Author"}
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_3764_11686" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                                    <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                                    </mask>
                                    <g mask="url(#mask0_3764_11686)">
                                    <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    </svg>
                                </div>
                                <div className='option-list'>
                                <div className='option' onClick={() => selectAuthor("Aparna Purushothaman")}>Aparna Purushothaman</div>
                                <div className='option' onClick={() => selectAuthor("Arun George")}>Arun George</div>
                                <div className='option' onClick={() => selectAuthor("Joseph James")}>Joseph James</div>
                                <div className='option' onClick={() => selectAuthor("Anu Antony")}>Anu Antony</div>
                                <div className='option' onClick={() => selectAuthor("Ranjita Rajan")}>Ranjita Rajan</div>
                                <div className='option' onClick={() => selectAuthor("Rahul Dhruvan")}>Rahul Dhruvan</div>
                                </div>

                            </div>
                            {errors.extra && <div className="invalid-feedback">{errors.extra}</div>}
                        </div>
                        {/* Category */}
                        <div className='category-select'>
                            <label>Select Category</label>
                            <div className="form-select custom-select" id="category" name="category"
                                onClick={() => toggleCategoryList(!categoryListOpened)} >
                                <div className="selected-value">{formData.category ? formData.category : "Select"}
                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <mask id="mask0_3764_11686" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                                    <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                                    </mask>
                                    <g mask="url(#mask0_3764_11686)">
                                    <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    </svg>
                                </div>
                                <div className='option-list'>
                                    {categoriesList.map((option, index) => (
                                        <div className='option' onClick={() => selectCategory(option)}>{option}</div>
                                        ))
                                    }
                                </div>
                            </div>
                            {errors.category && <div className="invalid-feedback">{errors.category}</div>}
                            
                            {selectedCategories && 
                            <div className='selected-options'>
                                {selectedCategories.map((option, index) => (
                                    <div key={index} className='optn'>
                                        <span >{option} </span>
                                        <button type='button' disabled={false} onClick={() => handleRemoveCategory(option)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="20" height="20" rx="10" fill="black"/>
                                            <path d="M6.56689 6.73535L13.737 13.9054" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M13.7373 6.73535L6.56724 13.9054" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                            
                                    </div>
                                ))}
                            </div>
                            }
                        </div>
                        {/* Pages */}
                        <div className='page-select'>
                            <label>Choose the pages to assign the post</label>
                            <div className="custom-page-select">
                                <div className="select-container">
                                    <button type='button' disabled={false} className="form-select" onClick={toggleCheckboxes}>{formData.page_name ? formData.page_name : "Choose Pages"}</button>
                                    {showCheckboxes && (
                                        <div className="checkbox-container">
                                            {[...Array(numColumns)].map((_, columnIndex) => (
                                                <div key={columnIndex} className="column">
                                                    {pages.slice(columnIndex * 8, (columnIndex + 1) * 8).map(page => (
                                                        <label key={page.id} className="custom-select-box" htmlFor={page.id}>
                                                            <input type="checkbox" id={page.id} name={page.id} value={page.id}
                                                            onChange={handleCheckboxChange} checked={selectedPages.includes(page.id)}
                                                            />
                                                            <span className="checkmark"></span>
                                                            {page.name}
                                                        </label>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* update button */}
                <div className='continue-sec'>
                    <button type='button' onClick={handleSubmit} className='admin-black-btn'>Update Main Section</button>
                </div>
                
                {/* Description */}

                { BlogMainSectionCompleted && 
                    <>
                    <div className='description'>
                        <div className='card'>
                            <div className='sub-titles'>
                                <div className='sub-title'>Blog Description</div>
                                <p className='sub-para'>Write the blog description</p>
                            </div>
                            
                            {/* Blog SubItems */}
                            {blogSections.map((section, index) => (
                               
                                <div className='sub-item' key={section.section_id}>
                                    {/* Section Title */}
                                    <div className='text-input'>
                                        <label>Section Title</label>
                                        <input type='text' className='form-control' 
                                            value={section.sub_title}
                                            onChange={(e) => handleSectionChange(section.section_id, 'sub_title', e.target.value)} />
                                        {sectionErrors[index]?.title && <div className="invalid-feedback">{sectionErrors[index].title}</div>}
                                    </div>
                                    <div className='img-upload'>
                                        <label>Upload Image</label>
                                        <FileDropzone onFileSelect={(imageURL) => handleItemFileSelect(section.section_id, imageURL)}
                                            inputId={`sectionImageInput_${section.section_id}`}  errors={sectionErrors} setErrors={setSectionErrors}  
                                            showPreview = {!!section.image_url} imagePreview={section.image_url}
                                            
                                        />
                                        { section.image_url &&
                                            <button type="button" className='remove-btn' onClick={() => handleItemFileRemove(section.section_id)} >Remove Image</button>
                                        }
                                        {sectionErrors[index]?.image_url && <div className="invalid-feedback">{sectionErrors[index].image_url}</div>}
                                    </div>
                                   
                                    <RichTextEditor sectionId={section.section_id} onContentChange={handleItemContent} initialContent={section.description} />

                                    {/* remove section */}
                                    {/* {section.section_id > 1 && ( )} */}
                                    <button type="button" className='remove-btn' onClick={() => removeSection(section.section_id,section.id)}>Remove Section</button>
                                    
                                </div>
                                    
                            ))}
                            {errors.section_error && <div className="invalid-feedback">{errors.section_error}</div>}
                            <div className='add-item'>
                                <button type='button' onClick={addBlogSection} >
                                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5121 2.08337C13.3729 2.08337 14.0823 2.78562 14.0823 3.64856L14.0827 10.9466H21.358C22.2188 10.9466 22.9166 11.6462 22.9166 12.5091C22.9166 13.3721 22.2188 14.0716 21.358 14.0716H14.0827L14.0825 21.3497C14.0825 22.2127 13.3848 22.9122 12.524 22.9122C11.6632 22.9122 10.9654 22.2127 10.9654 21.3497L10.9656 14.0716H3.66261C2.80184 14.0716 2.08325 13.3505 2.08325 12.4876C2.08325 11.6246 2.80184 10.9466 3.66261 10.9466H10.9656L10.9652 3.64856C10.9652 2.78562 11.6513 2.08337 12.5121 2.08337Z" fill="#0587DB"/>
                                    </svg>
                                    <span>Add Section</span>
                                </button>
                                
                            </div>
                            
                        </div>
                    </div>
                    
                    </>
                }

                {/* Preview */}
                { BlogMainSectionCompleted && 
                    <div className='continue-sec'>
                        <button type='button' onClick={handleDoneButton}  className='admin-blue-btn'>Done!</button>
                    </div>
                }

            </form>
            
        </div>
        

        <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={loadPage} />
        {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={loadPage} />}
        {isPreviewModalOpen && <BlogPreview message={message} setterFunction={setIsPreviewModalOpen} okClickedFunction={loadPage} blogSections={blogSections} />}
        
        {isLoading && <FixedOverlayLoadingSpinner />} 
        {IsImageCropOpen && <ImageCropper ImageURL={bannerImage} formData={formData}  setFormData={setFormData} closeModal={()=> setShowImageCrop(false)} />}
        </>

    )
}

export default BlogUpdate