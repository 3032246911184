import React, {useRef, useState, useEffect} from 'react';


const SubTitles = ({ blogData, currentSlide, handleTitleClick }) => {

    const [activeSubtitleIndex, setActiveSubtitleIndex] = useState(currentSlide);
    const containerRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        const index = Number(entry.target.dataset.index);
                        setActiveSubtitleIndex(index);
                    }
                });
            },
            { root: null, threshold: 0.5 }
        );

        const slides = document.querySelectorAll('.blog-slide');
        slides.forEach((slide) => observer.observe(slide));

        return () => {
            slides.forEach((slide) => observer.unobserve(slide));
        };
    }, []);

    useEffect(() => {
        setActiveSubtitleIndex(currentSlide);
    }, [currentSlide]);

    return (
        <div className='sub-titles' ref={containerRef}>
            {blogData.map((item, index) => (
                <div
                    key={index}
                    onClick={() => handleTitleClick(index)}
                    className={`sub-title ${index === activeSubtitleIndex ? 'active' : ''}`}
                    data-index={index}
                >
                    <p>{item.sub_title}</p>
                </div>
            ))}
        </div>
    );
};
export default SubTitles;