import React, { useEffect, useRef } from "react";

import './VioletCardsSection.scss'

const VioletCardsSection = ({ pageName, headingWeb, headingTab, headingMob, descriptionWeb, descriptionTab, descriptionMob, cardsData }) => {
  const renderHTML = (text) => ({ __html: text });


  const refs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-view');
        } else {
          entry.target.classList.remove('in-view');
        }
      },
      {
        threshold: 0.1,
      }
    );
    const elementsToObserve = refs.current;
    elementsToObserve.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);


  return (
    <div className='violet-card-section montserrat-lit'>

      <div className='head1'>
      <h1 className='texthead only-web' dangerouslySetInnerHTML={renderHTML(headingWeb)}></h1>
        <h1 className='texthead only-tab' dangerouslySetInnerHTML={renderHTML(headingTab)}></h1>
        <h1 className='only-mob' dangerouslySetInnerHTML={renderHTML(headingMob)}></h1>

        <p className='texthead only-web' dangerouslySetInnerHTML={renderHTML(descriptionWeb)}></p>
        <p className='texthead only-tab' dangerouslySetInnerHTML={renderHTML(descriptionTab)}></p>
        <p className='texthead only-mob' dangerouslySetInnerHTML={renderHTML(descriptionMob)}></p>
      </div>

      <div className='head2'>






        {cardsData.map((item, index) => {

          const titleRef = item.title ? (el) => (refs.current[index] = el) : null;


          return (

            <div className='each-sec' key={index}>
              <span>{item.title}</span>
              <p>{item.description}</p>
            </div>
          )
        })}


      </div>
    </div>
  )
}

export default VioletCardsSection