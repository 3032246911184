import React from "react";
import "./NoPage.scss"

const Contact = () => {


  return (

    <>

      <div className="page-not-found">
       <h4>Oops!</h4>
        <h1> 404</h1>
        <h3>Page  Not Found!</h3>
      </div>
    </>

  )
};

export default Contact;
