import React, {  useEffect, useRef, useState } from 'react'

import './StatisticsSection.scss'

const StatisticsSection = ({ data, background }) => {


  const statsRef = useRef(null);

  useEffect(() => {
      const stats = statsRef.current.querySelectorAll('.number');

      const updateCount = (stat) => {
          const target = +stat.getAttribute('data-target');
          const current = +stat.innerText.replace('%', '').replace('+', '');
          const increment = target / 100;

          if (current < target) {
              stat.innerText = Math.ceil(current + increment) + (stat.innerText.includes('%') ? '%' : (stat.innerText.includes('+') ? '+' : ''));
              setTimeout(() => updateCount(stat), 20);
          } else {
              stat.innerText = target + (stat.innerText.includes('%') ? '%' : (stat.innerText.includes('+') ? '+' : ''));
          }
      };

      const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
              if (entry.isIntersecting) {
                  stats.forEach(stat => updateCount(stat));
              }
          });
      }, { threshold: 0.1 });

      observer.observe(statsRef.current);

      return () => observer.disconnect();
  }, []);




  return (
    // <div
    //   className="statistics-section"
    //   style={{
    //     background: background
    //   }}
    // >
    //   <div className='inner'>

    //     {data.map((item, index) => {
    //       return (
    //         <div className='segment' key={index} >
    //           <div className="value"><span className="left-sign">{item.leftSign && item.leftSign}</span><span className="number">{item.number && item.number}</span><span className="right-sign">{item.rightSign && item.rightSign}</span></div>
    //           <div className="text">{item.text}</div>
    //         </div>
    //       )
    //     })}
    //   </div>
    // </div>


<div className='statistics-section montserrat-hard ' ref={statsRef}  
style={{
        background: background
      }}>


{data.map((item, index) => {
         return (
    <div className='card-each'>
    <div className='hr2'></div>
    <div className='number' data-target={item.number && item.number}>{item.leftSign && item.leftSign}0{item.rightSign && item.rightSign}</div>
    <span>{item.text}</span>
</div>
)
   })}

</div>
  )
}

export default StatisticsSection