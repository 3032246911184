import React, {useEffect, useState } from 'react'
import './CyberSecurity.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'
import SimpleSection from '../../common-component/SimpleSection/SimpleSection.js'
import ImageOverlapedSection from "../../common-component/ImageOverlapedSection/ImageOverlapedSection"
import ThreeCardsSection from '../../common-component/ThreeCardsSection/ThreeCardsSection.js'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'

import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'

import { useNavigate} from 'react-router-dom';

import API from "../../../API.js"
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';


import BlueSliderSection from '../../common-component/BlueSliderSection/BlueSliderSection.js'


const CyberSecurity = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
    setIsLoading(true)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        setBlogData(response.data)
        setIsLoading(false)

      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)

      });
  }, [])

  
  const sec3Data = [
    {
      title: "Infrastructure Security",
      description: "Fortify your digital foundation with our proactive infrastructure protection solutions.",
    },
    {
      title: "Application Security",
      description: "Ensure your applications are fortified against vulnerabilities with our expert solutions.",
    },
    {
      title: "Network Security",
      description: "Strengthen network defences against threats with our advanced security solutions.",
    },
    {
      title: "Cloud Security",
      description: "Secure your cloud environment with our comprehensive and scalable security solutions.",
    },
    {
      title: "Managed Security Services",
      description: "Let our expert team manage and monitor your security for optimal protection.",
    },
    {
      title: "Security Consulting & Strategy",
      description: "Gain strategic insights and customised security plans from our seasoned consultants.",
    },

    {
      title: "Risk Assessment & Security Audits",
      description: "We offer comprehensive risk assessments and security audits for maximum protection.",
    },


  ]
  const sec4Data = [
    { title: "",
     description: "",
     image_url:"/images/why-zog-global/sec-4-1.png"
     },

    { title: "",
     description: "",
    image_url:"/images/why-zog-global/sec-4-2.png" 
  },

    { title: "",
     description: "",
    image_url:"/images/why-zog-global/sec-4-3.png" 
  },
  
  ]

  const sec7Data=[
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    
  ]

  const sec3Data2 = [
    {
      title: "Website Security Audit",
      description: "Identify vulnerabilities and weaknesses in your website's security posture with our comprehensive audit and recommendations for remediation.",
    },
    {
      title: "IT Systems Audit",
      description: "Evaluate the security and compliance of your IT systems, networks, and infrastructure to identify areas for improvement and risk mitigation.",
    },
    {
      title: "Penetration Testing",
      description: "Simulate real-world attacks on your systems to test defences, identify vulnerabilities, and prioritise remediation efforts.",
    },
    {
      title: "GDPR Advisory",
      description: "Ensure compliance with the European Union's General Data Protection Regulation (GDPR) and protect sensitive personal data.",
    },
    {
      title: "ISO27001 Advisory",
      description: "Implement and maintain a robust Information Security Management System (ISMS) with our expert guidance and support.",
    },
    {
      title: "PCI-DSS Advisory",
      description: "Meet the Payment Card Industry Data Security Standard (PCI-DSS) requirements and safeguard sensitive payment card information.",
    },
    {
      title: "Cyber Essentials Certification",
      description: "Achieve certification in the UK Government's Cyber Essentials scheme, demonstrating your commitment to cybersecurity best practices.",
    },
    {
      title: "Cybersecurity Assurance",
      description: "Gain confidence in your organisation's cybersecurity posture with our expert assessment and recommendations for improvement.",
    },
    {
      title: "Third-Party Security Due Diligence",
      description: "Evaluate the cybersecurity risks associated with third-party vendors and service providers to protect your organisation's data and assets.",
    },
    {
      title: "Risk Assessments",
      description: "Identify, assess, and prioritise cybersecurity risks to inform strategic decision-making and resource allocation.",
    },
    {
      title: "Security Maturity Assessment",
      description: "Evaluate your organisation's cybersecurity maturity level and identify opportunities for improvement and growth.",
    },
    {
      title: "Security as a Service",
      description: "Outsource your cybersecurity needs to our team of experts, providing 24/7 monitoring, incident response, and security management.",
    },
    {
      title: "Data Privacy Assessments",
      description: "Identify and mitigate risks to personal data, ensuring compliance with global data privacy regulations and standards.",
    },
    {
      title: "Specialist Contractor Services",
      description: "Engage experienced cybersecurity professionals for short-term or project-based assignments to augment your in-house team.",
    },
    {
      title: "Incident Response",
      description: "Respond quickly and effectively to cybersecurity incidents, minimising downtime and reputational damage.",
    },
    {
      title: "Security Architecture Design",
      description: "Design and review security architectures to ensure they align with your organisation's business objectives and security requirements.",
    },
    {
      title: "Infrastructure Security",
      description: "Protecting physical and virtual systems, devices, and data from unauthorised access, use, disclosure, disruption, modification, or destruction.",
    },
    {
      title: "Application Security",
      description: "Ensuring the confidentiality, integrity, and availability of software applications and data through secure design, development, testing, and deployment practices. ",
    },
    {
      title: "Network Security",
      description: "Safeguarding computer networks from unauthorised access, use, disclosure, disruption, modification, or destruction through robust security measures and protocols.",
    },
    {
      title: "Cloud Security",
      description: "Providing secure cloud computing environments, protecting data and applications in cloud-based infrastructure, and ensuring compliance with cloud security standards.",
    },
    {
      title: "Managed Security Services",
      description: "Offering 24/7 monitoring, management, and maintenance of an organisation's cybersecurity infrastructure to detect and respond to threats in real time.",
    },
    {
      title: "Security Consulting & Strategy",
      description: "Developing and implementing comprehensive cybersecurity strategies, policies, and procedures to mitigate risks and protect an organisation's assets.",
    }
  ]

  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };

  return (
    <div className='cyber-security-page'>

<BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"cyber-security"}
        blueTitle={"Cybersecurity"}
        titleWeb={"Fostering Secure<br/> Business Growth"}
        titleTab={"Fostering Secure<br/> Business Growth"}
        titleMob={"Fostering<br/> Secure<br/> Business<br/> Growth"}
        subTitleWeb={"We ensure your business stays<br/> protected always."}
        subTitleTab={"We ensure your business stays<br/> protected always."}
        subTitleMob={"We ensure your business<br/> stays protected always."}
        iconTopWeb={'15%'}
        iconLeftWeb={'89%'}
        iconTopTab={'47%'}
        iconLeftTab={'78%'}
        iconTopMob={'20%'}
        iconLeftMob={'79%'}
        iconRotationWeb={185}
        iconRotationTab={185}
        iconRotationMob={185}>
      </BannerSubPages>


      <SimpleSection
        pageName={"cyber-security"}
        secNo={"2"}
        headdingPresent={true}
        buttonPresent={false}
        imagePosition={"left"}
        headingWeb={"Our Approach to<br/> Cybersecurity"}
        headingTab={"Our Approach to Cybersecurity"}
        headingMob={"Our Approach to<br/> Cybersecurity"}
        description={"Our innovative approach leverages the latest technologies to protect your data and infrastructure with unmatched efficiency. Our certified professionals, holding 15+ industry-recognised certifications and 10+ years of experience, are dedicated to safeguarding your business against evolving cyber threats. Partner with us for trusted, expert cybersecurity solutions."}
        buttonText={""}
      />



      {/* <AccordionSection 
        headingWeb={"Access Our Suite of<br/> Cybersecurity Solutions"}
       headingTab={"Access Our Suite of<br/> Cybersecurity Solutions"}
       headingMob={"Access Our<br/> Suite of<br/> Cybersecurity<br/> Solutions"}
       description={"Protect your business with our top-of-the-line cybersecurity solutions. Our full suite<br/> of services ensures you are safeguarded against possible threats. Don't take any<br/> chances with your valuable data; trust us to keep it secure."}
      
      accordionData={sec3Data} /> */}
      <BlueSliderSection
      pageName={"cyber-security"}
      secNo={"3"}
      headingWeb={"Access Our Suite of<br/> Cybersecurity Solutions"}
      headingTab={"Access Our Suite of<br/> Cybersecurity Solutions"}
      headingMob={"Access Our Suite of Cybersecurity Solutions"}
      subHeading={"Protect your business with our top-of-the-line cybersecurity solutions. Our full suite of services ensures you are safeguarded against possible threats. Don't take any chances with your valuable data; trust us to keep it secure."}
      cardsData={sec3Data2}
      />


<SimpleSection
        pageName={"cyber-security"}
        secNo={"4"}
        headdingPresent={true}
        buttonPresent={false}
        imagePosition={"left"}
        headingWeb={"Tackle Toughest<br/> Security Challenges"}
        headingTab={"Tackle Toughest Security Challenges"}
        headingMob={"Tackle Toughest<br/> Security Challenges"}
        description={"We integrate the Zero-Trust Principle into our cybersecurity framework, where trust is never assumed within or outside the network perimeter. By constantly verifying user identities and devices, we ensure that every access request undergoes rigorous authentication, bolstering your defence against unauthorised access."}
        buttonText={""}
      />


      <ImageOverlapedSection 
      pageName={'cyber-security'} 
      secNo={'5'} 
      imageFirst={false}
      numberPresent={false}
      headingWeb={"Our Featured Solutions"} 
      headingTab={"Our Featured Solutions"} 
      headingMob={"Our Featured Solutions"} 
      descriptionWeb={""}
      descriptionTab={""}
       descriptionMob={""}

      subHeadding1={"Ransomware Protection"} 
      description1={"Safeguard your data with robust encryption and proactive threat monitoring against ransomware attacks."} 
      subHeadding2={"Zero Trust Architecture"} 
      description2={"Reinvent security protocols with a holistic approach that verifies every user and device interaction."} 
      subHeadding3={"Microsegmentation"} 
      description3={"Secure your network by dividing it into smaller segments, limiting lateral movement and minimising risks."}
      />


<ThreeCardsSection
        pageName={'cyber-security'}
        secNo={'6'}
        cardHeight={420}
        alignment={"left"}
        headingWeb={"Our Methodology in Action"}
        headingTab={"Our Methodology in Action"}
        headingMob={"Our Methodology in Action"}
        descriptionWeb={"Our process integrates analysis, deployment, and continuous monitoring for tailored and effective cybersecurity solutions."}
        descriptionTab={"Our process integrates analysis, deployment, and continuous monitoring for tailored and effective cybersecurity solutions."}
        descriptionMob={"Our process integrates analysis, deployment, and continuous monitoring for tailored and effective cybersecurity solutions."}


        subHeading1={"Assessment & Analysis"}
        description1={"Thorough analysis identifies vulnerabilities and strengths for enhanced security."}
        subHeading2={"Implementation & Integration"}
        description2={"Tailored solutions deployed and integrated for comprehensive cybersecurity measures."}
        subHeading3={"Monitoring & Maintenance"}
        description3={"Continuous monitoring and updates ensure sustained protection against evolving threats."}
      />


<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>





      <BottomBanner
        pageName={"cyber-security"}
        titleWeb={"Let's Handle<br/> Your Online Safety"}
        titleTab={"Let's Handle<br/> Your Online Safety"}
        titleMob={"Let's Handle<br/> Your Online Safety"}
                
        subTitlePresent={true}
        subTitleWeb={"Get expert solutions for your security needs."}
        subTitleTab={"Get expert solutions for<br/> your security needs."}
        subTitleMob={"Get expert solutions for<br/> your security needs."}

        buttonText={"talk to our experts"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default CyberSecurity