import React, { useEffect, useState, useRef } from "react";
import './SpecialSliderSection.scss'
import $ from 'jquery';
import Slider from 'react-slick'

const SpecialSliderSection = ({ pageName, secNo, headingBoxPresent, headingWeb, headingTab, headingMob, descriptionWeb, descriptionTab, descriptionMob, boxAlignment, subTitle, data }) => {

  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index)
  };
 
  useEffect(() => {
    const imgs = document.querySelectorAll(".img-mark");

    if (imgs.length === 0) {
      // Exit if there are no images found
      return;
    }

    imgs.forEach((img, index) => {
      if (index === currentSlide) {
        img.classList.add("active");
      } else {
        img.classList.remove("active");
      }
    });
  }, [currentSlide]);


  const renderHTML = (text) => ({ __html: text });

  const sliderRef = useRef(null)

  const next = () => {
    sliderRef.current.slickNext();
  };

  const previous = () => {
    sliderRef.current.slickPrev();
  };
  
  
  return (
    
    <>
    <div className='white-head'>
      <div className='headfont only-web' dangerouslySetInnerHTML={renderHTML(headingWeb)}></div>
      <div className='headfont only-tab' dangerouslySetInnerHTML={renderHTML(headingTab)}></div>
      <div className='headfont only-mob' dangerouslySetInnerHTML={renderHTML(headingMob)}></div>

   
      <p className='only-web' dangerouslySetInnerHTML={renderHTML(descriptionWeb)}></p>
      <p className='only-tab' dangerouslySetInnerHTML={renderHTML(descriptionTab)}></p>
      <p className='only-mob' dangerouslySetInnerHTML={renderHTML(descriptionMob)}></p>

    </div>
      <div className='complex-slider'>
        <div className='width50'>
          <div className='arow-cont'>
            <img src="\images\healthcare\arrow-cs.svg" onClick={previous} />
            <img src="\images\healthcare\arrow2-cs.svg" onClick={next} />
          </div>
          <div className="slider-container">
            <Slider {...settings} ref={sliderRef}>

              {data && data.length > 0 && data.map((item, index) => {
                return (
                  <div>
                    <div className='card'>
                      <span className='montserrat-hard'>
                        {item.title}
                      </span>
                      <p className='montserrat-lit '>
                        {item.description}

                      </p>
                    </div>
                  </div>
                )
              }
              )
              }
            </Slider>
          </div>

        </div>
        <div className='width50-2'>


          {data.map((_, index) => (
            <img key={index} className='img-mark' src={`/images/${pageName}/sec-${secNo}-${index + 1}${window.innerWidth < 768 ? "-mob" : window.innerWidth < 1005 ? "-tab" : "-web"}.png`} alt="Healthcare" />
          ))}

        </div>
      </div>
    </>
  )
}

export default SpecialSliderSection