import React, {  useEffect, useRef, useState } from 'react'
import './FutureTechSolutions.scss'
import Banner from '../../common-component/BannerMainPages/BannerMainPages.js'
import DiamondSection from '../../common-component/DiamondSection/DiamondSection.js'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner'
import GreyCardsSection from '../../common-component/GreyCardsSection/GreyCardsSection'

import { Link, useNavigate } from 'react-router-dom';

import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import WhiteBanner from '../../common-component/BannerMainPages/WhiteBanner.js'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'




const View = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const sec3Data = [
    {
      title: "Improved Efficiency",
      description: "Leverage AI and automation to optimise business operations, increase efficiency, and empower your team to prioritise strategic tasks and innovation, ultimately fostering productivity and growth in your organisation.",
    },

    {
      title: "Enhanced Decision-Making",
      description: "Harness the power of AI for rapid, data-driven insight. Automate analysis of vast datasets, enabling informed decisions at unprecedented speeds. Gain a competitive edge in your industry with these advanced solutions.",
    },
    {
      title: "Cost-Effective",
      description: "Discover significant cost reductions by implementing automated processes. AI-driven solutions optimise resource allocation, reduce manual errors, and streamline workflows, leading to substantial financial benefits.",
    },
    {
      title: "Customer Satisfaction",
      description: "Elevate customer experiences with personalised interactions and quick problem resolution. AI-driven automation anticipates customer needs, ensuring satisfaction through enhanced communication and efficient service delivery.",
    },
    {
      title: "Enhanced Security",
      description: "Strengthen your defences against cyber threats with AI-powered security solutions. Automation detects and responds to potential risks in real time, fortifying your digital infrastructure and safeguarding sensitive information.",
    },
    {
      title: "Scalability and Flexibility",
      description: "AI and automation ensure seamless adaptation to business changes, offering scalability for efficient growth and flexibility to respond dynamically to market challenges, enabling your organisation to thrive.",
    },


  ]


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };

  const boxref=useRef(null);
  const boxref2=useRef(null);

  useEffect(() => {

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-col');
          setIsInView(true);
        } else {
          entry.target.classList.remove('in-col');
          setIsInView(false);
        }
      },
      {
        threshold: 0.1,
      }
    );
    const elementsToObserve = [
      boxref.current,
      boxref2.current,
     
    ];

    elementsToObserve.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);



  useEffect(() => {

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-col2');
          setIsInView(true);
        } else {
          entry.target.classList.remove('in-col2');
          setIsInView(false);
        }
      },
      {
        threshold: 0.1,
      }
    );
    const elementsToObserve = [
      
      boxref2.current,
     
    ];

    elementsToObserve.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);


  const [isInView, setIsInView] = useState(false);








  return (
    <div className='future-tech-solutions-page'>

      <WhiteBanner
        imglink={"/images/future-tech-solutions/Rectangle 195.png"}
        pageName={"future-tech-solutions"}
        titleWeb={"Welcome to the Future<br/> of Efficiency"}
        titleTab={"Welcome to<br/> the Future of Efficiency"}
        titleMob={"Welcome to<br/> the Future of Efficiency"}
        subTitleWeb={"Streamline operations, enhance efficiency, and boost<br/> innovation."}
        subTitleTab={"Streamline operations, enhance efficiency, and<br/> boost innovation."}
        subTitleMob={"Streamline operations, enhance<br/> efficiency, and boost innovation."}
        title1Web={"Future-Tech Solutions"}
        title2Tab={"Future-Tech Solutions"}
        title3Mob={"Future-Tech Solutions"}>

      </WhiteBanner>


    <div className='bluebox1' >
      <div className='fortext'>
        <span className='montserrat-hard'>Transformative Technologies for Progress</span>
        <p className='montserrat-lit'>From lead generation to customer engagement, data collection and analysis, and human resources, ZOG Global supercharge various aspects of your business with AI and Automation solutions.</p>


      </div>
      <div className='forline'>
        <div className='hr2'>
        <div className={`connect ${isInView ? 'active' : ''}`}></div>
        </div>


      </div>


    </div>

<div className={`mainsec2 ${isInView ? 'in-col' : 'in-col2'}`} ref={boxref}>
<div className="sec2-future" >
<div className='fortext'>
<span className='montserrat-hard'>AI-Powered Business Solutions</span>
<p className='montserrat-lit'>We empower businesses with strategic AI solutions, enhancing operations and decision-making. Harness the power of intelligent technology for tactical growth and sustained success in today's dynamic market.</p>
<Link to="/artificial-intelligence" className='montserrat-hard meter'>Learn more <span><img src="\images\quality-assurance\Frame (2).svg"/></span></Link>

</div>
<div className='forimg'>
  <img src="\images\future-tech-solutions\Banner2.png"
/>
</div>
</div>

<div className="sec21-future" >

<div className='forimg'>
  <img src="\images\future-tech-solutions\Banner2 (1).png"
/>
</div>

<div className='fortext'>
<span className='montserrat-hard'>Simplify Workflows Through RPA</span>
<p className='montserrat-lit'>We transform your business with next-level automation through Robotic Process Automation (RPA) solutions. Streamline workflows and boost productivity by eliminating manual tasks. Experience efficiency like never before.</p>
<Link to="/rpa"  className='montserrat-hard meter'>Learn more <span><img src="\images\quality-assurance\Frame (2).svg"/></span></Link>

</div>
</div>
</div>



<div className='bluebox1'>
      <div className='fortext'>
        <span className='montserrat-hard'>Benefits Of Our AI And RPA Solutions</span>
        <p className='montserrat-lit'>Our dynamic AI and RPA solutions mark the beginning of a new era in business optimisation. Dive into a realm where efficiency and innovation converge, propelling your organisations towards unparalleled success and growth.</p>


      </div>
      <div className='forline'>
        <div className='hr2'>
        
        </div>


      </div>


    </div>

<div className='sec3-future'>
  <div className='card'>
<span className='montserrat-hard'>Improved Efficiency</span>
<p className='montserrat-lit'>Leverage AI and automation to optimise business operations, increase efficiency, and empower your team to prioritise strategic tasks and innovation, ultimately fostering productivity and growth in your organisation.</p>
    
  </div>

  <div className='card'>
<span className='montserrat-hard'>Enhanced Decision-Making</span>
<p className='montserrat-lit'>Harness the power of AI for rapid, data-driven insight. Automate analysis of vast datasets, enabling informed decisions at unprecedented speeds. Gain a competitive edge in your industry with these advanced solutions.</p>
    
  </div>

  <div className='card'>
<span className='montserrat-hard'>Cost-Effective</span>
<p className='montserrat-lit'>Discover significant cost reductions by implementing automated processes. AI-driven solutions optimise resource allocation, reduce manual errors, and streamline workflows, leading to substantial financial benefits.</p>
    
  </div>

  
  <div className='card'>
<span className='montserrat-hard'>Customer Satisfaction</span>
<p className='montserrat-lit'>Elevate customer experiences with personalised interactions and quick problem resolution. AI-driven automation anticipates customer needs, ensuring satisfaction through enhanced communication and efficient service delivery.</p>
    
  </div>


  
  <div className='card'>
<span className='montserrat-hard'>Enhanced Security</span>
<p className='montserrat-lit'>Strengthen your defences against cyber threats with AI-powered security solutions. Automation detects and responds to potential risks in real time, fortifying your digital infrastructure and safeguarding sensitive information.</p>
    
  </div>


  
  <div className='card'>
<span className='montserrat-hard'>Scalability and Flexibility</span>
<p className='montserrat-lit'>AI and automation ensure seamless adaptation to business changes, offering scalability for efficient growth and flexibility to respond dynamically to market challenges, enabling your organisation to thrive.</p>
    
  </div>
  
  

</div>










<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>





<div className='sec4-future'>
  <img src="\images\future-tech-solutions\bottom.png"/>
  <div className='inside-text'>
    <p className='montserrat-hard'>Let's Build the Future Together!</p>
    <div type="button" onClick={() => navigate("/contact-us")} className='btn-sec4 montserrat-lit'>Get started now</div>

  </div>

</div>
























      {/* <DiamondSection
        pageName={'future-tech-solutions'}
        secNo={'2'}
        theme={"light"}
      headingBoxPresent={true}
      headingWeb={"Transformative<br/> Technologies for Progress"}
        headingTab={"Transformative Technologies for Progress"}
        headingMob={"Transformative Technologies for Progress"}
        descriptionWeb={"From lead generation to customer engagement, data collection and analysis, and<br/> human resources, ZOG Global supercharge various aspects of your business with AI<br/> and Automation solutions."}
        descriptionTab={"From lead generation to customer engagement, data collection and analysis, and human resources, ZOG Global supercharge various aspects of your business with AI and Automation solutions."}
        descriptionMob={"From lead generation to customer engagement, data collection and analysis, and human resources, ZOG Global supercharge various aspects of your business with AI and Automation solutions."}


        subHeadding1={"AI-Powered Business Solutions"}
        description1={"We empower businesses with strategic AI solutions, enhancing operations and decision-making. Harness the power of intelligent technology for tactical growth and sustained success in today's dynamic market."}
        subHeadding2={"Simplify Workflows Through RPA"}
        description2={"We transform your business with next-level automation through Robotic Process Automation (RPA) solutions. Streamline workflows and boost productivity by eliminating manual tasks. Experience efficiency like never before."}
      />

      <GreyCardsSection
        pageName={''}
        headingWeb={"Benefits Of Our AI And<br/> RPA Solutions"}
        headingTab={"Benefits Of Our AI And<br/> RPA Solutions"}
        headingMob={"Benefits Of Our<br/> AI And RPA<br/> Solutions"}
        descriptionWeb={"Our dynamic AI and RPA solutions mark the beginning of a new era in business<br/> optimisation. Dive into a realm where efficiency and innovation converge, propelling<br/> your organisations towards unparalleled success and growth."}
        descriptionTab={"Our dynamic AI and RPA solutions mark the beginning of a new era in business optimisation. Dive into a realm where efficiency and innovation> converge, propelling your organisations towards unparalleled success and growth."}
        descriptionMob={"Our dynamic AI and RPA solutions mark the beginning of a new era in business optimisation. Dive into a realm where efficiency and innovation converge, propelling your organisations towards unparalleled success and growth."}
        cardsData={sec3Data}

      />


      <BottomBanner
        pageName={"quality-assurance"}
        titleWeb={"Let's Build the<br/> Future Together!"}
        titleTab={"Let's Build the<br/> Future Together!"}
        titleMob={"Let's Build<br/> the Future<br/> Together!"}
        buttonText={"GET STARTED NOW"}
        buttonTheme={"light"}
        textTheme={"light"}
        buttonLink={'/contact-us'}
      >
      </BottomBanner> */}





      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default View