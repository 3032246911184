import React, { Component, useEffect, useState, useContext, useRef } from 'react'
import './Travel.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'
import SimpleSection from '../../common-component/SimpleSection/SimpleSection.js'
import SpecialSliderSection from "../../common-component/SpecialSliderSection/SpecialSliderSection"
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'


import Carousel from 'react-bootstrap/Carousel';
import { useNavigate, Link } from 'react-router-dom';

import API from "../../../API.js"
import LoadingSpinner from "../../../LoadingSpinner.js";
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';

import { UserContext } from '../../../authentication/pages/UserContext.js';

import Slider from 'react-slick'


const Travel = () => {
  const navigate = useNavigate();

  const { isLoggedIn, login, logout } = useContext(UserContext);

  const [tabSelected, selectTab] = useState(0);

  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
    setIsLoading(true)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        setBlogData(response.data)
        setIsLoading(false)

      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)

      });
  }, [])
  const sec3Data = [
    { title: "Travel Management Software", description: "Simplify bookings, manage itineraries, and elevate customer experiences with our comprehensive travel management software." },
    { title: "Customer Support Chatbots", description: "Enhance service efficiency and customer engagement with AI-powered chatbots tailored specifically for the travel industry." },
    { title: "Endpoint Security Solutions", description: "Ensure data protection and compliance with specialised endpoint security solutions crafted for the travel businesses." }
  ]

  const sec5Data = [
    {
      title: "Personalised Engagement",
      description: "Our data-driven insights craft unique travel experiences, ensuring each customer feels valued with personalised recommendations and services.",
    },
    {
      title: "Streamlined Operations",
      description: "We seamlessly integrate systems tailored to your needs, optimising workflows to boost productivity, cut costs, and ensure efficiency.",
    },
    {
      title: "Scalable Solutions",
      description: "Our flexible IT infrastructure grows with you, adapting to changing demands and expanding horizons while maintaining peak performance.",
    },
    {
      title: "Cost Efficiency",
      description: "We tailor processes to maximise resource use, driving down costs without compromising quality and ensuring value for your investment.",
    },
    {
      title: "Competitive Innovation",
      description: "Partnering with us means staying ahead with cutting-edge technology and offering innovative solutions that set you apart competitively.",
    },



  ]

  
  const sec4Data = [
    { title: "",
     description: "",
     image_url:"/images/why-zog-global/sec-4-1.png"
     },

    { title: "",
     description: "",
    image_url:"/images/why-zog-global/sec-4-2.png" 
  },

    { title: "",
     description: "",
    image_url:"/images/why-zog-global/sec-4-3.png" 
  },
  
  ]

  const sec6Data=[
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    
    
  ]
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };












  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index)
  };

  const sliderRef=useRef(null)
  
    const next = () => {
      sliderRef.current.slickNext();
  };
  
  const previous = () => {
      sliderRef.current.slickPrev();
  };

  const getImageSrc = (index) => {
    switch (index) {
      case 0:
        return "/images/travel/sec-3-1.png";
      case 1:
        return "/images/travel/sec-3-2.png";
      case 2:
        return "/images/travel/sec-3-3.png";
      case 3:
        return "/images/travel/sec-3-4.png";
      default:
        return "/images/travel/sec-3-1.png";
    }
  };
  

  useEffect(() => {
    const imgs = document.querySelectorAll(".img-mark");
  
    if (imgs.length === 0) {
      // Exit if there are no images found
      return;
    }
  
    imgs.forEach((img, index) => {
      if (index === currentSlide) {
        img.classList.add("active");
      } else {
        img.classList.remove("active");
      }
    });
  }, [currentSlide]);






  //bluecontainer
  

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
      
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
    ]

  };



  const sliderRef2=useRef(null)
  
    const next2 = () => {
      sliderRef2.current.slickNext();
  };
  
  const previous2 = () => {
      sliderRef2.current.slickPrev();
  };
  const [isToggled, setIsToggled] = useState(false);
  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  const [content1, setContent1]=useState(false);
   
  const cont1btn=()=>{
    setContent1(!content1);
  }


  









  return (
    <div className='travel-page'>

<BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"travel"}
        blueTitle={"Travel"}
        titleWeb={"Crafting Digital Destinies for Travel"}
        titleTab={"Crafting Digital<br/> Destinies for Travel"}
        titleMob={"Crafting Digital<br/> Destinies for<br/> Travel"}
        subTitleWeb={"Transforming travel businesses with<br/> expert IT solutions."}
        subTitleTab={"Transforming travel businesses with<br/> expert IT solutions."}
        subTitleMob={"Transforming travel businesses<br/> with expert IT solutions."}
        iconTopWeb={'70%'}  
        iconLeftWeb={'74%'}
        iconTopTab={'79%'}
        iconLeftTab={'41%'}
        iconTopMob={'73%'}
        iconLeftMob={'35%'}
        iconRotationWeb={104}
        iconRotationTab={104}
        iconRotationMob={104}>
      </BannerSubPages>


      {/* <SimpleSection
        pageName={"travel"}
        secNo={"2"}
        headdingPresent={true}
        buttonPresent={false}
        imagePosition={"left"}
        headingWeb={"Create Lasting Travel<br/> Memories"}
        headingTab={"Create Lasting Travel Memories"}
        headingMob={"Create Lasting Travel<br/> Memories"}
        description={"Integrating digital innovations into transportation infrastructure can significantly improve the quality and efficiency of travel experiences."}
        buttonText={""}
      /> */}

<div className='img-sec2-itoutsource'>

 
<div className='for-img'>
  <img src="\images\travel\sec-2.png"/>

</div>

<div className='for-text'>
  <span>Create Lasting Travel Memories</span>
  <p>Integrating digital innovations into transportation infrastructure can significantly improve the quality and efficiency of travel experiences.</p>

</div>


</div>













<div className='white-head'>
  <div className='headfont'>IT Services We Offer to Travel Industry</div>
  <p>Maximise your travel business's potential with our tailored IT services, driving operational efficiency, fortifying security, and elevating customer satisfaction.</p>
  
</div>




<div className='complex-slider'>
  <div className='width50'>
    <div className='arow-cont'>
    <img src="\images\healthcare\arrow-cs.svg" onClick={previous}/>
<img src="\images\healthcare\arrow2-cs.svg" onClick={next}/>
</div>
  <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
        <div>
        <div className='card'>
          <span className='montserrat-hard'>
          Travel Management Software
          </span>
          <p className='montserrat-lit '>
          Simplify bookings, manage itineraries, and elevate customer experiences with our comprehensive travel management software.
            </p>
        </div>
        </div>
        
        <div>
        <div className='card'>
          <span className='montserrat-hard'>
          Customer Support Chatbots
             </span>
          
          <p className='montserrat-lit '>
          Enhance service efficiency and customer engagement with AI-powered chatbots tailored specifically for the travel industry.
            
            </p>
        </div>
        </div>

        <div>
        <div className='card'>
          <span className='montserrat-hard'>Endpoint Security Solutions
          </span>
          <p className='montserrat-lit '>
          Ensure data protection and compliance with specialised endpoint security solutions crafted for the travel businesses.
          
          </p>
        </div>
        </div>

       
      </Slider>
    </div>

  </div>
  <div className='width50-2'>
  
 
  {sec3Data.map((_, index) => (
    <img key={index} className='img-mark' src={getImageSrc(index)} alt="Healthcare" />
  ))}
    
    </div>
</div>














<div className='img-sec2-itoutsource'>


<div className='for-text'>
  <span>Overcome Challenges</span>
  <p>
  Partner with us to overcome the challenges, leveraging innovative solutions and expert guidance to navigate the complexities of the travel industry.
  </p>

</div>


<div className='for-img'>
  <img src="\images\travel\sec-4.png"/>

</div>


</div>










<div id="targetDiv"  className={`blueslider-update ${isToggled ? 'black-bg' : ''}`}>

  <div className='head montserrat-hard'>
  Why Our IT Services Matter</div>

  <p className='montserrat-lit'></p>
  <div className='combine-btn'>
  <div className='arrow'>

    <img src="\images\protect-your-customers-data\1.svg" onClick={previous2}/>
    <img src="\images\protect-your-customers-data\Frame (1).svg" onClick={next2}/>

  </div>

  {/* <div className="toggle-container">
          <input type="checkbox" id="toggleButton" className="toggle-checkbox" onChange={handleToggleChange} />
          <label htmlFor="toggleButton" className="toggle-label">
            <span className="toggle-button">
            <img src="\images\protect-your-customers-data\icons8-open-lock.svg" className='im1'/>
            <img src="\images\protect-your-customers-data\icons8-lock.svg" className='im2'/>
            </span>
          </label>
        </div> */}
        </div>



       
  <div className='slickblue-update montserrat-lit'>
  <div className="slider-container ">
      <Slider ref={sliderRef2} {...settings2}>
        <div>
         <div className='cardofblue-update'>
          <span> Personalised Engagement</span>
          <p>Our data-driven insights craft unique travel experiences, ensuring each customer feels valued with personalised recommendations and services.</p>
         </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Streamlined Operations</span>
        <p>We seamlessly integrate systems tailored to your needs, optimising workflows to boost productivity, cut costs, and ensure efficiency.</p>

        </div>
        </div>
        <div>
        <div className='cardofblue-update'>

        <span>Scalable Solutions</span>
        <p>We tailor processes to maximise resource use, driving down costs without compromising quality and ensuring value for your investment.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Cost Efficiency</span>
        <p>We tailor processes to maximise resource use, driving down costs without compromising quality and ensuring value for your investment.</p>
        </div>
        </div>
       
       
        
       
      </Slider>
    </div>
    

  </div>




</div>









   


{/* 
<SpecialSliderSection 
      pageName={"travel"}
      secNo={"3"}
      
      headingBoxPresent={true}
      headingWeb={"IT Services We Offer to<br/> Travel Industry"}
      headingTab={"IT Services We Offer to<br/> Travel Industry"}
      headingMob={"IT Services We<br/> Offer to Travel<br/> Industry"}
      descriptionWeb={"Maximise your travel business's potential with our tailored IT services, driving<br/> operational efficiency, fortifying security, and elevating customer satisfaction."}
      descriptionTab={"Maximise your travel business's potential with our tailored IT services, driving operational efficiency, fortifying security, and elevating customer satisfaction."}
      descriptionMob={"Maximise your travel business's potential with our tailored IT services, driving operational efficiency, fortifying security, and elevating customer satisfaction."}
      boxAlignment={"center"}
      
      subTitle={""}
      data={sec3Data} 
      /> */}

{/* 
<SimpleSection
        pageName={"travel"}
        secNo={"4"}
        headdingPresent={true}
        buttonPresent={false}
        imagePosition={"right"}
        headingWeb={"Overcome Challenges"}
        headingTab={"Overcome Challenges"}
        headingMob={"Overcome Challenges"}
        description={"Partner with us to overcome the challenges, leveraging innovative solutions and expert guidance to navigate the complexities of the travel industry."}
        buttonText={""}
        
      /> */}
{/* 
      <BlueNotchSection
        headingWeb={"Why Our IT Services Matter"}     // <br/>
        headingTab={"Why Our<br/> IT Services Matter"}
        headingMob={"Why Our IT<br/> Services Matter"}
        descriptionPresent={false}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        listData={sec5Data}

      /> */}




<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>



      <div className='sec-7'>

      </div>




      <BottomBanner
        pageName={"travel"}
        titleWeb={"Looking for an<br/> IT Partner?"}
        titleTab={"Looking for an IT<br/> Partner?"}
        titleMob={"Looking for an<br/> IT Partner?"}
                
        subTitlePresent={true}
        subTitleWeb={"Reach out to our experienced team today."}
        subTitleTab={"Reach out to our experienced<br/> team today."}
        subTitleMob={"Reach out to our <br/>experienced team today."}

        buttonText={"talk to our team"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default Travel