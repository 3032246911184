import React, {  useEffect, useRef, useState } from 'react'
import './QualityAssurance.scss'

import Banner from '../../common-component/BannerMainPages/BannerMainPages.js'
import AccordionSection from '../../common-component/AccordionSection/AccordionSection.js'
import DiamondSection from '../../common-component/DiamondSection/DiamondSection.js'
import ImageOverlapedSection from '../../common-component/ImageOverlapedSection/ImageOverlapedSection.js'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'

import BottomBanner from '../../common-component/BottomBanner/BottomBanner'

import { Link, useNavigate } from 'react-router-dom';

import API from "../../../API.js"
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import WhiteBanner from '../../common-component/BannerMainPages/WhiteBanner.js'



const View = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
    setIsLoading(true)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        setBlogData(response.data)
        setIsLoading(false)
        

      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)

      });
  }, [])

  

  const sec2Data = [
    {
      title: "Automation Testing",
      description: "Automated test scripts can help the testing process run more smoothly and effectively. Testing an application's performance, load, and balance is best done through automation.",
      link: "/automation-testing"
    },
    {
      title: "Manual Testing",
      description: "Explore our comprehensive manual testing services for meticulous quality assurance. Our skilled team ensures thorough examination, identifying and rectifying issues to deliver flawless software solutions.",
      link: "/manual-testing"
    },
    {
      title: "Penetration Testing",
      description: "Penetration testing evaluates security teams, identifying flaws that risk data exposure. It ensures protection against unauthorised access, safeguards sensitive information and maintains authorisation protocols.",
      link: "/penetration-testing"
    },
    {
      title: "Performance Testing",
      description: "Assess system efficiency via performance testing for responsiveness, speed, and user experience under diverse workloads. Ensure optimal functionality and user satisfaction across heavy traffic, voluminous data, and numerous requests.",
      link: "/perfomance-testing"
    },
    {
      title: "Application Security Testing",
      description: "Enhance your digital fortress with our top-tier application security testing services. Safeguard your software against threats, vulnerabilities, and breaches for robust, resilient digital protection.",
      link: "/application-security-testing"
    },
    {
      title: "Accessibility Testing",
      description: "Ensure universal access to your digital presence with our comprehensive accessibility testing services, guaranteeing inclusivity and compliance with industry standards for a seamless user experience.",
      link: "/accessibility-testing"
    },

  ]

 


  const sec5Data = [
    {
      title: "Orientation & Training",
      description: "The initial step involves conducting a comprehensive orientation and training session for the QA team, covering project objectives, goals, expectations, and testing tools.",
    },

    {
      title: "Requirement Analysis",
      description: "The second phase entails a detailed requirement analysis, encompassing identifying key features, determining test types, and selecting necessary tools for testing execution.",
    },

    {
      title: "Test Planning",
      description: "After finalising the requirements, the team meticulously plans and designs test cases, outlining each case, expected results, pass/fail criteria, and test completion deadlines.",
    },
    {
      title: "Test Execution",
      description: "This stage involves executing test cases as per the plan, monitoring progress, and maintaining team communication to address issues and ensure project adherence to schedule.",
    },

    {
      title: "Test Reporting",
      description: "The final step includes examining and documenting software testing outcomes, preparing a comprehensive report assessing overall quality, and identifying detected problems or glitches.",
    },
  ]


  const sec6Data = [

    {
      title: "Mobilstrum otrebrattsur",
      description: "Nerväxt tiskade ett visukal, inklusive parade. Cykelbox Ikeamonarki poska. Haffa svininfluensa. Juholtare härgjord bloggare.",
      image_url: "/images/why-zog-global/sec-6-1.png"
    },

    {
      title: "Mobilstrum otrebrattsur",
      description: " Haffa svininfluensa. Juholtare härgjord bloggare.",
      image_url: "/images/why-zog-global/sec-6-1.png"
    },

    {
      title: "Mobilstrum otrebrattsur",
      description: "Nerväxt tiskade ett visukal, inklusive parade. Cykelbox Ikeamonarki poska.Nerväxt tiskade ett visukal, inklusive parade.  Haffa svininfluensa. Juholtare härgjord bloggare.",
      image_url: "/images/why-zog-global/sec-6-1.png"
    },
    {
      title: "Mobilstrum otrebrattsur",
      description: "Nerväxt tiskade ett visukal, inklusive parade. Cykelbox Ikeamonarki poska. Haffa svininfluensa. Juholtare härgjord bloggare.",
      image_url: "/images/why-zog-global/sec-6-1.png"
    },

    {
      title: "Mobilstrum otrebrattsur",
      description: "Nerväxt tiskade ett visukal, inklusive parade. Cykelbox Ikeamonarki poska. Haffa svininfluensa. Juholtare härgjord bloggare.",
      image_url: "/images/why-zog-global/sec-6-1.png"
    },

    {
      title: "Mobilstrum otrebrattsur",
      description: "Nerväxt tiskade ett visukal, inklusive parade. Cykelbox Ikeamonarki poska. Haffa svininfluensa. Juholtare härgjord bloggare.",
      image_url: "/images/why-zog-global/sec-6-1.png"
    },

  ]
  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };


  const boxref=useRef(null);

  useEffect(() => {

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-box');
        } else {
          entry.target.classList.remove('in-box');
        }
      },
      {
        threshold: 0.1,
      }
    );
    const elementsToObserve = [
      boxref.current,
     
    ];

    elementsToObserve.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);

  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  const [content1, setContent1]=useState(false);
   
  const cont1btn=()=>{
    setContent1(!content1);
  }


  const sections = [
    { title: 'Automation Testing',link:"/automation-testing", content: 'Automated test scripts can help the testing process run more smoothly and effectively. Testing an applications performance, load, and balance is best done through automation.', path:"/images/quality-assurance/Rectangle 185.png" },
    { title: 'Manual Testing',link:"/manual-testing", content: 'Explore our comprehensive manual testing services for meticulous quality assurance. Our skilled team ensures thorough examination, identifying and rectifying issues to deliver flawless software solutions.', path:"/images/home/content-dropdown/Rectangle 186.png" },
    { title: 'Penetration Testing',link:"/penetration-testing", content: 'Penetration testing evaluates security teams, identifying flaws that risk data exposure. It ensures protection against unauthorised access, safeguards sensitive information and maintains authorisation protocols.', path:"/images/home/content-dropdown/Rectangle 187.png"  },
    { title: 'Performance Testing',link:"/perfomance-testing", content: 'Assess system efficiency via performance testing for responsiveness, speed, and user experience under diverse workloads. Ensure optimal functionality and user satisfaction across heavy traffic, voluminous data, and numerous requests.', path:"/images/home/content-dropdown/Rectangle 188.png"  },
    { title: 'Application Security Testing',link:"/application-security-testing", content: 'Assess system efficiency via performance testing for responsiveness, speed, and user experience under diverse workloads. Ensure optimal functionality and user satisfaction across heavy traffic, voluminous data, and numerous requests.', path:"/images/home/content-dropdown/Rectangle 189.png"  },
    { title: 'Accessibility Testing',link:"/accessibility-testing", content: 'Ensure universal access to your digital presence with our comprehensive accessibility testing services, guaranteeing inclusivity and compliance with industry standards for a seamless user experience.', path:"/images/home/content-dropdown/Rectangle 190.png"  },
    
  ];



  return (
    <div className='quality-assurance-page'>

      <WhiteBanner
        imglink={"/images/quality-assurance/main-banner.png"}
        pageName={"quality-assurance"}
        titleWeb={"Quality Assurance<br/> Services"}
        titleTab={"Quality Assurance<br/> Services"}
        titleMob={"Quality Assurance Services"}
        subTitleWeb={"Our QA experts ensure top-quality, timely software<br/> development implementation."}
        subTitleTab={"Our QA experts ensure top-quality, timely software<br/> development implementation."}
        subTitleMob={"Our QA experts ensure top-quality,<br/> timely software development<br/> implementation."}
        title1Web={"QUALITY ASSURANCE"}
        title2Tab={"QUALITY ASSURANCE"}
        title3Mob={"QUALITY ASSURANCE"}>
        
      </WhiteBanner>


<div className='sec2-qa'>

  <div className='one'>
   
  <div className='textofani'> 


<p className='montserrat-hard '> 
<div className='bluebox'></div>
<div className='white' ref={boxref}></div>
QA Serv<span className='montserrat-hard animated-i'>i</span>ces<br/> We Offer 
</p>


</div>
<div className='hr2'></div>

  </div>
    <div className='two'>
      <p className='montserrat-lit '>We perform end-to-end testing of your infrastructure, applications, and data to guarantee that all your software is offered bug-free to end users.</p>
    </div>

</div>






<div className='sec6-homepage'>
    <div className='maincontentdiv-sec6-home'>
      {sections.map((section, index) => (
        <div className='firstdiv-sec6' key={index}>
          <div className='title-sec6' onClick={() => handleSectionClick(index)}>
            <span className='montserrat-hard'>{section.title}</span>
            <img 
              src="/images/home/Vector (5).svg" 
              alt="Toggle Content" 
              className={activeSection === index ? 'rotate' : ''}
            />
          </div>
          <div className={`content-s6 ${activeSection === index ? 'show' : ''}`}>
            <p>{section.content}</p>

            <Link to={section.link} className='montserrat-hard meter'>Learn more <span><img src="\images\quality-assurance\Frame (2).svg"/></span></Link>
          </div>
        </div>
      ))}
    </div>
    <div className='forimage-sec6-home'>
        {activeSection !== null ? (
          <img 
            src={sections[activeSection].path} 
            className='sec6-img' 
            alt={sections[activeSection].title} 
          />
        ):
        <img src="\images\quality-assurance\Rectangle 185.png" className='sec6-img'/>}
      </div>

    </div>



<div className='sec3-qa'>
  <p className='tl2 montserrat-hard'>Why is Quality Assurance Important?</p>
  <div className='hr2'></div>
  <p>We perform end-to-end testing of your infrastructure, applications, and data to guarantee that all your software is offered bug-free to end users.</p>
</div>




    <div className='sec2-ind'>
    <div className='second-sec2'>
     
     <div className='for-text'>
      
       <span className='montserrat-hard'>Mitigating Risks <br/> and Costs</span>
       <p className='montserrat-lit'>Beyond customer satisfaction, Quality Assurance minimises risks and reduces long-term costs. By identifying and rectifying issues early, businesses avoid potential setbacks and financial burdens, ensuring sustainable success.</p>
     
     </div>
     <div className='for-img'>
       <img src="\images\quality-assurance\sec-3-1.png" className='imgsrc'/>
        
     </div>
     
     
     
     
           </div>

      <div className='first-sec2'>
<div className='for-img'>
  <img src="\images\quality-assurance\sec-3-2-tab.png" className='imgsrc'/>
   
</div>
<div className='for-text'>
  <span className='montserrat-hard'>Ensuring  <br/>Customer Trust</span>
  <p className='montserrat-lit'>Quality Assurance guarantees products meet high standards, fostering trust among customers. Rigorous testing ensures reliability, instils confidence in your brand and fosters long-lasting customer relationships.</p>

</div>


      </div>

     





    </div>






<div className='sec5-qa'>
<div className='one-sec5'>
  <p className='montserrat-hard'>Why Choose ZOG Global For Quality Assurance Services?</p>
  <div className='hr2'>

  </div>
  </div> 
<div className='two-sec5'>
  <div className='cards'>
    <div className='card'>

      <span className='montserrat-hard'>Adapting Latest Tools and Frameworks</span>
      <p className='montserrat-lit '>Stay ahead with cutting-edge QA solutions, leveraging the latest tools and frameworks for unparalleled testing excellence.</p>

    </div>

    <div className='card'>
    <span className='montserrat-hard'>Testing Specialist Engineers</span>
      <p className='montserrat-lit '>Our expert QA engineers ensure precision and reliability, delivering seamless testing solutions tailored to your needs.</p>

      
      </div>

      <div className='card'>
      <span className='montserrat-hard'>High-Quality Services & Transparency</span>
      <p className='montserrat-lit '>Experience top-tier QA services characterised by excellence and transparency, ensuring your project's success and client satisfaction.</p>

      
      </div>

  </div>
  </div>  

</div>





<div className='sec6-qa'>
<div className='one-sec5'>
  <p className='montserrat-hard'>Onboarding Journey of Quality Assurance Project</p>
  <div className='hr2'>

  </div>
  </div> 

  <div className='secfirst'>
    
    <div className='for-text'>
      <span className='montserrat-hard'>Orientation & Training</span>
      <p className='montserrat-lit'>The initial step involves conducting a comprehensive orientation and training session for the QA team, covering project objectives, goals, expectations, and testing tools.</p>
    </div>
    <div className='for-img'>
      <img src="\images\quality-assurance\Rectangle 180 (1).png"/>
    </div>

  </div>



  <div className='secfirsto'>

  <div className='for-img'>
      <img src="\images\quality-assurance\Rectangle 180 (2).png"/>
    </div>
    
    <div className='for-text'>
      <span className='montserrat-hard'>Requirement Analysis</span>
      <p className='montserrat-lit'>The second phase entails a detailed requirement analysis, encompassing identifying key features, determining test types, and selecting necessary tools for testing execution.</p>
    </div>
    

  </div>



  <div className='secfirst'>
    
    <div className='for-text'>
      <span className='montserrat-hard'>Test Planning</span>
      <p className='montserrat-lit'>After finalising the requirements, the team meticulously plans and designs test cases, outlining each case, expected results, pass/fail criteria, and test completion deadlines.</p>
    </div>
    <div className='for-img'>
      <img src="\images\quality-assurance\Rectangle 180 (3).png"/>
    </div>

  </div>



  <div className='secfirsto'>
  <div className='for-img'>
      <img src="\images\quality-assurance\Rectangle 180 (4).png"/>
    </div>
    
    <div className='for-text'>
      <span className='montserrat-hard'>Test Execution</span>
      <p className='montserrat-lit'>This stage involves executing test cases as per the plan, monitoring progress, and maintaining team communication to address issues and ensure project adherence to schedule.</p>
    </div>
    

  </div>




  <div className='secfirst'>
  

    
    <div className='for-text'>
      <span className='montserrat-hard'>Test Reporting</span>
      <p className='montserrat-lit'>The final step includes examining and documenting software testing outcomes, preparing a comprehensive report assessing overall quality, and identifying detected problems or glitches.</p>
    </div>

    <div className='for-img'>
      <img src="\images\quality-assurance\Rectangle 180 (5).png"/>
    </div>
    
  </div>




  
  
  </div>












  <BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>



    <div className='sec7'>
      <img src="\images\quality-assurance\cde47b86a67db706ec70581701eab384.jpeg"/>
      <div className='for-text montserrat-lit'>
        <p className='montserrat-hard'>Connect Now For<br/> QA Consultation</p>
        <div type="button"  onClick={() => navigate("/contact-us")}>TALK TO OUR EXPERTS</div>
      </div>
    </div>





      {/* <AccordionSection 
      headingWeb={"QA Services We Offer"}
       headingTab={"QA Services We Offer"}
       headingMob={"QA Services<br/> We Offer"}
       description={"We perform end-to-end testing of your infrastructure, applications, and data to<br/> guarantee that all your software is offered bug-free to end users."}
      
      accordionData={sec2Data} />
       */}




      {/* <DiamondSection
      pageName={'quality-assurance'} 
      secNo={'3'} 
      theme={"dark"}
      headingBoxPresent={true}
      headingWeb={"Why is Quality Assurance<br/> Important?"} 
      headingTab={"Why is Quality Assurance Important?"} 
      headingMob={"Why is Quality Assurance Important?"} 
      descriptionWeb={"Elevate success with the pivotal role of quality assurance, ensuring precision,<br/> reliability, and customer satisfaction. Unleash the power of seamless processes for<br/> enduring business excellence."} 
      descriptionTab={"Elevate success with the pivotal role of quality assurance, ensuring precision, reliability, and customer satisfaction. Unleash the power of seamless processes for enduring business<br/> excellence."} 
      descriptionMob={"Elevate success with the pivotal role of quality assurance, ensuring precision, reliability, and customer satisfaction. Unleash the power of seamless processes for enduring business excellence."} 


      subHeadding1={"Ensuring Customer Trust"} 
      description1={"Quality Assurance guarantees products meet high standards, fostering trust among customers. Rigorous testing ensures reliability, instils confidence in your brand and fosters long-lasting customer relationships."} 
      subHeadding2={"Mitigating Risks and Costs"} 
      description2={"Beyond customer satisfaction, Quality Assurance minimises risks and reduces long-term costs. By identifying and rectifying issues early, businesses avoid potential setbacks and financial burdens, ensuring sustainable success."} 
        /> */}



{/* 

      
      <ImageOverlapedSection 
      pageName={'quality-assurance'} 
      secNo={'4'} 
      imageFirst={false}
      headingWeb={"Why Choose ZOG Global For<br/> Quality Assurance Services?"} 
      headingTab={"Why Choose ZOG Global For Quality Assurance Services?"} 
      headingMob={"Why Choose ZOG Global For Quality Assurance Services?"} 


      subHeadding1={"Adapting Latest Tools and Frameworks"} 
      description1={"Stay ahead with cutting-edge QA solutions, leveraging the latest tools and frameworks for unparalleled testing excellence."} 
      subHeadding2={"Testing Specialist Engineers"} 
      description2={"Our expert QA engineers ensure precision and reliability, delivering seamless testing solutions tailored to your needs."} 
      subHeadding3={"High-Quality Services & Transparency"} 
      description3={"Experience top-tier QA services characterised by excellence and transparency, ensuring your project's success and client satisfaction."}
      />




      <div className='sec-5'>
        <div className='inner'>
          <h1 className='only-web'>Onboarding Journey of<br />Quality Assurance Project</h1>
          <h1 className='only-tab'>Onboarding Journey of<br />Quality Assurance Project</h1>
          <h1 className='only-mob'>Onboarding Journey of Quality Assurance Project</h1>

          {sec5Data.map((item, index) => {
            return (
              <div className={index % 2 == 0 ? 'segment even' : 'segment odd'} >
                <div className='number'>{index + 1}</div>
                <div className='text-box'>
                  <h2>{sec5Data[index].title}</h2>
                  <p>{sec5Data[index].description}</p>
                </div>
              </div>

            )
          })}


        </div>
      </div> */}

      {/* <div className='sec-6'>
        <div className='inner'>
          <h1>
          Insights and Best Practices <br/>in Quality Assurance
          </h1>

          <div className='flex-box d-none d-lg-flex'>
            <div className='column'>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[0].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[0].title}</div>
                  <div className='sub-title'>{sec6Data[0].description}</div>
                </div>
              </div>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[1].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[1].title}</div>
                  <div className='sub-title'>{sec6Data[1].description}</div>
                </div>
              </div>


            </div>


            <div className='column'>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[2].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[2].title}</div>
                  <div className='sub-title'>{sec6Data[2].description}</div>
                </div>
              </div>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[2].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[3].title}</div>
                  <div className='sub-title'>{sec6Data[3].description}</div>
                </div>
              </div>


            </div>



            <div className='column'>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[4].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[4].title}</div>
                  <div className='sub-title'>{sec6Data[4].description}</div>
                </div>
              </div>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[5].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[5].title}</div>
                  <div className='sub-title'>{sec6Data[5].description}</div>
                </div>
              </div>

            </div>
          </div>



          <div className='flex-box d-none d-md-flex d-lg-none'>
            <div className='column'>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[0].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[0].title}</div>
                  <div className='sub-title'>{sec6Data[0].description}</div>
                </div>
              </div>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[1].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[1].title}</div>
                  <div className='sub-title'>{sec6Data[1].description}</div>
                </div>
              </div>


              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[2].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[2].title}</div>
                  <div className='sub-title'>{sec6Data[2].description}</div>
                </div>
              </div>
            </div>


            <div className='column'>



              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[3].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[3].title}</div>
                  <div className='sub-title'>{sec6Data[3].description}</div>
                </div>
              </div>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[4].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[4].title}</div>
                  <div className='sub-title'>{sec6Data[4].description}</div>
                </div>
              </div>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[5].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[5].title}</div>
                  <div className='sub-title'>{sec6Data[5].description}</div>
                </div>
              </div>

            </div>



          </div>
          <div className='flex-box d-md-none'>
            <div className='column'>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[0].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[0].title}</div>
                  <div className='sub-title'>{sec6Data[0].description}</div>
                </div>
              </div>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[1].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[1].title}</div>
                  <div className='sub-title'>{sec6Data[1].description}</div>
                </div>
              </div>


              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[2].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[2].title}</div>
                  <div className='sub-title'>{sec6Data[2].description}</div>
                </div>
              </div>




              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[3].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[3].title}</div>
                  <div className='sub-title'>{sec6Data[3].description}</div>
                </div>
              </div>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[4].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[4].title}</div>
                  <div className='sub-title'>{sec6Data[4].description}</div>
                </div>
              </div>

              <div className='card'>
                <div className='img-box'>
                  <img src={sec6Data[5].image_url}></img>
                </div>
                <div className='text-box'>
                  <div className='title'>{sec6Data[5].title}</div>
                  <div className='sub-title'>{sec6Data[5].description}</div>
                </div>
              </div>

            </div>



          </div>

        </div>
      </div> */}



      {/* <BottomBanner
        pageName={"quality-assurance"}
        titleWeb={"Connect Now<br/> For QA<br /> Consultation"}
        titleTab={"Connect Now<br/> For QA<br /> Consultation"}
        titleMob={"Connect<br/> Now For QA<br/> Consultation"}
        buttonText={"TALK TO OUR EXPERTS"}
        buttonTheme={"light"}
        textTheme={"light"}
        buttonLink={'/contact-us'}
      >
      </BottomBanner> */}




      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default View