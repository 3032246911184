import React, { useEffect, useRef } from "react";
import './ImageOverlapedSection.scss'
import { useNavigate } from "react-router-dom";
const ImageOverlapedSection = ({ pageName, secNo, imageFirst, numberPresent, headingWeb, headingTab, headingMob,descriptionWeb,descriptionTab, descriptionMob, subHeadding1, description1, subHeadding2, description2, subHeadding3, description3,subHeadding4 , description4 }) => {
  const renderHTML = (text) => ({ __html: text });
console.log("imageFirst from ImageOverlappedSection", imageFirst)

const navigate = useNavigate();

const  imgOverLappedSecRef1 = useRef(null);
const  imgOverLappedSecRef2 = useRef(null);
const  imgOverLappedSecRef3 = useRef(null);
const  imgOverLappedSecRef4 = useRef(null);


useEffect(() => {
  const observer = new IntersectionObserver(
    ([entry]) => {
        if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
        } else {
            entry.target.classList.remove('in-view');
        }
    },
    {
        threshold: 0.1,
    }
);
  const elementsToObserve = [imgOverLappedSecRef1.current, imgOverLappedSecRef2.current, imgOverLappedSecRef3.current, imgOverLappedSecRef4.current, ];
  elementsToObserve.forEach((element) => {
      if (element) observer.observe(element);
  });

  return () => {
      elementsToObserve.forEach((element) => {
          if (element) observer.unobserve(element);
      });
  };
}, []);

 
  return (
    <div className={descriptionWeb==null?'image-overlaped-section no-description':'image-overlaped-section'}>
      <h1 className={`only-web ${descriptionWeb ? 'with-p' : ''}`} dangerouslySetInnerHTML={renderHTML(headingWeb)}></h1>
      <h1 className={`only-tab ${descriptionWeb ? 'with-p' : ''}`} dangerouslySetInnerHTML={renderHTML(headingTab)}></h1>
      <h1 className={`only-mob ${descriptionWeb ? 'with-p' : ''}`} dangerouslySetInnerHTML={renderHTML(headingMob)}></h1>

      {descriptionWeb&&
      <>
      <p className='only-web' dangerouslySetInnerHTML={renderHTML(descriptionWeb)}></p>
      <p className='only-tab' dangerouslySetInnerHTML={renderHTML(descriptionTab)}></p>
      <p className='only-mob' dangerouslySetInnerHTML={renderHTML(descriptionMob)}></p>
      </>
}
      <div className={imageFirst?'sub-sec image-at-left':'sub-sec image-at-right'} ref={imgOverLappedSecRef1}>
        <div className='lhs' >
          <img src={`/images/${pageName}/sec-${secNo}-1.png`} className='only-web'></img>
          <img src={`/images/${pageName}/sec-${secNo}-1-tab.png`} className='only-tab'></img>
          <img src={`/images/${pageName}/sec-${secNo}-1.png`} className='only-mob'></img>

        </div>
        <div className='rhs'>
          <div className="num-title">
            {/* {numberPresent&&<div className="number">01</div>} */}
            <h2>{subHeadding1}</h2>
          </div>
          
          <p >{description1}</p>

        </div>
      </div>

      <div className={imageFirst===true?'sub-sec image-at-right':'sub-sec image-at-left'} ref={imgOverLappedSecRef2}>
        <div className='lhs'>
          <img src={`/images/${pageName}/sec-${secNo}-2.png`} className='only-web'></img>
          <img src={`/images/${pageName}/sec-${secNo}-2-tab.png`} className='only-tab'></img>
          <img src={`/images/${pageName}/sec-${secNo}-2.png`} className='only-mob' ></img>

        </div>

        <div className='rhs'>
          <div className="num-title">
            {/* {numberPresent&&<div className="number">02</div>} */}
            <h2>{subHeadding2}</h2>
          </div>
          <p>{description2}</p>

        </div>
      </div>

      <div className={imageFirst?'sub-sec image-at-left':'sub-sec image-at-right'} ref={imgOverLappedSecRef3}>
        <div className='lhs'>
          <img src={`/images/${pageName}/sec-${secNo}-3.png`} className='only-web' ></img>
          <img src={`/images/${pageName}/sec-${secNo}-3-tab.png`} className='only-tab'></img>
          <img src={`/images/${pageName}/sec-${secNo}-3.png`} className='only-mob'></img>

        </div>
        <div className='rhs'>
          <div className="num-title">
            {/* {numberPresent&&<div className="number">03</div>} */}
            <h2>{subHeadding3}</h2>
          </div>
        
          <p>{description3}</p>

        </div>
      </div>

      {subHeadding4&&
      <div className={imageFirst===true?'sub-sec image-at-right':'sub-sec image-at-left'} ref={imgOverLappedSecRef4}>
      <div className='lhs'>
        <img src={`/images/${pageName}/sec-${secNo}-4.png`} className='only-web'></img>
        <img src={`/images/${pageName}/sec-${secNo}-4-tab.png`} className='only-tab'></img>
        <img src={`/images/${pageName}/sec-${secNo}-4.png`} className='only-mob'></img>

      </div>

      <div className='rhs'>
      <div className="num-title">
        {/* {numberPresent&&<div className="number">04</div>} */}
        <h2>{subHeadding4}</h2>
      </div>
      
        <p >{description4}</p>

      </div>
    </div>}
    </div>

  )
}

export default ImageOverlapedSection