// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal.invoice-type .card {
  width: 393px;
  height: 445px;
  border-radius: 22px;
  padding: 30px 30px;
}
.custom-modal.invoice-type .card .close-btn {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  padding-bottom: 20px;
}
.custom-modal.invoice-type .card h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
}
.custom-modal.invoice-type .card p {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
}
.custom-modal.invoice-type .card .button-container {
  display: flex;
  flex-direction: column;
  width: 196px;
  margin-top: 30px;
}
.custom-modal.invoice-type .card .button-container .ok-button {
  font-family: Work Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.01em;
  text-align: center;
  padding: 13px 26px;
  border-radius: 8px;
  transition: all 0.3s ease;
  margin-bottom: 5px;
}
.custom-modal.invoice-type .card .button-container .ok-button:hover {
  background: #2694E3;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/common-components/InvoiceTypePopup/InvoiceTypePopup.scss"],"names":[],"mappings":"AACI;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;AAAR;AACQ;EACI,aAAA;EACA,yBAAA;EACA,eAAA;EACA,oBAAA;AACZ;AACQ;EACI,gBAAA;EACA,eAAA;EACA,iBAAA;AACZ;AACQ;EACI,eAAA;EACA,iBAAA;EACA,gBAAA;AACZ;AACQ;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;AACZ;AAAY;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;AAEhB;AADgB;EACI,mBAAA;EACA,WAAA;AAGpB","sourcesContent":[".custom-modal.invoice-type{\r\n    .card{\r\n        width: 393px;\r\n        height: 445px;\r\n        border-radius: 22px;\r\n        padding: 30px 30px;\r\n        .close-btn{\r\n            display: flex;\r\n            justify-content: flex-end;\r\n            cursor: pointer;\r\n            padding-bottom: 20px;\r\n        }\r\n        h1{\r\n            font-weight: 700;\r\n            font-size: 24px;\r\n            line-height: 30px;\r\n        }\r\n        p{\r\n            font-size: 16px;\r\n            line-height: 19px;\r\n            font-weight: 400;\r\n        }\r\n        .button-container{\r\n            display: flex;\r\n            flex-direction: column;\r\n            width: 196px;\r\n            margin-top: 30px;\r\n            .ok-button{\r\n                font-family: Work Sans;\r\n                font-size: 14px;\r\n                font-weight: 500;\r\n                line-height: 16px;\r\n                letter-spacing: -0.01em;\r\n                text-align: center;\r\n                padding: 13px 26px;\r\n                border-radius: 8px;\r\n                transition: all 0.3s ease;\r\n                margin-bottom: 5px;\r\n                &:hover{\r\n                    background: #2694E3;\r\n                    color: #fff;\r\n                }\r\n            }\r\n        }\r\n    }\r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
