import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';

import API from '../../../API';
import LoadingSpinner from "../../../LoadingSpinner";
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import $ from 'jquery';
import './JobPost.scss'
import RichTextEditor from '../../common-components/RichText/RichText';
import CustomDatePicker from '../../common-components/CustomDatePicker/CustomDatePicker';
import RangeSlider from '../../common-components/DoubleRangeSlider/RangeSlider';
import AddDataPopup from '../../common-components/AddDataPopup/AddDataPopup';
import { isRichTextEmpty } from '../../common-functions/commonFunctions';
import TimePickerComponent from '../../common-components/TimePicker/TimePicker';
import JobPreview from '../../common-components/JobPreview/JobPreview';

const JobPost = ( {editMode } ) => {
    let navigate = useNavigate();
    const [errors, setErrors] = useState({});
    useEffect(() => {
        $(function() {
      $(window).scrollTop(0);
      });
      }, [errors]) 

    const loadPage = () => {
        navigate(window.location.pathname);
    };

    const itemId = localStorage.getItem("itemSelectedId");

    const [message, setMessage] = useState(null);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [addModalTitle, setAddModalTitle] = useState('');
    const [addSectionAPI, setAddSectionAPI] = useState(""); // For Add modal list
    
    const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState('£')
    const [editObjJobID, setEditObjJobID] = useState(null)
    

    const [formData, setFormData] = useState({
        title: "",
        type: "",
        location: "",
        salary_currency: null,
        department: "", 
        is_salary_negotiable: false,
        skills: [],
        skill_description: '',
        job_roles: [],
        salary_range_from:0,
        salary_range_to:500,
        expiry_date:null,
        working_hours_from:'09:00:00',
        working_hours_to:'17:00:00',
        job_status:'pending',
        about_zog:"",
        disclaimer: "",
        is_published:false,
      });
    
    // IF EDIT MODE
    useEffect(() => {
      const itemId = localStorage.getItem("itemSelectedId");
      if (editMode && itemId) {
          setIsLoading(true)
          API.get(`/recruitment/jobs/${itemId}/`)
          .then(response => {
              console.log(response.data);
              setFormData({
                title: response.data.title || "",
                type: response.data.type || "",
                location: response.data.location || "",
                salary_currency: response.data.salary_currency || null,
                department: response.data.department || "", 
                is_salary_negotiable: response.data.is_salary_negotiable || false,
                skills: response.data.skills_required.map(item => item.skill_required) || [],
                // skills: response.data.skills || [],
                skill_description: response.data.skill_description || '',
                // job_roles:  [],
                job_roles: response.data.job_roles.map(item => item.job_role_required) || [],
                salary_range_from: response.data.salary_range_from || 0,
                salary_range_to: response.data.salary_range_to || 500,
                expiry_date: response.data.expiry_date || null,
                working_hours_from: response.data.working_hours_from || '09:00:00',
                working_hours_to: response.data.working_hours_to || '17:00:00',
                job_status: response.data.job_status || 'pending',
                about_zog: response.data.about_zog || "",
                disclaimer: response.data.disclaimer || "",
                is_published: response.data.is_published || false,
              });
              setSelectedCurrencySymbol(response.data.salary_currency_data.symbol || '£');
              setEditObjJobID(response.data.job_id || null)
              setIsLoading(false)
          })
          .catch(error => {
              console.log(error);
              setMessage(error.message);
              setIsErrorModalOpen(true);
              setIsLoading(false)
          });
      }
  }, [editMode]);

    const [jobDepartments, setJobDepartments] = useState([])
    // GET Job Departments from Backend
    useEffect(() => {
      API.get(`/recruitment/departments/`)
        .then((response) => {
          setJobDepartments(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
    }, [jobDepartments]);

    const [jobLocations, setJobLocations] = useState([])
    // GET Job Locations from Backend
    useEffect(() => {
      API.get(`/recruitment/locations/`)
        .then((response) => {
          setJobLocations(response.data)
        })
        .catch((error) => {
          console.log(error);
        });
        
    }, [jobLocations]);

    const [currencies, setCurrencies] = useState([])
    
    // GET Job Locations from Backend
    useEffect(() => {
      API.get(`/recruitment/currency/`)
        .then((response) => {
          setCurrencies(response.data)
          // console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
        
    }, []);

 

    const validateForm = (data) => { 
        const errors = {};

        if (!data.title.trim()) {
            errors.title = "Title is required.";
        }
        if (!data.type) {
            errors.job_type = "Select job type";
        }
        if (!data.department) {
          errors.department = "Select department";
        }
        if (!data.location) {
            errors.location = "Select job location";
        }
        if (!data.salary_currency) {
          errors.salary_currency = "Select currency";
        }
        if (data.skills.length === 0) {
          errors.skills = "Add skills";
        }
        if (!data.skill_description.trim() || (isRichTextEmpty && isRichTextEmpty(data.skill_description))) {
          errors.skill_description = "Add skill description";
        }
        if (data.job_roles.length === 0) {
          // console.log('empty');
          errors.job_roles = "Add job role";
        }
        if (!data.salary_range_to) {
          errors.salary_range = "Select salary range";
        }
        if (!data.salary_range_from) {
          errors.salary_range = "Select salary range";
        }
        if (!data.working_hours_from) {
          errors.working_hours = "Select salary range";
        }
        if (!data.working_hours_from) {
          errors.working_hours = "Select working hours";
        }
        if (!data.expiry_date) {
          errors.expiry_date = "Set expiry date";
        }

        return errors;
    };
    
    const handleInputChange = (e) => {
      const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

  
  


    // Job Type
    const [jobTypeListOpened, toggleJobTypeList] = useState(false);
    const selectJobType = (value) => {
        setFormData((formData) => ({
          ...formData,
          type: value
        }));
      }
    useEffect(() => {
        const $OptionDropList = $("#job_type .option-list");
        if (jobTypeListOpened) {
            const height = $OptionDropList[0].scrollHeight;
            $OptionDropList.animate({ height: height + "px" }, 400);
            $OptionDropList.css("opacity", "1");
        } else {
            $OptionDropList.animate({ height: "0px" }, 400);
            $OptionDropList.css("opacity", "0");
        }
    }, [jobTypeListOpened]);

    // Job Department
    const [departmentListOpened, toggleDepartmentList] = useState(false)
    useEffect(() => {
        const $OptionDropList = $("#department .option-list");
        if (departmentListOpened) {
            const height = $OptionDropList[0].scrollHeight;
            $OptionDropList.animate({ height: height + "px" }, 400);
            $OptionDropList.css("opacity", "1");
        } else {
            $OptionDropList.animate({ height: "0px" }, 400);
            $OptionDropList.css("opacity", "0");
        }
    }, [departmentListOpened]);

    const selectJobDepartment = (id,name) => {
      setFormData((formData) => ({
        ...formData,
        department: id
      }));
    }
    const handleAddDepartment = () => {
      setIsAddModalOpen(true);
      setAddModalTitle('Department');
      setAddSectionAPI("/recruitment/departments")
    }


    // Job Location
    const [locationListOpened, toggleLocationList] = useState(false)
    useEffect(() => {
        const $OptionDropList = $("#location .option-list");
        if (locationListOpened) {
            const height = $OptionDropList[0].scrollHeight;
            $OptionDropList.animate({ height: height + "px" }, 400);
            $OptionDropList.css("opacity", "1");
        } else {
            $OptionDropList.animate({ height: "0px" }, 400);
            $OptionDropList.css("opacity", "0");
        }
    }, [locationListOpened]);

    
    const selectJobLocation = (id,name) => {
      setFormData((formData) => ({
        ...formData,
        location: id
      }));
    }
    const handleAddLocation = () => {
      setIsAddModalOpen(true);
      setAddModalTitle('Location');
      setAddSectionAPI("/recruitment/locations")
    }

    // Currency
    const [currencyListOpened, toggleCurrencyList] = useState(false)
    useEffect(() => {
        const $OptionDropList = $("#currency .option-list");
        if (currencyListOpened) {
            const height = $OptionDropList[0].scrollHeight;
            $OptionDropList.animate({ height: height + "px" }, 400);
            $OptionDropList.css("opacity", "1");
        } else {
            $OptionDropList.animate({ height: "0px" }, 400);
            $OptionDropList.css("opacity", "0");
        }
    }, [currencyListOpened]);

    // Salary 
    const [salaryValues, setSalaryValues] = useState([0, 500]);
    const [salaryStep, setSalaryStep] = useState(10);
    const [salaryMinRange, setSalaryMinRange] = useState(0);
    const [salaryMaxRange, setSalaryMaxRange] = useState(500);


    
    const updateSalaryRange = (symbol) => {
      switch (symbol) {
        case '£': // Pound
          return { minRange: 50, maxRange: 2000,step:10 ,def_max:500, def_min:100,};
        case '$': // Dollar
          return { minRange: 50, maxRange: 2000,step:10 ,def_max:500, def_min:100,};
        case '₹': // Indian Rupee
          return { minRange: 5000, maxRange: 200000, step:500 ,def_max:20000, def_min:5000,};
        default:
          return { minRange: 0, maxRange: 2000, step:10 ,def_max:500, def_min:100,}; // Default range
      }
    };
    // add Editing data
    useEffect(() => {
      if (editMode){
        const ranges = updateSalaryRange(selectedCurrencySymbol);
        setSalaryMinRange(ranges.minRange);
        setSalaryMaxRange(ranges.maxRange);
        setSalaryStep(ranges.step);
        const minRange = formData.salary_range_from !== null ? formData.salary_range_from : ranges.def_min;
        const maxRange = formData.salary_range_to !== null ? formData.salary_range_to : ranges.def_max;
        setSalaryValues([minRange, maxRange]);
        console.log(ranges, minRange, maxRange,selectedCurrencySymbol);
      }
      
    }, [formData.salary_currency, formData.salary_range_from, formData.salary_range_to,selectedCurrencySymbol]);


    const selectCurrency = (currency) => {
      setFormData((formData) => ({
        ...formData,
        salary_currency: currency.id
      }));
      setSelectedCurrencySymbol(currency.symbol);
      setSalaryMaxRange(currency.max_range)
      const { minRange, maxRange,step,def_max,def_min } = updateSalaryRange(currency.symbol);
      setSalaryMinRange(minRange);
      setSalaryStep(step);
      setSalaryValues([def_min,def_max]);
      
    }
      
    const handleSalaryChange = (values) => {
      setSalaryValues(values);
      setFormData({
        ...formData,
        salary_range_from: values[0],
        salary_range_to: values[1],
      });
    };


    

    // Working Hours
    const handleWorkingHoursFromChange = (time) => {
      // console.log();
      setFormData({ ...formData, working_hours_from: time });
    };
    const handleWorkingHoursToChange = (time) => {
      // console.log();
      setFormData({ ...formData, working_hours_to: time });
    };

    // Negotiable
    const [negotiableListOpened, toggleNegotiableList] = useState(false)
    useEffect(() => {
        const $OptionDropList = $("#negotiable .option-list");
        if (negotiableListOpened) {
            const height = $OptionDropList[0].scrollHeight;
            $OptionDropList.animate({ height: height + "px" }, 400);
            $OptionDropList.css("opacity", "1");
        } else {
            $OptionDropList.animate({ height: "0px" }, 400);
            $OptionDropList.css("opacity", "0");
        }
    }, [negotiableListOpened]);

    const selectNegotiable = (value) => {
      setFormData((formData) => ({
        ...formData,
        is_salary_negotiable: value
      }));
    }
    // Expiry Date
    const handleExpiryChange = (date) =>{
      const formattedDate = date.toISOString().slice(0, 10);
      setFormData((formData) => ({
        ...formData,
        expiry_date: formattedDate
      }));
    }
    // Skills Required
    const handleAddSkill = () => {
      const skillInput = document.getElementById('skill_required');
      if (skillInput.value !== "") {
        setFormData({
          ...formData,
          skills: [...formData.skills, skillInput.value]
        });
        skillInput.value = ''; // Clear input field after adding skill
      }
    };
    const handleRemoveSkill = (index) => {
      const newSkills = formData.skills.filter((_, i) => i !== index);
      setFormData({
        ...formData,
        skills: newSkills
      });
    };

    // Skill Details
    const handleSkillDetails = (section_id, content) => {
      setFormData((formData) => ({
        ...formData,
        skill_description: content
      }));
    };

    // Job Role
    const handleAddJobRole = () => {
      const jobRoleInput = document.getElementById('job_role');
      if (jobRoleInput.value !== "") {
        setFormData({
          ...formData,
          job_roles: [...formData.job_roles, jobRoleInput.value]
        });
        jobRoleInput.value = '';
      }
    };
    
    const handleRemoveJobRole = (index) => {
      const newJobRoles = formData.job_roles.filter((_, i) => i !== index);
      setFormData({
        ...formData,
        job_roles: newJobRoles
      });
    };


    const handleDoneButton = () => {
        const validationErrors = validateForm(formData);
        setErrors(validationErrors);
        // console.log(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
          setIsPreviewModalOpen(true);
        }
      }


    // console.log(formData);
    


    return (
        <>
        
        <div className='px-0 px-md-4 py-3 full-screen-table-container job-post'>
            <div className='text-title'>
                <h3>{editMode ? "Edit Job" : "Post Job"}</h3>
            </div>
            <form className='form-card' >
             

              <div className='description'>
                <div className='card'>
                  <div className='sec-1'>
                    <div className='lhs'>
                      <div className=' text-input'>
                        <label>Job ID</label>
                        <input type='text' value={editObjJobID ? editObjJobID : 'ZGUKS15201'} id='jobid' className='form-control' disabled={true} />
                      </div>
                      <div className='text-input'>
                        <label>Job Title</label>
                        <input type='text' className='form-control' value={formData.title} name='title' onChange={handleInputChange}/>
                        {errors.title && <div className="invalid-feedback">{errors.title}</div>}
                      </div>
                      {/*  */}
                      <div className='job-type-select'>
                        <label>Job Type</label>
                        <div className="custom-select form-control form-select" id="job_type" name="job_type"
                          onClick={() => toggleJobTypeList(!jobTypeListOpened)}>
                          <div className="selected-value">{formData.type ? formData.type : "Select Job Type"}
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_3764_11686" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                            <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0_3764_11686)">
                            <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            </svg>
                          </div>
                          <div className='option-list'>
                            <div className='option' onClick={() => selectJobType("full time")}>full time</div>
                            <div className='option' onClick={() => selectJobType("permanent")}>permanent</div>
                            <div className='option' onClick={() => selectJobType("contract")}>contract</div>
                          </div>

                          
                        </div>
                        {errors.job_type && <div className="invalid-feedback">{errors.job_type}</div>}
                      </div>
                      <div className='department-select'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <label>Job Department</label>
                          <div className='add-button' onClick={handleAddDepartment}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00877 0.5C8.62853 0.5 9.13931 1.00562 9.13931 1.62694L9.1396 6.88154H14.3778C14.9976 6.88154 15.5 7.38522 15.5 8.00654C15.5 8.62786 14.9976 9.13154 14.3778 9.13154H9.1396L9.13949 14.3718C9.13949 14.9931 8.63708 15.4968 8.01732 15.4968C7.39757 15.4968 6.89516 14.9931 6.89516 14.3718L6.89527 9.13154H1.63714C1.01738 9.13154 0.5 8.61234 0.5 7.99102C0.5 7.3697 1.01738 6.88154 1.63714 6.88154H6.89527L6.89498 1.62694C6.89498 1.00562 7.38901 0.5 8.00877 0.5Z" fill="#0587DB"/>
                            </svg>Add Department</div>
                        </div>
                          
                        
                        <div className="custom-select form-control form-select" id="department" name="department"
                          onClick={() => toggleDepartmentList(!departmentListOpened)}>
                          <div className="selected-value">
                            {formData.department ? 
                            (jobDepartments.find(department => department.id === formData.department)?.name || "Select Department") : 
                            "Select Department"}
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_3764_11686" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                            <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0_3764_11686)">
                            <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            </svg>
                          </div>
                          <div className='option-list'>
                            {jobDepartments.map(department => (
                              <div 
                                key={department.id} 
                                className='option' 
                                onClick={() => selectJobDepartment(department.id,department.name)}
                              >
                                {department.name}
                              </div>
                            ))}
                          </div>
                        </div>
                          {errors.department && <div className="invalid-feedback">{errors.department}</div>}
                      </div>
                      <div className='location-select'>
                        <div className='d-flex align-items-center justify-content-between'>
                          <label>Job Location</label>
                          <div className='add-button' onClick={handleAddLocation}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00877 0.5C8.62853 0.5 9.13931 1.00562 9.13931 1.62694L9.1396 6.88154H14.3778C14.9976 6.88154 15.5 7.38522 15.5 8.00654C15.5 8.62786 14.9976 9.13154 14.3778 9.13154H9.1396L9.13949 14.3718C9.13949 14.9931 8.63708 15.4968 8.01732 15.4968C7.39757 15.4968 6.89516 14.9931 6.89516 14.3718L6.89527 9.13154H1.63714C1.01738 9.13154 0.5 8.61234 0.5 7.99102C0.5 7.3697 1.01738 6.88154 1.63714 6.88154H6.89527L6.89498 1.62694C6.89498 1.00562 7.38901 0.5 8.00877 0.5Z" fill="#0587DB"/>
                            </svg>Add Location</div>
                        </div>
                        <div className="custom-select form-control form-select" id="location" name="location"
                          onClick={() => toggleLocationList(!locationListOpened)} >
                          <div className="selected-value">
                            {formData.location ? 
                            (jobLocations.find(location => location.id === formData.location)?.name || "Select Location") : 
                            "Select Location"}
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_3764_11686" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                            <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0_3764_11686)">
                            <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            </svg>
                          </div>
                          <div className='option-list'>
                            {jobLocations.map(location => (
                              <div 
                                key={location.id} 
                                className='option' 
                                onClick={() => selectJobLocation(location.id,location.name)}
                              >
                                {location.name}
                              </div>
                            ))}
                          </div>
                        </div>
                          {errors.location && <div className="invalid-feedback">{errors.location}</div>}
                      </div>
                    </div>
                    <div className='rhs'>
                      <div className='currency-select'>
                        <label>Currency</label>
                        <div className="custom-select form-control form-select" id="currency" name="currency"
                          onClick={() => toggleCurrencyList(!currencyListOpened)} >
                          <div className="selected-value">{formData.salary_currency ? 
                            (currencies.find(currency => currency.id === formData.salary_currency)?.symbol + ' ' + 
                            currencies.find(currency => currency.id === formData.salary_currency)?.name || "Select Currency") : 
                            "Select Currency"}
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_3764_11686" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                            <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0_3764_11686)">
                            <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            </svg>
                          </div>
                          <div className='option-list'>
                            {currencies.map(currency => (
                              <div 
                                key={currency.id} 
                                className='option' 
                                onClick={() => selectCurrency(currency)}
                              >
                                {currency.symbol} {currency.name}
                              </div>
                            ))}
                          </div>
                          
                        </div>
                        {errors.salary_currency && <div className="invalid-feedback">{errors.salary_currency}</div>}
                      </div>
                      <div className='salary-range'>
                        <label>Salary Range</label>
                        <div className="range-slider">
                          <RangeSlider values={salaryValues} onChange={handleSalaryChange} min_range={salaryMinRange} max_range={salaryMaxRange} step={salaryStep} />
                          <div className='values'>
                            <input type='text' className='min' value={`${selectedCurrencySymbol}${salaryValues[0]}`} name='min' readOnly />
                            <span></span>
                            <input type='text' className='max' value={`${selectedCurrencySymbol}${salaryValues[1]}`} name='max' readOnly />
                              
                          </div>
                        </div>
                        {errors.salary_range && <div className="invalid-feedback">{errors.salary_range}</div>}
                      </div>
                      <div className='work-hours'>
                        <label>Working Hours</label>
                          <div className='values'>
                            <div className='min'>
                            <TimePickerComponent value={formData.working_hours_from} onChange={handleWorkingHoursFromChange}/>
                            </div>
                            <span className='divider'></span>
                            <div className='max'>
                            <TimePickerComponent value={formData.working_hours_to} onChange={handleWorkingHoursToChange}/>
                            </div>
                            
                          </div>
                         
                        {errors.working_hours && <div className="invalid-feedback">{errors.working_hours}</div>}
                      </div>
                      
                      <div className='salary-optn-select'>
                        <label>Salary Option</label>
                        <div className="custom-select form-control form-select" id="negotiable" name="negotiable"
                          onClick={() => toggleNegotiableList(!negotiableListOpened)} >
                          <div className="selected-value">{formData.is_salary_negotiable ? 'Negotiable' : "Fixed"}
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_3764_11686" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="24">
                            <path d="M24.5 24V0L0.5 0V24H24.5Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0_3764_11686)">
                            <path d="M23.5 6.5L12.5 17.5L1.5 6.5" stroke="#757F82" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            </svg>
                          </div>
                          <div className='option-list'>
                            <div className='option' onClick={() => selectNegotiable(true)}>Negotiable</div>
                            <div className='option' onClick={() => selectNegotiable(false)}>Fixed</div>
                          </div>
                          
                        </div>
                      </div>
                      <div className='expiry-select'>
                        <label>Set Job Expiry Date</label>
                        <CustomDatePicker
                          selected={formData.expiry_date}
                          dateFormat={"dd/MM/yyyy"} placeholder={'DD/MM/YYYY'} 
                          // showMonthYearPicker={showMonthYearPicker}
                          // showYearPicker={showYearPicker} 
                          onChange={(date)=>handleExpiryChange(date)}
                          minDate={new Date()}
                          />
                          {errors.expiry_date && <div className="invalid-feedback">{errors.expiry_date}</div>}
                      </div>
                    </div>
                  </div>

                  <div className='sec-2'>
                    <div className='text-input'>
                      <label>Skills Required</label>
                      {formData.skills && 
                        <div className='selected-options'>
                            {formData.skills.map((option, index) => (
                                <div key={index} className='optn'>
                                    <span >{option} </span>
                                    <button type='button' onClick={() => handleRemoveSkill(index)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="10" fill="#0587DB"/>
                                        <path d="M6.56689 6.73535L13.737 13.9054" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.7373 6.73535L6.56724 13.9054" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                        
                                </div>
                            ))}
                        </div>
                      }
                      <div className="input-with-icon">
                        <input type='text'  id='skill_required' className='form-control'/>
                        <div className='icon-btn' onClick={handleAddSkill}>
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5576 2L10.5576 18.8839" stroke="#959EAF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M19 10.4425L2.11613 10.4425" stroke="#959EAF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        
                      </div>
                      {errors.skills && <div className="invalid-feedback">{errors.skills}</div>}
                      
                    </div>
                    <div className='' >
                      <label>Skill Details</label>
                      <RichTextEditor sectionId={"skill_details"} onContentChange={handleSkillDetails} 
                      {...(editMode && formData.skill_description !== '' && { initialContent: formData.skill_description })} />
                      {errors.skill_description && <div className="invalid-feedback">{errors.skill_description}</div>}
                    </div>
                    <div className='text-input'>
                      <label>Add Role</label>
                      <div className="input-with-icon">
                        <input type='text'  id='job_role' className='form-control'/>
                        <div className='icon-btn' onClick={handleAddJobRole} >
                            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.5576 2L10.5576 18.8839" stroke="#959EAF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M19 10.4425L2.11613 10.4425" stroke="#959EAF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                      </div>
                      {errors.job_roles && <div className="invalid-feedback">{errors.job_roles}</div>}
                      {formData.job_roles && 
                        <div className='selected-texts'>
                            {formData.job_roles.map((option, index) => (
                                <div key={index} className='optn'>
                                    <span >{option} </span>
                                    <button type='button' onClick={() => handleRemoveJobRole(index)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="10" fill="#000"/>
                                        <path d="M6.56689 6.73535L13.737 13.9054" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M13.7373 6.73535L6.56724 13.9054" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                        
                                </div>
                            ))}
                        </div>
                      }
                    </div>
                    
                  </div>
                  
                  
                
                        
                </div>
              </div>
                  

              {/* Preview */}
              <div className='continue-sec'>
                  <button type='button' onClick={handleDoneButton}  className='admin-blue-btn'>Done!</button>
              </div>

            </form>
            
        </div>
        

        <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={loadPage} />
        {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={loadPage} />}
        {isPreviewModalOpen && <JobPreview message={message} setterFunction={setIsPreviewModalOpen} okClickedFunction={loadPage} formData={formData} setFormData={setFormData} jobLocations={jobLocations} currencySymbol={selectedCurrencySymbol} editMode={editMode} />}
        {isAddModalOpen && <AddDataPopup title={addModalTitle} addSectionAPI={addSectionAPI} setterFunction={setIsAddModalOpen} okClickedFunction={loadPage} />}
        
        {isLoading && <FixedOverlayLoadingSpinner />} 
        </>

    )
}

export default JobPost