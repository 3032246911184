// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload-container .drag-and-drop {
  background: #F7F7F9;
  height: 330px;
  border: 4px dotted #E7E7E7;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #648197;
  flex-direction: column;
  gap: 23px;
  overflow: hidden;
}
.file-upload-container .drag-and-drop .drag-text {
  font-family: Work Sans;
  font-size: 26px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: -0.03em;
  text-align: center;
}
.file-upload-container .drag-and-drop svg {
  width: 50px;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/common-components/ImageUploader/ImageFileUploader.scss"],"names":[],"mappings":"AAEI;EACI,mBAAA;EACA,aAAA;EACA,0BAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,sBAAA;EACA,SAAA;EACA,gBAAA;AADR;AAEQ;EACI,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;AAAZ;AAEQ;EACI,WAAA;EACA,YAAA;AAAZ","sourcesContent":[".file-upload-container{\r\n    \r\n    .drag-and-drop{\r\n        background: #F7F7F9;\r\n        height: 330px;\r\n        border: 4px dotted #E7E7E7;\r\n        border-radius: 5px;\r\n        display: flex;\r\n        align-items: center;\r\n        justify-content: center;\r\n        color: #648197;\r\n        flex-direction: column;\r\n        gap: 23px;\r\n        overflow: hidden;\r\n        .drag-text{\r\n            font-family: Work Sans;\r\n            font-size: 26px;\r\n            font-weight: 300;\r\n            line-height: 32px;\r\n            letter-spacing: -0.03em;\r\n            text-align: center;\r\n        }\r\n        svg{\r\n            width: 50px;\r\n            height: 50px;\r\n        }\r\n        \r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
