import React, { useContext, useEffect, useState, useRef } from 'react'
import "./BlueSliderSection.scss"
import { useNavigate, Link } from 'react-router-dom';
import Slider from 'react-slick'

import $ from 'jquery';

import { UserContext } from '../../../authentication/pages/UserContext.js';

import API from "../../../API.js"

function BlueSliderSection({pagename,headingWeb,headingTab,headingMob,descriptionWeb,descriptionTab,descriptionMob,cardsData, theme}) {

  const renderHTML = (text) => ({ __html: text });


  const sliderRef2=useRef(null)
  
  const next2 = () => {
    sliderRef2.current.slickNext();
};

const previous2 = () => {
    sliderRef2.current.slickPrev();
};
const [isToggled, setIsToggled] = useState(false);
const handleToggleChange = () => {
  setIsToggled(!isToggled);
};

  
  const navigate = useNavigate();



  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
      
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
    ]

  };


  return (
    
<div id="targetDiv"  className={`blueslider-update ${theme=="dark" ? 'black-bg' : ''}`}>


<div className='head montserrat-hard only-web' dangerouslySetInnerHTML={renderHTML(headingWeb)}></div>
        <div className='head montserrat-hard only-tab' dangerouslySetInnerHTML={renderHTML(headingTab)}></div>
        <div className='head montserrat-hard only-mob' dangerouslySetInnerHTML={renderHTML(headingMob)}></div>

<p className='montserrat-lit only-web' dangerouslySetInnerHTML={renderHTML(descriptionWeb)}></p>
<p className='montserrat-lit only-tab' dangerouslySetInnerHTML={renderHTML(descriptionTab)}></p>
<p className='montserrat-lit only-mob' dangerouslySetInnerHTML={renderHTML(descriptionMob)}></p>
<div className='combine-btn'>
<div className='arrow'>

  <img src="\images\protect-your-customers-data\1.svg" onClick={previous2}/>
  <img src="\images\protect-your-customers-data\Frame (1).svg" onClick={next2}/>

</div>

{/* <div className="toggle-container">
        <input type="checkbox" id="toggleButton" className="toggle-checkbox" onChange={handleToggleChange} />
        <label htmlFor="toggleButton" className="toggle-label">
          <span className="toggle-button">
          <img src="\images\protect-your-customers-data\icons8-open-lock.svg" className='im1'/>
          <img src="\images\protect-your-customers-data\icons8-lock.svg" className='im2'/>
          </span>
        </label>
      </div> */}
      </div>



     
<div className='slickblue-update montserrat-lit'>
<div className="slider-container ">
    <Slider ref={sliderRef2} {...settings2}>
    {cardsData&&cardsData.length>0&&cardsData.map((item, index) => {
      return (  
      <div>
       <div className='cardofblue-update'>
        <span> Personalised Engagement</span>
        <p>Our data-driven insights craft unique travel experiences, ensuring each customer feels valued with personalised recommendations and services.</p>
       </div>
      </div>
   
      )})}
     
      
     
    </Slider>
  </div>
  

</div>




</div>
  )
}

export default BlueSliderSection