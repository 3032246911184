import React, { useEffect, useRef } from "react";

import './FiveCardsSection.scss'

const FiveCardsSection = ({ pageName, secNo, cardHeightWeb, cardHeightTab, headingWeb, headingTab, headingMob, description, subHeading1, description1, subHeading2, description2, subHeading3, description3, subHeading4, description4, subHeading5, description5 }) => {
  const renderHTML = (text) => ({ __html: text });


  const fiveCardSecFlexBoxRef = useRef([]);
  const fiveCardSecRef1 = useRef([]);
  const fiveCardSecRef2 = useRef([]);
  const fiveCardSecRef3 = useRef([]);
  const fiveCardSecRef4 = useRef([]);
  const fiveCardSecRef5 = useRef([]);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
          if (entry.isIntersecting) {
              entry.target.classList.add('in-view');
          } else {
              entry.target.classList.remove('in-view');
          }
      },
      {
          threshold: 0.1,
      }
  );
    const elementsToObserve = [fiveCardSecFlexBoxRef.current,fiveCardSecRef1.current,fiveCardSecRef2.current,fiveCardSecRef3.current,fiveCardSecRef4.current,];
    elementsToObserve.forEach((element) => {
        if (element) observer.observe(element);
    });
  
    return () => {
        elementsToObserve.forEach((element) => {
            if (element) observer.unobserve(element);
        });
    };
  }, []);


  return (
    <div className='five-cards-section '>
<div className="inner">
      <h1 className='only-tab' dangerouslySetInnerHTML={renderHTML(headingTab)}></h1>
      <h1 className='only-mob' dangerouslySetInnerHTML={renderHTML(headingMob)}></h1>
      {description&&<p className='only-tab' dangerouslySetInnerHTML={renderHTML(description)}></p>}
      {description&&<p className='only-mob' dangerouslySetInnerHTML={renderHTML(description)}></p>}


      <div className='flex-box' ref={fiveCardSecFlexBoxRef}>

        <div className='text-box' style={window.innerWidth>992?{height:`${cardHeightWeb}px`}:window.innerWidth>768?{height:`${cardHeightTab}px`}:{}}>
          <h1  dangerouslySetInnerHTML={renderHTML(headingWeb)}></h1>
          {description&&<p dangerouslySetInnerHTML={renderHTML(description)}></p>}

        </div>
        <div className="wide-card">

        <div className='card' style={window.innerWidth>992?{height:`${cardHeightWeb}px`}:window.innerWidth>768?{height:`${cardHeightTab}px`}:{}} ref={fiveCardSecRef1} >
          <img src={`/images/${pageName}/sec-${secNo}-1.svg`} alt="Card 1"></img>
          <div className='title' dangerouslySetInnerHTML={renderHTML(subHeading1)}></div>
          <div className='description' dangerouslySetInnerHTML={renderHTML(description1)}></div>
        </div>

        <div className='card' style={window.innerWidth>992?{height:`${cardHeightWeb}px`}:window.innerWidth>768?{height:`${cardHeightTab}px`}:{}} ref={fiveCardSecRef2}>
          <img src={`/images/${pageName}/sec-${secNo}-2.svg`} alt="Card 2"></img>
          <div className='title' dangerouslySetInnerHTML={renderHTML(subHeading2)}></div>
          <div className='description' dangerouslySetInnerHTML={renderHTML(description2)}></div>
        </div>

        <div className='card' style={window.innerWidth>992?{height:`${cardHeightWeb}px`}:window.innerWidth>768?{height:`${cardHeightTab}px`}:{}} ref={fiveCardSecRef3}>
          <img src={`/images/${pageName}/sec-${secNo}-3.svg`} alt="Card 3"></img>
          <div className='title' dangerouslySetInnerHTML={renderHTML(subHeading3)}></div>
          <div className='description' dangerouslySetInnerHTML={renderHTML(description3)}></div>
        </div>

        <div className='card' style={window.innerWidth>992?{height:`${cardHeightWeb}px`}:window.innerWidth>768?{height:`${cardHeightTab}px`}:{}} ref={fiveCardSecRef4}>
          <img src={`/images/${pageName}/sec-${secNo}-4.svg`} alt="Card 4"></img>
          <div className='title' dangerouslySetInnerHTML={renderHTML(subHeading4)}></div>
          <div className='description' dangerouslySetInnerHTML={renderHTML(description4)}></div>
        </div>

       
        </div>
      </div>
    </div>
    </div>
  );
}

export default FiveCardsSection;
