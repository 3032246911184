// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-range-slider {
  width: 100%;
  display: flex;
  justify-content: center;
}
.custom-range-slider .slider {
  width: 100%;
  height: 10px;
  background-color: #C0DBFB;
  border-radius: 5px;
}
.custom-range-slider .slider .thumb {
  width: 24px;
  height: 24px;
  cursor: grab;
  background: #2280EF;
  border-radius: 50%;
  border: 4px solid #FFFFFF;
  top: -7px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
}
.custom-range-slider .slider .track-1 {
  background-color: #2280EF;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/common-components/DoubleRangeSlider/RangeSlider.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,uBAAA;AACJ;AAAI;EACI,WAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;AAER;AADQ;EACI,WAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,yBAAA;EACA,SAAA;EACA,uDAAA;AAGZ;AADQ;EACI,yBAAA;EACA,YAAA;AAGZ","sourcesContent":[".custom-range-slider{\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: center;\r\n    .slider{\r\n        width: 100%;\r\n        height: 10px;\r\n        background-color: #C0DBFB;\r\n        border-radius: 5px;\r\n        .thumb{\r\n            width: 24px;\r\n            height: 24px;\r\n            cursor: grab;\r\n            background: #2280EF;\r\n            border-radius: 50%;\r\n            border: 4px solid #FFFFFF;\r\n            top: -7px;\r\n            box-shadow: 0px 4px 4px 0px #00000040;\r\n        }\r\n        .track-1{\r\n            background-color: #2280EF;\r\n            height: 100%;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
