import React, { useContext, useEffect, useState } from 'react'
import "./BlogNewSection.scss"
import { useNavigate, Link } from 'react-router-dom';

import $ from 'jquery';

import { UserContext } from '../../../authentication/pages/UserContext.js';

import API from "../../../API.js"

function BlogNewSection({pagename}) {




  const { isLoggedIn, login, logout } = useContext(UserContext);

  const [tabSelected, selectTab] = useState(0);

  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
  
    API.get(`blogs/?page_name=${pagename}`)
    
      .then((response) => {
     
        setBlogData(response.data.slice(0, 4)); // Only take the first 4 items
        setIsLoading(false)
        console.log(blogData,"this is respose")
        
        
  
      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)
  
      });
  }, [blogData])
  
  
  console.log(blogData,"this is ourespose")
  
  const navigate = useNavigate();




  return (
    
<div className='sec8-whyzog'>

<div className='sec9-homepage'>
      <p className='montserrat-hard blogtitle'>Blog Highlights</p>
      <div className='boxcontent-sec8'>
        <div className='firstsec8'>
          {blogData && blogData.map((blog,index)=>(
               <div className='box1 montserrat-lit ' style={{
                backgroundImage: `url(${blog.image_url})`,
                backgroundSize: "cover",
                
              }}
              key={index}
              onClick={() => navigate(`/blog-details/${blog.id}`)}>
                <p className='montserrat-hard'>{blog.title}</p>
                <span>{blog.extra}</span>
                <span>{blog.created_at}</span>
                <img src="\images\home\arrow-bl-anim.svg" className='arrow-sec8'/>
    
              </div>


          ))}
       
         


        </div>
        

      </div>


    </div>

    </div>
  )
}

export default BlogNewSection