// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vW;
  text-align: center;
}
.page-not-found h4 {
  color: red;
}
.page-not-found h1 {
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/user-panel/pages/NoPage/NoPage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;AACJ;AACI;EACJ,UAAA;AACA;AACI;EACJ,WAAA;AACA","sourcesContent":[".page-not-found{\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n    height: 100vh;\r\n    width: 100vW;\r\n    text-align: center;\r\n\r\n    h4{\r\ncolor: red;\r\n    }\r\n    h1{\r\ncolor: gray;\r\n    }\r\n    h3{\r\n\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
