// constants.js



export const metaTags = {
  "Home": {
    title: "ZOG Global: IT Consultancy Services in Darlington, UK",
    description: "Transform your business with expert IT consultancy services in Darlington, UK. Contact us today to receive your free consultation.",
    keywords: "IT Consultancy Services, IT Services Company",
  },
 
   "why-zog-global": {
    title: "Why ZOG Global | Case Studies & Success Stories",
    description: "Discover the transformative journey of businesses with ZOG Global through compelling case studies and success stories.",
    keywords: "Case Studies",
  },
  "industries": {
    title: "Industry IT Services & Solution - ZOG Global",
    description: "Our IT solutions for Industries: Healthcare, Technology, Telecom, Education, Travel, Banking & Finance, Insurance, Legal, Media & Entertainment, and Pharma.",
    keywords: "Industry IT Services",
  },
 "digital-engineering": {
    title: "Digital Engineering | ZOG Global",
    description: "Explore cutting-edge Digital Engineering services for innovative solutions. Our expertise transforms ideas into reality, driving efficiency and excellence.",
    keywords: "Industry IT Services",
  },
  "quality-assurance": {
    title: "Quality Assurance Services | Ensure Excellence with ZOG Global",
    description: "ZOG Global provides robust quality assurance services to ensure your products meet the highest standards. Enhance reliability and customer satisfaction.",
    keywords: "Software Testing,Quality Assurance Services",
  },
  "future-tech-solutions": {
    title: "AI & Automation Solutions | ZOG Global",
    description: "We offer personalised AI & automation solutions tailored to elevate your business processes. Experience efficiency and growth with our advanced solutions.",
    keywords: "AI Solutions, Automation Solutions",
  },
  "insights": {
    title: "Explore Tech Insights",
    description: "Dive into the latest tech insights. Stay ahead in the fast-paced world of technology. Explore, learn, and innovate with us.",
    keywords: "Tech Insights",
  },


  // till above line data is updated. continue with below
  "What We Do": {
    title: "What We Do | IT Services Darlington - ZOG Global",
    description: "Navigate the digital landscape with confidence. Our IT services offer a seamless blend of expertise and innovation. Explore the possibilities now!",
    keywords: "IT Services Company",
  },

  "Transform Your Business": {
    title: "Transform Your Business | IT Services Darlington, UK",
    description: "Transform your business with our innovative IT services! Discover a comprehensive range of solutions to boost your online presence and drive success.",
    keywords: "IT Services UK",
  },

  "Protect Your Customers Data": {
    title: "Data Protection Services in Darlington, UK | ZOG Global",
    description: "Protect your digital assets with our comprehensive data protection services. Safeguard sensitive information and ensure compliance effortlessly.",
    keywords: "Data Protection Services, Data Protection Solution",
  },

  "Managed IT Services": {
    title: "Managed IT Service Provider - ZOG Global",
    description: "As your trusted Managed IT Service Provider, we optimise performance, enhance security, and ensure business continuity. Partner with us today!",
    keywords: "Managed IT Service Provider, Managed IT Services",
  },

  "Engagement Model": {
    title: "Engagement Model for Businesses - ZOG Global",
    description: "Explore our effective engagement model for your business: tailored solutions, collaborative processes, and innovation for success.",
    keywords: "Engagement Model",
  },

  "IT Outsourcing": {
    title: "IT Outsourcing Company UK - ZOG Global",
    description: "ZOG Global is a leading IT outsourcing company in the UK, providing comprehensive outsourced IT support to organisations, ensuring optimised business operations.",
    keywords: "IT Outsourcing Company, IT Outsourcing Services",
  },

  "Digital Transformation": {
    title: "Digital Transformation Solutions | Drive Innovation with ZOG Global",
    description: "Lead the digital revolution with our cutting-edge digital transformation solutions. Enhance efficiency and drive innovation for sustained growth.",
    keywords: "Digital Transformation Solutions",
  },

  "Cyber Security": {
    title: "Cyber Security Services | Protect Your Business with ZOG Global",
    description: "Stay ahead of cyber threats with ZOG Global's comprehensive cyber security services. Safeguard your business and maintain robust security.",
    keywords: "Cyber Security Services, Cyber Security Solutions",
  },

  "Cloud Computing": {
    title: "Cloud Computing Solutions | Scalable & Secure Cloud Services",
    description: "Elevate your business with scalable and secure cloud computing solutions. ZOG Global offers expert cloud services tailored to your needs.",
    keywords: "Cloud Computing Solutions, Cloud Services",
  },

  "a-i": {
    title: "AI Solutions | Transform Your Business with Artificial Intelligence",
    description: "Leverage the power of artificial intelligence to drive innovation and streamline operations. Explore our AI solutions for a competitive edge.",
    keywords: "Artificial Intelligence Solutions, AI Solutions",
  },

  "rpa": {
    title: "Automation and Robotics | RPA Services",
    description: "Our Robotic Process Automation (RPA) services are designed to automate business processes and streamline repetitive tasks for enhanced productivity.",
    keywords: "Automation and Robotics",
  },



  Insight: {
    title: "Explore Tech Insights",
    description: "Dive into the latest tech insights. Stay ahead in the fast-paced world of technology. Explore, learn, and innovate with us.",
    keywords: "Tech Insights",
  },
  Blog: {
    title: "Explore Our Top Tech Blogs | Unlocking the Latest in IT",
    description: "Stay updated on the latest tech blogs covering Cybersecurity, Artificial Intelligence, Automation, Cloud Computing, Quality Assurance, and more.",
    keywords: "Tech Blogs, IT Blogs",
  },
  "Be A Partner": {
    title: "Be A Partner - ZOG Global",
    description: "Unlock growth opportunities and elevate your business to new heights. Explore the benefits of becoming a partner with us. Be a part of success today!",
    keywords: "Be A Partner",
  },
  Career: {
    title: "Explore IT Careers at ZOG Global | Remote Jobs",
    description: "Discover exciting IT careers at ZOG Global! We embrace a remote work culture. Join a dynamic team passionate about technology. Check our latest vacancies.",
    keywords: "IT Careers",
  },
  Contact: {
    title: "Contact Us | IT Services Company UK - ZOG Global",
    description: "Connect with the best IT services company in Darlington, UK. Enhance your business with our tailored solutions. Contact us today for expert assistance.",
    keywords: "IT Services Company, IT Services UK",
  }
};
