// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.be-a-partner-page .sec-2 {
  width: 976px;
  margin: 0 auto;
}
.be-a-partner-page .sec-3 {
  width: 976px;
  margin: 0 auto;
}
.be-a-partner-page .sec-4 {
  width: 976px;
  margin: 0 auto;
}
.be-a-partner-page .sec-5 {
  width: 976px;
  margin: 0 auto;
}
.be-a-partner-page .sec-6 {
  width: 976px;
  margin: 0 auto;
}
.be-a-partner-page .sec-7 {
  width: 976px;
  margin: 0 auto;
}
.be-a-partner-page .sec-8 {
  width: 976px;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/user-panel/pages/BeAPartner/BeAPartner.scss"],"names":[],"mappings":"AAGE;EACE,YAAA;EACA,cAAA;AAFJ;AAOE;EACE,YAAA;EACA,cAAA;AALJ;AAWE;EACE,YAAA;EACA,cAAA;AATJ;AAgBE;EACE,YAAA;EACA,cAAA;AAdJ;AAkBE;EACE,YAAA;EACA,cAAA;AAhBJ;AAoBE;EACE,YAAA;EACA,cAAA;AAlBJ;AAsBE;EACE,YAAA;EACA,cAAA;AApBJ","sourcesContent":[".be-a-partner-page {\r\n \r\n\r\n  .sec-2 {\r\n    width: 976px;\r\n    margin: 0 auto;\r\n\r\n\r\n  }\r\n\r\n  .sec-3 {\r\n    width: 976px;\r\n    margin: 0 auto;\r\n\r\n\r\n\r\n  }\r\n\r\n  .sec-4 {\r\n    width: 976px;\r\n    margin: 0 auto;\r\n\r\n\r\n\r\n\r\n  }\r\n\r\n  .sec-5 {\r\n    width: 976px;\r\n    margin: 0 auto;\r\n\r\n  }\r\n\r\n  .sec-6 {\r\n    width: 976px;\r\n    margin: 0 auto;\r\n\r\n  }\r\n\r\n  .sec-7 {\r\n    width: 976px;\r\n    margin: 0 auto;\r\n\r\n  }\r\n\r\n  .sec-8 {\r\n    width: 976px;\r\n    margin: 0 auto;\r\n\r\n  }\r\n}\r\n\r\n@media (max-width: 1005px) {\r\n  .be-a-partner-page {\r\n   \r\n  }\r\n}\r\n\r\n\r\n@media (max-width:767px) {\r\n  .be-a-partner-page {\r\n    \r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
