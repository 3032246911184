import React, { useEffect, useState,useRef } from 'react'
import './ANS.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'
import GreyCardsSection from '../../common-component/GreyCardsSection/GreyCardsSection.js'
import GreySliderSection from '../../common-component/GreySliderSection/GreySliderSection.js'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'

import StatisticsSection from '../../common-component/StatisticsSection/StatisticsSection.js'
import DiamondSection from '../../common-component/DiamondSection/DiamondSection.js'

import { useNavigate } from 'react-router-dom';

import API from "../../../API.js"
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import Slider from 'react-slick';
import BlueSliderSection from '../../common-component/BlueSliderSection/BlueSliderSection.js'




const ANS = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


 



  const sec2Data = [
    {
      title: "Future-Ready Solutions",
      description: "Optimised to support digital transformation and offer a robust solution for current and future needs."
    },

    {
      title: "Dedicated Professionals",
      description: "A team consists of expert professionals with a dedication to excellence, ensuring customer satisfaction."
    },

    {
      title: "Innovative Efficiency",
      description: "Boost innovation, maximise efficiency, and provide exceptional convenience while ensuring secure operations."
    },
    {
      title: "Value-Driven Deployment",
      description: "We will help you identify the actual value of SD-WAN for your organisation and work with you to deliver your needs."
    }

  ]

  const sec5Data = [
    {
      text: "Resilience Enhancement",
      leftSign: "",
      number: "85",
      rightSign: "%",
    },

    {
      text: "Transfer Acceleration",
      leftSign: "",
      number: "72",
      rightSign: "%",
    },
    {
      text: "Scalability Surge",
      leftSign: "",
      number: "60",
      rightSign: "%",
    },
    {
      text: "Security Enhancement",
      leftSign: "",
      number: "48",
      rightSign: "%",
    },


  ]
  const sec6Data = [
    {
      title: "Amplify Workforce Engagement",
      description: "Evolve the technology and give people a workplace experience that meets their expectations.",
    },

    {
      title: "Rapid Response Management",
      description: "Give customers what they need when they need it and create new customer touch points quickly.",
    },
    {
      title: "Enhance Work Efficiency",
      description: "Give employees the freedom to work anywhere and connect your application fast and securely.",
    },
    {
      title: "Strengthen Network Dynamics",
      description: "Refine your current infrastructure to fasten the digital transformation with increased ROI.",
    },



  ]


  const sec7Data = [
    {
      title: "Trädmord infodemi dekaledes",
      auther: "By John Doe",
      date: "Jan 30, 2024",
      department: "Banking, UI/UX"
    },
    {
      title: "Trädmord infodemi dekaledes",
      auther: "By John Doe",
      date: "Jan 30, 2024",
      department: "Banking, UI/UX"
    },
    {
      title: "Trädmord infodemi dekaledes",
      auther: "By John Doe",
      date: "Jan 30, 2024",
      department: "Banking, UI/UX"
    },
    {
      title: "Trädmord infodemi dekaledes",
      auther: "By John Doe",
      date: "Jan 30, 2024",
      department: "Banking, UI/UX"
    },
    {
      title: "Trädmord infodemi dekaledes",
      auther: "By John Doe",
      date: "Jan 30, 2024",
      department: "Banking, UI/UX"
    },


  ]


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
      
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
    ]

  };

  const [isToggled, setIsToggled] = useState(false);
  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };
  const sliderRef2=useRef(null)

  const next2 = () => {
    sliderRef2.current.slickNext();
  };
  
  const previous2 = () => {
    sliderRef2.current.slickPrev();
  };
  
  return (
    <div className='ans-page'>

      <BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"ans"}
        blueTitle={"Advanced Networking Services"}
        titleWeb={"Transform Connectivity<br/> Maximise Efficiency"}
        titleTab={"Transform<br/> Connectivity Maximise<br/> Efficiency"}
        titleMob={"Transform<br/> Connectivity<br/> Maximise<br/> Efficiency"}
        subTitleWeb={"Streamlining networks for optimal<br/> performance and efficiency."}
        subTitleTab={"Streamlining networks for optimal<br/> performance and efficiency."}
        subTitleMob={"Streamlining networks for optimal<br/> performance and efficiency."}
        iconTopWeb={'47%'}
        iconLeftWeb={'73%'}
        iconTopTab={'62%'}
        iconLeftTab={'69%'}
        iconTopMob={'65%'}
        iconLeftMob={'73%'}
        iconRotationWeb={90}
        iconRotationTab={30}
        iconRotationMob={10}>
      </BannerSubPages>


      <GreySliderSection
        pageName={"ans"}
        secNo={"2"}
        headingWeb={"Why SD-WAN from ZOG Global?"}
        headingTab={"Why SD-WAN from ZOG Global?"}
        headingMob={"Why SD-WAN from ZOG Global?"}
        data={sec2Data}
      />


      

      <DiamondSection
        pageName={'ans'}
        secNo={'3'}
        theme={"light"}
        headingBoxPresent={false}



        subHeadding1={"Reliable Network with Outstanding Efficiency"}
        description1={"The cloud has transformed how we do business, and SD-WAN is a cost-effective way to keep up with the demand for cloud-based applications. SD-WAN can increase bandwidth, boost performance, and quickly add new locations and services, making it an essential tool for businesses that rely on the cloud."}
        subHeadding2={"Enhance Any Application to Any User Over Any Network"}
        description2={"No amount of bandwidth can address the performance impact of latency - but ZOG Global's SaaS accelerator can increase app performance by up to 10x. With WAN optimisation, cloud acceleration, SaaS acceleration, and client acceleration, ZOG Global delivers end-to-end application performance."}
        buttonText2={"Learn More"}
        buttonLink2={"/"}
      />
      <StatisticsSection data={sec5Data} background={"linear-gradient(180deg, #000022 0%, #000022 100%)"} />



      <BlueSliderSection
        pageName={'ans'}
        headingWeb={"Transform The Way You Connect"}
        headingTab={"Transform The Way<br/> You Connect"}
        headingMob={"Transform The Way You Connect"}
        descriptionWeb={"We empower flexible work environments, enabling seamless connections for improved productivity."}
        descriptionTab={"We empower flexible work environments, enabling seamless connections for improved productivity."}
        descriptionMob={"We empower flexible work environments, enabling seamless connections for improved productivity."}
        cardsData={sec6Data}

      />

<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>




      <BottomBanner
        pageName={"ans"}
        titleWeb={"Upgrade Your<br/> Network Today"}
        titleTab={"Upgrade Your<br/> Network Today"}
        titleMob={"Upgrade Your<br/> Network Today"}

        subTitlePresent={true}
        subTitleWeb={"Navigate the future with SDN and SD-WAN solutions."}
        subTitleTab={"Navigate the future with SDN<br/> and SD-WAN solutions."}
        subTitleMob={"Navigate the future with SDN and<br/> SD-WAN solutions."}

        buttonText={"talk to our experts"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default ANS