import React,{useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import API from '../../../API';
import ErrorModal from "../../../ErrorModal";
import PositiveModal from "../../../PositiveModal";
import DeleteConfirmModal from "../../../DeleteConfirmModal";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner"
import $ from 'jquery';

import './JobPreview.scss';
import { change24to12Hr, getFontSizeFactor, isRichTextEmpty, renderDescription } from "../../common-functions/commonFunctions"
import RichTextEditor from '../RichText/RichText';


const JobPreview = ({ title, state, setterFunction, okClickedFunction,formData ,setFormData,jobLocations, currencySymbol,editMode }) => {

    const okClicked=()=>{
        okClickedFunction();
        setterFunction(false)
    }

    let navigate = useNavigate();
    const loadPage = () => {
      navigate(window.location.pathname);
    };
    

    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const [isAboutEditing, setIsAboutEditing] = useState(false);
    const [isPolicyEditing, setIsPolicyEditing] = useState(false);
    
    const handleAboutChange = (section_id, content) => {
        setFormData((formData) => ({
          ...formData,
          about_zog: content
        }));
      };
    const handlePolicyChange = (section_id, content) => {
        setFormData((formData) => ({
            ...formData,
            disclaimer: content
        }));
    };

    const [errors, setErrors] = useState({});
    const validateForm = (data) => {
        const errors = {};
        if (!data.about_zog.trim() || (isRichTextEmpty && isRichTextEmpty(data.about_zog))) {
            errors.about_zog = "Add about zog section";
        }
        if (!data.disclaimer.trim() || (isRichTextEmpty && isRichTextEmpty(data.disclaimer))) {
        errors.disclaimer = "Add disclaimer/policy section";
        }

        return errors;
    }

  

    const handlePublish =  (isPublished) => {
        const validationErrors = validateForm(formData);
        setErrors(validationErrors);
        
        // console.log(validationErrors);
        if (Object.keys(validationErrors).length === 0) {


            formData.is_published = isPublished;
            const apiCall = editMode
                ? API.put(`/recruitment/jobs/${localStorage.getItem("itemSelectedId")}/`, formData)
                : API.post(`/recruitment/jobs/`, formData);

            // console.log(formData);
            apiCall.then(response => {
                setIsLoading(false);
                setMessage(isPublished ? 'Job has been published.' : 'Job saved as draft.');
                setIsMessageModalOpen(true);
            })
            .catch(error => {
                console.log("error",error )
                setMessage(error.message || "An error occurred.");
                setIsErrorModalOpen(true);
                setIsLoading(false)
            });
        }
        // setIsLoading(true);
        
    }
   
    // console.log(formData);

  return (
    <>
    <div className='custom-modal job-preview'>
        <div className='card'>
            <div className='close-btn' >
                <button  onClick={okClicked}>
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14.999 15.0005L44.999 45.0005" stroke="#263238" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M45.001 15.0005L15.001 45.0005" stroke="#263238" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
            <div className='lhs'>
                <h1>Publish Post</h1>
                <div className=' text-input'>
                    <label>Job ID</label>
                    <input type='text' value={'ZGUKS15201'} id='jobid' className='form-control' disabled={true} />
                </div>
                <div className='rich-text'>
                    <span className='title' onClick={()=>setIsAboutEditing(!isAboutEditing)}>
                        <label>About Zog</label>
                        <div className='edit-btn' onClick={()=>setIsAboutEditing(!isAboutEditing)}>
                            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.0625 36.7501L3.16724 24.2473L22.2153 5.19922L31.6134 14.5974L12.5654 33.6454L0.0625 36.7501ZM5.34896 25.422L3.33506 33.4776L11.3906 31.4637L28.3409 14.5135L22.2992 8.47178L5.34896 25.422Z" fill="#5C6366"/>
                                <path d="M10.5514 32.8897C9.7123 29.6171 7.19493 27.0998 3.92236 26.2607L4.50976 23.9111C8.62145 24.9181 11.8101 28.1067 12.817 32.2184L10.5514 32.8897Z" fill="#5C6366"/>
                                <path d="M10.2998 28.2751L8.62158 26.5129L25.3201 9.81445L26.9983 11.4927L10.2998 28.2751Z" fill="#5C6366"/>
                                <path d="M1.74072 35.072L5.43285 34.149C5.0972 32.8064 4.00634 31.7155 2.66375 31.3799L1.74072 35.072Z" fill="#5C6366"/>
                                <path d="M32.5366 13.6742L23.1384 4.27604L26.9145 0.5L27.5019 0.583917C32.0331 1.1713 35.6413 4.77951 36.2287 9.31076L36.3126 9.89816L32.5366 13.6742ZM26.5788 4.27604L32.6205 10.3177L33.7953 9.14295C33.124 6.12212 30.7744 3.77257 27.7536 3.10128L26.5788 4.27604Z" fill="#5C6366"/>
                            </svg>
                        </div>
                    </span>
                    <div className={isAboutEditing ? "" : "hidden"}>
                        <RichTextEditor sectionId={"about_zog"} onContentChange={handleAboutChange} 
                        {...(editMode && formData.about_zog !== '' && { initialContent: formData.about_zog })}
                        />
                    </div>
                    {errors.about_zog && <div className="invalid-feedback">{errors.about_zog}</div>}
                </div>
                <div className='rich-text'>
                    <span className='title' onClick={()=>setIsPolicyEditing(!isPolicyEditing)}>
                        <label>Disclaimer / Policy Statements</label>
                        <div className='edit-btn'>
                            <svg width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.0625 36.7501L3.16724 24.2473L22.2153 5.19922L31.6134 14.5974L12.5654 33.6454L0.0625 36.7501ZM5.34896 25.422L3.33506 33.4776L11.3906 31.4637L28.3409 14.5135L22.2992 8.47178L5.34896 25.422Z" fill="#5C6366"/>
                                <path d="M10.5514 32.8897C9.7123 29.6171 7.19493 27.0998 3.92236 26.2607L4.50976 23.9111C8.62145 24.9181 11.8101 28.1067 12.817 32.2184L10.5514 32.8897Z" fill="#5C6366"/>
                                <path d="M10.2998 28.2751L8.62158 26.5129L25.3201 9.81445L26.9983 11.4927L10.2998 28.2751Z" fill="#5C6366"/>
                                <path d="M1.74072 35.072L5.43285 34.149C5.0972 32.8064 4.00634 31.7155 2.66375 31.3799L1.74072 35.072Z" fill="#5C6366"/>
                                <path d="M32.5366 13.6742L23.1384 4.27604L26.9145 0.5L27.5019 0.583917C32.0331 1.1713 35.6413 4.77951 36.2287 9.31076L36.3126 9.89816L32.5366 13.6742ZM26.5788 4.27604L32.6205 10.3177L33.7953 9.14295C33.124 6.12212 30.7744 3.77257 27.7536 3.10128L26.5788 4.27604Z" fill="#5C6366"/>
                            </svg>
                        </div>
                        
                    </span>
                   
                    <div className={isPolicyEditing ? "text-container" : "hidden"}>
                        <RichTextEditor sectionId={"policy"} onContentChange={handlePolicyChange} 
                        {...(editMode && formData.disclaimer !== '' && { initialContent: formData.disclaimer })}/>
                    </div>
                    {errors.disclaimer && <div className="invalid-feedback">{errors.disclaimer}</div>}
                </div>
                <div className='submit-btns'>
                    <button type='button' onClick={() => handlePublish(true)} className='admin-blue-btn'>Publish Job</button>
                    <button type='button' onClick={() => handlePublish(false)} className='admin-normal-btn'>Save to Drafts</button>
                </div>
            </div>
            <div className='rhs'>
                <div className='row details' >
                    <div className=' left-side'>
                        
                        <div className=' detail'>
                        <div className="data-key p-0">Job ID</div>
                        <div className="data-value p-0 job-id">#ZG123456</div>
                        </div>
                        <div className="detail">
                        <div className="data-key p-0">Job Type</div>
                        <div className="data-value p-0 job-type">{formData.type}</div>
                        </div>
                        <div className="detail">
                        <div className="data-key p-0">Salary Range</div>
                        <div className="data-value salary p-0">
                            {currencySymbol} {formData.salary_range_from} - {formData.salary_range_to} 
                            <span className="salary-btn">
                                {formData.is_salary_negotiable ? "Negotiable" : "Fixed"}
                            </span>
                        </div>
                        </div>
                        <div className="detail">
                        <div className="data-key p-0">Working Hours</div>
                        <div className="data-value p-0">
                            <span className='text-lowercase'>{change24to12Hr(formData.working_hours_from)} - {change24to12Hr(formData.working_hours_to)}</span> BST</div>
                        </div>
                        <div className="detail">
                        <div className="data-key p-0">Location</div>
                        <div className="data-value p-0">
                        {jobLocations.find(location => location.id === formData.location)?.name}
                        </div>
                        </div>
                            
                        
                    </div>

                    <div className=' right-side'>

                        <div className='detail job-role'>
                        <h3>Your Role</h3>
                        {formData.job_roles.map((item, index) => (
                            <p key={index} className='role'>{item}</p>
                        ))}
                        
                        </div>

                        <div className='detail skill'>  
                        <h3>Required Skillset</h3>
                        <div className='skillset'>
                            <p className='req-skill'>Required Skills</p>
                            <p>{formData.skills.map(skill => skill).join(', ')}</p>
                        </div>
                        <p className='skill-descr' dangerouslySetInnerHTML={{ __html: renderDescription(formData.skill_description,getFontSizeFactor()) }} /> 
                        </div>
                        
                        <div className='detail about'>  
                        <h3>About Us</h3>
                        <p dangerouslySetInnerHTML={{ __html: renderDescription(formData.about_zog,getFontSizeFactor()) }} />
                        </div>
                        <div className='detail policy'>  
                        <h3>Disclaimer / Policy Statements</h3>
                        <p dangerouslySetInnerHTML={{ __html: renderDescription(formData.disclaimer,getFontSizeFactor()) }} />
                        </div>
                    </div>
                </div>
            </div>

            
            
        
        </div>
            
        
    </div>
    {isLoading && <FixedOverlayLoadingSpinner />} 
    <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={loadPage} />
    {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={()=> navigate('/admin/jobs/')} />}
    </>
  );
};

export default JobPreview;