import React, { useEffect, useRef, useState} from 'react'
import './Banking.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages'
import DiamondSection from '../../common-component/DiamondSection/DiamondSection.js'
import SpecialSliderSection from "../../common-component/SpecialSliderSection/SpecialSliderSection"
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'


import { useNavigate} from 'react-router-dom';

import API from "../../../API.js"
import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import Slider from 'react-slick'



const Banking = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blogData, setBlogData] = useState(null);


  useEffect(() => {
    setIsLoading(true)
    API.get("blogs/?page_name=generic&section_number=1")
      .then((response) => {
        setBlogData(response.data)
        setIsLoading(false)

      })
      .catch((error) => {
        setMessage(error.data.message);
        setIsErrorModalOpen(true)
        setIsLoading(false)

      });
  }, [])

  
  const sec3Data = [
    { title: "Risk Assessment", description: "We execute thorough analyses to identify vulnerabilities, assess potential threats, and recommend risk mitigation strategies." },
    { title: "Data Protection", description: "We deploy advanced measures to safeguard your sensitive data, ensuring compliance and protecting against unauthorised access." },
    { title: "Network Security", description: "Our expert solutions fortify your networks against cyber threats, prioritising confidentiality, integrity, and availability." },
    { title: "Security Testing", description: "We conduct meticulous testing to uncover system vulnerabilities, fortifying your defences against potential cyber-attacks." }
  ]

  const sec4Data = [
    {
      title: "Minimising Customer Turnover",
      description: "Enhance customer satisfaction and loyalty through personalised solutions, proactive communication, and effective loyalty programs, reducing turnover rates.",
    },
    {
      title: "Fraud Detection & Prevention",
      description: "Safeguard customers and institutions from fraudulent activities by employing advanced algorithms, real-time monitoring systems, and robust biometric authentication methods.",
    },
    {
      title: "Regulatory Compliance Management",
      description: "Mitigate risks and assure adherence to regulatory requirements by utilising compliance software, conducting regular audits, and providing comprehensive staff training.",
    },
    {
      title: "Managing Legacy Systems",
      description: "Overcome limitations of legacy systems by embracing modernisation strategies such as cloud migration, technology integration, and adopting agile methodologies.  ",
    },
    {
      title: "Optimising Operational Efficiency",
      description: "Streamline processes and reduce costs by implementing automation, digitisation, and workflow optimisation tools, enhancing overall operational efficiency.",
    },



  ]

  
  const sec5Data=[
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    {
      title:"Trädmord infodemi dekaledes",
      auther:"By John Doe",
      date:"Jan 30, 2024",
      department:"Banking, UI/UX"
    },
    
    
  ]


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  useEffect(() => {

  }, [])

  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };








  
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentSlide(index)
  };

  const sliderRef=useRef(null)
  
    const next = () => {
      sliderRef.current.slickNext();
  };
  
  const previous = () => {
      sliderRef.current.slickPrev();
  };

  const getImageSrc = (index) => {
    switch (index) {
      case 0:
        return "/images/banking/sec-3-1-tab.png";
      case 1:
        return "/images/banking/sec-3-2-web.png";
      case 2:
        return "/images/banking/sec-3-3-tab.png";
      case 3:
        return "/images/banking/sec-3-4-tab.png";
      default:
        return "/images/banking/sec-3-1-tab.png";
    }
  };
  

  useEffect(() => {
    const imgs = document.querySelectorAll(".img-mark");
  
    if (imgs.length === 0) {
      // Exit if there are no images found
      return;
    }
  
    imgs.forEach((img, index) => {
      if (index === currentSlide) {
        img.classList.add("active");
      } else {
        img.classList.remove("active");
      }
    });
  }, [currentSlide]);













  return (
    <div className='banking-page'>

<BannerSubPages
        imglink={"/images/Frame 36428.png"}
        pageName={"banking"}
        blueTitle={"Banking & Financial Services"}
        titleWeb={"Secure your<br/>Financial Future"}
        titleTab={"Secure your<br/> Financial Future"}
        titleMob={"Secure your<br/> Financial Future"}
        subTitleWeb={"Modernise and secure your banking and<br/> financial operations with our services."}
        subTitleTab={"Modernise and secure your banking and<br/> financial operations with our services."}
        subTitleMob={"Modernise and secure your<br/> banking and financial<br/> operations with our services."}
        iconTopWeb={'15%'}
        iconLeftWeb={'89%'}
        iconTopTab={'51%'}
        iconLeftTab={'73%'}
        iconTopMob={'68%'}
        iconLeftMob={'59%'}
        iconRotationWeb={5}
        iconRotationTab={12}
        iconRotationMob={202}>
      </BannerSubPages>

      {/* <DiamondSection
        pageName={'banking'}
        secNo={'2'}
        theme={"light"}
        headingBoxPresent={false}
    
        subHeadding1={"Sustainability in Financial Services"}
        description1={"A sustainable world needs sustainable finance. We are helping to build a better financial services industry that is stronger, fairer, and more sustainable by helping organisations create value, accelerate the transition and make a measurable difference."}
        subHeadding2={"Solve your Security Challenges"}
        description2={"At ZOG Global, we offer dynamic solutions for your security challenges, ensuring comprehensive protection for your organisation's digital assets. Explore our cybersecurity expertise today for a robust defence."}
      /> */}







<div className='img-sec2-itoutsource'>


<div className='for-img'>
    <img src="\images\banking\sec-2.png"/>

  </div>
 

  <div className='for-text'>
    <span>Sustainability in Financial Services</span>
    <p>A sustainable world needs sustainable finance. We are helping to build a better financial services industry that is stronger, fairer, and more sustainable by helping organisations create value, accelerate the transition and make a measurable difference.</p>

  </div>

  

</div>







<div className='img-sec2-itoutsource'>

 

  <div className='for-text'>
    <span>Solve your Security Challenges</span>
    <p>At ZOG Global, we offer dynamic solutions for your security challenges, ensuring comprehensive protection for your organisation's digital assets. Explore our cybersecurity expertise today for a robust defence.</p>

  </div>

  <div className='for-img'>
    <img src="\images\banking\sec-2-2.png"/>

  </div>

</div>










{/* <SpecialSliderSection 
      pageName={"banking"}
      secNo={"3"}
      
      headingBoxPresent={true}
      headingWeb={"Comprehensive<br/> Cybersecurity Solutions"}
      headingTab={"Comprehensive Cybersecurity Solutions"}
      headingMob={"Comprehensive<br/> Cybersecurity<br/> Solutions"}
      descriptionWeb={"Our security solutions ensure that your digital assets are safeguarded against<br/> unauthorised access, breaches, and cyber-attacks, allowing you to focus on your core<br/> business objectives confidently."}
      descriptionTab={"Our security solutions ensure that your digital assets are safeguarded against unauthorised access, breaches, and cyber-attacks, allowing you to focus on your core business objectives confidently."}
      descriptionMob={"Our security solutions ensure that your digital assets are safeguarded against unauthorised access, breaches, and cyber-attacks, allowing you to focus on your core business objectives confidently."}
      boxAlignment={"center"}
      
      subTitle={""}
      data={sec3Data} 
      /> */}






<div className='white-head'>
  <div className='headfont'>Comprehensive Cybersecurity Solutions</div>
  <p>Our security solutions ensure that your digital assets are safeguarded against unauthorised access, breaches, and cyber-attacks, allowing you to focus on your core business objectives confidently.</p>
  
</div>




<div className='complex-slider'>
  <div className='width50'>
    <div className='arow-cont'>
    <img src="\images\healthcare\arrow-cs.svg" onClick={previous}/>
<img src="\images\healthcare\arrow2-cs.svg" onClick={next}/>
</div>
  <div className="slider-container">
      <Slider {...settings} ref={sliderRef}>
        <div>
        <div className='card'>
          <span className='montserrat-hard'>Risk Assessment
          </span>
          <p className='montserrat-lit '>
          We execute thorough analyses to identify vulnerabilities, assess potential threats, and recommend risk mitigation strategies.

          </p>
        </div>
        </div>
        
        <div>
        <div className='card'>
          <span className='montserrat-hard'> Data Protection
          </span>
          
          <p className='montserrat-lit '>
          We deploy advanced measures to safeguard your sensitive data, ensuring compliance and protecting against unauthorised access.

          </p>
        </div>
        </div>

        <div>
        <div className='card'>
          <span className='montserrat-hard'>
          Network Security

          </span>
          <p className='montserrat-lit '>
Our expert solutions fortify your networks against cyber threats, prioritising confidentiality, integrity, and availability.
</p>
        </div>
        </div>

        <div>
        <div className='card'>
          <span className='montserrat-hard'>Security Testing
       </span>
          <p className='montserrat-lit '>
          We conduct meticulous testing to uncover system vulnerabilities, fortifying your defences against potential cyber-attacks.

          </p>
        </div>
        </div>
        
      </Slider>
    </div>

  </div>
  <div className='width50-2'>
  
 
  {sec3Data.map((_, index) => (
    <img key={index} className='img-mark' src={getImageSrc(index)} alt="Healthcare" />
  ))}
    
    </div>
</div>









<div className='pink-card montserrat-lit'>

<div className='head1'>
<div className='texthed'>How we Address your Challenges</div>
<p></p>
</div>

<div className='head2'>
<div className='each-sec'>
<span>Minimising Customer Turnover</span>
<p>Enhance customer satisfaction and loyalty through personalised solutions tailored to individual preferences. Implement proactive communication that addresses concerns promptly and effective loyalty programs that reward frequent patrons, thereby lowering turnover rates effectively.</p>
</div>

<div className='each-sec'>
<span>Fraud Detection & Prevention
</span>
<p>
Safeguard customers and institutions from fraudulent activities with advanced algorithms that detect anomalies in real-time. Utilise robust biometric authentication methods ensuring secure transactions and continuous monitoring systems that promptly flag suspicious activities for immediate action.
</p>
</div>

<div className='each-sec'>
<span>Regulatory Compliance Management
</span>
<p>
Mitigate risks and ensure adherence to regulatory requirements by leveraging compliance software for streamlined reporting. Conduct regular audits to identify gaps and provide comprehensive staff training on compliance protocols and updates, ensuring robust adherence.

</p>
</div>

<div className='each-sec'>
<span>
Managing Legacy Systems
</span>
<p>
Overcome limitations of legacy systems by migrating to the cloud for scalability and accessibility. Integrate new technologies to enhance functionality and adopt agile methodologies for quicker updates and improved system responsiveness.
</p>
</div>
<div className='each-sec'>
<span>Optimising Operational Efficiency</span>
<p>
Streamline processes and reduce costs through automation tools that simplify repetitive tasks. Digitise manual processes for faster execution and implement workflow optimisation strategies that enhance overall efficiency across all operational facets.
</p>
</div>


</div>
</div>

















{/* 

      <BlueNotchSection
        headingWeb={"How we Address your Challenges"}     // <br/>
        headingTab={"How we Address<br/> your Challenges"}
        headingMob={"How we<br/> Address your<br/> Challenges"}
        descriptionPresent={false}
        descriptionWeb={""}
        descriptionTab={""}
        descriptionMob={""}
        listData={sec4Data}

      /> */}







<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>




      <div className='sec-6'>

      </div>


      <div className='sec-7'>

      </div>




      <BottomBanner
        pageName={"banking"}
        titleWeb={"Looking for a<br/> Reliable IT Partner?"}
        titleTab={"Looking for a<br/> Reliable IT Partner?"}
        titleMob={"Looking for a<br/> Reliable IT Partner?"}
                
        subTitlePresent={true}
        subTitleWeb={"Connect with us for secure<br/> banking and finance solutions."}
        subTitleTab={"Connect with us for secure<br/> banking and finance solutions."}
        subTitleMob={"Connect with us for secure<br/> banking and finance solutions."}

        buttonText={"talk to our experts"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>



      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default Banking