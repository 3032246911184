import React, { useEffect, useRef, useState } from 'react'
import './ProtectYourCustomersData.scss'
import BannerSubPages from '../../common-component/BannerSubPages/BannerSubPages.js'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner.js'
import GreyCardsSection from '../../common-component/GreyCardsSection/GreyCardsSection.js'
import VioletCardsSection from '../../common-component/VioletCardsSection/VioletCardsSection'
import AccordionSection from '../../common-component/AccordionSection/AccordionSection.js'
import BlogNewSection from "../../common-component/BlogNewSection/BlogNewSection.js"
import { useNavigate } from 'react-router-dom';

import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';
import Slider from 'react-slick'



const ProtectYourCustomersData = () => {
  const navigate = useNavigate();


  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);





  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


  useEffect(() => {

  }, [])


  const sec2Data = [
    {
      title: "Tailored Compliance",
      description: "Tailoring data protection strategies ensures compliance with legal standards and industry norms, seamlessly integrating with operations while addressing organisational nuances.",
    },

    {
      title: "Customised Solutions",
      description: "We personalise data protection with thorough assessments, crafting strategies to integrate seamlessly with your existing infrastructure for optimal security.",
    },
    {
      title: "Expert Guidance",
      description: "Collaborate with ZOG Global for expert data protection. Our team offers comprehensive support and valuable insights for informed decision-making from consultation to maintenance.",
    },
    {
      title: "Advanced Threat Detection",
      description: "We use advanced tech and algorithms for proactive threat detection, neutralising emerging risks in real-time and ensuring robust defence against cyber threats.",
    },
    {
      title: "Ongoing Monitoring",
      description: "We ensure ongoing data security with vigilant monitoring, swiftly addressing irregularities for safeguarding and compliance and maintaining operational integrity post-implementation.",
    },
    {
      title: "Customer Trust Assurance",
      description: "Through robust data protection measures and compliance, we bolster trust, strengthen relationships, and boost brand credibility, fostering customer loyalty.",
    },


  ]

  const sec3Data = [
    {
      title: "Public Sector",
      description: "Protect valuable commercial data with our tailored solutions. From financial institutions to retail, our services fortify data integrity, ensuring compliance and enhancing trust among customers, shareholders, and stakeholders.",
    },
    {
      title: "SMEs",
      description: "Empower small and medium enterprises with robust data security. Our scalable solutions cater to SMEs' unique needs, providing cost-effective yet comprehensive protection, ensuring compliance, and fostering growth without compromising security.",
    },
    {
      title: "Multinationals",
      description: "Secure vast multinational operations with our globally compliant strategies. We align data protection across borders, ensuring regulatory adherence while fortifying sensitive information, bolstering trust, and mitigating risks on a global scale.",
    },



  ]


  const sec4Data = [
    {
      title: "Risk Assessment",
      description: "Identify vulnerabilities and evaluate potential risks within your data infrastructure. Our comprehensive analysis provides insights to fortify defences, ensuring proactive mitigation strategies against potential threats.",
    },
    {
      title: "Encryption Solutions",
      description: "Implement robust encryption protocols to secure sensitive data. Our encryption services ensure that your information remains unintelligible to unauthorised users, fortifying your data against breaches or unauthorised access.",
    },
    {
      title: "Data Privacy Consulting",
      description: "Rely on our expertise to navigate complex data privacy regulations. Our consulting services offer guidance in aligning your practices with legal frameworks, ensuring compliance and fostering a privacy-centric culture within your organisation.",
    },
    {
      title: "Incident Response Planning",
      description: "Prepare for potential data breaches with our incident response planning. We develop tailored strategies to respond to security incidents swiftly, minimising their impact and ensuring a timely and effective resolution.",
    },
    {
      title: "Continuous Monitoring",
      description: "Our vigilant monitoring services offer real-time oversight of your data environment. Through continuous surveillance, we detect anomalies and potential threats, enabling proactive intervention to maintain data integrity and compliance.",
    },
    {
      title: "Access Control Solutions",
      description: "Implement robust access control measures to manage data accessibility. Our solutions ensure that only authorised personnel can access specific information, reducing the risk of internal breaches or unauthorised data manipulation.",
    },
    {
      title: "Compliance Audits",
      description: "Validate your adherence to data protection regulations through our compliance audits. We conduct thorough assessments, identify gaps, and recommend corrective measures to ensure ongoing compliance and minimise legal risks."

    }


  ]
  const sec5SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
      
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          centerMode: true,
          centerPadding: '0px',
          infinite: true,
          dots: false,

        },
      },
    ]

  };



  const sliderRef=useRef(null)
  
    const next = () => {
      sliderRef.current.slickNext();
  };
  
  const previous = () => {
      sliderRef.current.slickPrev();
  };

  const [isToggled, setIsToggled] = useState(false);
  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (index) => {
    setActiveSection(activeSection === index ? null : index);
  };

  const [content1, setContent1]=useState(false);
   
  const cont1btn=()=>{
    setContent1(!content1);
  }



  const sections = [
    { title: 'Risk Assessment', content: 'Identify vulnerabilities and evaluate potential risks within your data infrastructure. Our comprehensive analysis provides insights to fortify defences, ensuring proactive mitigation strategies against potential threats.',path:"/images/protect-your-customers-data/Rectangle 185.png" },
    { title: 'Encryption Solutions', content: 'Implement robust encryption protocols to secure sensitive data. Our encryption services ensure that your information remains unintelligible to unauthorised users, fortifying your data against breaches or unauthorised access.', path:"/images/home/content-dropdown/Rectangle 186.png" },
    { title: 'Data Privacy Consulting', content: 'Rely on our expertise to navigate complex data privacy regulations. Our consulting services offer guidance in aligning your practices with legal frameworks, ensuring compliance and fostering a privacy-centric culture within your organisation.', path:"/images/home/content-dropdown/Rectangle 187.png"  },
    { title: 'Incident Response Planning', content: 'Prepare for potential data breaches with our incident response planning. We develop tailored strategies to respond to security incidents swiftly, minimising their impact and ensuring a timely and effective resolution.', path:"/images/home/content-dropdown/Rectangle 188.png"  },
    { title: 'Continuous Monitoring', content: 'Our vigilant monitoring services offer real-time oversight of your data environment. Through continuous surveillance, we detect anomalies and potential threats, enabling proactive intervention to maintain data integrity and compliance.', path:"/images/home/content-dropdown/Rectangle 189.png"  },
    { title: ' Access Control Solutions', content: 'Implement robust access control measures to manage data accessibility. Our solutions ensure that only authorised personnel can access specific information, reducing the risk of internal breaches or unauthorised data manipulation.', path:"/images/home/content-dropdown/Rectangle 190.png"  },
    { title: 'Compliance Audits', content: 'Validate your adherence to data protection regulations through our compliance audits. We conduct thorough assessments, identify gaps, and recommend corrective measures to ensure ongoing compliance and minimise legal risks.',  path:"/images/home/content-dropdown/Rectangle 191.png"  },
   
  ];










  return (
    <div className='protect-your-customers-data-page'>

<BannerSubPages
        imglink={"/images/protect-your-customers-data/Rectangle 195.png"}
        pageName={"protect-your-customers-data"}
        blueTitle={"Protect your Customers Data"}
        titleWeb={"Ensuring<br/> Security & Trust"}
        titleTab={"Ensuring<br/> Security & Trust"}
        titleMob={"Ensuring<br/> Security & Trust"}
        subTitleWeb={"Our bespoke solutions are your fortress<br/> against data vulnerabilities and<br/> breaches."}
        subTitleTab={"Our bespoke solutions are your fortress<br/> against data vulnerabilities and<br/> breaches."}
        subTitleMob={"Our bespoke solutions are your<br/> fortress against data vulnerabilities<br/> and breaches."}

        iconTopWeb={'67%'}
        iconLeftWeb={'77%'}
        iconTopTab={'65%'}
        iconLeftTab={'57%'}
        iconTopMob={'63%'}
        iconLeftMob={'60%'}
        iconRotationWeb={151} 
        iconRotationTab={169}
        iconRotationMob={170}>
      </BannerSubPages>

      {/* <GreyCardsSection
        pageName={'protect-your-customers-data'}
        headingWeb={"How We Ensure<br/> Next-Level Protection"}
        headingTab={"How We Ensure<br/> Next-Level Protection"}
        headingMob={"How We Ensure Next-Level Protection"}
        descriptionWeb={"ZOG Global offers unparalleled data protection services in the UK, ensuring your information remains secure and compliant. Here are six compelling reasons why our solutions stand out"}
        descriptionTab={"ZOG Global offers unparalleled data protection services in the UK, ensuring your information remains secure and compliant. Here are six compelling reasons why our solutions stand out"}
        descriptionMob={"ZOG Global offers unparalleled data protection services in the UK, ensuring your information remains secure and compliant. Here are six compelling reasons why our solutions stand out"}
        cardsData={sec2Data}

      /> */}


<div id="targetDiv"  className={`blueslider-update ${isToggled ? 'black-bg' : ''}`}>

  <div className='head montserrat-hard'>
  How We Ensure Next-Level Protection  </div>

  <p className='montserrat-lit'>ZOG Global offers unparalleled data protection services in the UK, ensuring your information remains secure and compliant. Here are six compelling reasons why our solutions stand out.</p>
  <div className='combine-btn'>
  <div className='arrow'>

    <img src="\images\protect-your-customers-data\1.svg" onClick={previous}/>
    <img src="\images\protect-your-customers-data\Frame (1).svg" onClick={next}/>

  </div>

  <div className="toggle-container">
          <input type="checkbox" id="toggleButton" className="toggle-checkbox" onChange={handleToggleChange} />
          <label htmlFor="toggleButton" className="toggle-label">
            <span className="toggle-button">
            <img src="\images\protect-your-customers-data\icons8-open-lock.svg" className='im1'/>
            <img src="\images\protect-your-customers-data\icons8-lock.svg" className='im2'/>
            </span>
          </label>
        </div>
        </div>



       
  <div className='slickblue-update montserrat-lit'>
  <div className="slider-container ">
      <Slider ref={sliderRef} {...settings}>
        <div>
         <div className='cardofblue-update'>
          <span>Tailored Compliance</span>
          <p>Tailoring data protection strategies ensures compliance with legal standards and industry norms, seamlessly integrating with operations while addressing organisational nuances.</p>
         </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Customised Solutions</span>
        <p>We personalise data protection with thorough assessments, crafting strategies to integrate seamlessly with your existing infrastructure for optimal security.</p>

        </div>
        </div>
        <div>
        <div className='cardofblue-update'>

        <span>Expert Guidance</span>
        <p>Collaborate with ZOG Global for expert data protection. Our team offers comprehensive support and valuable insights for informed decision-making from consultation to maintenance.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Tailored Compliance</span>
        <p>Tailoring data protection strategies ensures compliance with legal standards and industry norms, seamlessly integrating with operations while addressing organisational nuances.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Advanced Threat Detection</span>
        <p>We use advanced tech and algorithms for proactive threat detection, neutralising emerging risks in real-time and ensuring robust defence against cyber threats.</p>
        </div>
        </div>
        <div>
        <div className='cardofblue-update'>
        <span>Expert Guidance</span>
        <p>Tailoring data protection strategies ensures compliance with legal standards and industry norms, seamlessly integrating with operations while addressing organisational nuances.</p>
        </div>
        </div>
        
       
      </Slider>
    </div>
    

  </div>



</div>






      {/* <BlueNotchSection
        headingWeb={"Custom Data Protection Solutions<br/> Across Industries"}
        headingTab={"Custom Data Protection Solutions Across Industries"}
        headingMob={"Custom Data Protection Solutions Across Industries"}
        descriptionPresent={true}
        descriptionWeb={"Our adaptable data protection services cater to various industries, ensuring robust security measures aligned with sector-specific needs."}
        descriptionTab={"Our adaptable data protection services cater to various industries, ensuring robust security measures aligned with sector-specific needs."}
        descriptionMob={"Our adaptable data protection services cater to various industries, ensuring robust security measures aligned with sector-specific needs."}
        listData={sec3Data}

      /> */}



<div className='pink-card montserrat-lit'>

  <div className='head1'>
<div className='texthed'>Custom Data Protection Solutions Across Industries</div>
<p>Our adaptable data protection services cater to various industries, ensuring robust security measures aligned with sector-specific needs.</p>
</div>

<div className='head2'>
  <div className='each-sec'>
  <span>Public Sector</span>
  <p>Protect valuable commercial data with our tailored solutions. From financial institutions to retail, our services fortify data integrity, ensuring compliance and enhancing trust among customers, shareholders, and stakeholders. We provide advanced security measures, risk assessments, and customised strategies to shield against evolving threats, ensuring robust defence and operational continuity.</p>
  </div>

  <div className='each-sec'>
  <span>SMEs</span>
  <p>
Empower small and medium enterprises with robust data security. Our scalable solutions cater to SMEs' unique needs, providing cost-effective yet comprehensive protection, ensuring compliance, and fostering growth without compromising security. Our services include continuous monitoring, threat detection, and user-friendly interfaces, allowing SMEs to focus on growth while we handle their security.
</p>
  </div>

  <div className='each-sec'>
  <span>Multinationals</span>
  <p>
Secure vast multinational operations with our globally compliant strategies. We align data protection across borders, ensuring regulatory adherence while fortifying sensitive information, bolstering trust, and mitigating risks on a global scale. Our integrated solutions offer real-time monitoring, compliance management, and cross-border data protection, ensuring seamless security operations worldwide.
</p>
  </div>


</div>
</div>





<div className='ash-body'>
  <div className='main-head montserrat-hard'>
  Comprehensive Service Offerings
  </div>
<div className='sub-head'>
Our range of specialised services ensures holistic data security and compliance, safeguarding your valuable assets.
</div>
</div>


<div className='sec6-homepage'>
    <div className='maincontentdiv-sec6-home'>
      {sections.map((section, index) => (
        <div className='firstdiv-sec6' key={index}>
          <div className='title-sec6' onClick={() => handleSectionClick(index)}>
            <span className='montserrat-hard'>{section.title}</span>
            <img 
              src="/images/home/Vector (5).svg" 
              alt="Toggle Content" 
              className={activeSection === index ? 'rotate' : ''}
            />
          </div>
          <div className={`content-s6 ${activeSection === index ? 'show' : ''}`}>
            <p>{section.content}</p>
          </div>
        </div>
      ))}
    </div>
    <div className='forimage-sec6-home'>
        {activeSection !== null ? (
          <img 
            src={sections[activeSection].path} 
            className='sec6-img' 
            alt={sections[activeSection].title} 
          />
        ):
        <img src="\images\protect-your-customers-data\Rectangle 185.png" className='sec6-img'/>}
      </div>

    </div>















      {/* <AccordionSection
        headingWeb={"Comprehensive Service Offerings"}
        headingTab={"Comprehensive Service Offerings"}
        headingMob={"Comprehensive Service Offerings"}
        description={"Our range of specialised services ensures holistic data security and compliance, safeguarding your valuable assets."}

        accordionData={sec4Data} /> */}





      <BottomBanner
        pageName={"protect-your-customers-data"}
        titleWeb={"Secure<br/> Your Data Today"}
        titleTab={"Secure<br/> Your Data Today"}
        titleMob={"Secure<br/> Your Data Today"}
        subTitlePresent={true}
        subTitleWeb={"Ready to fortify your data security?<br/> Our experts are here to assist you."}
        subTitleTab={"Ready to fortify your data security?<br/> Our experts are here to assist you."}
        subTitleMob={"Ready to fortify your data security?<br/> Our experts are here to assist you."}

        buttonText={"SECURE YOUR DATA TODAY"}
        buttonTheme={"light"}
        buttonLink={"/contact-us"}
        textTheme={"light"}
      >
      </BottomBanner>


    

  <BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>




      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default ProtectYourCustomersData