// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faq-contact-page .accordion {
  border: none;
}
.faq-contact-page .accordion__button {
  background-color: rgba(224, 90, 103, 0.07) !important;
  color: #2c2c2c !important;
  font-size: 14px;
  font-weight: 500;
}
.faq-contact-page .accordion__button:before {
  height: 7px !important;
  width: 7px !important;
  margin-right: 24px;
}
.faq-contact-page .main {
  font-size: 120%;
  color: red;
}
.faq-contact-page #faq {
  display: block;
}
.faq-contact-page #contactus {
  display: none;
}
.faq-contact-page .tab-btn {
  font-family: Lato;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #191B2D;
  cursor: pointer;
  border-bottom: 5px solid #D9D9D9;
}
.faq-contact-page .tab-btn:hover, .faq-contact-page .tab-btn.active {
  font-family: Lato;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  color: #E05A67;
  border-bottom: 5px solid #E05A67;
}`, "",{"version":3,"sources":["webpack://./src/user-panel/pages/FAQContact/FAQContact.scss"],"names":[],"mappings":"AAMA;EACI,YAAA;AALJ;AAQA;EACI,qDAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;AANJ;AASA;EACI,sBAAA;EACA,qBAAA;EACA,kBAAA;AAPJ;AAUA;EACI,eAAA;EACA,UAAA;AARJ;AAWE;EACE,cAAA;AATJ;AAYE;EACE,aAAA;AAVJ;AAaE;EACE,iBAAA;EACJ,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EACA,eAAA;EACA,gCAAA;AAXA;AAgBE;EACE,iBAAA;EACJ,gBAAA;EACA,mBAAA;EACA,gBAAA;EACA,cAAA;EAEA,gCAAA;AAfA","sourcesContent":[".faq-contact-page\r\n{\r\n\r\n   .banner-section{\r\n\r\n}\r\n.accordion {\r\n    border: none;\r\n} \r\n\r\n.accordion__button {\r\n    background-color:  rgba(224, 90, 103, 0.07) !important;\r\n    color: #2c2c2c !important;\r\n    font-size: 14px;\r\n    font-weight: 500;\r\n}\r\n\r\n.accordion__button:before {\r\n    height: 7px !important;\r\n    width: 7px !important;\r\n    margin-right: 24px;\r\n}\r\n\r\n.main {\r\n    font-size: 120%;\r\n    color: red;\r\n  }\r\n\r\n  #faq{\r\n    display: block;\r\n  }\r\n\r\n  #contactus{\r\n    display: none;\r\n  }\r\n\r\n  .tab-btn{\r\n    font-family: Lato;\r\nfont-weight: 600;\r\nletter-spacing: 0em;\r\ntext-align: left;\r\ncolor: #191B2D;\r\ncursor: pointer;\r\nborder-bottom: 5px solid #D9D9D9;\r\n;\r\n;\r\n\r\n  }\r\n  .tab-btn:hover,.tab-btn.active{\r\n    font-family: Lato;\r\nfont-weight: 600;\r\nletter-spacing: 0em;\r\ntext-align: left;\r\ncolor: #E05A67;\r\n\r\nborder-bottom: 5px solid #E05A67;\r\n\r\n  }\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
