import React,{ useEffect, useState, useContext, useRef } from 'react'
import './DigitalEngineering.scss'
import Banner from '../../common-component/BannerMainPages/BannerMainPages'
import BottomBanner from '../../common-component/BottomBanner/BottomBanner'
import SimpleSection from '../../common-component/SimpleSection/SimpleSection'
import ThreeCardsSection from '../../common-component/ThreeCardsSection/ThreeCardsSection'

import { useNavigate } from 'react-router-dom';

import ErrorModal from "../../../ErrorModal.js";
import PositiveModal from "../../../PositiveModal.js";
import FixedOverlayLoadingSpinner from "../../../FixedOverlayLoadingSpinner.js"
import $ from 'jquery';


import Slider from 'react-slick'
import WhiteBanner from '../../common-component/BannerMainPages/WhiteBanner.js'
import BlogNewSection from '../../common-component/BlogNewSection/BlogNewSection.js'


const View = () => {
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const sec4Data = [
    {
      title: "Cybersecurity",
      description: "Safeguard your digital assets with cutting-edge measures against cyber threats." ,
      link: "/cyber-security",
    } ,

    {
      title: "Software Development" ,
      description: "Craft innovative, high-quality software solutions for diverse business needs.",
      link: "/software-development",
    } ,

    {
      title: "Automation" ,
      description: "Boost efficiency and reduce manual efforts with streamlined,automated processes.",
      link: "/automation",
    } ,
    {
      title: "Cloud",
      description: "Harness the power of cloud computing for scalable and flexible solutions.",
      link: "/cloud",
    },

    {
      title: "DevOps",
      description: "Merge development and operations for faster, more reliable software delivery.",
      link: "/devops",
    } ,

    {
      title: "DevSecOps ",
      description: "Integrate security seamlessly into the development process for robust apps.",
      link: "/devsecops",
    },
    {
      title: "Advanced Networking Services",
      description: "Transform connectivity with SD-WAN and SDN for agile, scalable networks.",
      link: "/ans",
    },

    {
      title: "Testing",
      description: "Ensure software reliability through expert testing for optimal performance.",
      link: "/testing",
    },

    {
      title: "Unified Communication",
      description: "Seamlessly integrate communication tools for efficient and collaborative workflows.",
      link: "/unified-communication",
    },


  ]


  useEffect(() => {
    $(function () {
      $(window).scrollTop(0);
    });
  }, [])


 
  const sec4SlideSettings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 1000,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,

        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,

        }
      }
    ]
  };

  

  const sec2ref=useRef(null);
 

  useEffect(() => {

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('in-div');
        } else {
          entry.target.classList.remove('in-div');
        }
      },
      {
        threshold: 0.1,
      }
    );
    const elementsToObserve = [
      sec2ref.current,
     
    ];

    elementsToObserve.forEach((element) => {
      if (element) observer.observe(element);
    });

    return () => {
      elementsToObserve.forEach((element) => {
        if (element) observer.unobserve(element);
      });
    };
  }, []);


  const sliderRef=useRef(null)
const sliderRef2=useRef(null)
  const next = () => {
    sliderRef.current.slickNext();
};

const previous = () => {
    sliderRef.current.slickPrev();
};
const next2 = () => {
  sliderRef2.current.slickNext();
};

const previous2 = () => {
  sliderRef2.current.slickPrev();
};


const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 3000,
  cssEase: "linear",
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 1033,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
       
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed:500,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        infinite: true,
        dots: true,
        centerMode: true,
        centerPadding: '0px',
      },
    },
  ]
  
};










  return (
    <div className='digital-engineering-page'>

      <WhiteBanner
      imglink={"/images/digital-engineering/Rectangle 195.png"}
        pageName={"digital-engineering"}
        title1Web={"Digital Engineering"}
        title2Tab={"Digital Engineering"}
        title3Mob={"Digital Engineering"}
        titleWeb={"Transforming Ideas into<br/> <span>Digital Excellence<span>"}
        titleTab={"Transforming Ideas<br/> into Digital Excellence"}
        titleMob={"Transforming<br/> Ideas into Digital<br/> Excellence"}
        subTitleWeb={"Get reliable IT support services for your small to medium-sized business in the UK."}
        subTitleTab={"Get reliable IT support services for your small to<br/> medium-sized business in the UK."}
        subTitleMob={"Get reliable IT support services for<br/> your small to medium-sized<br/> business in the UK."}>
      </WhiteBanner>


    <div ref={sec2ref} className='sec2-digital'>
      <div className='for-img'>
        <img src="\images\digital-engineering\Banner2.png" className='img-sec2-digital'/>
      </div>
      <div className='for-text montserrat-lit'>
        <p>Business growth is a multifaceted process that goes beyond simply outpacing competitors. It requires not only staying ahead in the market but also developing the resilience and capabilities to sustain that success over the long term. With our help, you can transform your IT infrastructure through a combination of tailored services, expert proficiency, and strategic insights.</p>

      </div>

    </div>




    <div className='sec3-digital '>
      <p className='title montserrat-hard'>
      Why Choose Our IT Services
      </p>

      <div className='hr2'>
            <div className='hr3'></div>
            <div className='hr4'></div>
            <div className='hr5'></div>
            </div>



      <div className='cards-digital'>
<div className='cards'>
  <p className='tl montserrat-hard'>Grow<br/> Your Business</p>
  <p className='montserrat-lit'>Elevate success with tailored IT solutions for exponential business growth.</p>
</div>
<div className='cards'>
  <p className='tl montserrat-hard'>Empower<br/> Your People</p>
  <p className='montserrat-lit'>Boost productivity and morale through empowering IT support for your team.</p>
</div>
<div className='cards'>
  <p  className=' tl montserrat-hard'>Save<br/> Your Budget</p>
  <p className='montserrat-lit'>Optimise costs with efficient IT services, ensuring a budget-friendly approach.</p>
</div>


      </div>
      

    </div>







    <div className='sec4-digital'>
      <div className='div12'>
        <div className='div1'>
        <img src="\images\digital-engineering\Intersect.svg" className='imgsr'/>
        </div>

        <div className='div2'>
        <div className='hr2'></div>
        </div>
       
      </div>


    </div>

<div className='sec5-digital'>

    <div className="section2-homepage">
       <img
          src="\images\digital-engineering\bg-triangle.png"
          className="trianglelogo-homepage"
        />

        <div className="slider-container montserrat-lit">
         
         
          <img src="\images\home\Frame (2).svg" className='arrow2-home' onClick={previous}/>
          <img src="\images\home\Frame (1).svg" className='arrow1-home' onClick={next}/>
          <Slider ref={sliderRef} {...settings}>
            <div>
            <div className='hm1'>
              <div
                className="sliderimg"
                >
                  <img src='/images/home/Frame 322.png' className='imo'/>
                Software development
                <p>Safeguard your digital assets with cutting-edge measures against cyber threats.</p>

              </div>
            </div>
            </div>

            <div>
            <div className='hm1'>
              <div
                className="sliderimg"
                >
                   <img src='/images/home/Frame 327.png' className='imo'/>
                Cloud Solutions
                <p>Safeguard your digital assets with cutting-edge measures against cyber threats.</p>

              </div>
            </div>
            </div>

            
        <div>
        <div className='hm1'>
              <div
                className="sliderimg"
                >
                   <img src='/images/home/Frame 328.png' className='imo'/>
                Automation
                <p>Safeguard your digital assets with cutting-edge measures against cyber threats.</p>

              </div>
            </div>
        </div>
            
        <div>
        <div className='hm1'>
              <div
                className="sliderimg"
                >
                   <img src='/images/home/Frame 325.png' className='imo'/>
                Cybersecurity
                <p>Safeguard your digital assets with cutting-edge measures against cyber threats.</p>
              </div>
            </div>

        </div>
          

           <div>
            <div className='hm1'>
              <div
                className="sliderimg"
                >
                   <img src='/images/home/Frame 329.png' className='imo'/>
                Software Testing
                <p>Safeguard your digital assets with cutting-edge measures against cyber threats.</p>

              </div>
            </div>
            </div>

            <div>
            <div className='hm1'>
              <div
                className="sliderimg"
               >
                   <img src="\images\home\cybersec\Rectangle 187 (6).png" className='imo'/>

                Business Analysis
                <p>Safeguard your digital assets with cutting-edge measures against cyber threats.</p>

              </div>
            </div>
            </div>

            
          </Slider>
        </div>
      </div>

      </div>




<BlogNewSection pagename={"generic&section_number=1"}></BlogNewSection>




    <div className='sec7-digital'>
      <div className='for-text  '>
        <p className='montserrat-hard'>Get a Free<br/> Consultation</p>
        <button type="button" onClick={() => navigate("/contact-us")}>Book a meeting</button>
      </div>
      <div className='for-img'>

        <img src="\images\digital-engineering\Banner2 (1).png" />
      </div>

    </div>























      {/* <SimpleSection
        pageName={"digital-engineering"}
        secNo={"2"}
        headdingPresent={false}
        buttonPresent={false}
        imagePosition={"left"}
        headingWeb={""}
        headingTab={""}
        headingMob={""}
        description={"Business growth is a multifaceted process that goes beyond simply outpacing competitors. It requires not only staying ahead in the market but also developing the resilience and capabilities to sustain that success over the long term. With our help, you can transform your IT infrastructure through a combination of tailored services, expert proficiency, and strategic insights."}
        buttonText={""}
        />


<ThreeCardsSection
        pageName={'digital-engineering'}
        secNo={'3'}
        cardHeight={405}
        headingWeb={"Why Choose Our IT Services?"}
        headingTab={"Why Choose Our IT Services?"}
        headingMob={"Why Choose Our<br/> IT Services?"}
        descriptionWeb={"We redefine success. Choose innovation; choose us. At ZOG Global, excellence is not<br/> an option; it's a promise."}
        descriptionTab={"We redefine success. Choose innovation; choose us. At ZOG Global, excellence is not an option; it's a promise."}
        descriptionMob={"We redefine success. Choose innovation; choose us. At ZOG Global, excellence is not an option; it's a promise."}


        subHeading1={"Grow<br/> Your Business"}
        description1={"Elevate success with tailored IT solutions for exponential business growth."}
        subHeading2={"Empower<br/> Your People"}
        description2={"Boost productivity and morale through empowering IT support for your team."}
        subHeading3={"Save<br/> Your Budget"}
        description3={"Optimise costs with efficient IT services, ensuring a budget-friendly approach."}
      />


      <div className='sec-4'>
        <h1>Digital Engineering<br/> Offerings</h1>

        <Slider {...sec4SlideSettings}>

          {sec4Data.map((item, index) => {
            return (
              <div className='card' key={index}>
                <div className='inner' style={{ backgroundImage: `url("/images/digital-engineering/sec-4-${index + 1}.png")` }}>
                  <div className='title'>{item.title}</div>
                  <div className='description'>{item.description}</div>

                  <button onClick={() => navigate(`${item.link}`)}>Learn More <img src='/images/industries/sec-4-arrow.svg'></img></button>
                </div>
              </div>
            )
          })}


        </Slider>

      </div>






      <BottomBanner
        pageName={"digital-engineering"}
        titleWeb={"Get a Free<br/> Consultation"}
        titleTab={"Get a Free<br/> Consultation"}
        titleMob={"Get a Free<br/> Consultation"}
        subTitleWeb={"Get reliable IT support services for your small to<br/> medium-sized business in the UK."}
        subTitleTab={"Get reliable IT support services for your small to<br/> medium-sized business in the UK."}
        subTitleMob={"Get reliable IT support services for<br/> your small to medium-sized<br/> business in the UK."}
        buttonText={"BOOK A MEETING"}
        buttonTheme={"dark"}
        textTheme={"dark"}
        buttonLink={'/contact-us'}
      >
      </BottomBanner> */}






      {isLoading && <FixedOverlayLoadingSpinner />}
      <ErrorModal state={isErrorModalOpen} message={message} setterFunction={setIsErrorModalOpen} okClickedFunction={() => navigate("/")} />
      {isMessageModalOpen && <PositiveModal message={message} setterFunction={setIsMessageModalOpen} okClickedFunction={() => { }} />}

    </div>

  );


}

export default View