// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-container .address-form .sec2 {
  margin-top: 10px;
  width: 100%;
  display: flex;
  gap: 10px;
}
.address-container .address-form .sec2 .text-input {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/admin-panel/common-components/AddressInput/AddressInput.scss"],"names":[],"mappings":"AAEQ;EACI,gBAAA;EACA,WAAA;EACA,aAAA;EACA,SAAA;AADZ;AAEY;EACI,mBAAA;AAAhB","sourcesContent":[".address-container{\r\n    .address-form{\r\n        .sec2{\r\n            margin-top: 10px;\r\n            width: 100%;\r\n            display: flex;\r\n            gap: 10px;\r\n            .text-input{\r\n                margin-bottom: 10px;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
